class PatientNoteFormData {
  constructor() {
    this.reset();
  }

  reset() {
    this.id = null;
    this.noteType = { text: '', value: '' };
    this.noteContent = '';
    this.interventionDate = null;
    this.interventionStartTime = null;
    this.interventionEndTime = null;
    this.interventionTime = null;
    this.createdAt = null;
    this.createdBy = null;
  }

  setData(data) {
    this.id = data.id;
    this.noteType = data.noteType;
    this.noteContent = data.noteContent;
    this.interventionDate = data.interventionDate;
    this.interventionStartTime = data.interventionStartTime;
    this.interventionEndTime = data.interventionEndTime;
    this.interventionTime = data.interventionTime;
    this.createdAt = data.createdAt;
    this.createdBy = data.createdBy;
  }
}

export { PatientNoteFormData };

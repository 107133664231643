import api from './baseApiService';

class NotificationService {
  async getNotifications(limit, offset, patientId = null) {
    const params = { limit, offset, patientId };
    return await api.get('notifications', params);
  }

  async getNotificationById(notificationId) {
    return await api.get(`notification/${notificationId}`);
  }

  async getUnreadNotificationCount() {
    return await api.get('notifications/unread_count');
  }

  async updateNotificationReadStatus(notificationId, data) {
    return await api.put(`notification/${notificationId}/read_status`, data);
  }

  async markAllNotificationsAsRead() {
    return await api.post('notifications/mark_all_as_read');
  }

  async markAllNotificationsOfPatientAsRead(patientId) {
    return await api.post(`notifications/patient/${patientId}/mark_all_as_read`);
  }

  async deleteAllNotifications() {
    return await api.delete('notifications/delete_all');
  }

  async deleteAllNotificationsOfPatient(patientId) {
    return await api.delete(`notifications/patient/${patientId}/delete_all`);
  }
}

export default new NotificationService();

<template>
  <div>
    <WaitModal :show="showWaitModal" />
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <div
      v-if="!showWaitModal && callbackRequests && callbackRequests.length === 0"
      ref="noCallbackRequest"
      class="ml-6 font-weight-bold black--text"
    >
      {{ $t('callbackRequest.noCallbackRequest') }}
    </div>

    <div v-else-if="!showWaitModal">
      <v-card-text v-for="(request, requestIndex) in callbackRequests" ref="callbackRequests" :key="requestIndex">
        <div class="d-flex justify-space-between align-center mt-1">
          <div class="d-flex align-center">
            <span class="font-weight-black text-body-1 black--text">
              {{ `${request.patient.lastName}, ${request.patient.firstName}` }}
            </span>

            <v-btn x-small class="ml-3" icon @click="request.showPatientDetails = !request.showPatientDetails">
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </div>

          <div v-if="request.takenCare" ref="takeCareAt" class="text-body-2 black--text">
            {{ getFormattedTime(request.takenCare.at) }}
          </div>

          <v-btn
            v-else
            ref="takeCare"
            :disabled="request.disableRequestButton"
            color="primary"
            @click="updateCallbackRequest(request, callbackRequestTypes.takeCare)"
            >{{ $t('callbackRequest.takeCare') }}</v-btn
          >
        </div>

        <div class="d-flex align-start justify-space-between mt-7">
          <div class="d-flex align-start">
            <v-icon>mdi-clock-outline</v-icon>

            <div class="ml-5">
              <div class="text-body-1 font-weight-medium black--text">
                {{ getFormatRequestedAt(request.requestedAt) }}
              </div>
              <div class="text--secondary">
                {{ getFormatRequestedAtDetails(request.requestedAt) }}
              </div>
            </div>
          </div>

          <v-btn
            v-if="request.takenCare"
            ref="resolveRequest"
            class="elevation-0 font-weight-bold"
            :disabled="request.disableRequestButton"
            @click="updateCallbackRequest(request, callbackRequestTypes.resolve)"
          >
            {{ $t('callbackRequest.resolve') }}
          </v-btn>
        </div>

        <v-divider class="mt-5"></v-divider>

        <div v-if="request.takenCare && request.userHealthWorkerId === request.takenCare.healthWorkerId" class="mt-5">
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex align-start">
              <v-icon>mdi-video</v-icon>

              <div class="d-flex align-center ml-5">
                <div>
                  <div class="text-body-1 font-weight-medium black--text">{{ $t('patientVideoCall') }}</div>
                  <div class="text--secondary black--text">{{ $t('callbackRequest.virtuoseVirtualAssistant') }}</div>
                </div>
              </div>
            </div>

            <v-btn
              ref="videoCallButton"
              class="elevation-0 font-weight-bold"
              color="primary"
              :disabled="request.disableRequestButton"
              @click="videoCallPatient(request, request.patient.id)"
            >
              {{ $t('callbackRequest.contact') }}
            </v-btn>
          </div>

          <div class="d-flex justify-space-between align-center mt-7">
            <div class="d-flex align-start">
              <v-icon>mdi-phone</v-icon>

              <div class="d-flex align-center ml-5">
                <div>
                  <div class="text-body-1 font-weight-medium black--text">{{ request.patient.phoneNumber }}</div>
                  <div class="text--secondary">{{ $t('callbackRequest.homePhone') }}</div>
                </div>
              </div>
            </div>

            <v-btn
              ref="phoneCallButton"
              :disabled="request.disableRequestButton"
              class="elevation-0 font-weight-bold"
              :href="`tel:+${getFormatPhoneNumber(request.patient.phoneNumber)}`"
            >
              {{ $t('callbackRequest.contact') }}
            </v-btn>
          </div>

          <v-divider class="mt-5"></v-divider>
        </div>

        <div v-if="request.showPatientDetails" class="mt-5">
          <div class="d-flex align-start">
            <v-icon>mdi-map-marker-outline</v-icon>

            <div class="ml-5">
              <div class="text-body-1 font-weight-medium black--text">{{ request.patient.address }}</div>
              <div class="text--secondary">{{ request.patient.city }}</div>
            </div>
          </div>

          <div class="d-flex align-start mt-7">
            <v-icon>mdi-stethoscope</v-icon>

            <div class="ml-5">
              <div>
                <div
                  v-for="(healthWorker, healthWorkerIndex) in request.associatedHealthWorkers"
                  :key="healthWorkerIndex"
                >
                  <div class="text-body-1 font-weight-medium black--text">
                    {{ `${healthWorker.lastName}, ${healthWorker.firstName}` }}
                  </div>

                  <div class="text--secondary mb-5">{{ healthWorker.type }}</div>
                </div>
              </div>
            </div>
          </div>

          <v-divider></v-divider>
        </div>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import translationMixin from '@/translationMixin';
import callbackRequestService from '@/services/callbackRequestService';
import messageService from '@/services/messageService';
import videoCallService from '@/services/videoCallService';
import { format, differenceInWeeks, differenceInDays, differenceInMinutes } from 'date-fns';

export default {
  name: 'CallbackRequestList',
  mixins: [translationMixin],

  data() {
    return {
      error: null,
      showWaitModal: false,
      callbackRequests: [],
      callbackRequestTypes: {
        resolve: 'resolve',
        takeCare: 'takeCare',
      },
    };
  },

  created() {
    this.getCallbackRequests();
  },

  methods: {
    getCallbackRequests: async function () {
      this.showWaitModal = true;

      try {
        const requests = await callbackRequestService.getCallbackRequests();
        requests.forEach((request) => {
          request.showPatientDetails = false;
          request.disableRequestButton = false;

          request.associatedHealthWorkers = request.associatedHealthWorkers.sort((a, b) =>
            a.lastName.localeCompare(b.lastName)
          );
        });

        this.callbackRequests = requests;
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    getFormatPhoneNumber: function (phoneNumber) {
      return phoneNumber.replace(/[^0-9]/g, '');
    },

    isCallbackRequestFromToday: function (requestedAt) {
      const requestedAtDate = format(new Date(requestedAt), 'yyyy-MM-dd');
      const todayDate = format(new Date(), 'yyyy-MM-dd');

      return requestedAtDate === todayDate;
    },

    getFormatTime: function (dateTime) {
      return format(new Date(dateTime), this.getLanguage() === 'fr' ? 'HH:mm' : 'hh:mm b');
    },

    getFormatRequestedAt: function (requestedAt) {
      if (this.isCallbackRequestFromToday(requestedAt)) {
        return this.getFormatTime(requestedAt);
      }

      const weeksDifference = differenceInWeeks(new Date(), new Date(requestedAt));

      if (weeksDifference === 0) {
        const nameDay = new Date(requestedAt).toLocaleDateString(this.getLanguage(), { weekday: 'long' });
        return nameDay.charAt(0).toUpperCase() + nameDay.slice(1);
      }

      return format(new Date(requestedAt), 'yyyy-MM-dd');
    },

    getFormatRequestedAtDetails: function (requestedAt) {
      const minutesDifference = differenceInMinutes(new Date(), new Date(requestedAt));

      if (minutesDifference === 0) {
        return this.$t('callbackRequest.justNow');
      }

      const requestAtDetails = this.getRequestAtDetails(minutesDifference);

      return this.$t('callbackRequest.timeAgo')
        .replace('{timeValue}', requestAtDetails.timeDifference)
        .replace('{timeUnit}', requestAtDetails.timeUnit.toLowerCase());
    },

    getFormattedTime: function (takenCareAt) {
      const takenCareDate = this.isCallbackRequestFromToday(takenCareAt)
        ? this.$t('today').toLowerCase()
        : this.getFormatRequestedAt(takenCareAt);

      const takenCareTime = this.getFormatTime(takenCareAt);

      const isDateOlderThanOneWeek = differenceInDays(new Date(), new Date(takenCareAt)) >= 7;

      const dateText = isDateOlderThanOneWeek
        ? `${this.$t('callbackRequest.on')} ${takenCareDate.toLowerCase()}`
        : takenCareDate.toLowerCase();

      return this.$t('callbackRequest.takenCareAt').replace('{{date}}', dateText).replace('{{time}}', takenCareTime);
    },

    updateCallbackRequest: async function (callbackRequest, requestType) {
      callbackRequest.disableRequestButton = true;

      const data = {
        requestType: requestType,
      };

      this.showWaitModal = true;

      try {
        const takenCareRequest = await callbackRequestService.updateCallbackRequest(callbackRequest.id, data);

        if (requestType === this.callbackRequestTypes.resolve) {
          this.callbackRequests = this.callbackRequests.filter((x) => x.id !== callbackRequest.id);

          if (this.callbackRequests.length === 0) {
            this.$emit('closeCallbackRequestsNavigation', false);
          }
        } else if (requestType === this.callbackRequestTypes.takeCare) {
          callbackRequest.takenCare = takenCareRequest;
        }
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;

      callbackRequest.disableRequestButton = false;
    },

    videoCallPatient: async function (callbackRequest, patientId) {
      callbackRequest.disableRequestButton = true;

      const chatData = {
        subject: this.$t('callbackRequest.singularName'),
        chatType: 'callbackRequest',
        patientId: patientId,
        participants: {
          includePatient: true,
        },
      };

      this.showWaitModal = true;

      try {
        const chatId = await messageService.createConversation(chatData);

        const videoCallData = {
          patientId: patientId,
          conversationId: chatId,
        };

        const videoCall = await videoCallService.createVideoCall(videoCallData);

        this.$router.push({
          name: 'PatientVideoCall',
          params: { patientId: patientId, callId: videoCall.id, conversationId: chatId },
        });

        this.$emit('closeCallbackRequestsNavigation');
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;

      callbackRequest.disableRequestButton = false;
    },

    getRequestAtDetails: function (minutesDifference) {
      const MINUTES_IN_HOUR = 60;
      const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24;
      const MINUTES_IN_WEEK = MINUTES_IN_DAY * 7;
      const MINUTES_IN_MONTH = MINUTES_IN_DAY * 30;
      const MINUTES_IN_YEAR = MINUTES_IN_DAY * 365;

      let timeDifference, timeUnit;

      switch (true) {
        case minutesDifference < MINUTES_IN_HOUR:
          timeDifference = minutesDifference;
          timeUnit = timeDifference === 1 ? this.$t('minute') : this.$t('minutes');
          break;

        case minutesDifference < MINUTES_IN_DAY:
          timeDifference = Math.floor(minutesDifference / MINUTES_IN_HOUR);
          timeUnit = timeDifference === 1 ? this.$t('hour') : this.$t('hours').toLowerCase();
          break;

        case minutesDifference < MINUTES_IN_WEEK:
          timeDifference = Math.floor(minutesDifference / MINUTES_IN_DAY);
          timeUnit = timeDifference === 1 ? this.$t('day') : this.$t('days').toLowerCase();
          break;

        case minutesDifference < MINUTES_IN_MONTH:
          timeDifference = Math.floor(minutesDifference / MINUTES_IN_WEEK);
          timeUnit = timeDifference === 1 ? this.$t('week') : this.$t('weeks');
          break;

        case minutesDifference < MINUTES_IN_YEAR:
          timeDifference = Math.floor(minutesDifference / MINUTES_IN_MONTH);
          timeUnit = timeDifference === 1 ? this.$t('month') : this.$t('months');
          break;

        default:
          timeDifference = Math.floor(minutesDifference / MINUTES_IN_YEAR);
          timeUnit = timeDifference === 1 ? this.$t('year') : this.$t('years');
      }

      return {
        timeDifference,
        timeUnit,
      };
    },
  },
};
</script>

<template>
  <div>
    <v-row v-if="patientAlerts.length > 0">
      <v-col
        v-for="(alert, alertIndex) in patientAlerts"
        :key="alertIndex"
        :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
      >
        <v-card class="pa-2">
          <MonitoringAlertRealtimeContentHeader
            class="py-2 px-4"
            :patient-data="alert"
            :status-levels-for-activity-type="statusLevelsForActivityType"
            :title="title"
            @resolveAlert="resolvePatientMonitoringAlert"
            @viewMonitoringDetail="viewPatientMonitoringDetail"
          />

          <MonitoringAlertRealtimeContentDetails
            class="pa-0 pr-3 pl-4 pb-2"
            :patient-data="alert"
            @viewMonitoringDetail="viewPatientMonitoringDetail"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col class="mt-2 text--secondary text-body-2">{{ $t('patientMonitoringAlert.noInformation') }}</v-col>
    </v-row>
  </div>
</template>

<script>
import MonitoringAlertRealtimeContentDetails from './MonitoringAlertRealtimeContentDetails.vue';
import MonitoringAlertRealtimeContentHeader from './MonitoringAlertRealtimeContentHeader.vue';

import patientMonitoringAlertService from '@/services/patientMonitoringAlertService';
import translationMixin from '@/translationMixin';

export default {
  name: 'PatientMonitoringAlertContentRealtime',
  components: { MonitoringAlertRealtimeContentDetails, MonitoringAlertRealtimeContentHeader },
  mixins: [translationMixin],

  props: {
    patientAlerts: {
      type: Array,
      required: true,
    },

    statusLevelsForActivityType: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },
  },

  methods: {
    viewPatientMonitoringDetail: function (patientId, activityId) {
      this.$router.push({
        name: 'PatientMonitoringGrid',
        params: { patientId: patientId, detailType: String(activityId) },
      });
    },

    resolvePatientMonitoringAlert: async function (patientId, patientMonitoringAlertIds) {
      await patientMonitoringAlertService.resolveMonitoringAlert(patientId, {
        resolved: true,
        patientMonitoringAlertIds: patientMonitoringAlertIds,
      });
    },
  },
};
</script>

class HealthInfoFormData {
  constructor() {
    this.reset();
  }

  reset() {
    this.medicalConditions = null;
    this.allergies = null;
    this.other = null;
  }

  setData(conditions) {
    if (!conditions) {
      this.reset();
      return;
    }
    this.medicalConditions = conditions.medicalConditions ? conditions.medicalConditions : null;
    this.allergies = conditions.allergies ? conditions.allergies : null;
    this.other = conditions.other ? conditions.other : null;
  }
}

export { HealthInfoFormData };

<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent :max-width="dialogMaxWidth">
      <v-card>
        <ModalTitle :title="title" @btn-clicked="closeDialog(false)" />

        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <v-form ref="protocolModalForm">
            <v-row>
              <v-col cols="12" :md="protocolId != null ? 6 : 12">
                <v-text-field
                  ref="protocolName"
                  v-model="formData.name"
                  filled
                  :label="$t('name')"
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-textarea
                  ref="description"
                  v-model="formData.description"
                  height="135"
                  :label="$t('description')"
                  filled
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-row>
                  <!-- <v-col cols="auto">
                  <v-checkbox ref="checkActive" v-model="formData.active" :label="$t('active')" />
                </v-col> -->
                  <v-col cols="auto">
                    <v-checkbox
                      ref="checkNormative"
                      v-model="formData.normative"
                      :label="$t('normative')"
                      :disabled="!$can(permissions.actions.manage, permissions.subjects.normative)"
                    />
                  </v-col>
                </v-row>

                <RequiredFieldsLegend />
              </v-col>

              <v-col v-if="protocolId !== null" :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'">
                <detail-field
                  v-if="protocolId !== null"
                  :id="getIdByName('createdDate')"
                  ref="createdDate"
                  v-model="formData.createdDate"
                  :label="$t('createdDate')"
                />

                <detail-field
                  v-if="protocolId !== null"
                  :id="getIdByName('createdBy')"
                  ref="createdBy"
                  v-model="formData.createdBy"
                  :label="$t('createdBy')"
                />

                <detail-field
                  v-if="protocolId !== null"
                  :id="getIdByName('modifiedDate')"
                  ref="modifiedDate"
                  v-model="formData.modifiedDate"
                  :label="$t('modifiedDate')"
                />

                <detail-field
                  v-if="protocolId !== null"
                  :id="getIdByName('modifiedBy')"
                  ref="modifiedBy"
                  v-model="formData.modifiedBy"
                  :label="$t('modifiedBy')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="createOrUpdateAction"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import permissionMixin from '@/auth/permissionMixin';
import validationRulesMixin from '@/validationRulesMixin';
import virtuoseMixin from '@/virtuoseMixin';
import SaveButton from '@/components/SaveButton.vue';

export default {
  name: 'ProtocolModal',

  components: { SaveButton },
  mixins: [translation, accessibility, virtuoseMixin, validationRulesMixin, permissionMixin],

  props: {
    protocolId: {
      type: Number,
      required: false,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      title: '',
      formData: {
        name: '',
        description: '',
        normative: false,
        active: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
      },
      originalFormData: {},
    };
  },
  computed: {
    dialogMaxWidth: function () {
      return this.protocolId !== null ? '1200px' : '800px';
    },
  },

  watch: {
    show: function () {
      if (this.show) {
        if (this.protocolId != null) {
          this.editClicked();
        } else {
          this.init();
        }
      }
    },
  },
  methods: {
    init: function () {
      this.title = this.$t('createNewProtocol');
      this.formData = {
        name: '',
        description: '',
        normative: false,
        active: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
      };
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
    },

    editClicked: function () {
      this.isLoading = true;
      this.title = this.$t('editProtocol');

      let successCallback = function (response) {
        this.showWaitModal = false;

        let protocol = response.data;
        this.formData.name = protocol.name;
        this.formData.description = protocol.description;
        this.formData.normative = protocol.normative;
        this.formData.active = protocol.active;
        this.formData.createdDate = protocol.createdAt;
        this.formData.createdBy =
          protocol.createdBy != null ? protocol.createdBy.firstName + ' ' + protocol.createdBy.lastName : '';
        this.formData.modifiedDate = protocol.updatedAt;
        this.formData.modifiedBy =
          protocol.updatedBy != null ? protocol.updatedBy.firstName + ' ' + protocol.updatedBy.lastName : '';
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
        this.isLoading = false;
      };

      this.getProtocolById(this.protocolId, successCallback, this.defaultErrorCallBack);
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
      this.isProcessing = false;
    },

    createOrUpdateAction: function () {
      let isValid = this.$refs.protocolModalForm.validate();

      if (!isValid || this.isProcessing) return;
      this.showWaitModal = true;
      this.isProcessing = true;

      var successCallback = function () {
        this.closeDialog();
        this.$emit('refresh');
        this.showWaitModal = false;
        this.isProcessing = false;
      };

      var data = {
        name: this.formData.name,
        description: this.formData.description,
        active: this.formData.active,
        normative: this.formData.normative,
      };

      if (this.protocolId == null) {
        this.createProtocol(data, successCallback, this.defaultErrorCallBack);
      } else {
        this.updateProtocol(this.protocolId, data, successCallback, this.defaultErrorCallBack);
      }
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>

<style scoped>
.protocol-tabs.v-tabs-items {
  overflow: visible;
}
</style>

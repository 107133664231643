<template>
  <ConfirmationModal
    ref="confModal"
    :question="$t('confirmationQuestion')"
    :show="showMessage"
    :title="$t('confirmationIgnore')"
    @cancel="showMessage = false"
    @ok="doIgnore"
  />
</template>

<script>
import translation from '@/translationMixin';
import ConfirmationModal from '@/components/ConfirmationModal.vue';

export default {
  name: 'ModificationWarnModal',
  components: {
    ConfirmationModal,
  },
  mixins: [translation],
  props: {
    hasModifications: {
      type: Function,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      showMessage: false,
    };
  },
  mounted: function () {
    if (this.$router) {
      this.unsubscribeRouter = this.$router.beforeEach((to, from, next) => {
        this.handleRoute(to, from, next);
      });
    }
  },
  destroyed() {
    if (this.unsubscribeRouter) {
      this.unsubscribeRouter();
    }
  },
  methods: {
    handleRoute(_to, _from, next) {
      if (this.hasModifications()) {
        this.showMessage = true;
        this.ignoreCallback = () => next();
        next(false);
      } else {
        next();
      }
    },
    doIgnore() {
      if (this.ignoreCallback) {
        this.showMessage = false;
        this.ignoreCallback();
      }
    },
  },
};
</script>

<style></style>

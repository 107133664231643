<template>
  <div>
    <div v-if="callbackRequestsCount > 0" ref="callbackRequestBtn">
      <v-toolbar v-if="$vuetify.breakpoint.smAndUp" absolute class="abs-right mt-3" elevation="8">
        <v-btn
          ref="openCbrNavigationBtn"
          :text="!isAnyRequestNotTakenCare"
          color="error"
          @click="openCallbackRequestsNavigation"
        >
          <v-icon>mdi-phone-return</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp" class="ml-2">{{ $t('callbackRequest.pluralName') }}</span>

          <CallbackRequestBadge :callback-requests-count="callbackRequestsCount" :offset-y="1" />
        </v-btn>
      </v-toolbar>

      <v-btn v-else ref="openCbrNavigationBtn" icon @click="openCallbackRequestsNavigation">
        <v-icon>mdi-phone-return</v-icon>

        <CallbackRequestBadge :callback-requests-count="callbackRequestsCount" :offset-y="-2" />
      </v-btn>
    </div>

    <CallbackRequestNavigation
      v-if="showCallbackRequests"
      ref="callbackRequestNavigation"
      @closeNavigationDrawer="closeCallbackRequestsNavigation"
    />
  </div>
</template>

<script>
import CallbackRequestBadge from './CallbackRequestBadge.vue';
import CallbackRequestNavigation from './CallbackRequestNavigation.vue';

import callbackRequestService from '@/services/callbackRequestService';

import callbackRequestEventBus from './callbackRequestEventBus';
import applicationEventBus from '@/applicationEventBus';

import translationMixin from '@/translationMixin';

export default {
  name: 'CallbackRequestButton',

  components: {
    CallbackRequestBadge,
    CallbackRequestNavigation,
  },

  mixins: [translationMixin],

  data() {
    return {
      timerToCheckCallbackRequests: null,
      timerToCheckFavicon: null,

      callbackRequestsCount: 0,
      isAnyRequestNotTakenCare: false,
      showCallbackRequests: false,

      faviconUrl: '/static/img/favicon.png',
      faviconAlertUrl: '/static/img/favicon_alert.png',
      notificationSoundUrl: '/static/sounds/notification.mp3',
    };
  },

  watch: {
    isAnyRequestNotTakenCare() {
      if (this.isAnyRequestNotTakenCare) {
        applicationEventBus.$emit('isAnyPriorityAlert', 'callbackRequest', true);
      } else {
        applicationEventBus.$emit('isAnyPriorityAlert', 'callbackRequest', false);
      }
    },
  },

  created() {
    callbackRequestEventBus.$on('openCallbackRequestsNavigation', () => {
      this.openCallbackRequestsNavigation();
    });
    this.init();
  },

  beforeDestroy() {
    this.stopCallbackRequestsTimer();
    callbackRequestEventBus.$off('openCallbackRequestsNavigation');
  },

  methods: {
    init: async function () {
      const callbackRequestFeature = await callbackRequestService.getCallbackRequestFeatureConfig();

      if (callbackRequestFeature) {
        await this.hasPendingCallbackRequest();

        this.startCallbackRequestsTimer();
      }
    },

    hasPendingCallbackRequest: async function () {
      try {
        const callbackRequestData = await callbackRequestService.getCallbackRequestCount();

        this.callbackRequestsCount = callbackRequestData?.count;
        this.isAnyRequestNotTakenCare = callbackRequestData?.isAnyRequestNotTakenCareOf;
      } catch (error) {
        // will be handled in the future using WebSockets
      }
    },

    closeCallbackRequestsNavigation: async function (isAnyCallbackRequest) {
      if (!isAnyCallbackRequest) {
        this.callbackRequestsCount = 0;
        applicationEventBus.$emit('isAnyPriorityAlert', 'callbackRequest', false);
      } else {
        this.showCallbackRequests = false;
        await this.hasPendingCallbackRequest();
      }
    },

    openCallbackRequestsNavigation: function () {
      this.showCallbackRequests = true;
    },

    startCallbackRequestsTimer: function () {
      this.timerToCheckCallbackRequests = setInterval(this.hasPendingCallbackRequest, 5000);
    },

    stopCallbackRequestsTimer: function () {
      clearInterval(this.timerToCheckCallbackRequests);
    },
  },
};
</script>

<style scoped>
.abs-right {
  right: 32px;
}
</style>

export default {
  methods: {
    resetOverriddenTriggerStatus(activity, triggerIndex) {
      let defaultTriggers = activity.defaultTriggers;
      let overriddenTriggers = activity.overriddenTriggers;

      overriddenTriggers = overriddenTriggers.map((trigger, index) => {
        if (index === triggerIndex) {
          const { ...rest } = defaultTriggers[index];
          return rest;
        } else {
          return trigger;
        }
      });

      const alertsMatch = activity.overriddenParameters?.alerts?.every((alert, index) => {
        return this.isAlertMatch(index);
      });

      const overridden = alertsMatch ? this.checkOverriddenTrigger(overriddenTriggers) : true;
      activity.overridden = overridden;

      activity.overriddenTriggers = overriddenTriggers;
    },

    updateTrigger(activity, triggerIndex) {
      let overriddenTriggers = activity.overriddenTriggers;
      const overriddenTrigger = !this.isTriggerMatch(triggerIndex);

      overriddenTriggers = overriddenTriggers.map((trigger, index) => {
        if (index === triggerIndex) {
          if (overriddenTrigger) {
            return {
              ...trigger,
              overriddenTrigger: overriddenTrigger && (trigger.repeatCount !== '' || trigger.repeatMinute !== ''),
            };
          } else {
            const { ...rest } = trigger;
            return rest;
          }
        } else {
          return trigger;
        }
      });

      activity.overriddenTriggers = overriddenTriggers;

      const overridden = this.checkOverriddenTrigger(activity.overriddenTriggers);
      activity.overridden = overridden;
    },
  },
};

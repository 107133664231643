<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent :max-width="dialogMaxWidth">
      <v-card>
        <ModalTitle :title="$t('protocolActivities')" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <div>
            <list-item-selector
              :first-data-grid-name="protocolId ? `protocol-${protocolId}-activities-assigned-grid` : null"
              :second-data-grid-name="protocolId ? `protocol-${protocolId}-activities-to-assign-grid` : null"
              :columns-assigned="columns"
              :columns-available="columns"
              :all-items="allActivities"
              :assigned-items.sync="formData.assignedActivities"
              :available-title="$t('availableActivity')"
              :assigned-title="$t('currentProtocolActivity')"
              :data-loading="showWaitModal"
              :show-expand="true"
            >
              <template #expanded-item="{ item }">
                <td
                  v-if="activityTypesMonitoredByVA.includes(item.activityTypeCode)"
                  class="pa-4"
                  :colspan="columns.length"
                >
                  <v-select
                    :value="getActivityFrequency(item.id)"
                    filled
                    :items="frequencies"
                    :label="$t('frequency')"
                    @change="addActivityFrequency(item.id, $event)"
                  >
                  </v-select>
                  <v-text-field
                    v-if="getActivityFrequency(item.id) === 'customized'"
                    ref="executionDays"
                    :value="getActivityExecutionDays(item.id)"
                    :label="$t('executionDays')"
                    filled
                    placeholder="2,4,6"
                    :hint="$t('executionDaysHint')"
                    class="required-indicator"
                    :rules="[validationRules.required]"
                    @change="addActivityExecutionDays(item.id, $event)"
                  ></v-text-field>
                </td>
              </template>

              <template #assignedItem.data-table-expand="{ expand, item, isExpanded }">
                <div v-if="activityTypesMonitoredByVA.includes(item.activityTypeCode)">
                  <v-icon
                    ref="iconItemExpanded"
                    class="v-data-table__expand-icon"
                    :class="{ 'v-data-table__expand-icon--active': isExpanded }"
                    @click="expand(!isExpanded)"
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </template>

              <template #availableItem.data-table-expand="{}"></template>
            </list-item-selector>
          </div>

          <v-row v-if="isProtocolNormative" class="justify-end">
            <v-col cols="auto">
              <div class="text-caption">{{ $t('onlyNormativeActivities') }}</div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="updateProtocolActivities"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import virtuoseMixin from '@/virtuoseMixin';
import validationRulesMixin from '@/validationRulesMixin';
import ListItemSelector from '@/components/ListItemsSelector.vue';
import SaveButton from '@/components/SaveButton.vue';
import { FrequencyTypes } from './constants';
import { ActivityTypes } from '@/components/PatientMonitoring/constants';

export default {
  name: 'ProtocolActivity',

  components: {
    ListItemSelector,
    SaveButton,
  },
  mixins: [translation, accessibility, virtuoseMixin, validationRulesMixin],

  props: {
    protocolId: {
      type: Number,
      required: false,
      default: null,
    },

    isProtocolNormative: {
      type: Boolean,
      required: false,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loadedProtocolId: null,
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,

      error: null,

      allActivities: [],
      formData: {
        assignedActivities: [],
      },
      originalFormData: {},
      frequencies: [
        { value: FrequencyTypes.DAILY, text: this.$t('daily') },
        { value: FrequencyTypes.CUSTOMIZED, text: this.$t('customized') },
      ],
    };
  },
  computed: {
    columns: function () {
      return [
        {
          text: 'activityProtocolName',
          value: 'name',
          filterable: true,
        },
        {
          text: 'activityProtocolDescription',
          value: 'description',
          filterable: true,
        },
        {
          text: 'activityType',
          value: 'activityTypeName',
          filterable: true,
        },
        {
          text: '',
          value: 'data-table-expand',
          filterable: false,
          sortable: false,
        },
      ];
    },
    dialogMaxWidth: function () {
      return this.protocolId !== null ? '1200px' : '800px';
    },
    activityTypesMonitoredByVA: function () {
      return [ActivityTypes.APPT, ActivityTypes.DLG, ActivityTypes.PRE, ActivityTypes.WEI, ActivityTypes.PHT];
    },
  },

  watch: {
    protocolId: function () {
      this.init();
    },
    show() {
      if (this.show) {
        this.init();
      } else {
        this.loadedProtocolId = null;
      }
    },
  },
  methods: {
    init: function () {
      if (!this.protocolId || !this.show || this.loadedProtocolId === this.protocolId) {
        return;
      }

      this.loadedProtocolId = this.protocolId;
      this.showWaitModal = true;
      this.formData.assignedActivities = [];
      this.getAvailableActivities();
    },
    loadProtocolActivities: function () {
      this.showWaitModal = true;

      const activitiesSuccess = function (response) {
        let protocol_activities = response.data;
        this.showWaitModal = false;

        const protocolActivityMap = new Map();
        protocol_activities.forEach((activity) => {
          protocolActivityMap.set(activity.id, {
            frequency: activity.frequency,
            executionDays: activity.executionDays,
          });
        });

        this.formData.assignedActivities = this.allActivities
          .filter((activity) => protocolActivityMap.has(activity.id))
          .map((activity) => {
            const protocolActivity = protocolActivityMap.get(activity.id) || {};
            return {
              ...activity,
              frequency: protocolActivity.frequency || FrequencyTypes.DAILY,
              executionDays: protocolActivity.executionDays || null,
            };
          });
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      };
      this.getProtocolActivities(this.protocolId, activitiesSuccess, this.defaultErrorCallBack);
    },

    getAvailableActivities: function () {
      const activitiesSuccess = function (response) {
        this.showWaitModal = false;

        this.allActivities = response.data
          .filter((activity) => (this.isProtocolNormative ? activity.normative : activity))
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
              description: item.description,
              activityTypeName: item.activityType.name,
              activityTypeCode: item.activityType.code,
            };
          });
        if (this.protocolId != null) {
          this.loadProtocolActivities();
        }
      };

      this.getActivities(activitiesSuccess, this.defaultErrorCallBack);
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
      this.isProcessing = false;
    },

    closeDialog: function () {
      this.$emit('close');
    },

    updateProtocolActivities: function () {
      let isValid = this.$refs.executionDays?.validate();

      if (isValid === false || this.isProcessing) return;
      this.showWaitModal = true;
      this.isProcessing = true;

      var successCallback = function () {
        this.closeDialog();
        this.$emit('refresh');
        this.showWaitModal = false;
        this.isProcessing = false;
      };

      var data = {
        activities: this.formData.assignedActivities,
      };

      this.updateProtocolActivity(this.protocolId, data, successCallback, this.defaultErrorCallBack);
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },

    getActivityFrequency(activityId) {
      const activity = this.formData.assignedActivities.find((activity) => activity.id === activityId);
      return activity?.frequency || FrequencyTypes.DAILY;
    },

    getActivityExecutionDays(activityId) {
      const activity = this.formData.assignedActivities.find((activity) => activity.id === activityId);
      return activity?.executionDays || null;
    },

    addActivityFrequency(activityId, value) {
      const activity = this.formData.assignedActivities.find((activity) => activity.id === activityId);
      if (activity) {
        this.$set(activity, 'frequency', value);
      }
    },

    addActivityExecutionDays(activityId, value) {
      const activity = this.formData.assignedActivities.find((activity) => activity.id === activityId);
      if (activity) {
        this.$set(activity, 'executionDays', value);
      }
    },
  },
};
</script>

<style scoped></style>

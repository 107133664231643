var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.patientData)?_c('v-container',{attrs:{"fluid":""}},[_c('ErrorModal',{attrs:{"error":_vm.error},on:{"close-error-modal":function($event){_vm.error = null}}}),_c('v-row',[_c('v-col',{attrs:{"cols":"auto no-print"}},[_c('div',[_c('span',{staticClass:"text-h5 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.patientData.patient.lastName)+", "+_vm._s(_vm.patientData.patient.firstName)+" ")])]),_c('div',[_vm._v(_vm._s(_vm.patientData.patient.birthDate))])])],1),_c('v-card',{staticClass:"mt-8"},[(_vm.detailsActivityTypeCodes.some((x) => _vm.patientData.patientActivityIotRealtime[x]))?_c('div',{staticClass:"pa-5"},[(_vm.hasPatientIotDateTime())?_c('span',{ref:"biobeatSyncDate"},[_vm._v(" "+_vm._s(_vm.$t('iotRealtime.lastDataReceived') + ' ')+" "+_vm._s(_vm.lastIotDataDatetime)+" ")]):(_vm.hasPatientData())?_c('span',[_vm._v(_vm._s(_vm.$t('iotRealtime.detailsNotAvailable')))]):_c('span',[_vm._v(_vm._s(_vm.$t('noDataAvailable')))])]):_vm._e(),_c('WaitModal',{attrs:{"show":_vm.showWaitModal}}),(!_vm.showWaitModal && (_vm.hasPatientIotDateTime() || (!_vm.hasPatientIotDateTime() && !_vm.hasPatientData())))?_c('v-row',{staticClass:"mb-5",class:{
        'ml-7': _vm.detailsActivityTypeCodes.some((x) => _vm.patientData.patientActivityIotRealtime[x]),
      }},[(!_vm.detailsActivityTypeCodes.some((x) => _vm.patientData.patientActivityIotRealtime[x]))?_c('div',{staticClass:"ma-4 pl-2"},[_vm._v(" "+_vm._s(_vm.$t('iotRealtime.noPatientVitalSignActivity'))+" ")]):_vm._l((_vm.detailsActivityTypeCodes),function(activityTypeCode,index){return [(
            _vm.patientData.patientActivityIotRealtime[activityTypeCode] ||
            (activityTypeCode === _vm.realtimeDataTypeCodes.ECG && _vm.hasPatientPatch())
          )?_c('v-col',{key:index,attrs:{"cols":"12"}},[_c('div',[_c('div',{staticClass:"d-flex align-center d-print-inline-flex"},[_c('div',{staticClass:"my-1",class:{
                  'width-100': _vm.$vuetify.breakpoint.smAndDown,
                  'width-60': _vm.$vuetify.breakpoint.md,
                  'width-70': _vm.$vuetify.breakpoint.lg,
                  'width-80': _vm.$vuetify.breakpoint.xl,
                }},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"font-weight-medium pl-1 mt-2 mb-1 text-body-2"},[_vm._v(" "+_vm._s(_vm.$t(activityTypeCode + 'FullName') + ' (' + _vm.$t(activityTypeCode + 'Unit') + ')')+" ")])]),(
                    _vm.getObservationValue(activityTypeCode) ||
                    (activityTypeCode === _vm.realtimeDataTypeCodes.ECG && _vm.getPatientVitalSignData(activityTypeCode))
                  )?_c('VitalSignsChart',{attrs:{"patient-data":_vm.getPatientVitalSignData(activityTypeCode),"new-chart-data":_vm.patientData.newChartData && _vm.patientData.newChartData[activityTypeCode],"activity-type-code":activityTypeCode,"zoom-level":_vm.zoomLevel,"x-max-date":_vm.patientData.lastVitalSignsSyncData.lastDataDatetime},on:{"updateZoomLevel":_vm.updateZoomLevel}}):_c('div',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('noDataAvailable')))])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"width-350px"},[(activityTypeCode !== _vm.realtimeDataTypeCodes.ECG && _vm.getPatientVitalSignData(activityTypeCode))?[_c('div',{staticClass:"d-flex px-7"},[_c('div',{staticClass:"d-flex justify-center align-center width-45px"},[(_vm.hasValueTriggeredAnAlert(activityTypeCode))?_c('v-icon',{staticClass:"d-flex align-center pr-2",attrs:{"large":"","color":"red"}},[_vm._v(" mdi-alert-circle")]):_vm._e()],1),(_vm.getObservationValue(activityTypeCode))?_c('div',{class:{
                        'red--text': _vm.hasValueTriggeredAnAlert(activityTypeCode),
                        'mt-5 no-data text-center': !_vm.getObservationValue(activityTypeCode),
                        'grey--text':
                          _vm.isDelayedValue(_vm.patientData, activityTypeCode) || !_vm.getObservationValue(activityTypeCode),
                      }},[_c('span',{staticClass:"font-weight-bold",class:{
                          [_vm.getDetailsVitalSignsFontColor(activityTypeCode)]:
                            !_vm.hasValueTriggeredAnAlert(activityTypeCode) &&
                            _vm.getObservationValue(activityTypeCode) &&
                            !_vm.isDelayedValue(_vm.patientData, activityTypeCode),
                          'value-font-size': _vm.getObservationValue(activityTypeCode) && activityTypeCode !== 'APR',
                          'arterial-pressure-font-size':
                            _vm.getObservationValue(activityTypeCode) && activityTypeCode === 'APR',
                        }},[_vm._v(_vm._s(_vm.getObservationValue(activityTypeCode)))]),_c('span',[(![_vm.ActivityTypes.SAT, _vm.ActivityTypes.BDT].includes(activityTypeCode))?_c('span',[_vm._v(" ")]):_vm._e(),_vm._v(_vm._s(_vm.$t(`${activityTypeCode}Unit`)))]),(_vm.patientData.patientActivityIotRealtime[activityTypeCode].lastDataDatetime)?_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"text--secondary text-body-2 d-inline-block"},on),[_vm._v(" "+_vm._s(_vm.getRelativeDate( _vm.patientData.patientActivityIotRealtime[activityTypeCode].timeDifference ))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getFormatDate(_vm.patientData.patientActivityIotRealtime[activityTypeCode].lastDataDatetime)))])])],1):_vm._e()]):_vm._e()])]:_vm._e()],2):_vm._e()])])]):_vm._e()]})],2):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent :max-width="dialogMaxWidth">
      <v-card>
        <ModalTitle :title="$t('resourceAssign')" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <div>
            <PatientInfoDetails ref="patientDetails" class="mb-4" :patient-info="patientInfo" />

            <list-item-selector
              :first-data-grid-name="patientId ? `patient-${patientId}-ressources-assigned-grid` : null"
              :second-data-grid-name="patientId ? `patient-${patientId}-ressources-to-assign-grid` : null"
              :columns-assigned="columns"
              :columns-available="columns"
              :all-items="allResources"
              :assigned-items.sync="formData.assignedResources"
              :available-title="$t('availableResource')"
              :assigned-title="$t('assignedResource')"
              :data-loading="showWaitModal"
            >
              <template #expanded-item="{ item }">
                <td class="pa-4" :colspan="columns.length">
                  <div v-for="resource in item.assignedResources" :key="resource.id">
                    {{ resource.name }}
                  </div>
                </td>
              </template>

              <template #assignedItem.data-table-expand="{ expand, isExpanded }">
                <div>
                  <v-icon
                    ref="iconItemExpanded"
                    class="v-data-table__expand-icon"
                    :class="{ 'v-data-table__expand-icon--active': isExpanded }"
                    @click="expand(!isExpanded)"
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </template>

              <template #availableItem.data-table-expand="{}"></template>
            </list-item-selector>
          </div>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="updateResourcesModal"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import virtuoseMixin from '@/virtuoseMixin';
import validationRulesMixin from '@/validationRulesMixin';
import PatientInfoDetails from '@/components/Patient/PatientAssignDetails';
import ListItemSelector from '@/components/ListItemsSelector.vue';
import SaveButton from '@/components/SaveButton.vue';
import resourceService from '@/services/resourceService';

export default {
  name: 'ResourcesAssignModal',

  components: {
    PatientInfoDetails,
    ListItemSelector,
    SaveButton,
  },
  mixins: [translation, accessibility, virtuoseMixin, validationRulesMixin],

  props: {
    resourceId: {
      type: Number,
      required: false,
      default: null,
    },
    patientId: {
      type: Number,
      required: false,
      default: null,
    },
    patientInfo: {
      type: Object,
      required: false,
      default: () => {},
    },
    isResourceNormative: {
      type: Boolean,
      required: false,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selectedResourceId: null,
      selectedPatientId: null,
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,

      error: null,

      allResources: [],
      formData: {
        assignedResources: [],
      },
      originalFormData: {},
    };
  },
  computed: {
    columns: function () {
      return [
        {
          text: 'resourceName',
          value: 'name',
          filterable: true,
        },
        {
          text: 'resourceDescription',
          value: 'description',
          filterable: true,
        },
        {
          text: 'resourceFileType',
          value: 'format',
          filterable: true,
        },
      ];
    },
    dialogMaxWidth: function () {
      return this.protocolId !== null ? '1200px' : '800px';
    },
  },

  watch: {
    patientId: function () {
      this.init();
    },
    show() {
      if (this.show) {
        this.init();
      } else {
        this.loadedPatientId = null;
      }
    },
  },

  methods: {
    async init() {
      if (!this.patientId || !this.show || this.loadedPatientId === this.patientId) {
        return;
      }

      this.loadedPatientId = this.patientId;
      this.resources = [];
      this.showWaitModal = true;
      this.showDelete = false;
      this.showAddModal = false;
      this.formData.assignedResources = [];

      try {
        await this.loadAvailableResources();
      } catch (error) {
        this.defaultErrorCallBack(error);
      } finally {
        this.showWaitModal = false;
      }
    },
    async loadAvailableResources() {
      this.showWaitModal = true;
      try {
        let [allResources, patientResources] = await Promise.all([
          resourceService.getResources(),
          resourceService.getPatientResources(this.patientId),
        ]);
        this.allResources = allResources.map((item) => {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
            format: item.format,
          };
        });
        let assignedIds = patientResources.map((x) => x.id);

        this.formData.assignedResources = this.allResources.filter((x) => assignedIds.includes(x.id));
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      } catch (error) {
        this.defaultErrorCallBack(error);
      } finally {
        this.showWaitModal = false;
      }
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
      this.isProcessing = false;
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },

    async updateResourcesModal() {
      this.showWaitModal = true;
      this.isProcessing = true;

      try {
        let data = {
          resourcesIds: this.formData.assignedResources.map((x) => x.id),
        };
        await resourceService.updatePatientResource(this.patientId, data);
        this.closeDialog();
        this.$emit('refresh', true);
      } catch (error) {
        this.error = error;
      } finally {
        this.showWaitModal = false;
        this.isProcessing = false;
      }
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="showWaitModal" />

    <v-data-table
      :items="items"
      :headers="headers"
      :footer-props="footerProps"
      :items-per-page="25"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-expand
      item-key="at"
      class="elevation-1 mt-4"
      mobile-breakpoint="0"
    >
      <template #item.statusLevelId="{ item }">
        <div>
          <v-icon :color="getIconColor(item.statusLevelCode)">
            {{ getIconName(item.statusLevelCode) }}
          </v-icon>
        </div>
      </template>

      <template #expanded-item="{ item }">
        <td :colspan="headers.length + 1">
          <div class="my-2">
            <div
              v-for="(executionDetail, executionIndex) in item.executionResults"
              :key="executionIndex"
              class="my-7 text-body-1 black--text mx-2 ml-md-4 mr-md-4"
              :class="{ 'text-body-2': $vuetify.breakpoint.sm }"
            >
              <div class="d-flex align-center">
                <v-icon
                  v-if="executionDetail.statusLevelCode !== warningCode"
                  :color="getAnamExecutionColor(executionDetail.statusLevelCode)"
                >
                  {{ getAnamExecutionIcon(executionDetail.statusLevelCode) }}
                </v-icon>
                <v-icon
                  v-if="executionDetail.statusLevelCode === warningCode"
                  :color="getIconColor(executionDetail.statusLevelCode)"
                >
                  {{ getIconName(executionDetail.statusLevelCode) }}
                </v-icon>

                <div :class="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'd-block' : 'd-flex align-center'">
                  <div class="d-flex">
                    <div class="font-weight-black ml-5 ml-md-9">{{ executionDetail.scheduledDateTime }}</div>
                    <div v-if="executionDetail.executionDateTime == null" class="ml-md-7 ml-5 text--secondary">
                      {{ $t('notAnswered') }}
                    </div>
                    <div v-if="executionDetail.executionDateTime != null" class="ml-md-7 ml-5 text--secondary">
                      {{ $t('answeredAt') + ' ' + executionDetail.executionDateTime }}
                    </div>
                  </div>

                  <v-btn
                    v-if="!!executionDetail.executionDateTime"
                    elevation="0"
                    class="font-weight-bold ml-md-5 ml-4"
                    :class="{ 'mb-3': $vuetify.breakpoint.sm }"
                    x-small
                    @click="executionDetail.showAnswers = !executionDetail.showAnswers"
                  >
                    {{ executionDetail.showAnswers ? $t('hideAnswers') : $t('showAnswers') }}
                  </v-btn>
                </div>
              </div>

              <div
                v-if="areAllAnswersExpress(executionDetail)"
                class="ml-md-15 ml-sm-4 ml-2 my-2 text--secondary text-body-2"
              >
                {{ $t('expressAnswer') }}
              </div>

              <div
                v-for="(dialogueResult, dialogueIndex) in executionDetail.resultsDetails"
                :key="dialogueIndex"
                class="ml-md-15 ml-sm-4 ml-2 my-2"
              >
                <div class="d-flex">
                  <v-icon :color="getAnamAlertColor(dialogueResult.statusLevelCode)">
                    {{ getAnamAlertIcon(dialogueResult.statusLevelCode) }}
                  </v-icon>

                  <div class="ml-4">{{ dialogueResult.dialogueName }}</div>
                </div>

                <div v-if="areAllAnswersVisible(dialogueResult, executionDetail)" class="pt-1 pb-3">
                  <v-alert
                    v-for="(answer, answerIndex) in dialogueResult.answersDetails"
                    :key="answerIndex"
                    :text="answer.statusLevelCode !== normalCode"
                    :color="getAnamAlertColor(answer.statusLevelCode)"
                    dense
                    class="ml-10 mb-1 mt-0 d-table"
                  >
                    <div class="d-flex align-center">
                      <v-icon
                        v-if="answer.alertLevel === 'abnormal'"
                        :color="getAnamAlertColor(alertCode)"
                        class="me-4"
                        >{{ getAnamAlertIcon(alertCode) }}</v-icon
                      >
                      <v-icon
                        v-if="answer.alertLevel === 'warning'"
                        :color="getAnamAlertColor(warningCode)"
                        class="me-4"
                        >{{ getAnamAlertIcon(warningCode) }}</v-icon
                      >

                      <div :class="{ 'text-body-2': $vuetify.breakpoint.sm }">
                        <div class="d-flex">
                          <div class="text--secondary">{{ $t('question') }}:</div>
                          <div class="black--text ml-2">{{ answer.questionAsked }}</div>
                        </div>

                        <div class="black--text d-flex">
                          <div class="text--secondary">{{ $t('response') }}:</div>
                          <div class="ml-2">
                            <span>{{ answer.answer }}</span>
                            <span class="font-italic">{{
                              answer.spokenAnswer ? ` ("${answer.spokenAnswer}")` : ''
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-alert>
                </div>
              </div>
            </div>
          </div>
        </td>
      </template>
    </v-data-table>
  </span>
</template>

<script>
import translationMixin from '@/translationMixin';
import iconMixin from '../iconMixin';
import filterMixin from '../filterMixin';
import { StatusLevels } from '../constants';
import patientMonitoringService from '@/services/patientMonitoringService';

export default {
  name: 'PatientAnamnese',
  mixins: [translationMixin, iconMixin, filterMixin],
  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },

    activityId: {
      type: Number,
      default: null,
    },

    filters: {
      type: Object,
      required: false,
      default: null,
      validator(value) {
        return value == null || (typeof value === 'object' && !Array.isArray(value));
      },
    },
  },
  data: () => ({
    items: [],
    sortBy: 'at',
    sortDesc: true,
    error: null,
    showWaitModal: false,
    alertCode: StatusLevels.AL,
    warningCode: StatusLevels.WA,
    normalCode: StatusLevels.NO,
    requestFilters: null,
  }),

  computed: {
    footerProps: function () {
      return {
        itemsPerPageOptions: [5, 10, 25, 50, -1],
        showFirstLastPage: true,
        'items-per-page-text': this.$t('rowsByPage'),
      };
    },

    headers() {
      return [
        {
          text: this.$t('statusLevelId'),
          textalign: 'center',
          value: 'statusLevelId',
          align: 'center',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t('at'),
          value: 'at',
          sortable: false,
          order: 'desc',
        },
      ];
    },
    dateFrom() {
      return this.calculateNonChartDate('start');
    },
    dateTo() {
      return this.calculateNonChartDate('end');
    },
  },

  watch: {
    patientId: function () {
      this.init();
    },

    activityId: function () {
      this.init();
    },

    filters: function () {
      this.init();
    },

    requestFilters: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.$emit('update:request-filters', newVal);
        }
      },
      deep: true,
    },
  },

  created: function () {
    this.init();
  },
  methods: {
    init: async function () {
      await this.getPatientDialogueResults();
    },

    getPatientDialogueResults: async function () {
      const newFilters = this.setRequestFilters();
      this.requestFilters = newFilters;

      try {
        this.showWaitModal = true;
        const patientData = await patientMonitoringService.getPatientDialoguesResults(this.requestFilters);
        this.getStatusLevelCodeAndDialoguesNames(patientData.results);
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    getStatusLevelCodeAndDialoguesNames: function (patientDataResults) {
      this.items = patientDataResults.map((item) => {
        item.executionResults.forEach((executionDetail) => {
          const isOnlyAlertOrWarningDialogue = executionDetail.resultsDetails.every(
            (dialogue) => dialogue.statusLevelCode === this.alertCode || dialogue.statusLevelCode === this.warningCode
          );
          executionDetail.showAnswers = isOnlyAlertOrWarningDialogue ? true : null;
        });

        return {
          at: item.at,
          executionResults: item.executionResults,
          statusLevelCode: item.statusLevelCode,
        };
      });
    },

    areAllAnswersVisible: function (dialogueResult, executionDetail) {
      return (
        ((dialogueResult.statusLevelCode === this.alertCode || dialogueResult.statusLevelCode === this.warningCode) &&
          executionDetail.showAnswers === null) ||
        executionDetail.showAnswers === true
      );
    },

    areAllAnswersExpress(executionDetail) {
      return (
        executionDetail.resultsDetails &&
        executionDetail.resultsDetails.length > 0 &&
        executionDetail.resultsDetails.every((dialogueResult) =>
          dialogueResult.answersDetails.every((answer) => answer.expressAnswer)
        )
      );
    },
  },
};
</script>

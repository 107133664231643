<template>
  <v-badge
    ref="badge"
    :value="callbackRequestsCount != 0"
    overlap
    color="primary"
    top
    offset-x="1"
    :offset-y="offsetY"
    :content="callbackRequestsCount"
  />
</template>

<script>
export default {
  name: 'CallbackRequestBadge',

  props: {
    callbackRequestsCount: {
      type: Number,
      required: true,
    },

    offsetY: {
      type: Number,
      required: true,
    },
  },
};
</script>

import api from './baseApiService';

export class VocalMessageService {
  async getVocalMessages() {
    return await api.get('vocalmessages');
  }

  async getVocalMessageById(vocalMessageId) {
    return await api.get(`vocalmessage/${vocalMessageId}`);
  }

  async createVocalMessage(data) {
    return await api.post('vocalmessage/', data);
  }

  async updateVocalMessage(vocalMessageId, data) {
    return await api.put(`vocalmessage/${vocalMessageId}/`, data);
  }

  async deleteVocalMessage(vocalMessageId) {
    return await api.delete(`vocalmessage/${vocalMessageId}/delete/`);
  }

  async getVocalMessagesByIotType(IotTypeId) {
    return await api.get(`vocalmessagesiottype/${IotTypeId}`);
  }
}

export default new VocalMessageService();

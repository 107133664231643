<template>
  <div
    :class="{
      'd-flex align-center justify-space-between pb-1': $vuetify.breakpoint.lgAndDown,
      'width-100': $vuetify.breakpoint.lgAndDown,
    }"
  >
    <div :class="getObservationValueIconColor(iotStatesEvent)" class="d-flex justify-center">
      <div class="d-flex justify-center align-center" :class="$vuetify.breakpoint.lgAndDown ? 'ml-1' : ''">
        <v-icon
          v-if="
            iotStateEventAlertCodes.includes(iotStatesEvent.values[0]) ||
            iotStateEventWarningCodes.includes(iotStatesEvent.values[0]) ||
            iotStateEventErrorCodes.includes(iotStatesEvent.values[0])
          "
          :color="getObservationValueTextColor(iotStatesEvent)"
          small
          class="pl-1"
          :class="{
            'pr-2': $vuetify.breakpoint.xl,
          }"
          >{{ getObservationValueIcon(iotStatesEvent) }}</v-icon
        >

        <img
          v-else-if="iotStatesEvent.values == statusLevels.CH"
          class="pr-1"
          :class="$vuetify.breakpoint.xl ? 'pr-2' : 'ml-1'"
          :src="'/static/incontinenceicon/change_essity.svg'"
          height="11px"
          :alt="'change_essity-icon'"
        />
        <span
          :class="{
            'ml-1': $vuetify.breakpoint.lgAndDown,
            'max-width-125': $vuetify.breakpoint.xl,
            'text-center': true,
          }"
        >
          {{ getIotStateValueText() }}
        </span>
      </div>
    </div>

    <v-tooltip bottom>
      <template #activator="{ on }">
        <div
          :class="{
            'ml-5': $vuetify.breakpoint.lgAndDown,
          }"
          class="text--secondary text-body-2 text-center"
          v-on="on"
        >
          {{ getRelativeDate(iotStatesEvent.timeDifference) }}
        </div>
      </template>

      <span> {{ getFormatDate(iotStatesEvent.lastDataDatetime) }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import translationMixin from '@/translationMixin';
import iotRealtimeMixin from '@/components/PatientIotRealtime/iotRealtimeMixin.js';

import {
  IotStateEventCodes,
  IotStateEventAlertCodes,
  IotStateEventErrorCodes,
  IotStateEventWarningCodes,
} from '@/components/PatientIotRealtime/constants.js';

import { StatusLevels } from '@/components/PatientMonitoring/constants';

export default {
  name: 'IotRealtimeGridIotStates',

  mixins: [iotRealtimeMixin, translationMixin],

  props: {
    iotStatesEvent: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      iotStateEventAlertCodes: IotStateEventAlertCodes,
      iotStateEventErrorCodes: IotStateEventErrorCodes,
      iotStateEventWarningCodes: IotStateEventWarningCodes,
      statusLevels: StatusLevels,
    };
  },

  methods: {
    getIotStateValueText: function () {
      const eventCode = this.iotStatesEvent.values[0];

      switch (
        eventCode // Switch case for each case to keep a trace of where these translations are used
      ) {
        case IotStateEventCodes.APP_CRASHED:
          return this.$t('iotRealtime.status.appCrashed');

        case IotStateEventCodes.FALL_DETECTED:
          return this.$t('iotRealtime.status.fallDetected');

        case IotStateEventCodes.FALL_MODE:
          return this.$t('iotRealtime.status.fallMode');

        case IotStateEventCodes.MOTION_MODE:
          return this.$t('iotRealtime.status.motionMode');

        case IotStateEventCodes.NOT_WORKING:
          return this.$t('iotRealtime.status.notWorking');

        case IotStateEventCodes.PEOPLE_MODE:
          return this.$t('iotRealtime.status.peopleMode');

        case IotStateEventCodes.RUNNING:
          return this.$t('iotRealtime.status.running');

        case IotStateEventCodes.CALCULATION_ERROR:
          return this.$t('iotRealtime.status.calculationError');

        case IotStateEventCodes.FAULTY:
          return this.$t('iotRealtime.status.faulty');

        case IotStateEventCodes.NO_TRANSMITTER:
          return this.$t('iotRealtime.status.noTransmitter');

        case IotStateEventCodes.SIGNAL_LOST:
          return this.$t('iotRealtime.status.signalLost');

        case IotStateEventCodes.STRIP_TRANSMITTER:
          return this.$t('iotRealtime.status.stripDetachedFromTransmitter');

        case IotStateEventCodes.STRIP_BRIEF:
          return this.$t('iotRealtime.status.stripDetachedFromBrief');

        case StatusLevels.CH:
          return this.$t('Change');

        case StatusLevels.WE:
          return this.$t('Wet');

        case StatusLevels.DR:
          return this.$t('Dry');

        default:
          return eventCode;
      }
    },
  },
};
</script>

<style scoped>
.width-100 {
  width: 100%;
}

.max-width-125 {
  max-width: 125px;
}

.inc-wet-text-color {
  color: #f2ba4c;
}
</style>

<template>
  <v-sheet v-show="items.length != 0" v-bind="dynamicBind" flat color="transparent" class="pa-1 no-print">
    <v-row dense class="paginator-row">
      <v-col class="filter-col mb-4">
        <span class="filter-label text-body-1 mr-2"> {{ $t('filter') }}: </span>
        <v-select
          v-model="filterNameField"
          dense
          class="column-select"
          v-bind="dynamicBind"
          :no-data-text="$t('nodatatext')"
          :items="filterFields"
          :label="$t('column')"
          item-text="label"
          item-value="name"
          hide-details
          outlined
          @input="rePaginateAndRedirectToPageOne"
        />
        <v-text-field
          ref="filterValue"
          v-model="filterValue"
          class="value-text-field"
          v-bind="dynamicBind"
          :label="$t('value')"
          dense
          hide-details
          outlined
          @blur="rePaginateAndRedirectToPageOne"
          @keyup.enter="rePaginateAndRedirectToPageOne"
        />
      </v-col>

      <v-col class="rpp-index-col mb-4">
        <v-text-field
          ref="resultPerPage"
          v-model="resultByPage"
          class="results-per-page"
          :label="$t('resultByPage')"
          :rules="[parseInt(resultByPage) >= 0]"
          dense
          hide-details
          outlined
          @blur="rePaginateAndRedirectToPageOne"
          @keyup.enter="rePaginateAndRedirectToPageOne"
        />
        <span class="mx-4 mt-4" :aria-label="resultIndexDisplay + $t('of') + nbResults">
          {{ resultIndexDisplay }} {{ $t('of') }} {{ nbResults }}
        </span>
      </v-col>
      <v-col class="pagination-col">
        <v-pagination
          v-bind="dynamicBind"
          ref="paginator"
          v-model="pageNumber"
          :aria-label="getPageNumberDescription()"
          color="#707070"
          :total-visible="5"
          :length="paginatedItems.length"
          @input="repaginate"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';

export default {
  name: 'ItemsPaginator',
  mixins: [translation, accessibility],
  props: {
    items: {
      type: Array,
      required: false,
      default: null,
    },
    includeFields: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    includeFieldsSortIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    trigSort: {
      type: Boolean,
      required: false,
      default: false,
    },
    describedBy: {
      type: String,
      default: null,
      required: false,
    },
    initialFilter: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      filterNameField: null,
      resultByPage: 20,
      filterValue: '',
      paginatedItems: [],
      currentIndex: 0,
      pageNumber: 1,
      sortAscendingMask: [],
      sortData: false,
      resultIndex: '',
      nbResults: 0,
      firstElementNumber: 0,
      lastElementNumber: 0,
      dynamicBind: {},
      dynamicRequiredBind: {},
    };
  },

  computed: {
    showFilter: function () {
      return this.paginatedItems.length > 0 && this.includeFields.length > 0;
    },
    resultIndexDisplay: function () {
      return `${this.firstElementNumber} - ${this.lastElementNumber}`;
    },

    filterFields: function () {
      return this.includeFields.map((name) => this.makeName(name));
    },
  },

  watch: {
    items: {
      deep: true,
      handler() {
        var oldPageNumber = this.pageNumber;
        this.rePaginateAndRedirectToPage(oldPageNumber);
      },
    },
    trigSort: function () {
      if (this.trigSort) {
        this.sortAction();
        this.$emit('sortDone', true);
      }
    },
    initialFilter: function () {
      if (this.initialFilter && this.filterValue === '') {
        this.filterValue = this.initialFilter.value;
        this.filterNameField = this.initialFilter.nameField;
        this.rePaginateAndRedirectToPageOne();
      }
    },
    filterValue: function () {
      this.$emit('filter-value-changed');
    },
  },

  created() {
    this.filterNameField = null;
    this.filterValue = '';
    this.pageNumber = 1;
    this.dynamicRequiredBind = this.getRequiredBindObject(false);

    this.initAscendingMask();
    this.getAllFieldsName();

    this.paginate(1);

    if (this.describedBy != null) {
      this.dynamicBind = {
        'aria-describedBy': this.describedBy,
      };

      this.dynamicRequiredBind['aria-describedBy'] = this.describedBy;
    }
  },

  mounted: function () {
    this.$nextTick(function () {
      this.setNextAndPreviousButtonAriaLabel();
    });
  },

  methods: {
    setNextAndPreviousButtonAriaLabel: function () {
      var el = this.$refs.paginator.$el;
      var nextIndex = el.childNodes.length - 1;
      var prevElement = el.childNodes[0];
      var nextElement = el.childNodes[nextIndex];
      var prevButton = prevElement.childNodes[0];
      var nextButton = nextElement.childNodes[0];
      prevButton.setAttribute('aria-label', this.$t('previousPage'));
      nextButton.setAttribute('aria-label', this.$t('nextPage'));
    },

    initAscendingMask: function () {
      for (var i = 0; i < this.includeFields.length; i++) {
        this.sortAscendingMask[i] = false;
      }
    },

    sortAction: function () {
      this.sortAscendingMask[this.includeFieldsSortIndex] = !this.sortAscendingMask[this.includeFieldsSortIndex];

      this.sortAscending = this.sortAscendingMask[this.includeFieldsSortIndex];
      this.sortField = this.includeFields[this.includeFieldsSortIndex];
      this.sortData = true;
      this.repaginate(null);
    },

    rePaginateAndRedirectToPageOne: function () {
      this.sortData = false;
      this.repaginate(1);
    },

    rePaginateAndRedirectToPage: function (pageNumber) {
      this.sortData = false;
      this.repaginate(pageNumber);
    },

    repaginate: function (pageNumber) {
      this.resultByPage = parseInt(this.resultByPage, 10);

      if (isNaN(this.resultByPage) || this.resultByPage < 1) {
        this.resultByPage = 20;
      }

      this.paginate(pageNumber);
    },

    sort: function (a, b) {
      var aValue = a[this.sortField];
      var bValue = b[this.sortField];

      var left = this.sortAscending ? aValue : bValue;
      var right = this.sortAscending ? bValue : aValue;

      var result = 0;

      var isArray = Array.isArray(left);

      if (left == null && right != null) {
        result = -1;
      } else if (left != null && right == null) {
        result = 1;
      } else {
        if (typeof left == 'string') {
          result = left.localeCompare(right, undefined, {
            sensitivity: 'accent',
          });
        } else if (isArray) {
          var joinLeft = left.join(' ');
          var joinRight = right.join(' ');
          result = joinLeft.localeCompare(joinRight, undefined, {
            sensitivity: 'accent',
          });
        } else if (typeof left == 'boolean') {
          if (left == false && right == true) {
            result = -1;
          } else if (left == true && right == false) {
            result = 1;
          }
        } else if (typeof left == 'number') {
          result = left - right;
        }
      }

      return result;
    },

    filterItems: function (item) {
      var keep = true;

      var itemType = typeof item[this.filterNameField];
      var isArray = Array.isArray(item[this.filterNameField]);

      if (itemType == 'string') {
        keep = this.removeAccent(item[this.filterNameField].toLowerCase()).includes(
          this.removeAccent(this.filterValue.toLowerCase())
        );
      } else if (itemType == 'boolean') {
        keep = this.$t(item[this.filterNameField]).toLowerCase().includes(this.filterValue.toLowerCase());
      } else if (itemType == 'number') {
        keep = item[this.filterNameField].toString().includes(this.filterValue);
      } else if (isArray) {
        var joinItems = item[this.filterNameField].join(' ');
        keep = joinItems.toLowerCase().includes(this.filterValue.toLowerCase());
      } else {
        if (item[this.filterNameField] != null) {
          keep = item[this.filterNameField] == this.filterValue;
        } else if (this.filterValue != '') {
          keep = false;
        }
      }

      return keep;
    },

    getPageNumberDescription: function () {
      var pageNumberDescription = this.$t('pageNumber');
      return pageNumberDescription + ' : ' + this.pageNumber;
    },

    makeName: function (name) {
      var label = this.$t(name);

      return {
        name: name,
        label: label,
      };
    },

    getAllFieldsName: function () {
      if (this.filterFields.length > 0) {
        this.filterNameField = this.filterFields[0].name;
      }
    },

    removeAccent: function (str) {
      var map = {
        '-': '_| ',
        a: 'á|à|ã|â',
        e: 'é|è|ê',
        i: 'í|ì|î',
        o: 'ó|ò|ô|õ',
        u: 'ú|ù|û|ü',
        c: 'ç',
        n: 'ñ',
      };

      str = str.toLowerCase();

      for (var pattern in map) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern);
      }

      return str;
    },

    paginate: function (pageNumber) {
      this.paginatedItems = [];
      this.paginatedItems[0] = [];

      var filteredItems =
        this.showFilter && this.filterNameField != ''
          ? this.items.filter((item) => this.filterItems(item))
          : this.items;

      if (this.sortData) {
        filteredItems.sort(this.sort);
      }

      var i = 0;
      var j = 0;
      var k = 0;

      for (; i < filteredItems.length; i++) {
        this.paginatedItems[j][k] = filteredItems[i];

        if ((i + 1) % this.resultByPage == 0 && i < filteredItems.length - 1) {
          k = 0;
          j++;
          this.paginatedItems[j] = [];
        } else {
          k++;
        }
      }

      var nbTotalPages = this.paginatedItems.length;

      if (pageNumber != null) {
        if (pageNumber <= nbTotalPages) {
          this.pageNumber = pageNumber;
        } else {
          this.pageNumber = 1;
        }
      }

      this.currentIndex = this.pageNumber - 1;

      this.firstElementNumber = this.currentIndex * this.resultByPage;
      this.lastElementNumber = this.firstElementNumber + this.paginatedItems[this.currentIndex].length;
      this.nbResults = filteredItems.length;

      var currentPage = this.paginatedItems[this.currentIndex];

      this.$emit('currentPageItems', currentPage);
      this.$emit('filteredItems', filteredItems);
    },
  },
};
</script>

<style scoped>
.v-pagination {
  width: auto;
}
.paginator-row {
  flex-direction: row;
  align-items: center;
}
.rpp-index-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 260px;
  min-width: 260px;
}
.filter-col {
  display: flex;
  flex-direction: column;
  max-width: 350px;
}
.value-text-field {
  max-width: auto;
}
.column-select {
  max-width: auto;
}
.results-per-page {
  max-width: 110px;
}
@media (min-width: 500px) {
  .value-text-field {
    max-width: 100px;
  }
  .column-select {
    max-width: 160px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .filter-col {
    flex-direction: row;
    align-items: center;
    min-width: 350px;
  }
  .filter-col > span {
    margin-top: 15px;
  }
}
@media (min-width: 1200px) {
  .value-text-field {
    max-width: 150px;
  }
  .column-select {
    max-width: 210px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .filter-col {
    max-width: 450px;
    margin-right: 20px;
  }
}
</style>

<template>
  <v-container>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row align="center">
      <v-col>
        <PageTitle :title="$t('patientVitalSigns')" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <iframe :src="leomedUrlWithToken" frameborder="0" width="100%" class="iframe-height" title="Vital Signs Data">
          </iframe>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import applicationService from '@/services/applicationService';
import { format } from 'date-fns';

export default {
  name: 'PatientVitalSigns',
  mixins: [translationMixin, accessibility],
  data() {
    return {
      error: null,
      timerToRefreshToken: null,
      every12hIntervalRefresh: 1000 * (12 * 60 * 60), // 12 hours in milliseconds
      leomedUrlWithoutToken: '',
      leomedUrlWithToken: '',
    };
  },

  async mounted() {
    this.startTimer();
  },

  beforeDestroy() {
    this.stopTimer();
  },

  async created() {
    await this.getLeomedUrl();
    await this.initLeomedUrl();
  },

  methods: {
    initLeomedUrl: async function () {
      await this.getLeomedUrlSessionToken();
      return;

      // let currentSessionToken = localStorage.getItem('leomedSessionToken');

      // if (!currentSessionToken) {
      //   await this.getLeomedUrlSessionToken();
      //   return;
      // }

      // const isTokenStillValid = this.checkIfTokenStillValid(currentSessionToken);

      // if (isTokenStillValid) {
      //   this.buildLeomedUrlSessionToken(currentSessionToken);
      // }

      // this.buildLeomedUrlSessionToken(currentSessionToken);
    },

    checkIfTokenStillValid: async function (currentSessionToken) {
      if (currentSessionToken == 'tab_login') {
        await this.getLeomedUrlSessionToken();
        return false;
      }
      const sessionTokenPayload = currentSessionToken.split('.')[1];
      const decodedPayload = JSON.parse(atob(sessionTokenPayload));

      const tokenExpirationDate = format(new Date(decodedPayload.exp * 1000), 'yyyy-MM-dd');
      const todayDate = format(new Date(), 'yyyy-MM-dd');

      if (todayDate >= tokenExpirationDate) {
        await this.getLeomedUrlSessionToken();
        return false;
      }

      return true;
    },

    getLeomedUrl: async function () {
      try {
        const demoFeatureConfig = await applicationService.getDemoFeatureConfig();
        this.leomedUrlWithoutToken = demoFeatureConfig.leomedUrl;
      } catch (error) {
        this.error = error;
      }
    },

    getLeomedUrlSessionToken: async function () {
      try {
        const token = await applicationService.getPartnerSessionToken('leomed');
        const sessionToken = token.sessionToken;

        localStorage.setItem('leomedSessionToken', sessionToken);
        this.buildLeomedUrlSessionToken(sessionToken);
      } catch (error) {
        this.error = error;
      }
    },

    buildLeomedUrlSessionToken: function (sessionToken) {
      if (sessionToken == 'tab_login') {
        this.leomedUrlWithToken = this.leomedUrlWithoutToken;
        return;
      }
      let language = this.getLanguage();
      this.leomedUrlWithToken = `${this.leomedUrlWithoutToken}?session_token=${sessionToken}&language=${language}`;
    },

    startTimer() {
      this.timerToRefreshToken = setInterval(this.initLeomedUrl, this.every12hIntervalRefresh);
    },

    stopTimer() {
      clearInterval(this.timerToRefreshToken);
    },
  },
};
</script>
<style scoped>
.iframe-height {
  height: calc(100vh - 150px);
}
</style>

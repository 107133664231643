<template>
  <div v-if="show" class="card-justify">
    <div class="card-width my-1">
      <v-progress-circular indeterminate color="primary" class="card-width"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import translation from '@/translationMixin';
export default {
  name: 'WaitModal',
  mixins: [translation],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>

<style scoped>
.card-justify {
  justify-content: center;
  display: flex;
}

.card-width {
  width: 30px;
}
</style>

<template>
  <v-row dense>
    <v-col>
      <div class="text-caption">{{ $t('requiredFieldsLegend') }}</div>
    </v-col>
  </v-row>
</template>

<script>
import translation from '@/translationMixin';
export default {
  name: 'RequiredFieldsLegend',
  components: {},
  mixins: [translation],
};
</script>

import translation from '@/translationMixin';
export default {
  mixins: [translation],
  data: function () {
    return {
      requiredErrorMessages: [],
    };
  },

  methods: {
    setFocusToIconButtonAndTooltip: function (refName, index = null) {
      this.$nextTick(() => {
        var ref = index == null ? this.$refs[refName] : this.$refs[refName][index];
        var boutonRef = ref.$refs.bouton.$el;
        boutonRef.focus();
      });
    },

    setFocusToBtn: function (refName, index = null) {
      this.$nextTick(() => {
        var ref = index == null ? this.$refs[refName] : this.$refs[refName][index];
        ref.$el.focus();
      });
    },

    getRequiredBindObject: function (readOnly = false) {
      var data = {};

      if (readOnly == false) {
        data = this.getRequiredObject();
      } else {
        data = {
          readonly: true,
        };
      }

      return data;
    },

    getRequiredObject: function () {
      return {
        required: true,
        'append-outer-icon': 'mdi-asterisk',
      };
    },

    getIdByName: function (name) {
      return this._uid + '_' + name;
    },

    getIdsByNames: function (names) {
      var result = names.map((name) => this.getIdByName(name)).join(' ');
      return result;
    },

    isEmptyValue: function (value) {
      const invalidValues = ['{}', '[]'];
      const emptyString = typeof value == 'string' && value.trim() == '';

      return (
        typeof value == 'undefined' || value == null || emptyString || invalidValues.includes(JSON.stringify(value))
      );
    },

    hasDuplicates: function (array) {
      return new Set(array).size !== array.length;
    },

    makeLocalErrorObject: function (messageId, id) {
      return {
        message: this.$t(messageId),
        refId: id,
      };
    },

    getRefNameFromParent: function (fieldRefName) {
      for (var currentParent = this.$parent; currentParent; currentParent = currentParent.$parent) {
        for (var key in currentParent.$refs) {
          var ref = currentParent.$refs[key];

          if (ref && ref._uid == this._uid) {
            this[fieldRefName] = key;
            break;
          }
        }
      }
    },

    validateObjectParameter: function (ref, refName) {
      var required = ref.$attrs ? ref.$attrs.required : false;

      if (required) {
        var value = ref.$options.propsData.value;
        var id = ref.$options.propsData.id;

        if (this.isEmptyValue(value)) {
          this.requiredErrorMessages.push(this.makeLocalErrorObject(refName, id));
        }
      }
    },

    validateNotEmptyParametersRecursive: function (currentVue) {
      for (var childIndex = 0; childIndex < currentVue.$children.length; childIndex++) {
        this.validateNotEmptyParametersRecursive(currentVue.$children[childIndex]);
      }

      for (var refName in currentVue.$refs) {
        var ref = currentVue.$refs[refName];

        if (typeof ref != 'undefined') {
          if (Array.isArray(ref) == false) {
            this.validateObjectParameter(ref, refName);
          } else {
            for (var refIndex = 0; refIndex < ref.length; refIndex++) {
              var currRef = ref[refIndex];
              this.validateObjectParameter(currRef, refName);
            }
          }
        }
      }
    },

    validateNotEmptyParameters: function () {
      this.requiredErrorMessages = [];
      this.validateNotEmptyParametersRecursive(this);

      return this.requiredErrorMessages.length == 0;
    },

    clearErrorMessages: function () {
      this.requiredErrorMessages = [];
    },
  },
};

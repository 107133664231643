<template>
  <div style="display: inline-block">
    <v-tooltip
      max-width="300"
      :top="position === 'top'"
      :bottom="position === 'bottom'"
      open-delay="200"
      transition="fade-transition"
      color="#212121"
    >
      <template #activator="{ on }">
        <!-- Small -->
        <v-btn
          ref="bouton"
          :aria-label="tooltipText"
          :disabled="disabled"
          :small="size === 'small'"
          :large="size === 'large'"
          icon
          :class="[btnClasses]"
          @click.stop="btnClicked"
          v-on="on"
        >
          <v-icon :class="[iconClasses]" :small="size === 'small'" :large="size === 'large'">{{ icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import accessibility from '@/accessibilityMixin';
export default {
  name: 'IconButtonAndTooltip',
  components: {},
  mixins: [accessibility],
  props: {
    icon: {
      type: String,
      required: true,
      validator: function (icon) {
        return icon.length > 0;
      },
    },
    tooltipText: {
      type: String,
      required: true,
      validator: function (tooltipText) {
        return tooltipText.length > 0;
      },
    },
    describedBy: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: null,
    },
    position: {
      type: String,
      required: true,
      validator: function (position) {
        return position === 'top' || position === 'bottom' || position === 'left' || position === 'right';
      },
    },
    btnClasses: {
      type: String,
      required: false,
      default: null,
    },
    iconClasses: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  created: function () {},

  mounted: function () {
    this.$nextTick(function () {
      if (this.describedBy != '') {
        if (this.$refs.bouton) {
          this.$refs.bouton.$refs.link.setAttribute('aria-describedby', this.describedBy);
        }
      }
    });
  },

  methods: {
    btnClicked: function (event) {
      this.$emit('btn-clicked', event);
    },
  },
};
</script>

<style scoped>
/* i {
  color: inherit !important;
} */
.rotateY180 {
  transform: rotateY(180deg);
}
</style>

<template>
  <v-badge
    ref="badge"
    :value="unreadCount > 0"
    :offset-y="$vuetify.breakpoint.xs ? -2 : 10"
    overlap
    :content="unreadCount"
  />
</template>

<script>
import notificationService from '@/services/notificationService';
import wsNotificationsMixin from '@/wsNotificationsMixin';

export default {
  name: 'NotificationCount',
  mixins: [wsNotificationsMixin],

  data() {
    return {
      unreadCount: 0,
    };
  },

  async mounted() {
    await this.refreshUnreadNotificationCount();
    this.subscribeToNotificationCount();
  },

  methods: {
    onNotificationCount(webSocketData) {
      this.unreadCount = webSocketData.data.newNotificationCount;
    },

    async refreshUnreadNotificationCount() {
      try {
        const data = await notificationService.getUnreadNotificationCount();
        this.unreadCount = data.unreadCount;
      } catch (error) {
        this.error = error;
        this.unreadCount = 0;
      }
    },
  },
};
</script>

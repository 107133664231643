<template>
  <div
    class="d-flex justify-center"
    :class="{
      'width-100': [activityTypes.WEI, activityTypes.GLY].includes(activityTypeCode) && $vuetify.breakpoint.lgAndDown,
    }"
  >
    <div
      class="d-flex"
      :class="{
        'flex-column': ![activityTypes.WEI, activityTypes.GLY].includes(activityTypeCode) || $vuetify.breakpoint.xl,
        'justify-space-between':
          $vuetify.breakpoint.lgAndDown &&
          [activityTypes.WEI, activityTypes.GLY, activityTypes.FDE, activityTypes.INC].includes(activityTypeCode),
        'width-100':
          $vuetify.breakpoint.lgAndDown &&
          [activityTypes.WEI, activityTypes.GLY, activityTypes.FDE, activityTypes.INC].includes(activityTypeCode),
        'ml-1': $vuetify.breakpoint.lgAndDown,
      }"
    >
      <div
        class="d-flex"
        :class="{
          'justify-center': $vuetify.breakpoint.xl,
          'justify-space-between':
            [activityTypes.WEI, activityTypes.GLY].includes(activityTypeCode) && $vuetify.breakpoint.lgAndDown,
          'justify-end':
            ![activityTypes.WEI, activityTypes.GLY].includes(activityTypeCode) || $vuetify.breakpoint.lgAndDown,
          'grey--text':
            isDelayedValue(patientVitalSign, activityTypeCode) &&
            ![activityTypes.WEI, activityTypes.GLY].includes(activityTypeCode),
          'red--text': isVitalSignValueAnAlert(),
        }"
      >
        <v-icon
          v-if="isVitalSignValueAnAlert()"
          :class="$vuetify.breakpoint.lgAndDown ? 'pr-1' : 'pr-2'"
          color="red"
          small
          >mdi-alert-circle
        </v-icon>

        <div
          v-for="(value, valueIndex) in patientVitalSign.patientActivityIotRealtime[activityTypeCode].values"
          :key="valueIndex"
        >
          <div>
            {{
              value +
              (valueIndex !== patientVitalSign.patientActivityIotRealtime[activityTypeCode].values.length - 1
                ? '/'
                : '')
            }}
          </div>
        </div>

        <span v-if="![activityTypes.SAT, activityTypes.BDT].includes(activityTypeCode)">&nbsp;</span
        >{{ $t(`${activityTypeCode}Unit`) }}
      </div>

      <div
        v-if="
          [activityTypes.WEI, activityTypes.GLY].includes(activityTypeCode) ||
          isDelayedValue(patientVitalSign, activityTypeCode)
        "
        class="text--secondary text-body-2 d-flex"
        :class="{
          'text-center': [activityTypes.WEI, activityTypes.GLY].includes(activityTypeCode),
          'justify-end':
            ![activityTypes.WEI, activityTypes.GLY].includes(activityTypeCode) && $vuetify.breakpoint.lgAndDown,
          'justify-center': $vuetify.breakpoint.xl,
        }"
      >
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div
              class="d-flex justify-center align-center"
              :class="{
                'ml-5':
                  $vuetify.breakpoint.lgAndDown && [activityTypes.WEI, activityTypes.GLY].includes(activityTypeCode),
              }"
              v-on="on"
            >
              {{ getRelativeDate(patientVitalSign.patientActivityIotRealtime[activityTypeCode].timeDifference) }}
            </div>
          </template>

          <span>
            {{ getFormatDate(patientVitalSign.patientActivityIotRealtime[activityTypeCode].lastDataDatetime) }}</span
          >
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from '@/translationMixin';
import iotRealtimeMixin from '@/components/PatientIotRealtime/iotRealtimeMixin.js';

import { ActivityTypes } from '@/components/PatientMonitoring/constants.js';

export default {
  name: 'IotRealtimeGridVitalSigns',

  mixins: [iotRealtimeMixin, translationMixin],

  props: {
    activityTypeCode: {
      required: true,
      type: String,
    },

    patientVitalSign: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      activityTypes: ActivityTypes,
    };
  },

  methods: {
    isVitalSignValueAnAlert: function () {
      return this.patientVitalSign.patientActivityIotRealtime[this.activityTypeCode]?.hasAlert;
    },
  },
};
</script>

<style scoped>
.width-100 {
  width: 100%;
}
</style>

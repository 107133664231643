import { EmoticonCodes } from '@/components/Dialogue/constants';

export const EmoticonList = [
  { code: EmoticonCodes.EXCITED, mdi: 'mdi-emoticon-excited-outline' },
  { code: EmoticonCodes.HAPPY, mdi: 'mdi-emoticon-happy-outline' },
  { code: EmoticonCodes.NEUTRAL, mdi: 'mdi-emoticon-neutral-outline' },
  { code: EmoticonCodes.SAD, mdi: 'mdi-emoticon-sad-outline' },
  { code: EmoticonCodes.SICK, mdi: 'mdi-emoticon-sick-outline' },
  { code: EmoticonCodes.ANGRY, mdi: 'mdi-emoticon-angry-outline' },
];

export default {
  methods: {
    getMdiEmoticon(code) {
      const emoticon = EmoticonList.find((emoticon) => emoticon.code === code);
      if (!emoticon) {
        return null;
      }
      return emoticon.mdi;
    },
  },
};

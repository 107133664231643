import { Hub } from '@aws-amplify/core';

/**
 * @typedef {string} ListenerEventsEnum
 **/

/**
 * Enum for listener events (values taken from https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/)
 * @readonly
 * @enum {ListenerEventsEnum}
 */
export const ListenerEvents = {
  signIn: 'signIn',
  signOut: 'signOut',
};

export class AuthListener {
  constructor() {
    this.handlers = {};
    Hub.listen('auth', (data) => this.raise(data?.payload?.event));
  }

  /**
   * Calls all the handlers registered for the specified event
   * @param {ListenerEventsEnum} event
   */
  raise(event) {
    if (typeof event !== 'string') {
      return;
    }
    let handlers = this.handlers[event];

    if (handlers) {
      handlers.forEach((h) => {
        h(event);
      });
    }
  }

  /**
   * Register a new handler for the specified event
   * @param {ListenerEventsEnum} event
   * @param {*} handler
   */
  on(event, handler) {
    if (!this.handlers[event]) {
      this.handlers[event] = [];
    }
    this.handlers[event].push(handler);
  }

  /**
   * Unregister a registered handler for the specified event
   * @param {ListenerEventsEnum} event
   * @param {*} handler
   */
  off(event, handler) {
    if (!this.handlers[event]) {
      return;
    }

    this.handlers[event] = this.handlers[event].filter((h) => h !== handler);
  }
}

export default new AuthListener();

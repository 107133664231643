<template>
  <div>
    <v-tabs v-model="tab" align-tabs="start" color="primary">
      <v-tab value="notes" @click="updateCurrentTab('notes')">{{ $t('patientNote.notes') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="mt-5">
      <v-tab-item>
        <Notes :patient-id="patientId" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import virtuoseMixin from '@/virtuoseMixin';
import Notes from './PatientNotesTabs/NotesGrid.vue';

export default {
  name: 'PatientNotes',
  components: {
    Notes,
  },
  mixins: [translation, virtuoseMixin],
  props: {
    patientId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    error: null,
    tab: 'notes',
  }),
  watch: {},
  mounted() {},
  methods: {
    updateCurrentTab: function (value) {
      this.tab = value;
    },
  },
};
</script>

<style scoped></style>

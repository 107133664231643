class BaselinesFormData {
  constructor() {
    this.reset();
  }

  reset() {
    this.height = null;
    this.weight = null;
    this.heartRate = null;
    this.systolic = null;
    this.diastolic = null;
  }

  setData(baselines) {
    if (!baselines) {
      this.reset();
      return;
    }
    this.height = baselines.height ? baselines.height : null;
    this.weight = baselines.weight ? baselines.weight : null;
    this.heartRate = baselines.heartRate ? baselines.heartRate : null;
    this.systolic = baselines.systolic ? baselines.systolic : null;
    this.diastolic = baselines.diastolic ? baselines.diastolic : null;
  }
}

export { BaselinesFormData };

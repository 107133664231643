<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="showWaitModal" />
    <v-data-table
      :items="items"
      :expanded="items.filter((x) => x.statusLevelCode !== statusLevelsNO)"
      :headers="headers"
      :footer-props="footerProps"
      :items-per-page="25"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      item-key="alertDate"
      class="elevation-1 mt-4"
      mobile-breakpoint="0"
    >
      <template #item.statusLevelId="{ item }">
        <div>
          <v-icon :color="getIconColor(item.statusLevelCode)">
            {{ getIconName(item.statusLevelCode) }}
          </v-icon>
        </div>
      </template>

      <template #expanded-item="{ item }">
        <td :colspan="headers.length + 1">
          <div class="my-2 ml-10">
            <v-alert
              v-for="(child, index) in item.resultDetails"
              :key="index"
              :text="item.statusLevelCode !== statusLevelsNO"
              :color="getFDEAlertColor(item.statusLevelCode)"
              dense
              class="mb-0"
            >
              <div>
                <span>
                  <span class="d-inline text-body-2">{{ child.alertTime }}</span>
                </span>
                <div
                  v-if="child.alertDetails && item.statusLevelCode !== statusLevelsNO"
                  class="d-inline text-body-2 text--secondary"
                >
                  - {{ $t('fallDetected') }}

                  <span class="video-icon" @click="checkVideoAndOpen(child, index)">
                    <v-icon>mdi-file-video-outline</v-icon>
                  </span>
                  <div v-if="child.errorMessage" class="error-message">{{ child.errorMessage }}</div>
                </div>
              </div>
            </v-alert>
          </div>
        </td>
      </template>
    </v-data-table>

    <div class="legend my-4">
      <div>
        <div class="font-weight-bold text--secondary text-body-2 mb-1">{{ $t('fdeValues') }}</div>
        <div class="legend-items-container">
          <div>
            <v-icon color="green" class="mr-2" small>mdi-check-circle</v-icon>
            <span class="text-body-2">{{ $t('noFallDetected') }}</span>
          </div>
          <div>
            <v-icon color="red" class="mr-2" small>mdi-alert-octagon</v-icon>
            <span class="text-body-2">{{ $t('fallDetected') }}</span>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import translationMixin from '@/translationMixin';
import patientMonitoringService from '@/services/patientMonitoringService';
import iconMixin from '../iconMixin';
import filterMixin from '../filterMixin';
import { StatusLevels } from '../constants';
import { ActivityTypes } from '@/components/PatientMonitoring/constants';

export default {
  name: 'PatientFallDetection',
  mixins: [translationMixin, iconMixin, filterMixin],
  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },
    filters: {
      type: Object,
      required: false,
      default: null,
      validator(value) {
        return value == null || (typeof value === 'object' && !Array.isArray(value));
      },
    },
    activityId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    items: [],
    statusLevels: [],
    sortBy: 'alertDate',
    sortDesc: true,
    error: null,
    showWaitModal: false,
    statusLevelsNO: StatusLevels.NO,
    requestFilters: null,
  }),
  computed: {
    footerProps: function () {
      return {
        itemsPerPageOptions: [5, 10, 25, 50, -1],
        showFirstLastPage: true,
        'items-per-page-text': this.$t('rowsByPage'),
      };
    },
    headers() {
      return [
        {
          text: this.$t('statusLevelId'),
          textalign: 'center',
          value: 'statusLevelId',
          align: 'center',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t('alertDate'),
          value: 'alertDate',
          sortable: false,
          order: 'desc',
        },
      ];
    },
    dateFrom() {
      return this.calculateNonChartDate('start');
    },
    dateTo() {
      return this.calculateNonChartDate('end');
    },
  },
  watch: {
    patientId: function () {
      this.getPatientFallDetectionData();
    },
    filters: function () {
      this.getPatientFallDetectionData();
    },
    requestFilters: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.$emit('update:request-filters', newVal);
        }
      },
      deep: true,
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    init: function () {
      this.getPatientFallDetectionData();
    },
    async getPatientFallDetectionData() {
      const newFilters = this.setRequestFilters(ActivityTypes.FDE);
      this.requestFilters = newFilters;

      try {
        const fdeResponse = await patientMonitoringService.getPatientFallDetection(this.requestFilters);

        this.statusLevels = fdeResponse.statusLevels;

        var statusLevelMap = this.statusLevels.reduce((map, level) => map.set(level.id, level.code), new Map());

        this.items = fdeResponse.results.map((item) => {
          return {
            ...item,
            statusLevelCode: statusLevelMap.get(item.statusLevelId),
          };
        });
      } catch (error) {
        this.error = error;
        this.showWaitModal = false;
      }
    },

    async checkVideoAndOpen(item) {
      try {
        const response = await fetch(item.alertDetails.videoPath, { method: 'HEAD', mode: 'no-cors' });
        if (response.ok || response.type === 'opaque') {
          this.$set(item, 'errorMessage', '');

          window.open(item.alertDetails.videoPath, '_blank');
        } else {
          this.handleError(item, response.status);
        }
      } catch (error) {
        this.handleError(item);
      }
    },

    handleError(item, status) {
      // En attente de l'installation de certains services sur la VM du Groupe Vitae pour travailler la gestion des erreurs
      let errorMessage = '';
      if (status === 404) {
        errorMessage = 'La vidéo est introuvable (Erreur 404).';
      } else {
        errorMessage = 'Une erreur est survenue lors de la récupération de la vidéo.';
      }
      this.$set(item, 'errorMessage', errorMessage);
    },
  },
};
</script>

<style scoped>
.legend {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 80px;
  row-gap: 16px;
}
.legend-items-container {
  display: flex;
  list-style: none;
  flex-direction: row;
  align-items: baseline;
  column-gap: 24px;
}
.video-icon {
  cursor: pointer;
}
</style>

import NotificationRedirect from './NotificationRedirect';

export default {
  moduleName: 'notification',
  routes: [
    {
      path: '/notification/:notificationId',
      name: 'NotificationRedirect',
      component: NotificationRedirect,
      props: (route) => {
        let props = {};
        props.notificationId = Number.parseInt(route.params.notificationId);
        return props;
      },
    },
  ],
};

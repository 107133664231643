<template>
  <div v-show="errArray.length > 0" class="py-3">
    <v-row class="mx-0">
      <v-col cols="auto">
        <v-btn icon color="error" class="ml-2" @click.stop="clearMsgs()">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <div class="red--text">{{ $t('errorMissingParameters') }}</div>
        <span
          v-for="(error, index) in errArray"
          ref="errors"
          :key="index"
          class="mx-2 red--text"
          :aria-labelledBy="error.refId"
        >
          {{ error.message }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import translation from '@/translationMixin';
export default {
  name: 'UserError',
  components: {},
  mixins: [translation],
  props: {
    errorArray: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      errArray: [],
    };
  },
  watch: {
    errorArray: function () {
      this.errArray = this.errorArray.map((x) => x);
    },
  },
  created: function () {},
  methods: {
    clearMsgs: function () {
      this.errArray = [];
      this.$emit('clearMsgs', true);
    },
  },
};
</script>

<style scoped></style>

import api from './baseApiService';

class CallbackRequests {
  async getCallbackRequestFeatureConfig() {
    return await api.get('callbackrequestfeature');
  }

  async getCallbackRequestCount() {
    return await api.get('callbackrequestcount');
  }

  async getCallbackRequests() {
    return await api.get('callbackrequests');
  }

  async updateCallbackRequest(id, data) {
    return await api.put(`callbackrequest/${id}/`, data);
  }
}

export default new CallbackRequests();

import api from './baseApiService';

class CohortService {
  async getCohorts() {
    return await api.get('cohorts');
  }

  async getCohort(cohortId) {
    return await api.get(`cohort/${cohortId}`);
  }

  async deleteCohort(cohortId) {
    return await api.delete(`cohort/${cohortId}/delete/`);
  }

  async createUpdateCohort(data) {
    return await api.post('cohort/', data);
  }

  async getPatientCohorts(patientId) {
    return await api.get(`patientcohorts/${patientId}`);
  }

  async updatePatientCohort(patientId, data) {
    return await api.put(`patientcohort/${patientId}/`, data);
  }
}

export default new CohortService();

import { create as axiosCreate } from 'axios';
import AxiosUtils from './services/axiosUtils';

function CapaHttp(moduleUrl, vue) {
  this.vue = vue;

  this.cognitoLogout = function () {
    const success = (response) => {
      const url = response.data.logoutUrl;
      const node = document.createElement('a');
      node.href = url;
      node.click();
      node.remove();
    };

    const errorCallback = () => {};
    var url = 'api/logout/url';
    this.executeRequestAxios(url, 'GET', null, {}, success, errorCallback, true);
  };

  this.moduleUrl = moduleUrl;
  this.axiosHandle = axiosCreate({ baseURL: '' });

  this.getCsrfToken = function () {
    return AxiosUtils.getCsrfToken();
  };

  this.postConfig = AxiosUtils.getPostConfig();

  this.postBlobConfig = Object.assign({ responseType: 'blob' }, this.postConfig);

  this.fileUploadConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      'X-CSRFToken': this.getCsrfToken(),
    },
  };

  this.postFormConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRFToken': this.getCsrfToken(),
    },
  };

  this.redirectHomeOn401 = function (error) {
    if (typeof error.response != 'undefined' && error.response.status == 401) {
      this.cognitoLogout();
    }
  };

  this.executeRequestAxios = async function (
    url,
    method,
    data = null,
    config = {},
    successCallBack,
    errorCallback,
    exactUrl = false
  ) {
    var promise = null;
    var totalUrl = exactUrl == false ? this.moduleUrl + '/' + url : url;

    await AxiosUtils.setAccessToken(config);

    switch (method) {
      case 'GET':
        promise = this.axiosHandle.get(totalUrl, config);
        break;

      case 'POST':
        promise = this.axiosHandle.post(totalUrl, data, config);
        break;

      case 'PATCH':
        promise = this.axiosHandle.patch(totalUrl, data, config);
        break;

      case 'PUT':
        promise = this.axiosHandle.put(totalUrl, data, config);
        break;

      case 'DELETE':
        promise = this.axiosHandle.delete(totalUrl, config);
        break;
    }

    var vue = this.vue;

    var successCallBackWrapper = function (response) {
      successCallBack.call(vue, response);
    };

    var errorCallbackWrapper = function (error) {
      errorCallback.call(vue, error);
    };

    promise.then(successCallBackWrapper, errorCallbackWrapper);
  };

  this.get = function (url, successCallBack, errorCallback) {
    this.executeRequestAxios(url, 'GET', null, {}, successCallBack, errorCallback);
  };

  this.getWithConfig = function (url, config, successCallBack, errorCallback) {
    this.executeRequestAxios(url, 'GET', null, config, successCallBack, errorCallback);
  };

  this.getFile = function (url, successCallBack, errorCallback) {
    this.executeRequestAxios(url, 'GET', null, { responseType: 'blob' }, successCallBack, errorCallback);
  };

  this.post = function (url, data, successCallBack, errorCallback) {
    this.executeRequestAxios(url, 'POST', data, this.postConfig, successCallBack, errorCallback);
  };

  this.postBlob = function (url, data, successCallBack, errorCallback) {
    this.executeRequestAxios(url, 'POST', data, this.postBlobConfig, successCallBack, errorCallback);
  };

  this.postForm = function (url, data, successCallBack, errorCallback) {
    this.executeRequestAxios(url, 'POST', data, this.postFormConfig, successCallBack, errorCallback);
  };

  this.postFile = function (url, data, successCallBack, errorCallback) {
    this.executeRequestAxios(url, 'POST', data, this.fileUploadConfig, successCallBack, errorCallback);
  };

  this.patch = function (url, data, successCallBack, errorCallback) {
    this.executeRequestAxios(url, 'PATCH', data, this.postConfig, successCallBack, errorCallback);
  };

  this.put = function (url, data, successCallBack, errorCallback) {
    this.executeRequestAxios(url, 'PUT', data, this.postConfig, successCallBack, errorCallback);
  };

  this.delete = function (url, successCallBack, errorCallback) {
    var config = AxiosUtils.getDeleteConfig();

    this.executeRequestAxios(url, 'DELETE', null, config, successCallBack, errorCallback);
  };

  this.deleteWithJsonData = function (url, data, successCallBack, errorCallback) {
    var config = {
      data: data,
      'Content-Type': 'application/json',
      headers: { 'X-CSRFToken': this.getCsrfToken() },
    };

    this.executeRequestAxios(url, 'DELETE', null, config, successCallBack, errorCallback);
  };
}

export default {
  methods: {
    getCapaHttp: function (moduleUrl) {
      return new CapaHttp(moduleUrl, this);
    },
  },
};

import getModulesRoutes from './generatedRoutes.js';
import getGlobalComponents from './generatedGlobalComponents.js';
import '@capacitor-community/http';

export default {
  getAllRoutes: function () {
    var modules = getModulesRoutes();
    var allRoutes = modules.flatMap((x) => x);

    return allRoutes;
  },

  addComponentsInVue: function (vue) {
    var allModules = getGlobalComponents();

    allModules.forEach((moduleComponents) => {
      moduleComponents.components.forEach((entry) => {
        vue.component(entry.name, entry.component);
      });
    });
  },
};

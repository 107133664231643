export default {
  methods: {
    closeManualEntryModal: function () {
      this.showVitalSignsManualEntry = false;
    },

    openVitalSignsManualEntryModal: function () {
      this.observations.forEach((x) => {
        x.value = null;

        if (x.name === 'Weight') {
          x.unitType = 'kg';
        } else if (x.name === 'BodyTemperature') {
          x.unitType = '°C';
        }
      });
      this.showVitalSignsManualEntry = !this.showVitalSignsManualEntry;
    },
  },
};

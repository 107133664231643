<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-form ref="monitoringFilterForm">
      <v-row align="start" class="print-end">
        <v-col v-if="useMonitoringStatus" align-self="start" cols="12" sm="3">
          <v-select
            :id="getIdByName('statusLevels')"
            ref="statusLevels"
            v-model="statusLevelFilters"
            class="print-mt-4"
            clearable
            multiple
            :label="$t('statusLevels')"
            :items="statusLevels"
            :item-text="(item) => $t(item.name)"
            item-value="id"
            outlined
            small-chips
            deletable-chips
            :disabled="isFetching"
            :no-data-text="$t('noDataAvailable')"
            :hide-details="$vuetify.breakpoint.xs"
          />
        </v-col>
        <v-col v-if="useMonitoringStatus" align-self="start" :cols="$vuetify.breakpoint.xs ? 12 : false">
          <v-select
            :id="getIdByName('cohortMonitoring')"
            ref="cohortMonitoring"
            v-model="cohortFilters"
            class=""
            clearable
            multiple
            :label="$t('cohortMonitoring')"
            :items="cohorts"
            item-text="name"
            item-value="id"
            outlined
            small-chips
            deletable-chips
            :disabled="isFetching"
            :no-data-text="$t('noDataAvailable')"
            :hide-details="$vuetify.breakpoint.xs"
          />
        </v-col>

        <v-col align-self="start" :cols="$vuetify.breakpoint.xs ? 12 : false">
          <v-select
            :id="getIdByName('filterTypes')"
            ref="filterTypes"
            v-model="timeFilterType"
            :label="$t('timeFilterType')"
            :items="filterTypes"
            item-text="label"
            item-value="value"
            outlined
            :no-data-text="$t('noDataAvailable')"
            :hide-details="$vuetify.breakpoint.xs"
          />
        </v-col>

        <v-col v-if="isTextField" :cols="$vuetify.breakpoint.xs ? 12 : 'auto'">
          <v-text-field
            ref="hoursDays"
            v-model.number="timeFilterValue"
            type="number"
            :label="hoursDaysLabel"
            outlined
            class="required-indicator"
            required
            min="1"
            :rules="[
              validationRules.inRange(1, timeFilterType === 'days' ? maxDays : maxHours),
              validationRules.required,
            ]"
            @input="
              () => {
                resetIfNegative();
                validateTimeFilter();
              }
            "
          />
        </v-col>

        <v-col v-if="isRange" :cols="$vuetify.breakpoint.xs ? 12 : 'auto'">
          <DateTimePickerField
            ref="fromDate"
            v-model="dateFrom"
            :max-date="dateTo ? dateTo : new Date()"
            :min-date="minDateToSelect"
            :label="$t('fromDate')"
            picker-type="dateTime"
            required
            outlined
          />
        </v-col>
        <v-col v-if="isRange" :cols="$vuetify.breakpoint.xs ? 12 : 'auto'">
          <DateTimePickerField
            ref="toDate"
            v-model="dateTo"
            :min-date="dateFrom ? dateFrom : minDate"
            :max-date="new Date()"
            :label="$t('toDate')"
            required
            outlined
            picker-type="dateTime"
          />
        </v-col>

        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 'auto'">
          <div class="d-flex flex-row">
            <v-btn
              ref="apply"
              color="primary"
              :text="!$vuetify.breakpoint.xs"
              class="mt-2 ml-auto"
              :disabled="isFetching || !isValid || isDisabled"
              @click.stop="emitFilter()"
            >
              <v-icon>mdi-filter</v-icon>{{ $t('apply') }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { TimeFilterType, StatusLevels } from './constants';
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import cohortsService from '@/services/cohortsService';
import patientMonitoringService from '@/services/patientMonitoringService';

export default {
  name: 'PatientMonitoringFilter',
  components: {},
  mixins: [translationMixin, accessibility, validationRulesMixin],

  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialValues: {
      type: Object,
      required: false,
      default: null,

      validator(value) {
        return value == null || (typeof value === 'object' && !Array.isArray(value));
      },
    },

    useMonitoringStatus: {
      type: Boolean,
      required: false,
      default: true,
    },

    configurationValues: {
      type: Object,
      required: false,
      default: null,
    },

    showIncontinence: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      timeFilterType: TimeFilterType.LAST24,
      timeFilterValue: 1,
      minDate: '2022-11-01 08:00:00',
      statusLevelFilters: this.configurationValues?.statusLevelIds ?? [],
      allStatusLevels: [],
      cohorts: [],
      cohortFilters: this.configurationValues?.cohortIds ?? [],
      dateFrom: null,
      dateTo: null,
      error: null,
      isFetching: false,
      isValid: true,
      maxDays: 30,
      maxHours: 72,
      minDateToSelect: new Date('2018-01-01 00:00:00'),
    };
  },

  computed: {
    filterTypes: function () {
      return [TimeFilterType.LAST24, TimeFilterType.DAYS, TimeFilterType.HOURS, TimeFilterType.RANGE].map((item) => {
        return { label: this.$t('filter' + item), value: item };
      });
    },

    isTextField: function () {
      return this.timeFilterType === TimeFilterType.HOURS || this.timeFilterType === TimeFilterType.DAYS;
    },

    isRange: function () {
      return this.timeFilterType === TimeFilterType.RANGE;
    },

    hoursDaysLabel: function () {
      return this.timeFilterType === TimeFilterType.HOURS ? this.$t('nbHours') : this.$t('nbDays');
    },

    statusLevels: function () {
      const incontinenceCodes = [StatusLevels.DR, StatusLevels.WE, 'LH', 'MH', StatusLevels.CH];

      if (this.showIncontinence) {
        return this.allStatusLevels;
      } else {
        return this.allStatusLevels.filter((x) => !incontinenceCodes.includes(x.code));
      }
    },
  },

  watch: {
    configurationValues: function () {
      this.statusLevelFilters = this.configurationValues?.statusLevelIds ?? [];
      this.cohortFilters = this.configurationValues?.cohortIds ?? [];
    },

    initialValues: function () {
      this.init();
    },

    timeFilterType: function () {
      this.resetIfNegative();
      this.validateTimeFilter();
    },

    dateFrom: function () {
      if (!this.dateFrom || !this.dateTo) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
    },

    dateTo: function () {
      if (!this.dateFrom || !this.dateTo) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
    },
  },

  created: function () {
    this.init();
  },

  methods: {
    async init() {
      this.isFetching = true;
      this.setInitialData();

      try {
        let [allCohorts, allStatusLevels] = await Promise.all([
          cohortsService.getCohorts(),
          patientMonitoringService.getStatusLevels(),
        ]);

        this.cohorts = allCohorts;
        this.allStatusLevels = allStatusLevels;
      } catch (error) {
        this.error = error;
      }

      this.isFetching = false;
    },

    setInitialData: function () {
      if (this.initialValues != null) {
        this.timeFilterType = this.initialValues.timeFilterType || TimeFilterType.LAST24;
        this.timeFilterValue = this.initialValues.timeFilterValue || 0;
        this.dateFrom = this.initialValues.dateFrom || null;
        this.dateTo = this.initialValues.dateTo || null;
        this.statusLevelFilters = this.initialValues.statusLevelIds || [];
        this.cohortFilters = this.initialValues.cohortFiltersIds || [];
      }
    },

    resetIfNegative: function () {
      if (this.timeFilterValue === 0 || this.timeFilterValue < 0) this.timeFilterValue = 1;
    },

    validateTimeFilter: function () {
      switch (this.timeFilterType) {
        case TimeFilterType.DAYS:
          this.isValid =
            this.timeFilterValue >= 1 &&
            this.timeFilterValue <= this.maxDays &&
            this.timeFilterValue.toString().length < 3
              ? true
              : false;
          this.$nextTick(() => {
            this.$refs.monitoringFilterForm.validate();
          });
          break;

        case TimeFilterType.HOURS:
          this.isValid =
            this.timeFilterValue >= 1 &&
            this.timeFilterValue <= this.maxHours &&
            this.timeFilterValue.toString().length < 3
              ? true
              : false;
          this.$nextTick(() => {
            this.$refs.monitoringFilterForm.validate();
          });
          break;

        case TimeFilterType.LAST24:
          this.isValid = true;
          break;

        case TimeFilterType.RANGE:
          if (!this.dateFrom || !this.dateTo) {
            this.isValid = false;
          }
          break;
      }
    },

    async emitFilter() {
      this.isValid = this.$refs.monitoringFilterForm.validate();
      if (!this.isValid) return;

      const timeFilterValue = parseInt(this.timeFilterValue, 10);

      var statusLevelFilters = this.statusLevelFilters.length === 0 ? null : this.statusLevelFilters;
      var cohortFilters = this.cohortFilters.length === 0 ? null : this.cohortFilters;

      var result = {
        timeFilterType: this.timeFilterType,
        timeFilterValue: timeFilterValue,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        statusLevelIds: statusLevelFilters,
        cohortPatients: cohortFilters,
      };
      this.$emit('filter', result);
    },
  },
};
</script>

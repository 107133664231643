<template>
  <div class="d-flex justify-end align-center flex-wrap" :class="$vuetify.breakpoint.xs ? ['mx-1'] : ['mx-5']">
    <div class="d-flex align-center mr-7">
      <div class="text-body-2">{{ $t('resultByPage') }}</div>
      <div class="max-width-70 mx-7">
        <v-select
          v-model="paginationData.itemsPerPage"
          class="pagination-select"
          item-text="name"
          item-value="value"
          :items="itemsPerPageOptions"
          @change="changeItemsPerPage"
        />
      </div>
      <div class="text-body-2">{{ getPaginationValuesText() }}</div>
    </div>
    <div class="d-flex">
      <div class="d-flex">
        <v-btn icon :disabled="disablePageButton(1)" @click="changePageTo(1)">
          <v-icon>mdi-page-first</v-icon>
        </v-btn>
        <v-btn icon :disabled="disablePageButton(1)" @click="changePageTo(paginationData.currentPage - 1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
      <div class="d-flex">
        <v-btn
          :disabled="disablePageButton(getPaginationTotalPages())"
          icon
          @click="changePageTo(paginationData.currentPage + 1)"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          icon
          :disabled="disablePageButton(getPaginationTotalPages())"
          @click="changePageTo(getPaginationTotalPages())"
        >
          <v-icon>mdi-page-last</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import accessibility from '@/accessibilityMixin';
import translation from '@/translationMixin';

let paginationData = {
  itemsPerPage: 5,
  currentPage: 1,
  startIndex: 0,
  endIndex: 5,
};

export default {
  name: 'Pagination',

  mixins: [translation, accessibility],

  props: {
    count: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      itemsPerPageOptions: [
        {
          name: 5,
          value: 5,
        },
        {
          name: 10,
          value: 10,
        },
        {
          name: 25,
          value: 25,
        },
        {
          name: 50,
          value: 50,
        },
        {
          name: this.$t('all'),
          value: 'all',
        },
      ],
      paginationData: paginationData,
      previousItemPerPageValue: paginationData.itemsPerPage,
    };
  },

  mounted() {
    this.setStartAndEndIndexes();
  },

  methods: {
    setStartAndEndIndexes() {
      const itemsPerPage = paginationData.itemsPerPage === 'all' ? this.count : paginationData.itemsPerPage;
      paginationData.startIndex = Math.min((paginationData.currentPage - 1) * itemsPerPage, this.count);
      paginationData.endIndex = Math.min(paginationData.startIndex + itemsPerPage, this.count);
      if (this.count === 0) {
        paginationData.startIndex = 0;
        paginationData.endIndex = 0;
      }
    },

    changeItemsPerPage(itemsPerPageValue) {
      paginationData.itemsPerPage = itemsPerPageValue;
      paginationData.currentPage = 1;
      this.setStartAndEndIndexes();
      this.$emit('items-per-page-update', paginationData.itemsPerPage);
      this.$emit('page-update', paginationData.currentPage);
      if (itemsPerPageValue < this.previousItemPerPageValue) {
        if (itemsPerPageValue === 5) {
          window.scrollTo(0, 0); // Without this line, when we go from selecting 10 items to 5 there's a glitch
        } else {
          this.$refs?.gridContent?.scrollIntoView({
            behavior: 'instant',
            block: 'end',
          });
        }
      }
      this.previousItemPerPageValue = paginationData.itemsPerPage === 'all' ? this.count : paginationData.itemsPerPage;
    },

    getPaginationValuesText() {
      return this.$t('paginationPagesNumber')
        .replace('{{startIndex}}', paginationData.startIndex + 1)
        .replace('{{endIndex}}', this.count === 0 ? 0 : paginationData.endIndex)
        .replace('{{totalData}}', this.count);
    },

    disablePageButton(limitPage) {
      return paginationData.currentPage === limitPage || paginationData.itemsPerPage === 'all' || this.count === 0;
    },

    getPaginationTotalPages() {
      return Math.ceil(this.count / paginationData.itemsPerPage);
    },

    changePageTo(page) {
      paginationData.currentPage = page;
      this.setStartAndEndIndexes();
      this.$emit('page-update', page);
    },
  },
};
</script>

<style scoped>
.pagination-select {
  width: min-content;
  font-size: 0.875rem;
}
</style>

<template>
  <div>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.smAndUp"
      ref="cbrNavigationDrawer"
      v-model="showCallbackRequestsNavigation"
      v-click-outside="closeCallbackRequestsNavigation"
      width="520px"
      app
      disable-resize-watcher
      fixed
      temporary
      right
    >
      <v-card flat>
        <div class="d-flex align-center justify-space-between">
          <v-card-title class="font-weight-black">{{ $t('callbackRequest.pluralName') }}</v-card-title>
          <v-btn class="mr-6" icon @click="closeCallbackRequestsNavigation"><v-icon>mdi-close</v-icon></v-btn>
        </div>

        <CallbackRequestList
          ref="callbackRequestList"
          @closeCallbackRequestsNavigation="closeCallbackRequestsNavigation"
        />
      </v-card>
    </v-navigation-drawer>

    <v-bottom-sheet
      v-else
      ref="cbrBottomSheet"
      v-model="showCallbackRequestsNavigation"
      scrollable
      @input="closeBottomSheetIfBottomSheetIsFalse"
    >
      <v-card max-height="60vh">
        <v-card-title class="font-weight-black">{{ $t('callbackRequest.pluralName') }}</v-card-title>
        <v-card-text class="pa-0">
          <CallbackRequestList
            ref="callbackRequestList"
            @closeCallbackRequestsNavigation="closeCallbackRequestsNavigation"
          />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import translationMixin from '@/translationMixin';
import CallbackRequestList from './CallbackRequestList.vue';

export default {
  name: 'CallbackRequestNavigation',
  components: {
    CallbackRequestList,
  },
  mixins: [translationMixin],

  data() {
    return {
      showCallbackRequestsNavigation: true,
    };
  },

  methods: {
    closeBottomSheetIfBottomSheetIsFalse: function (isMobileUi) {
      if (!isMobileUi) {
        this.closeCallbackRequestsNavigation();
      }
    },

    closeCallbackRequestsNavigation(isAnyCallbackRequest = true) {
      this.showCallbackRequestsNavigation = false;
      this.$emit('closeNavigationDrawer', isAnyCallbackRequest);
    },
  },
};
</script>

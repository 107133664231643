import CohortHome from './CohortHome.vue';

export default {
  moduleName: 'cohort',
  routes: [
    {
      path: '/cohort',
      name: 'CohortHome',
      component: CohortHome,
    },
  ],
};

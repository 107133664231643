<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-icon size="19" :color="getBatteryIconColorOnDeviceBattery()" v-on="on">
        {{ getBatteryLevelIconOnDeviceBattery() }}
      </v-icon>
    </template>

    <span> {{ `${$t('iotRealtime.remainingBattery')} ${batteryLevel}%` }}</span>
  </v-tooltip>
</template>

<script>
import translationMixin from '@/translationMixin';

export default {
  name: 'IotRealtimeBatteryIcon',

  mixins: [translationMixin],

  props: {
    batteryLevel: {
      required: true,
      type: Number,
    },
  },

  methods: {
    getBatteryLevelIconOnDeviceBattery: function () {
      if (this.batteryLevel > 90) return 'mdi-battery';

      if (this.batteryLevel > 0) {
        const roundedUpBatteryLevel = Math.ceil(this.batteryLevel / 10) * 10;
        return `mdi-battery-${roundedUpBatteryLevel}`;
      }

      return 'mdi-battery-off-outline';
    },

    getBatteryIconColorOnDeviceBattery: function () {
      switch (true) {
        case this.batteryLevel <= 20:
          return 'red';

        case this.batteryLevel <= 30:
          return 'amber';

        default:
          return 'black';
      }
    },
  },
};
</script>

import { parse, format, setMinutes, setHours, addHours, subHours, parseISO } from 'date-fns';
import { TimeFilterType } from './constants';

export default {
  methods: {
    calculateNonChartDate(timeOfDay) {
      if (this.filters?.notificationDatetime) {
        const currentDate = new Date();

        const dateString = this.filters.notificationDatetime;
        const formatString = 'yyyy-MM-dd HH:mm:ss';
        const parsedDate = parse(dateString, formatString, currentDate);
        const adjustedDate =
          timeOfDay === 'start' ? setMinutes(setHours(parsedDate, 0), 0) : setMinutes(setHours(parsedDate, 23), 59);

        return format(adjustedDate > currentDate ? currentDate : adjustedDate, 'yyyy-MM-dd HH:mm:ss');
      }
      return null;
    },

    calculateChartDate(timeOfDay) {
      if (this.filters?.alertTime || this.filters?.notificationDatetime) {
        const dateString = this.filters.alertTime || this.filters.notificationDatetime;
        let parsedDate;

        if (this.filters.alertTime) {
          parsedDate = parseISO(dateString);
        } else {
          const formatString = 'yyyy-MM-dd HH:mm:ss';
          parsedDate = parse(dateString, formatString, new Date());
        }

        const adjustedDate = timeOfDay === 'start' ? subHours(parsedDate, 12) : addHours(parsedDate, 12);
        return format(adjustedDate, 'yyyy-MM-dd HH:mm:ss');
      }
      return null;
    },

    setRequestFilters(activityTypeCode) {
      return {
        patientId: this.patientId,
        timeFilterType: this.filters?.notificationDatetime
          ? TimeFilterType.RANGE
          : this.filters?.timeFilterType ?? null,
        timeFilterValue: this.filters?.timeFilterValue ?? null,
        dateFrom: this.dateFrom || (this.filters?.dateFrom ?? null),
        dateTo: this.dateTo || (this.filters?.dateTo ?? null),
        activityTypeCode: activityTypeCode ?? null,
        activityId: this.activityId,
        fromNotification: this.filters?.notificationDatetime || this.filters?.fromNotification ? true : false,
      };
    },
  },
};

import { AbilityBuilder, Ability } from '@casl/ability';

const permissionsRegEx = /(?<appname>[^.]*)\.(?<action>[^_]*)_(?<subject>.*)/;

export function defineAbility(permissions) {
  const { can, build } = new AbilityBuilder(Ability);

  if (Array.isArray(permissions)) {
    permissions.forEach((permission) => {
      /** Permissions from the backend must be in the format appname.action_subject (for example 'virtuose.change_patients').
       * We extract the action and generate an ability with this action/subject (for example can('change', 'virtuose.patients'))
       */
      let match = permission.match(permissionsRegEx);
      if (match && match.groups?.appname && match.groups?.action && match.groups?.subject) {
        can(match.groups.action, `${match.groups.appname}.${match.groups.subject}`);
      }
    });
  }
  return build();
}

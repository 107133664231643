export default {
  en: {
    iot: 'Connected Devices',
    iotDeviceType: 'Device',
    iotDeviceCompany: 'Supplier',
    iotDeviceModel: 'Model',
    iotDeviceModelNumber: '#Model',
    iotDeviceSerialNumber: '#Serie',
    iotDeviceURL: 'Device URL',
    iotDeviceVersion: 'Version',
    iotDeviceStatus: 'Status',
    iotConfiguration: 'IOT Configuration',
    iotRoomConfiguration: 'IOT Room Configuration',
    iotDeviceAssociated: 'Assiociated',
    configure: 'Configure',
    createIot: 'Add device',
    editIot: 'Modify device',
    deleteIotQuestion: 'Are you sure you want to delete this Device?',
    errorIOTDeviceStatusMustBeProvided: 'IOT device status must be provided',
    errorIOTDeviceTypeMustBeProvided: 'IOT device type must be provided',
    errorModelMustBeProvided: 'IOT device model must be provided',
    errorSerieMustBeProvided: 'IOT device serial number must be provided',
    errorIOTCannotBeConfigurated: 'This IOT device type cannot be configurated',
    errorConfiguratedStatusDoesNotExists: 'Status configured does not exists',
    errorPatientVirtualAssistantNotConfigured: 'IOT device is not configured',

    currentPatientIOT: 'Patient IOT Device(s)',
    availableIOT: 'Available IOT Device(s)',
    patientIOTDevices: 'Assign IOT Device(s)',
    DOmedicPatientDoesNotExist: 'The patient must be created by the partner',
    noAssociationKeyFound: 'No association key found by the partner for this patient',

    'patientDevice.patientAssociationKey': 'Patient Association Key',
    'patientDevice.patientAssociationCode': 'Association Code',
    'patientDevice.associationTenantName ': 'Environment name',
    'patientDevice.deviceConfiguration': 'Patient Device Configuration',
    'patientDevice.enterAssociationKeyInxPill':
      'Enter this association key in the xPill application in order to complete the configuration',
    'patientDevice.scanQrCodeToAssociateBalance':
      'Scan this QR code in LeoMed application in order to complete the configuration',
    'patientDevice.associate': 'Associate',
    'patientDevice.remove': 'remove',
    'patientDevice.enterAssociationCodeInVA':
      'This information must be entered into the Virtual Assistant application in order to complete the configuration',
    'patientDevice.VaAlreadyConfigured': 'This Virtual Assistant is configured for the patient',

    'patientDevice.questionDissociate':
      'After the disassociation, the patient will no longer be able to use this connected device. Do you really want to continue?',
    'patientDevice.continue': 'Continue',

    patientAlreadyHasABalance: 'This patient is already associated with a Withings scale',
    patientAlreadyHasAnxPill: 'This patient is already associated with an xPill',
    anyPartnerLinkedToThisIOTDeviceModel: 'No partner configured for this connected device',
  },
  fr: {
    iot: 'Objets connectés',
    iotDeviceType: 'Objet',
    iotDeviceCompany: 'Fournisseur',
    iotDeviceModel: 'Modèle',
    iotDeviceModelNumber: '#Modèle',
    iotDeviceSerialNumber: '#Série',
    iotDeviceURL: "URL de l'appareil",
    iotDeviceVersion: 'Version',
    iotDeviceStatus: 'Statut',
    iotConfiguration: "Configuration de l'object connecté",
    iotRoomConfiguration: "Configuration de la chambre de l'object connecté",
    iotDeviceAssociated: 'Associé',
    configure: 'Configurer',
    createIot: 'Ajouter un appareil',
    editIot: "Modifier l'appareil",
    deleteIotQuestion: 'Êtes-vous certain de vouloir supprimer cet appareil ?',
    errorIOTDeviceStatusMustBeProvided: "Le statut de l'objet connecté doit être spécifié",
    errorIOTDeviceTypeMustBeProvided: "Le type de l'objet connecté doit être spécifié",
    errorModelMustBeProvided: "Le modèle de l'objet connecté doit être spécifié",
    errorSerieMustBeProvided: "Le numéro de série de l'objet connecté doit être spécifié",
    errorIOTCannotBeConfigurated: "Ce type d'objet connecté ne peut être configuré",
    errorConfiguratedStatusDoesNotExists: "Le statut configuré n'existe pas",
    errorPatientVirtualAssistantNotConfigured: "L'objet connecté n'est pas configuré",

    currentPatientIOT: 'Objet(s) connecté(s) du patient',
    availableIOT: 'Objet(s) connecté(s) disponible(s)',
    patientIOTDevices: 'Assignation objet(s) connecté(s)',
    DOmedicPatientDoesNotExist: 'Le patient doit être créé chez le partenaire',
    noAssociationKeyFound: "Aucune clé d'association trouvée chez le partenaire pour ce patient",

    'patientDevice.patientAssociationKey': "Clé d'association du patient",
    'patientDevice.patientAssociationCode': "Code d'association",
    'patientDevice.associationTenantName ': "Nom de l'environnement",
    'patientDevice.deviceConfiguration': "Configuration du patient et de l'objet connecté",
    'patientDevice.enterAssociationKeyInxPill':
      'Cette clé d’association doit être saisie dans l’application xPill afin de compléter la configuration',
    'patientDevice.scanQrCodeToAssociateBalance':
      'Ce code QR doit être scanné dans l’application LeoMed afin de compléter la configuration',
    'patientDevice.associate': 'Associer',
    'patientDevice.remove': 'Désassocier',
    'patientDevice.enterAssociationCodeInVA':
      'Ces informations doivent être saisies dans l’application Assistant Virtuel afin de compléter la configuration',
    'patientDevice.VaAlreadyConfigured': 'Cet Assistant Virtuel a été configuré pour le patient',
    'patientDevice.questionDissociate':
      'Suite à la désassociation le patient ne pourra plus utiliser cet objet connecté. Désirez-vous vraiment continuer ?',
    'patientDevice.continue': 'Continuer',

    patientAlreadyHasABalance: 'Ce patient est déjà associé à une balance Withings',
    patientAlreadyHasAnxPill: 'Ce patient est déjà associé à un xPill',
    anyPartnerLinkedToThisIOTDeviceModel: "Aucun partenaire configuré pour ce type d'objet connecté",
  },
};

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import './assets/css/main.css';
import { abilitiesPlugin } from '@casl/vue';
import { defineAbility } from './auth/ability';

import Plugins from '@/components/CustomPlugins/plugins.js';

import IconButtonAndTooltip from '@/components/IconButtonAndTooltip';
import IconInfoAndTooltip from '@/components/IconInfoAndTooltip';
import ItemsPaginator from '@/components/ItemsPaginator';
import WaitModal from '@/components/WaitModal';
import ErrorModal from '@/components/ErrorModal';
import DeletionModal from '@/components/DeletionModal';
import SuccessModal from '@/components/SuccessModal';
import ModalTitle from '@/components/ModalTitle';
import PageTitle from '@/components/PageTitle';
import UserError from '@/components/UserError';
import ButtonLink from '@/components/ButtonLink';
import ModificationWarnModal from '@/components/ModificationWarnModal.vue';
import ConfirmationModal from '@/components/ConfirmationModal';
import ConfirmationMessage from '@/components/ConfirmationMessage';
import DateTimePickerField from '@/components/DateTimePickerField';
import DetailField from '@/components/DetailField.vue';
import RequiredFieldsLegend from '@/components/RequiredFieldsLegend.vue';

Vue.config.productionTip = false;

Vue.use(abilitiesPlugin, defineAbility());

Vue.component('IconButtonAndTooltip', IconButtonAndTooltip);
Vue.component('ItemsPaginator', ItemsPaginator);
Vue.component('WaitModal', WaitModal);
Vue.component('ErrorModal', ErrorModal);
Vue.component('ModalTitle', ModalTitle);
Vue.component('PageTitle', PageTitle);
Vue.component('UserError', UserError);
Vue.component('SuccessModal', SuccessModal);
Vue.component('DeletionModal', DeletionModal);
Vue.component('ButtonLink', ButtonLink);
Vue.component('ModificationWarnModal', ModificationWarnModal);
Vue.component('ConfirmationModal', ConfirmationModal);
Vue.component('ConfirmationMessage', ConfirmationMessage);
Vue.component('DateTimePickerField', DateTimePickerField);
Vue.component('IconInfoAndTooltip', IconInfoAndTooltip);
Vue.component('DetailField', DetailField);
Vue.component('RequiredFieldsLegend', RequiredFieldsLegend);

Plugins.addComponentsInVue(Vue);

var createVue = async function () {
  new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
};

createVue();

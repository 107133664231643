import api from './baseApiService';
export class PatientMonitoringAlertService {
  async getPatientMonitoringAlerts() {
    return await api.get('monitoringalertrealtime');
  }

  async resolveMonitoringAlert(patientId, data) {
    return await api.put(`monitoringalert/${patientId}/resolve/`, data);
  }

  async getPendingPriorityAlertsTypes() {
    return await api.get('pendingpriorityalert');
  }
}

export default new PatientMonitoringAlertService();

<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent :max-width="dialogMaxWidth">
      <v-card>
        <ModalTitle :title="title" />

        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <v-form ref="resourceModalForm" lazy-validation>
            <v-row>
              <v-col cols="12" :md="12">
                <v-text-field
                  ref="resourceName"
                  v-model="formData.resourceName"
                  filled
                  :label="$t('resources.resourceName')"
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-textarea
                  ref="resourceDescription"
                  v-model="formData.resourceDescription"
                  filled
                  :label="$t('resources.resourceDescription')"
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-row>
                  <v-col :cols="(!formData.uploadedFile && !resourceId) || $vuetify.breakpoint.xs ? 12 : 10">
                    <v-file-input
                      ref="upload"
                      v-model="formData.uploadedFile"
                      :label="$t('resources.resourceFile')"
                      accept=".pdf"
                      filled
                      :required="true"
                      truncate-length="40"
                      :rules="
                        !formData.resourceFile ? [validationRules.required, validateFileType] : [validateFileType]
                      "
                      :prepend-icon="fileExistIcon"
                      :counter="false"
                      :placeholder="truncatedFileName"
                      :persistent-placeholder="!!truncatedFileName"
                      :hint="$t('fileSupportedText')"
                      :persistent-hint="true"
                      class="required-indicator"
                    />
                  </v-col>

                  <v-col v-if="formData.uploadedFile || resourceId" cols="2">
                    <v-btn
                      ref="openFileBtn"
                      :class="$vuetify.breakpoint.smAndUp ? 'mt-4' : ''"
                      :disabled="isProcessing || showWaitModal"
                      color="primary"
                      small
                      @click="openUploadedFile"
                      >{{ $t('resources.openResource') }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <RequiredFieldsLegend />
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>

          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>

          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="handleSaveMode"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import permissionMixin from '@/auth/permissionMixin';
import ResourceService from '@/services/resourceService';
import { ResourcesFormData } from './ResourcesFormData';
import SaveButton from '@/components/SaveButton.vue';

export default {
  name: 'ResourceModal',
  components: { SaveButton },
  mixins: [translation, accessibility, validationRulesMixin, permissionMixin],

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    resourceData: {
      type: Object,
      required: false,
      default: null,
    },
    resourceId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      error: null,
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      title: '',
      formData: new ResourcesFormData({}),
      fileExistIcon: 'mdi-file-document',
    };
  },
  computed: {
    dialogMaxWidth: function () {
      return '800px';
    },
    truncatedFileName: function () {
      const file = this.formData.uploadedFile;
      if (file && file.name) {
        const name = file.name;
        return this.truncateText(name);
      }
      return this.formData.resourceOriginalFile;
    },
  },
  watch: {
    show: async function () {
      if (this.show) {
        if (this.resourceId && this.resourceId !== null) {
          this.initEdition();
        } else {
          this.init();
        }
      } else {
        this.formData.reset();
      }
    },
  },
  methods: {
    init: function () {
      this.title = this.$t('resources.addNewResource');
      this.formData.reset();
    },

    initEdition: function () {
      this.title = this.$t('resources.editResource');
      this.formData.setData(this.resourceData);
    },

    truncateText: function (text, truncLength = 40) {
      if (text) return text.length > truncLength ? text.slice(0, truncLength) + '...' : text;
    },

    handleSaveMode() {
      if (this.resourceId) {
        this.editAction();
      } else {
        this.createAction();
      }
    },

    async createAction() {
      const isValid = this.$refs.resourceModalForm.validate();
      if (!isValid || this.isProcessing) return;

      this.showWaitModal = true;
      this.isProcessing = true;

      const formData = new FormData();
      formData.append('resourceFile', this.formData.uploadedFile);
      formData.append('resourceName', this.formData.resourceName);
      formData.append('resourceFormat', this.formData.resourceFormat);
      formData.append('resourceDescription', this.formData.resourceDescription);

      try {
        await ResourceService.createResource(formData);
        this.closeDialog();
        this.$emit('refresh', true);
      } catch (error) {
        this.error = error;
      } finally {
        this.showWaitModal = false;
        this.isProcessing = false;
      }
    },

    async editAction() {
      const isValid = this.$refs.resourceModalForm.validate();
      if (!isValid || this.isProcessing) return;

      this.showWaitModal = true;
      this.isProcessing = true;

      const formData = new FormData();
      formData.append('resourceFile', this.formData.uploadedFile);
      formData.append('resourceName', this.formData.resourceName);
      formData.append('resourceFormat', this.formData.resourceFormat);
      formData.append('resourceDescription', this.formData.resourceDescription);

      try {
        await ResourceService.updateResource(this.resourceId, formData);
        this.closeDialog();
        this.$emit('refresh', true);
      } catch (error) {
        this.error = error;
      } finally {
        this.showWaitModal = false;
        this.isProcessing = false;
      }
    },

    openUploadedFile: async function () {
      this.showWaitModal = true;

      const uploadedFile = this.formData.uploadedFile;

      try {
        let fileUrlToOpen;

        if (uploadedFile) {
          fileUrlToOpen = URL.createObjectURL(uploadedFile);
        } else {
          const resourceFile = await ResourceService.getResourceUrl(this.resourceId);
          fileUrlToOpen = resourceFile.url;
        }

        window.open(fileUrlToOpen, '_blank');
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },

    validateFileType(value) {
      if (!value) return true;

      const allowedTypes = ['application/pdf'];

      if (allowedTypes.includes(value.type)) {
        return true;
      }

      return this.$t('resources.pdfOnly');
    },
  },
};
</script>

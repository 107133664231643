import api from './baseApiService';

class PatientService {
  async getPatients() {
    return await api.get('patients');
  }

  async getPatientById(id) {
    return await api.get(`patient/${id}`);
  }

  async createPatient(data) {
    return await api.post('patient/', data);
  }

  async updatePatient(id, data) {
    return await api.put(`patient/${id}/`, data);
  }

  async deletePatient(id) {
    return await api.delete(`patient/${id}/delete/`);
  }

  async getPatientParameters() {
    return await api.get('patientparameters');
  }

  async getPatientsWithNotifications() {
    return await api.get('patientswithnotifications');
  }

  async getPatientNotes(patientId) {
    return await api.get(`patient/${patientId}/notes`);
  }

  async getSinglePatientNote(noteId) {
    return await api.get(`patient/notes/${noteId}`);
  }

  async createPatientNote(data, patientId) {
    return await api.post(`patient/${patientId}/notes/`, data);
  }

  async getUserIsPatientHealthworker(patientId) {
    return await api.get(`patient/${patientId}/user_is_healthworker`);
  }
}

export default new PatientService();

import PatientVitalSigns from './PatientVitalSigns';
export default {
  moduleName: 'PatientVitalSigns',
  routes: [
    {
      path: '/patientvitalsigns',
      name: 'PatientVitalSigns',
      component: PatientVitalSigns,
    },
  ],
};

<template>
  <div>
    <v-row class="mb-3">
      <v-col>
        <v-text-field
          v-model="searchInput"
          dense
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          :disabled="initDataLoading"
          clearable
          outlined
          @input="filterPatientOnSeachValue"
        >
        </v-text-field>
      </v-col>

      <v-col cols="auto">
        <v-btn ref="filtersIcon" :disabled="initDataLoading" icon @click="showFilterSheet = !showFilterSheet">
          <v-icon v-if="appliedCohortIds.length === 0">mdi-filter</v-icon>
          <v-icon v-else color="primary">mdi-filter-check</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <template v-if="!initDataLoading">
      <div v-if="!filteredIotRealtime.length === 0">
        {{ $t('iotRealtime.noPatientToMonitor') }}
      </div>

      <template v-else>
        <IotRealtimeGridContent
          ref="iotRealtimeGridContent"
          :activity-types-codes="activityTypesCodes"
          :filtered-iot-realtime="filteredIotRealtime"
          :user-grid-configuration="userGridConfiguration"
          @emitPaginationConfig="emitPaginationConfig"
          @viewPatientIotRealtimeDetail="viewPatientIotRealtimeDetail"
        />
      </template>

      <v-navigation-drawer v-model="showFilterSheet" fixed temporary right touchless>
        <v-card flat>
          <v-card-title>{{ $t('filters') }}</v-card-title>

          <v-card-text>
            <v-row align="start">
              <v-col align-self="start" :cols="12">
                <v-select
                  :id="getIdByName('cohortMonitoring')"
                  ref="cohortMonitoring"
                  v-model="selectedCohortIds"
                  clearable
                  multiple
                  :label="$t('cohortMonitoring')"
                  :items="cohorts"
                  item-text="name"
                  item-value="id"
                  outlined
                  small-chips
                  deletable-chips
                  :no-data-text="$t('noDataAvailable')"
                  hide-details
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col :cols="12">
                <div class="d-flex flex-row">
                  <v-btn ref="apply" color="primary" class="mt-2 ml-auto" @click.stop="emitCohortFilter()">
                    <v-icon> mdi-filter </v-icon>
                    {{ $t('apply') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-navigation-drawer>
    </template>
  </div>
</template>

<script>
import accessibility from '@/accessibilityMixin';
import translationMixin from '@/translationMixin';
import { containsString } from '@/utils/stringUtils';

import IotRealtimeGridContent from './IotRealtimeGridContent.vue';

export default {
  name: 'IotRealtimeGridContainer',

  components: {
    IotRealtimeGridContent,
  },

  mixins: [accessibility, translationMixin],

  props: {
    activityTypesCodes: {
      required: true,
      type: Array,
    },

    initDataLoading: {
      required: true,
      type: Boolean,
    },

    iotRealTimeData: {
      required: true,
      type: Array,
    },

    cohorts: {
      required: true,
      type: Array,
    },

    userGridConfiguration: {
      required: false,
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      filteredIotRealtime: [],
      appliedCohortIds: this.userGridConfiguration?.cohortIds ? this.userGridConfiguration.cohortIds : [],
      selectedCohortIds: this.userGridConfiguration?.cohortIds ? this.userGridConfiguration.cohortIds : [],
      searchInput: '',
      showFilterSheet: false,
    };
  },

  watch: {
    iotRealTimeData: function () {
      this.filterPatientOnSeachValue();
    },

    'userGridConfiguration.cohortIds': function () {
      this.selectedCohortIds = this.userGridConfiguration?.cohortIds ? this.userGridConfiguration.cohortIds : [];
      this.appliedCohortIds = this.selectedCohortIds;
    },
  },

  created() {
    this.filterPatientOnSeachValue();
  },

  methods: {
    filterPatientOnSeachValue: function () {
      if (!this.searchInput) {
        this.filteredIotRealtime = this.iotRealTimeData;
        return;
      }

      this.filteredIotRealtime = this.iotRealTimeData.filter((iotRealTime) => {
        const { firstName, lastName } = iotRealTime.patient;

        return (
          containsString(` ${lastName} ${firstName}`, this.searchInput) ||
          containsString(` ${firstName} ${lastName}`, this.searchInput)
        );
      });
    },

    emitCohortFilter: function () {
      this.$emit('applyCohortFilter', this.selectedCohortIds);
      this.appliedCohortIds = this.selectedCohortIds;
    },

    emitPaginationConfig: function (paginationData) {
      this.$emit('updatePaginationConfig', paginationData);
    },

    viewPatientIotRealtimeDetail: function (patientId) {
      this.$emit('viewPatientIotRealtimeDetail', patientId);
    },
  },
};
</script>

export default {
  en: {
    protocol: 'Protocol',
    protocolName: 'Protocol name for the user',
    confirmDeleteProtocol: 'Are you sure you want to delete this protocol?',
    createNewProtocol: 'Create new protocol',
    editProtocol: 'Edit protocol',

    assignActivities: 'Activities',
    protocolActivities: 'Assign Activities to protocol',

    currentProtocolActivity: "Protocol's Activities",
    availableActivity: 'Available Activities',
    activityProtocolName: 'Name',
    activityProtocolDescription: 'Description',

    frequency: 'Frequency',
    daily: 'Daily',
    customized: 'Customized',
    executionDays: 'Execution days',
    executionDaysHint:
      'Ex: 1,3 indicates days 1 and 3 of the protocol, while 1-3 designates the period from day 1 to day 3 of the protocol.',

    ongoingProtocols: 'Active',
    completedProtocols: 'Inactive',
  },
  fr: {
    protocol: 'Protocole',
    protocolName: "Nom de du protocole pour l'usager",
    confirmDeleteProtocol: 'Voulez-vous supprimer ce protocole?',
    createNewProtocol: 'Créer un nouveau protocole',
    editProtocol: 'Modifier le protocole',

    assignActivities: 'Activités',
    protocolActivities: "Assignation d'activités au protocole",

    currentProtocolActivity: 'Activités du protocole',
    availableActivity: 'Activités disponibles',
    activityProtocolName: 'Nom',
    activityProtocolDescription: 'Description',

    frequency: 'Fréquence',
    daily: 'Quotidienne',
    customized: 'Personnalisée',
    executionDays: "Jours d'exécution",
    executionDaysHint:
      'Ex: 1,3 indique les jours 1 et 3 du protocole, tandis que 1-3 désigne la période du jour 1 au jour 3 du protocole',

    ongoingProtocols: 'Actifs',
    completedProtocols: 'Inactifs',
  },
};

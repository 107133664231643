class UserFormData {
  constructor() {
    this.reset();
  }

  reset() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.createdAt = null;
    this.lastLogin = null;
    this.accessGroups = [];
    this.active = true;
  }

  setData(user) {
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.createdAt = user.createdAt;
    this.lastLogin = user.lastLogin;
    this.accessGroups = user.accessGroups;
    this.active = user.active;
  }
}

export { UserFormData };

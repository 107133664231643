import PatientVideoCall from './PatientVideoCall.vue';

export default {
  moduleName: 'patientVideoCall',
  routes: [
    {
      path: '/patientmonitoring/:patientId?/conversations/:conversationId?/videocall/:callId?',
      name: 'PatientVideoCall',
      component: PatientVideoCall,
      props: (route) => {
        let props = {};

        if (route.params?.patientId) {
          props.patientId = Number.parseInt(route.params.patientId);
        }

        if (route.params?.conversationId) {
          props.conversationId = Number.parseInt(route.params.conversationId);
        }

        if (route.params?.callId) {
          props.callId = Number.parseInt(route.params.callId);
        }

        return props;
      },
    },
  ],
};

export default {
  en: {
    patientHasAlreadyBeenTakenCareOf: 'The patient has already been taken care of',
    'callbackRequest.timeAgo': '{timeValue} {timeUnit} ago',
    'callbackRequest.justNow': 'Just Now',
    'callbackRequest.noCallbackRequest': 'No callback requests for now',
    'callbackRequest.takeCare': 'Take care',
    'callbackRequest.takenCareAt': 'Taken care {{date}} at {{time}}',
    'callbackRequest.resolve': 'Resolve',
    'callbackRequest.singularName': 'Callback Request',
    'callbackRequest.pluralName': 'Callback Requests',
    'callbackRequest.virtuoseVirtualAssistant': 'Virtuose Virtual Assistant',
    'callbackRequest.homePhone': 'Phone number',
    'callbackRequest.contact': 'Contact',
    'callbackRequest.on': 'on ',
  },
  fr: {
    patientHasAlreadyBeenTakenCareOf: 'Le patient a déjà été pris en charge',
    'callbackRequest.timeAgo': 'il y a {timeValue} {timeUnit}',
    'callbackRequest.justNow': "À l'instant",
    'callbackRequest.noCallbackRequest': 'Aucune demande de rappel pour le moment',
    'callbackRequest.takeCare': 'Prendre en charge',
    'callbackRequest.takenCareAt': 'Pris en charge {{date}} à {{time}}',
    'callbackRequest.resolve': 'Résoudre',
    'callbackRequest.singularName': 'Demande de rappel',
    'callbackRequest.pluralName': 'Demandes de rappel',
    'callbackRequest.virtuoseVirtualAssistant': 'Assistant Virtuel Virtuose',
    'callbackRequest.homePhone': 'Téléphone domicile',
    'callbackRequest.contact': 'Contacter',
    'callbackRequest.on': 'le',
  },
};

<template>
  <div>
    <v-dialog v-if="show" v-model="show" scrollable max-width="400px" persistent eager>
      <v-card>
        <ModalTitle :title="$t('error')" />

        <v-card-text>
          <v-row v-if="serverErrorCode != null">
            <v-col :aria-label="$t('error')" cols="3">code</v-col>
            <v-col :aria-label="$t('error')">
              <span ref="errorCode">{{ serverErrorCode }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col :aria-label="$t('error')" cols="3">message</v-col>
            <v-col :aria-label="$t('error')">
              <span ref="errorText">{{ serverErrorText }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn ref="ok" text color="primary" @click="closeErrorDialog()">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import translation from '@/translationMixin';
export default {
  name: 'ErrorModal',
  mixins: [translation],
  props: {
    error: {
      required: true,
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
  },
  data() {
    return {
      show: false,
      serverErrorCode: null,
      serverErrorText: '',
    };
  },

  watch: {
    error: function () {
      if (this.error != null) {
        this.defaultErrorCallBack(this.error);
      } else {
        this.show = false;
      }
    },
  },

  created: function () {},

  methods: {
    formatJsonString: function (data) {
      var message = data.message;
      var errorText = this.$t(message[0]);
      var messageLength = message.length;

      for (var i = 1; i < messageLength; i++) {
        errorText = errorText.replace('%s', message[i]);
      }

      return errorText;
    },

    closeErrorDialog: function () {
      this.$emit('close-error-modal', true);
    },

    defaultErrorCallBack: function (error) {
      if (typeof error.response != 'undefined') {
        this.serverErrorCode = error.response.status;

        switch (this.serverErrorCode) {
          case 0: // Case "Network Error"
            this.serverErrorText = this.$t('errorNetwork');
            break;
          case 400:
            var isJson = error.response.headers['content-type'] == 'application/json';

            if (isJson == false) {
              this.serverErrorText = this.$t(error.response.data);
            } else {
              this.serverErrorText = this.formatJsonString(error.response.data);
            }
            break;

          case 401:
          case 404:
            this.serverErrorText = this.$t(error.response.data);
            break;

          case 403:
            this.serverErrorText = error.response.data ? this.$t(error.response.data) : this.$t('error403');
            break;

          default:
            this.serverErrorText = error.response.data;
            break;
        }
      } else {
        this.serverErrorCode = null;
        this.serverErrorText = this.$t(error.message);
      }

      this.show = true;
    },
  },
};
</script>

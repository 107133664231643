import { subjects, actions } from './permissions';

export default {
  data() {
    return {
      permissions: {
        actions,
        subjects,
      },
    };
  },
};

<template>
  <v-dialog
    v-if="showManualEntry"
    ref="manualEntryDialog"
    value="showManualEntry"
    scrollable
    persistent
    max-width="600px"
  >
    <v-card>
      <ErrorModal :error="error" @close-error-modal="error = null" />
      <WaitModal :show="isProcessing" />

      <ModalTitle :title="$t('manualEntry')" />

      <v-card-text>
        <v-form ref="manualEntryForm" lazy-validation>
          <v-row v-for="(observation, index) in observations" :key="index">
            <v-col cols="12">
              <v-text-field
                ref="manualEntryValue"
                v-model="observation.value"
                class="required-indicator"
                :label="$t(observation.name.charAt(0).toLowerCase() + observation.name.slice(1))"
                :rules="[
                  validationRules.required,
                  observation.name === 'Saturation' ? validationRules.atMost(100) : true,
                  validationRules.atLeast(0),
                  ['BodyTemperature', 'CapillaryGlycemia', 'Weight'].includes(observation.name)
                    ? true
                    : validationRules.integer,
                ]"
                :suffix="observation.unitType ? observation.unitType : $t(`${observation.name}Suffix`)"
                :append-icon="['Weight', 'BodyTemperature'].includes(observation.name) ? 'mdi-autorenew' : ''"
                type="number"
                hide-spin-buttons
                filled
                dense
                @click:append="
                  observation.unitType =
                    observation.name === 'Weight'
                      ? observation.unitType === 'kg'
                        ? 'lb'
                        : 'kg'
                      : observation.unitType === '°C'
                      ? '°F'
                      : '°C'
                "
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <DateTimePickerField
                ref="manualEntryDateTime"
                v-model="dataDateTime"
                :max-date="new Date()"
                :label="$t('dataDateTime')"
                picker-type="dateTime"
                required
              />
            </v-col>
          </v-row>

          <RequiredFieldsLegend />
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn text @click="closeManualEntryModal">{{ $t('cancel') }}</v-btn>

        <SaveButton
          :is-loading="isLoading"
          :is-processing="isProcessing"
          :show-wait-modal="showWaitModal"
          :handle-click="saveManualEntryData"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SaveButton from '@/components/SaveButton.vue';
import patientMonitoringService from '@/services/patientMonitoringService';
import translationMixin from '@/translationMixin';
import validationRulesMixin from '@/validationRulesMixin';
import { format } from 'date-fns';

export default {
  name: 'VitalSignsManualEntry',
  components: { SaveButton },
  mixins: [translationMixin, validationRulesMixin],
  props: {
    patientId: {
      type: Number,
      required: true,
    },

    observations: {
      type: Array,
      required: true,
    },

    showManualEntry: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      dataDateTime: format(new Date().setSeconds(0), 'yyyy-MM-dd HH:mm:ss'),
      isProcessing: false,
      isLoading: false,
      showWaitModal: false,
      error: null,
    };
  },
  created() {},

  methods: {
    saveManualEntryData: async function () {
      if (!this.$refs.manualEntryForm.validate()) {
        return;
      }

      this.isProcessing = true;

      try {
        this.convertData();

        const data = {
          patientId: this.patientId,
          observations: this.observations,
          dateTime: format(new Date(this.dataDateTime), 'yyyy-MM-dd HH:mm:ssxxx'),
        };

        await patientMonitoringService.saveManualEntryVitalSigns(data);

        this.$emit('updateChartData');
        this.closeManualEntryModal();
      } catch (error) {
        this.error = error;
      }

      this.isProcessing = false;
    },

    convertData: function () {
      this.observations.forEach((x) => {
        if (x?.unitType === 'lb') {
          x.value = parseFloat((x.value / 2.205).toFixed(2));
        } else if (x?.unitType === '°F') {
          x.value = parseFloat(((x.value - 32) / 1.8).toFixed(2));
        }
      });
    },

    closeManualEntryModal: function () {
      this.$emit('closeManualEntryModal');
    },
  },
};
</script>

<template>
  <div class="pa-4 customization-container">
    <DataGrid
      :items="formattedProtocols"
      :columns="columns"
      :show-select="false"
      grid-name="patient_protocols_history_grid"
      :items-per-page="5"
      :data-loading="loading"
    >
    </DataGrid>
  </div>
</template>

<script>
import DataGrid from '@/components/DataGrid';
import protocolService from '@/services/protocolService';
import translation from '@/translationMixin';

const actionToTranslationKey = {
  created: 'protocolCreated',
  deleted: 'protocolDeleted',
  start_date_updated: 'protocolStartDateUpdated',
  end_date_updated: 'protocolEndDateUpdated',
};

export default {
  name: 'PatientProtocolsHistory',
  components: {
    DataGrid,
  },
  mixins: [translation],
  props: {
    patientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      protocols: [],
      columns: [
        { text: this.$t('protocolName'), value: 'protocolName', sortable: false },
        { text: this.$t('protocolAction'), value: 'action', sortable: false },
        { text: this.$t('protocolUpdatedBy'), value: 'updatedBy.username', sortable: false },
        { text: this.$t('protocolUpdatedAt'), value: 'updatedAt', sortable: false },
      ],
      loading: false,
    };
  },
  computed: {
    formattedProtocols() {
      return this.protocols.map((protocol) => {
        let date;
        if (protocol.action === 'start_date_updated') {
          date = protocol.startDate;
        } else if (protocol.action === 'end_date_updated') {
          date = protocol.endDate;
        }
        let action = this.$t(actionToTranslationKey[protocol.action]);
        if (date) {
          action = `${action} (${date})`;
        }

        const audit = {
          ...protocol,
          action,
        };

        return audit;
      });
    },
  },
  mounted() {
    this.fetchPatientProtocolsHistory();
  },
  methods: {
    async fetchPatientProtocolsHistory() {
      this.loading = true;
      try {
        const response = await protocolService.getPatientProtocolsChangesHistory(this.patientId);
        this.protocols = response;
      } catch (error) {
        console.error('Error fetching protocols:', error);
        this.protocols = [];
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>

import api from './baseApiService';

export class ProtocolService {
  async getProtocols() {
    return await api.get('protocols');
  }

  async getPatientProtocols(patientId) {
    return await api.get(`patientprotocols/${patientId}`);
  }

  async getProtocolActivities(protocolId) {
    return await api.get(`protocolactivities/${protocolId}`);
  }

  async testCodeExecutorForPatient(patientId, data) {
    return await api.post(`codexecutor/test/${patientId}/`, data);
  }

  async getPatientProtocolsChangesHistory(patientId) {
    return await api.get(`patientprotocols/${patientId}/history`);
  }
}

export default new ProtocolService();

import capacitorMixin from '@/CapaHttp';
import { LanguageVue } from '@/translationMixin';

export default {
  mixins: [capacitorMixin],
  data: function () {
    return {};
  },

  created: function () {
    this.projectHandle = this.getCapaHttp('');
  },

  methods: {
    getUserLoginStatus: function (successCallBack, errorCallback) {
      var url = 'login/status/get/';
      this.projectHandle.get(url, successCallBack, errorCallback);
    },

    apiLogin: function (data, successCallBack, errorCallback) {
      var url = 'api/login/';
      this.projectHandle.post(url, data, successCallBack, errorCallback);
    },

    apiLogout: function (successCallBack, errorCallback) {
      var url = 'api/logout/';

      this.projectHandle.post(url, null, successCallBack, errorCallback, true);
    },

    resetPassword: function (data, successCallBack, errorCallback) {
      var language = LanguageVue.getLanguage();
      var url = 'user/password/reset/' + language + '/';

      this.projectHandle.pos(url, data, successCallBack, errorCallback);
    },

    cognitoLogout: function () {
      this.projectHandle.cognitoLogout();
    },

    changeUserPassword: function (data, successCallBack, errorCallback) {
      var urlStart = 'accounts/password/reset/key/';

      var success = function () {
        var postUrl = urlStart + data.redirectToken + '/';

        let formData = new FormData();
        formData.append('password1', data.password);
        formData.append('password2', data.password);
        formData.append('csrfmiddlewaretoken', this.getCsrfToken());
        formData.append('action', '');
        this.projectPost(postUrl, formData, successCallBack, errorCallback, true);
      };

      var url = urlStart + data.token;

      this.projectHandle.get(url, success, errorCallback);
    },
  },
};

<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <v-row v-if="$vuetify.breakpoint.smAndUp">
      <v-col>
        <v-btn
          v-if="isPatientHealthWorker"
          ref="addNote"
          class="mt-3"
          color="primary"
          exact
          @click="showAddNoteAction(null)"
        >
          <span>{{ $t('add') }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <DataGrid
      :items="notes"
      :columns="columns"
      grid-name="notes_grid"
      :show-select="false"
      :items-per-page="25"
      :data-loading="showWaitModal"
    >
      <template #item.actions="{ item }">
        <div class="text-end">
          <v-btn :ref="`actions_${item.id}`" :text="true" @click="consultPatientNote(item.id)">{{
            $t('patientNote.consult')
          }}</v-btn>
        </div>
      </template>
      <template #item.noteType="{ item }">
        <div>
          <span>{{ getNoteTypeText(item.noteType) }}</span>
        </div>
      </template>
    </DataGrid>

    <NotesModal
      v-if="showModal"
      :show.sync="showModal"
      :patient-id="patientId"
      :note-id="selectedNoteId"
      @refresh="init()"
    />
  </div>
</template>

<script>
import translation from '@/translationMixin';
import virtuoseMixin from '@/virtuoseMixin';
import PatientService from '@/services/patientService';
import NotesModal from './NotesModal.vue';
import permissionMixin from '@/auth/permissionMixin';
import DataGrid from '@/components/DataGrid.vue';

export default {
  name: 'Notes',
  components: { NotesModal, DataGrid },
  mixins: [translation, virtuoseMixin, permissionMixin],
  props: {
    patientId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: null,
      showModal: false,
      showWaitModal: false,
      notes: [],
      isPatientHealthWorker: false,
      selectedNoteId: null,
      noteTypesConst: {
        proNote: 'professional_note',
        intervention: 'intervention',
      },
    };
  },
  computed: {
    columns() {
      let columns = [
        {
          text: 'patientNote.createdAt',
          value: 'createdAt',
          filterable: true,
          visible: true,
        },
        {
          text: 'patientNote.noteType',
          value: 'noteType',
          filterable: true,
          visible: true,
        },
        {
          text: 'patientNote.note',
          value: 'noteContent',
          filterable: true,
          visible: true,
        },
      ];
      if (this.$can(this.permissions.actions.view, this.permissions.subjects.patient)) {
        columns.push({
          text: 'actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        });
      }
      return columns;
    },
  },
  watch: {
    patientId() {
      if (this.patientId) {
        this.getPatientNotes();
      }
    },
  },
  mounted() {
    if (this.patientId) {
      this.getPatientNotes();
    }
  },
  methods: {
    async init() {
      this.notes = [];
      this.selectedNoteId = null;
      this.getPatientNotes();
    },
    showAddNoteAction: function (noteId) {
      this.selectedNoteId = noteId;
      this.showModal = true;
    },

    async getPatientNotes() {
      this.showWaitModal = true;

      try {
        let [notesData, isPatientHealthWorker] = await Promise.all([
          PatientService.getPatientNotes(this.patientId),
          PatientService.getUserIsPatientHealthworker(this.patientId),
        ]);

        this.notes = notesData;
        this.isPatientHealthWorker = isPatientHealthWorker;
      } catch (error) {
        this.error = error;
      } finally {
        this.showWaitModal = false;
      }
    },
    consultPatientNote: function (noteId) {
      this.showAddNoteAction(noteId);
    },
    getNoteTypeText: function (noteValue) {
      if (noteValue === this.noteTypesConst.proNote) {
        return this.$t('patientNote.proNote');
      } else if (noteValue === this.noteTypesConst.intervention) {
        return this.$t('patientNote.intervention');
      }

      return '';
    },
  },
};
</script>

<style scoped>
::v-deep table th[aria-label='actions'] {
  text-align: end !important;
}
::v-deep table th[aria-label='actions'] > span.datagrid-header {
  padding: 0 18px;
}
</style>

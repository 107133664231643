import ResourcesHome from './ResourcesHome';

export default {
  moduleName: 'resources',
  routes: [
    {
      path: '/resources',
      name: 'resourcesHome',
      component: ResourcesHome,
    },
  ],
};

<template>
  <span>
    <IconButtonAndTooltip
      ref="showClicked"
      icon="mdi-delete"
      position="top"
      :size="small ? 'small' : 'normal'"
      :tooltip-text="$t(toolTipText)"
      icon-classes="eb-black-color"
      :described-by="describedBy"
      @btn-clicked="buttonClicked()"
    />

    <v-dialog v-if="show" v-model="show" eager scrollable max-width="670">
      <div ref="dialogRef">
        <v-card class="eb-white-bg">
          <ModalTitle :title="$t('deleteConfirmation')" @btn-clicked="close" />

          <v-card-text class="pb-0">
            <v-col>
              <span>{{ removeQuestionData }}</span>
            </v-col>
            <v-col>
              <v-textarea
                v-show="showComment"
                v-model="description"
                :label="$t('comments')"
                filled
                auto-grow
                rows="2"
              />
            </v-col>
          </v-card-text>

          <v-card-actions>
            <v-btn outlined @click.stop="deleteObject">{{ $t('yes') }}</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
export default {
  name: 'DeletionModal',
  mixins: [translation, accessibility],
  props: {
    small: {
      type: Boolean,
      required: false,
      default: true,
    },
    showComment: {
      type: Boolean,
      required: false,
      default: false,
    },
    removeQuestion: {
      type: String,
      required: false,
      default: function () {
        return null;
      },
    },
    objectToDelete: {
      type: Object,
      required: true,
    },
    toolTipText: {
      type: String,
      required: false,
      default: 'trashTip',
      validator: function (toolTipText) {
        return toolTipText.length > 0;
      },
    },
    describedBy: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
      description: '',
      toolTip: '',
      removeQuestionData: null,
    };
  },

  created: function () {
    this.removeQuestionData = this.removeQuestion != null ? this.removeQuestion : this.$t('removeQuestion');
  },

  methods: {
    buttonClicked: function () {
      this.show = true;
    },

    close: function () {
      this.show = false;
      this.setFocusToIconButtonAndTooltip('showClicked');
    },
    deleteObject: function () {
      var result = {
        objectToDelete: this.objectToDelete,
        description: this.description,
      };

      this.$emit('delete', result);
      this.show = false;
    },
  },
};
</script>

<template>
  <qrcode-vue :value="valueInput" :size="sizeInput" :level="levelInput" />
</template>
<script>
import QrcodeVue from 'qrcode.vue';

export default {
  components: {
    QrcodeVue,
  },
  props: {
    valueInput: {
      type: String,
      required: true,
    },
    sizeInput: {
      type: Number,
      default: () => 200,
    },
    levelInput: {
      type: String,
      default: () => 'L',
    },
  },
  data: function () {
    return {};
  },
};
</script>

import IotHome from './IotHome';

export default {
  moduleName: 'iot',
  routes: [
    {
      path: '/iot',
      name: 'IotHome',
      component: IotHome,
    },
  ],
};

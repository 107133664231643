<template>
  <div v-if="patientId" class="d-none d-print-inline-flex print-padding-0">
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <v-row>
      <v-col>
        <detail-field ref="mrn" :value="mrn" :label="$t('mrn')" />
        <detail-field ref="lastName" :value="lastName" :label="$t('lastName')" />
        <detail-field ref="firstName" :value="firstName" :label="$t('firstName')" />
        <detail-field ref="gender" :value="gender" :label="$t('gender')" />
        <detail-field ref="birthDate" :value="birthDate" :label="$t('birthDate')" />
        <detail-field ref="nasm" :value="nasm" :label="$t('nasm')" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import virtuoseMixin from '@/virtuoseMixin';

export default {
  name: 'PatientPrintInfo',

  mixins: [translation, virtuoseMixin],

  props: {
    patientId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      error: null,
      mrn: '',
      firstName: '',
      lastName: '',
      birthDate: null,
      gender: '',
      nasm: '',
    };
  },
  watch: {
    patientId() {
      if (this.patientId) {
        this.showUserDetails();
      }
    },
  },

  mounted() {
    if (this.patientId) {
      this.showUserDetails();
    }
  },
  methods: {
    showUserDetails: function () {
      let successCallback = function (response) {
        let patient = response.data;
        this.mrn = patient.mrn;
        this.firstName = patient.firstName;
        this.lastName = patient.lastName;
        this.birthDate = patient.birthDate;
        this.nasm = patient.nasm;
        this.gender = patient.gender.name;
      };

      let errorCallback = (error) => {
        this.error = error;
      };

      this.getResidentById(this.patientId, successCallback, errorCallback);
    },
  },
};
</script>

<style scoped>
.detail-field-container {
  margin: 0 !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'd-flex align-center justify-space-between pb-1': _vm.$vuetify.breakpoint.lgAndDown,
    'width-100': _vm.$vuetify.breakpoint.lgAndDown,
  }},[_c('div',{staticClass:"d-flex justify-center",class:_vm.getObservationValueIconColor(_vm.iotStatesEvent)},[_c('div',{staticClass:"d-flex justify-center align-center",class:_vm.$vuetify.breakpoint.lgAndDown ? 'ml-1' : ''},[(
          _vm.iotStateEventAlertCodes.includes(_vm.iotStatesEvent.values[0]) ||
          _vm.iotStateEventWarningCodes.includes(_vm.iotStatesEvent.values[0]) ||
          _vm.iotStateEventErrorCodes.includes(_vm.iotStatesEvent.values[0])
        )?_c('v-icon',{staticClass:"pl-1",class:{
          'pr-2': _vm.$vuetify.breakpoint.xl,
        },attrs:{"color":_vm.getObservationValueTextColor(_vm.iotStatesEvent),"small":""}},[_vm._v(_vm._s(_vm.getObservationValueIcon(_vm.iotStatesEvent)))]):(_vm.iotStatesEvent.values == _vm.statusLevels.CH)?_c('img',{staticClass:"pr-1",class:_vm.$vuetify.breakpoint.xl ? 'pr-2' : 'ml-1',attrs:{"src":'/static/incontinenceicon/change_essity.svg',"height":"11px","alt":'change_essity-icon'}}):_vm._e(),_c('span',{class:{
          'ml-1': _vm.$vuetify.breakpoint.lgAndDown,
          'max-width-125': _vm.$vuetify.breakpoint.xl,
          'text-center': true,
        }},[_vm._v(" "+_vm._s(_vm.getIotStateValueText())+" ")])],1)]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"text--secondary text-body-2 text-center",class:{
          'ml-5': _vm.$vuetify.breakpoint.lgAndDown,
        }},on),[_vm._v(" "+_vm._s(_vm.getRelativeDate(_vm.iotStatesEvent.timeDifference))+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.getFormatDate(_vm.iotStatesEvent.lastDataDatetime)))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
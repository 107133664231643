<template>
  <div>{{ $t('notification_redirecting') }}</div>
</template>

<script>
import notificationService from '@/services/notificationService';
import translationMixin from '@/translationMixin';
import { NotificationTypesVC } from './constants';
import { ActivityTypes, MonitoringTypes } from '@/components/PatientMonitoring/constants';
import { format } from 'date-fns';
import callbackRequestEventBus from '@/components/CallbackRequest/callbackRequestEventBus';

export default {
  name: 'NotificationRedirect',
  mixins: [translationMixin],
  props: {
    notificationId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      notification: null,
    };
  },
  created: function () {},
  mounted: async function () {
    await this.init();
  },
  methods: {
    init: async function () {
      try {
        this.notification = await notificationService.getNotificationById(this.notificationId);
        const notificationType = this.notification.notificationType;
        let patientId, activityId, conversationId, notificationDatetime, alertTime;

        switch (true) {
          case notificationType === NotificationTypesVC.ACT_ALERT:
            patientId = this.notification.data.patientId;
            activityId = this.notification.data.activityId;
            alertTime = this.notification.data.alertTime;

            if (this.notification.data.activityTypeCode === ActivityTypes.PRE) {
              // Datetime at midnight because the activity can be executed all day
              notificationDatetime = format(new Date(new Date(alertTime).setHours(0, 0, 0, 0)), 'yyyy-MM-dd HH:mm:ss');
            } else {
              notificationDatetime = this.notification.createdAt;
            }

            this.$router.replace({
              name: 'PatientMonitoringGrid',
              params: {
                patientId,
                detailType: activityId,
              },
              query: { notificationDatetime, alertTime },
            });
            break;

          case notificationType === NotificationTypesVC.NEW_MESSAGE:
            patientId = this.notification.data.patientId;
            conversationId = this.notification.data.conversationId;

            this.$router.replace({
              name: 'PatientMonitoringGrid',
              params: { patientId, detailType: MonitoringTypes.CONVERSATIONS, detailId: conversationId },
            });
            break;

          case notificationType === NotificationTypesVC.NEW_CALLBACK_REQUEST:
            this.$router.replace({ name: 'PatientMonitoringGrid' });
            callbackRequestEventBus.$emit('openCallbackRequestsNavigation');
            break;
        }

        await notificationService.updateNotificationReadStatus(this.notificationId, { read: true });
      } catch (error) {
        this.$router.push({ name: 'Home' });
      }
    },

    getMidnightTime(time) {
      return format(new Date(new Date(time).setHours(0, 0, 0, 0)), 'yyyy-MM-dd HH:mm:ss');
    },
  },
};
</script>

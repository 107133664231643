export const isDateToday = (value) => {
  const today = new Date();
  const date = new Date(value);
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

/**
 * Returns the time portion of the date formatted according to the locale
 * @param {*} date Date containing the time to format
 * @param {*} userLanguage Display language
 * @returns Formatted time
 */
export const getFormattedTime = (date, userLanguage) => {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
  };
  if (userLanguage === 'en') {
    options.hour12 = true;
  }

  return new Date(date).toLocaleTimeString(userLanguage, options);
};

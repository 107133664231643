import api from './baseApiService';

class MessageService {
  async getPatientConversations(patientId) {
    return await api.get(`conversations/${patientId}`);
  }

  async endConversation(chatId, data) {
    return await api.put(`conversation/${chatId}/end`, data);
  }

  async createConversation(data) {
    return await api.post('conversation/', data);
  }

  async updateChatSubject(chatId, data) {
    return await api.put(`conversation/${chatId}/`, data);
  }

  async getConversationMessages(chatId) {
    return await api.get(`conversation/${chatId}/messages`);
  }

  async sendMessage(chatId, data) {
    return await api.post(`conversation/${chatId}/message/`, data);
  }
}

export default new MessageService();

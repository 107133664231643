import api from './baseApiService';

class UserService {
  async getUsers() {
    return await api.get('users');
  }

  async getUserById(id) {
    return await api.get(`user/${id}`);
  }

  async createUser(data) {
    return await api.post('user/', data);
  }

  async updateUser(id, data) {
    return await api.put(`user/${id}/`, data);
  }

  async getCurrentUserPermissions() {
    return await api.get('currentuser/permissions');
  }

  async getAllAccessGroups() {
    return await api.get('accessgroups');
  }

  async getUserPreferences() {
    return await api.get('userpreferences');
  }

  async updateUserPreferences(data) {
    return await api.post('userpreferences/', data);
  }

  async resendEmailInvitation(userId) {
    return api.put(`user/${userId}/resendinvitation/`);
  }

  async updateUserLastLogin() {
    return api.put('login/');
  }

  async getUserToken() {
    return api.post('usertoken');
  }

  async getUserGridConfiguration(gridName) {
    return await api.get(`usergridconfiguration/${gridName}`);
  }

  async updateUserGridConfiguration(gridName, data) {
    return await api.put(`usergridconfiguration/${gridName}/`, data);
  }
}

export default new UserService();

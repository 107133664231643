import api from './baseApiService';

export class ActivityService {
  async getActivities() {
    return await api.get('activities');
  }

  async getActivityById(activityId) {
    return await api.get(`activity/${activityId}`);
  }

  async deleteActivity(activityId) {
    return await api.delete(`activity/${activityId}/delete/`);
  }

  async getActivityTypes() {
    return await api.get('activitytypes');
  }

  async getPatientActivities(patientId) {
    return await api.get(`patientactivities/${patientId}`);
  }

  async updatePatientActivitiesAndProtocols(patientId, data) {
    return await api.put(`patientactivitiesprotocols/patient/${patientId}/`, data);
  }

  async getActivityAlertFieldsByActivityType(activityTypeId) {
    return await api.get(`activityalertfields/${activityTypeId}`);
  }

  async getPrescriptiveVideos() {
    return await api.get(`prescriptivelearning`);
  }

  async getPrescriptiveVideo(prescriptiveVideoId) {
    return await api.get(`prescriptivelearning/${prescriptiveVideoId}/embed`);
  }
}

export default new ActivityService();

<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent max-width="600px">
      <v-card>
        <ModalTitle :title="$t('patientDevice.deviceConfiguration')" />
        <WaitModal :show="showWaitModal" />

        <v-card-text>
          <v-form ref="IOTPatientDeviceConfigurationForm">
            <template v-if="!showWaitModal">
              <v-autocomplete
                v-if="!isIotAssociated"
                ref="patients"
                v-model="formData.patientId"
                :items="allPatients"
                :label="$t('patient')"
                :item-text="(item) => item.lastName + ' ' + item.firstName + ' - ' + item.birthDate"
                item-value="id"
                filled
                :no-data-text="$t('noDataAvailable')"
                class="required-indicator"
                :rules="[validationRules.required]"
                @input="resetAssociationKey()"
              />

              <detail-field
                v-else
                :id="getIdByName('patientInformation')"
                ref="patientInformation"
                :value="getPatientInformation()"
                :label="$t('patient')"
              />
            </template>

            <IotPatientConfigurationInfo
              v-if="isIotAssociated"
              :iot-device-type-code="formData.iotDeviceTypeCode"
              :association-key="formData.associationKey"
              :association-tenant="formData.associationTenant"
              :association-code="formData.associationCode"
              :is-va-configuration-completed="formData.isVaConfigurationCompleted"
            ></IotPatientConfigurationInfo>
          </v-form>

          <RequiredFieldsLegend v-if="!isIotAssociated && !showWaitModal" />
        </v-card-text>

        <v-card-actions class="justify-end">
          <template v-if="!isIotAssociated">
            <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialogAndRefresh(false)">
              {{ $t('cancel') }}
            </v-btn>

            <v-btn
              :id="getIdByName('associate')"
              ref="associate"
              :disabled="formData.patientId === null || isProcessing"
              text
              color="primary"
              @click.stop="associate()"
            >
              {{ $t('patientDevice.associate') }}
            </v-btn>
          </template>

          <template v-else>
            <v-btn
              ref="close"
              text
              :disabled="isProcessing"
              @click.stop="closeDialogAndRefresh(calculateRefreshCondition)"
            >
              {{ $t('close') }}
            </v-btn>

            <v-btn
              v-if="isIotAssociated && !isIoTxPill()"
              :id="getIdByName('dissociate')"
              ref="removeIOT"
              text
              color="primary"
              :disabled="isProcessing"
              @click.stop="showConfirmationDeleteModal = true"
            >
              {{ $t('patientDevice.remove') }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationModal
      :question="$t('patientDevice.questionDissociate')"
      :show="showConfirmationDeleteModal"
      :title="$t('patientDevice.continue')"
      @cancel="showConfirmationDeleteModal = false"
      @ok="acceptConfirmationModal()"
    />
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import IotPatientConfigurationInfo from '@/components/Iot/IotPatientConfigurationInfo.vue';
import patientService from '@/services/patientService';
import iotService from '@/services/iotService';
import { IOTDeviceTypeCodes } from '../PatientMonitoring/constants';

export default {
  name: 'IotPatientConfiguration',
  components: { IotPatientConfigurationInfo },
  mixins: [translation, accessibility, validationRulesMixin],

  props: {
    iotId: {
      type: Number,
      required: false,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      showWaitModal: false,
      isProcessing: false,
      showConfirmationDeleteModal: false,
      allPatients: [],
      patientLastName: '',
      patientFirstName: '',
      patientBirthDate: '',
      formData: {
        patientId: null,
        associationKey: null,
        associationCode: null,
        associationTenant: null,
        iotDeviceTypeCode: null,
        isVaConfigurationCompleted: false,
      },
    };
  },

  computed: {
    isIotAssociated: function () {
      return this.formData.associationKey || this.formData.associationCode || this.formData.isVaConfigurationCompleted
        ? true
        : false;
    },

    calculateRefreshCondition: function () {
      return (
        (!this.formData.isVaConfigurationCompleted &&
          this.formData.iotDeviceTypeCode === IOTDeviceTypeCodes.VIRTUALASSISTANT) ||
        this.formData.iotDeviceTypeCode !== IOTDeviceTypeCodes.VIRTUALASSISTANT
      );
    },
  },

  watch: {
    show: function () {
      if (this.show) {
        this.init();
      }
    },
  },

  methods: {
    async init() {
      this.formData = {
        patientId: null,
        associationKey: null,
        associationCode: null,
        associationTenant: null,
        iotDeviceTypeCode: null,
        isVaConfigurationCompleted: false,
      };

      await this.getAllData();
    },

    isIoTxPill() {
      return this.formData.iotDeviceTypeCode === IOTDeviceTypeCodes.XPILL;
    },

    async getAllData() {
      this.showWaitModal = true;

      try {
        let [allPatients, associationData] = await Promise.all([
          await patientService.getPatients(),
          await iotService.getExistingPatientAssociationData(this.iotId),
        ]);

        this.allPatients = allPatients.sort((a, b) => a.lastName.localeCompare(b.lastName));

        if (associationData.patientId && associationData.iotDeviceTypeCode) {
          this.formData.patientId = associationData.patientId;
          this.formData.iotDeviceTypeCode = associationData.iotDeviceTypeCode;

          if (associationData.associationKey) {
            this.formData.associationKey = associationData.associationKey;
          } else if (associationData.associationCode && associationData.associationTenant) {
            this.formData.associationCode = associationData.associationCode;
            this.formData.associationTenant = associationData.associationTenant;
          } else if (associationData.isVaConfigurationCompleted) {
            this.formData.isVaConfigurationCompleted = associationData.isVaConfigurationCompleted;
          }
        }
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    async associate() {
      let isValid = this.$refs.IOTPatientDeviceConfigurationForm.validate();

      if (!isValid || this.isProcessing) return;
      this.showWaitModal = true;
      this.isProcessing = true;

      try {
        let data = {
          iotDeviceId: this.iotId,
          patientId: this.formData.patientId,
        };

        let associationData = await iotService.associatePatientToDevice(data);

        const isAssociationCodeOrKey =
          associationData.associationKey || (associationData.associationCode && associationData.associationTenant);

        if (associationData.iotDeviceTypeCode && isAssociationCodeOrKey) {
          this.formData.iotDeviceTypeCode = associationData.iotDeviceTypeCode;

          if (associationData.associationKey) {
            this.formData.associationKey = associationData.associationKey;
          } else if (associationData.associationCode && associationData.associationTenant) {
            this.formData.associationCode = associationData.associationCode;
            this.formData.associationTenant = associationData.associationTenant;
          }
        }
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
      this.isProcessing = false;
    },

    getPatientInformation: function () {
      let patientInformation = this.allPatients.find((patient) => patient.id === this.formData.patientId);
      return patientInformation.lastName + ' ' + patientInformation.firstName + ' ' + patientInformation.birthDate;
    },

    resetAssociationKey: function () {
      this.formData.associationKey = null;
    },

    acceptConfirmationModal: async function () {
      this.showWaitModal = true;
      this.isProcessing = true;

      try {
        let data = {
          patientId: this.formData.patientId,
        };

        await iotService.removePatientDevice(this.iotId, data);
        this.showConfirmationDeleteModal = false;
        this.closeDialogAndRefresh(true);
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
      this.isProcessing = false;
    },

    closeDialogAndRefresh: function (configCompleted) {
      if (configCompleted) {
        this.$emit('refresh');
      }
      this.$emit('update:show', false);
    },
  },
};
</script>

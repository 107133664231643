<template>
  <v-container>
    <v-row>
      <v-col :class="{ 'd-flex justify-space-between align-center mb-5': $vuetify.breakpoint.smAndUp }">
        <div class="page-title">
          {{ $t('patientSummary') }}
        </div>
        <v-btn :class="{ 'mt-3': $vuetify.breakpoint.xs }" color="primary" @click="printPage">
          {{ $t('print') }}
        </v-btn>
        <PatientPrintInfo :patient-id="patientId" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>{{ $t('generalInformation') }}</v-card-title>
          <v-card-text>
            <detail-field ref="mrn" :value="mrn" :label="$t('mrn')" />
            <detail-field ref="lastName" :value="lastName" :label="$t('lastName')" />
            <detail-field ref="firstName" :value="firstName" :label="$t('firstName')" />
            <detail-field ref="gender" :value="gender" :label="$t('gender')" />
            <detail-field ref="birthDate" :value="birthDate" :label="$t('birthDate')" />
            <detail-field v-if="isDead" ref="deathDate" :value="deathDate" :label="$t('deathDate')" />
            <detail-field v-else ref="age" :value="age" :label="$t('age')" />
            <detail-field ref="nasm" :value="nasm" :label="$t('nasm')" />
            <detail-field ref="language" :value="language" :label="$t('language')" />
            <detail-field ref="type" :value="type" :label="$t('type')" />
            <detail-field ref="status" :value="isActive ? $t('active') : $t('inactive')" :label="$t('status')" />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="isAnyBaselineSet || isAnyHealthInfoSet">
        <v-card>
          <v-card-title>{{ $t('clinicalData') }}</v-card-title>
          <v-card-text v-if="baselines">
            <detail-field
              v-if="baselines.height"
              ref="height"
              :value="`${baselines.height} cm`"
              :label="$t('baselines.height')"
            />
            <detail-field
              v-if="baselines.weight"
              ref="weight"
              :value="`${baselines.weight} kg`"
              :label="$t('baselines.weight')"
            />
            <detail-field
              v-if="baselines.heartRate"
              ref="heartRate"
              :value="`${baselines.heartRate} bpm`"
              :label="$t('baselines.heartRate')"
            />
            <detail-field
              v-if="baselines.systolic"
              ref="systolic"
              :value="`${baselines.systolic} mmhg`"
              :label="$t('baselines.systolic')"
            />
            <detail-field
              v-if="baselines.diastolic"
              ref="diastolic"
              :value="`${baselines.diastolic} mmhg`"
              :label="$t('baselines.diastolic')"
            />
          </v-card-text>
          <v-card-text v-if="healthInfo">
            <detail-field ref="allergies" :value="healthInfo.allergies" :label="$t('healthInfo.allergies')" />
            <detail-field
              ref="medicalConditions"
              :value="healthInfo.medicalConditions"
              :label="$t('healthInfo.medicalConditions')"
            />
            <detail-field ref="other" :value="healthInfo.other" :label="$t('healthInfo.other')" />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-title>{{ $t('contactInformation') }}</v-card-title>
          <v-card-text>
            <detail-field ref="address" :value="address" :label="$t('address')" />
            <detail-field ref="city" :value="city" :label="$t('city')" />
            <detail-field ref="province" :value="province" :label="$t('province')" />
            <detail-field ref="postalCode" :value="postalCode" :label="$t('postalCode')" />
            <detail-field ref="phone" :value="phone" :label="$t('phone')" />
            <detail-field ref="email" :value="email" :label="$t('email')" />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-title>{{ isAdmitted ? $t('admissionDetails') : $t('serviceDetails') }}</v-card-title>
          <v-card-text>
            <detail-field ref="facility" :value="facility" :label="$t('facility')" />
            <detail-field v-if="isAdmitted" ref="room" :value="room" :label="$t('room')" />
            <detail-field v-if="isAdmitted" ref="bed" :value="bed" :label="$t('bed')" />
            <detail-field ref="organization" :value="organization" :label="$t('organization')" />
            <detail-field
              ref="admissionDate"
              :value="admissionDate"
              :label="isAdmitted ? $t('admissionDate') : $t('serviceStartDate')"
            />
            <detail-field
              ref="admissionEndDate"
              :value="admissionEndDate"
              :label="isAdmitted ? $t('admissionEndDate') : $t('serviceEndDate')"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="caregivers.length > 0">
        <v-card>
          <v-card-title>{{ $t('caregivers') }}</v-card-title>
          <div class="d-flex flex-wrap">
            <v-card
              v-for="caregiver in caregivers"
              :key="`caregiver_${caregiver.caregiverId}`"
              elevation="0"
              :class="{ 'print-card': caregivers.length > 1 }"
            >
              <v-card-text>
                <detail-field
                  ref="caregiverFirstName"
                  :value="caregiver.caregiverFirstName"
                  :label="$t('caregiverFirstName')"
                />
                <detail-field
                  ref="caregiverLastName"
                  :value="caregiver.caregiverLastName"
                  :label="$t('caregiverLastName')"
                />
                <detail-field ref="caregiverEmail" :value="caregiver.caregiverEmail" :label="$t('caregiverEmail')" />
                <detail-field ref="caregiverPhone" :value="caregiver.caregiverPhone" :label="$t('caregiverPhone')" />
                <detail-field
                  ref="caregiverRelation"
                  :value="caregiver.caregiverRelation"
                  :label="$t('caregiverRelation')"
                />
              </v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-col>

      <v-col v-if="healthworkers.length > 0">
        <v-card>
          <v-card-title>{{ $t('healthworkers') }}</v-card-title>
          <div class="d-flex flex-wrap">
            <v-card
              v-for="healthworker in healthworkers"
              :key="`healthworker_${healthworker.id}`"
              elevation="0"
              :class="{ 'print-card': healthworkers.length > 1 }"
            >
              <v-card-text>
                <detail-field ref="healthworkerLastName" :value="healthworker.lastName" :label="$t('lastName')" />
                <detail-field ref="healthworkerFirstName" :value="healthworker.firstName" :label="$t('firstName')" />
                <detail-field ref="healthworkerEmail" :value="healthworker.email" :label="$t('email')" />
                <detail-field ref="healthworkerPhone" :value="healthworker.phone" :label="$t('phone')" />
                <detail-field ref="healthworkerType" :value="healthworkerTypeName" :label="$t('healthWorkerType')" />
              </v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-col>

      <v-col v-if="cohorts.length > 0">
        <v-card>
          <v-card-title>{{ $t('cohorts') }}</v-card-title>
          <div class="d-flex flex-wrap">
            <v-card
              v-for="cohort in cohorts"
              :key="`cohort_${cohort.id}`"
              elevation="0"
              :class="{ 'print-card': cohorts.length > 1 }"
            >
              <v-card-text>
                <detail-field ref="cohortName" :value="cohort.name" :label="$t('name')" />
                <detail-field ref="cohortDescription" :value="cohort.description" :label="$t('description')" />
              </v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-col>

      <v-col v-if="protocols.length > 0">
        <v-card>
          <v-card-title>{{ $t('protocols') }}</v-card-title>

          <div v-if="ongoingProtocols.length > 0">
            <v-subheader>{{ $t('ongoingProtocols') }}</v-subheader>
            <div class="d-flex flex-wrap">
              <v-card
                v-for="protocol in ongoingProtocols"
                :key="`protocol_ongoing_${protocol.protocolId}`"
                elevation="0"
                :class="{ 'print-card': ongoingProtocols.length > 1 }"
              >
                <v-card-text>
                  <detail-field ref="protocolName" :value="protocol.protocolName" :label="$t('name')" />
                  <detail-field
                    ref="protocolDescription"
                    :value="protocol.protocolDescription"
                    :label="$t('description')"
                  />
                  <detail-field ref="protocolStartDate" :value="protocol.startDate" :label="$t('startDate')" />
                  <detail-field ref="protocolEndDate" :value="protocol.endDate" :label="$t('endDate')" />
                </v-card-text>
              </v-card>
            </div>
          </div>

          <div v-if="completedProtocols.length > 0">
            <v-subheader>{{ $t('completedProtocols') }}</v-subheader>
            <div class="d-flex flex-wrap">
              <v-card
                v-for="protocol in completedProtocols"
                :key="`protocol_completed_${protocol.protocolId}`"
                elevation="0"
                :class="{ 'print-card': completedProtocols.length > 1 }"
              >
                <v-card-text>
                  <detail-field ref="protocolName" :value="protocol.protocolName" :label="$t('name')" />
                  <detail-field
                    ref="protocolDescription"
                    :value="protocol.protocolDescription"
                    :label="$t('description')"
                  />
                  <detail-field ref="protocolStartDate" :value="protocol.startDate" :label="$t('startDate')" />
                  <detail-field ref="protocolEndDate" :value="protocol.endDate" :label="$t('endDate')" />
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col v-if="activities.length > 0">
        <v-card>
          <v-card-title>{{ $t('activities') }}</v-card-title>

          <div v-if="ongoingActivities.length > 0">
            <v-subheader>{{ $t('ongoingActivities') }}</v-subheader>
            <div class="d-flex flex-wrap">
              <v-card
                v-for="activity in ongoingActivities"
                :key="`activity_ongoing_${activity.id}`"
                elevation="0"
                :class="{ 'activity-card': ongoingActivities.length > 1 }"
              >
                <v-card-text>
                  <detail-field ref="activityName" :value="activity.name" :label="$t('name')" />
                  <detail-field ref="activityDescription" :value="activity.description" :label="$t('description')" />
                  <detail-field ref="activityType" :value="activity.activityTypeName" :label="$t('type')" />
                  <detail-field ref="activityStartDate" :value="activity.startDate" :label="$t('startDate')" />
                  <detail-field ref="activityEndDate" :value="activity.endDate" :label="$t('endDate')" />
                  <detail-field
                    ref="protocolActivityName"
                    :value="activity.protocolActivityName"
                    :label="$t('protocol')"
                  />
                </v-card-text>
              </v-card>
            </div>
          </div>

          <div v-if="completedActivities.length > 0">
            <v-subheader>{{ $t('completedActivities') }}</v-subheader>
            <div class="d-flex flex-wrap">
              <v-card
                v-for="activity in completedActivities"
                :key="`activity_completed_${activity.id}`"
                elevation="0"
                :class="{ 'activity-card': completedActivities.length > 1 }"
              >
                <v-card-text>
                  <detail-field ref="activityName" :value="activity.name" :label="$t('name')" />
                  <detail-field ref="activityDescription" :value="activity.description" :label="$t('description')" />
                  <detail-field ref="activityType" :value="activity.activityTypeName" :label="$t('type')" />
                  <detail-field ref="activityStartDate" :value="activity.startDate" :label="$t('startDate')" />
                  <detail-field ref="activityEndDate" :value="activity.endDate" :label="$t('endDate')" />
                  <detail-field
                    ref="protocolActivityName"
                    :value="activity.protocolActivityName"
                    :label="$t('protocol')"
                  />
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col v-if="iotDevices.length > 0">
        <v-card>
          <v-card-title>{{ $t('iot') }}</v-card-title>
          <div class="d-flex flex-wrap">
            <v-card
              v-for="iotDevice in iotDevices"
              :key="`iotdevice_${iotDevice.id}`"
              elevation="0"
              :class="{ 'print-card': iotDevices.length > 1 }"
            >
              <v-card-text>
                <detail-field
                  ref="iotDeviceType"
                  :value="iotDevice.iotdeviceModel.type.name"
                  :label="$t('iotDeviceType')"
                />
                <detail-field
                  ref="iotDeviceCompany"
                  :value="iotDevice.iotdeviceModel.company.name"
                  :label="$t('iotDeviceCompany')"
                />
                <detail-field
                  ref="iotDeviceModelNumber"
                  :value="iotDevice.modelNumber"
                  :label="$t('iotDeviceModelNumber')"
                />
                <detail-field ref="iotDeviceSerie" :value="iotDevice.serie" :label="$t('iotDeviceSerialNumber')" />
              </v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="avoid-break-after avoid-break-inside">
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 'auto'">
        <DateTimePickerField
          ref="fromDate"
          :value="admissionDate"
          :label="$t('fromDate')"
          picker-type="dateTime"
          read-only
        />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 'auto'">
        <DateTimePickerField
          ref="toDate"
          :value="computedEndDate"
          :label="$t('toDate')"
          picker-type="dateTime"
          read-only
        />
      </v-col>
    </v-row>

    <WaitModal :show="isFetching" />

    <DataGrid
      class="monitoring-grid print-vertical"
      :items="patientResults"
      :columns="columns"
      :show-select="false"
      grid-name="patient_monitoring_summary"
      :data-loading="isFetching"
    >
      <template v-for="activity in activities" #[`item.activity._${activity.id}`]="{ item }">
        <td :ref="`activityTd_${activity.id}`" :key="`activityTd_${activity.id}`" class="text-center">
          <PatientMonitoringIcon :ref="`patientMonitoringIcon_${activity.id}`" :item-type="activity" :item="item" />
        </td>
      </template>
    </DataGrid>

    <StatusLevelLegend :show-incontinence="showIncontinence" />
  </v-container>
</template>

<script>
import translation from '@/translationMixin';
import virtuoseMixin from '@/virtuoseMixin';
import healthcareProfessionalService from '@/services/healthcareProfessionalService';
import cohortsService from '@/services/cohortsService';
import iotService from '@/services/iotService';
import protocolService from '@/services/protocolService';
import activityService from '@/services/activityService';
import patientMonitoringService from '@/services/patientMonitoringService';
import StatusLevelLegend from '@/components/PatientMonitoring/StatusLevelLegend.vue';
import DataGrid from '@/components/DataGrid.vue';
import PatientMonitoringIcon from '@/components/PatientMonitoring/PatientMonitoringIcon.vue';
import { format } from 'date-fns';
import { ActivityTypes } from '@/components/PatientMonitoring/constants';
import PatientPrintInfo from './PatientPrintInfo.vue';

export default {
  name: 'PatientSummary',

  components: {
    DataGrid,
    PatientMonitoringIcon,
    PatientPrintInfo,
    StatusLevelLegend,
  },

  mixins: [translation, virtuoseMixin],

  props: {
    patientId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      error: null,
      activities: [],
      address: '',
      admissionDate: '',
      admissionEndDate: '',
      age: null,
      baselines: {
        heartRate: null,
        weight: null,
        height: null,
        diastolic: null,
        systolic: null,
      },
      bed: '',
      birthDate: null,
      caregivers: [],
      city: '',
      cohorts: [],
      columns: [],
      createdAt: '',
      createdBy: '',
      deathDate: null,
      email: '',
      organization: null,
      filters: {
        timeFilterType: 'range',
        dateFrom: '',
        dateTo: '',
      },
      firstName: '',
      gender: '',
      healthInfo: {
        allergies: null,
        medicalConditions: null,
        other: null,
      },
      healthworkers: [],
      iotDevices: [],
      isActive: false,
      isAdmitted: true,
      isDead: true,
      isFetching: false,
      facility: null,
      lastName: '',
      language: '',
      mrn: '',
      nasm: '',
      patientResults: [],
      phone: '',
      postalCode: '',
      protocols: [],
      province: '',
      room: '',
      showIncontinence: true,
      statusLevels: [],
      type: '',
      updatedAt: '',
      updatedBy: '',
    };
  },

  computed: {
    healthworkerTypeName() {
      return this.healthworker?.healthworkerType?.name;
    },
    isAnyBaselineSet() {
      return this.baselines && Object.values(this.baselines).some((value) => value !== null && value !== '');
    },
    isAnyHealthInfoSet() {
      return Object.values(this.healthInfo).some((value) => value !== null && value !== '');
    },
    computedEndDate() {
      return this.admissionEndDate || format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    },
    ongoingProtocols() {
      return this.protocols
        .filter((protocol) => !protocol.endDate || new Date(protocol.endDate) > new Date())
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    },
    completedProtocols() {
      return this.protocols
        .filter((protocol) => protocol.endDate && new Date(protocol.endDate) <= new Date())
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    },
    ongoingActivities() {
      return this.activities
        .filter((activity) => !activity.endDate || new Date(activity.endDate) > new Date())
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    },
    completedActivities() {
      return this.activities
        .filter((activity) => activity.endDate && new Date(activity.endDate) <= new Date())
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    },
  },

  mounted() {
    this.getPatientInfo();
  },

  methods: {
    getPatientInfo: function () {
      let successCallback = async function (response) {
        let patient = response.data;
        this.mrn = patient.mrn;
        this.firstName = patient.firstName;
        this.lastName = patient.lastName;
        this.birthDate = patient.birthDate;
        this.deathDate = patient.deathDate;
        this.language = patient.language?.name;
        this.nasm = patient.nasm;
        this.organization = patient.organization?.name;
        this.facility = patient.facility?.name;
        this.age = patient.age;
        this.address = patient.address;
        this.city = patient.city;
        this.province = patient.province?.name;
        this.postalCode = patient.postalCode;
        this.phone = patient.phone;
        this.email = patient.email;
        this.type = patient.patientType?.name ? this.$t(patient.patientType.name) : null;
        this.typeCode = patient.patientType?.code;
        this.createdAt = patient.created_at;
        this.createdBy = patient.created_by.firstName + ' ' + patient.created_by.lastName;
        this.updatedAt = patient.updated_at;
        this.updatedBy = patient.updated_by.firstName + ' ' + patient.updated_by.lastName;
        this.deathDate ? (this.isDead = true) : (this.isDead = false);
        this.isActive = patient.active;
        this.gender = patient.gender?.name;
        this.baselines = patient.baselines;
        this.caregivers = patient.caregiverInfo;
        this.healthInfo = patient.healthInfo;
        this.admissionDate = patient.startDate;

        if (this.typeCode == 'residency' || this.typeCode == 'hospitalized') {
          this.isAdmitted = true;
          this.room = patient.roombed?.room ?? '';
          this.bed = patient.roombed?.bed ?? '';
        } else {
          this.isAdmitted = false;
        }

        this.getPatientsMonitoringSummary();
      };

      let errorCallback = (error) => {
        this.error = error;
      };

      this.getResidentById(this.patientId, successCallback, errorCallback);
    },

    async getPatientsMonitoringSummary() {
      this.isFetching = true;
      try {
        await this.fetchPatientRelatedData();
        await this.processActivities();
        await this.fetchAndProcessMonitoringSummary();
      } catch (error) {
        this.error = error;
      } finally {
        this.isFetching = false;
      }
    },

    async fetchPatientRelatedData() {
      const [healthworkers, cohorts, iotDevices, protocols, patientActivities] = await Promise.all([
        healthcareProfessionalService.getPatientHealthcareProfessionals(this.patientId),
        cohortsService.getPatientCohorts(this.patientId),
        iotService.getPatientIOTDevices(this.patientId),
        protocolService.getPatientProtocols(this.patientId),
        activityService.getPatientActivities(this.patientId),
      ]);

      this.healthworkers = healthworkers;
      this.cohorts = cohorts;
      this.iotDevices = iotDevices;
      this.protocols = protocols;
      this.patientActivities = patientActivities;
    },

    async processActivities() {
      const protocolActivitiesResponses = await Promise.all(
        this.protocols.map((p) => protocolService.getProtocolActivities(p.protocolId))
      );
      const protocolActivities = protocolActivitiesResponses.flat();

      const protocolMap = new Map(this.protocols.map((p) => [p.id, p]));
      const protocolActivityMap = new Map(protocolActivities.map((pa) => [pa.protocolActivityId, pa]));

      this.activities = this.patientActivities
        .filter((pa) => pa.displayName)
        .map((pa) => {
          const protocolActivity = protocolActivityMap.get(pa.protocolActivityId);
          const protocol = protocolActivity ? protocolMap.get(protocolActivity.protocol_id) : null;

          return {
            id: pa.activityId,
            name: pa.name,
            description: pa.description,
            displayName: pa.displayName,
            activityTypeCode: pa.activityTypeCode,
            activityTypeName: pa.activityTypeName,
            startDate: pa.startDate,
            endDate: pa.endDate,
            protocolActivityName: protocol?.protocolName,
          };
        });

      this.showIncontinence = this.activities.some((x) => x.activityTypeCode === ActivityTypes.INC);
    },

    async fetchAndProcessMonitoringSummary() {
      this.filters = {
        timeFilterType: 'range',
        dateFrom: `${this.admissionDate} 00:00:00`,
        dateTo: this.computedEndDate,
      };

      const data = await patientMonitoringService.getPatientMonitoringSummary(this.filters);
      const rawPatients = data.patientsResults;

      this.statusLevels = data.statusLevels;
      this.columns = this.activities.map((activity) => ({
        text: activity.displayName,
        value: `activity._${activity.id}`,
        filterable: false,
        visible: true,
        sortable: false,
        align: 'center',
      }));

      const activitiesMap = Object.fromEntries(
        this.activities.map(({ id, activityTypeCode }) => [id, activityTypeCode])
      );
      const statusLevelMap = Object.fromEntries(this.statusLevels.map(({ id, code }) => [id, code]));

      this.patientResults = rawPatients
        .filter((r) => r.result.length !== 0 && r.patient.id === this.patientId)
        .map((r) => {
          const patient = { ...r.patient, monitoring: {} };

          r.result.forEach((result) => {
            const keyName = `_${result.activityId}`;
            const valueCode = statusLevelMap[result.statusLevelId];

            patient.monitoring[keyName] = {
              valueCode: valueCode,
              alertCount: result.alertCount,
              activityId: result.activityId,
              activityTypeCode: activitiesMap[result.activityId],
            };
          });

          return patient;
        });
    },

    printPage() {
      window.print();
    },
  },
};
</script>

<style scoped>
.v-card {
  height: 100%;
  padding-right: 20px;
}
.v-card__text {
  padding-top: 0;
}
.col {
  min-width: fit-content;
}
.activity-card {
  width: 25%;
}
.v-subheader {
  font-weight: 700;
}
::v-deep .v-text-field__details,
::v-deep .v-input__append-inner,
::v-deep .v-input__slot::before,
::v-deep .search-filter,
::v-deep .v-data-footer {
  display: none !important;
}
::v-deep .v-input__slot {
  background: none !important;
  margin-bottom: 0 !important;
}
@media print {
  .v-card {
    padding-right: 0;
    height: auto !important;
  }
  .col,
  .v-col {
    padding: 0 !important;
  }
  .v-card__title {
    padding-bottom: 0;
  }
  .avoid-break-inside,
  .detail-field-container,
  ::v-deep .v-sheet,
  DateTimePickerField {
    break-inside: avoid;
  }
  .avoid-break-after,
  .v-subheader,
  .v-card__title {
    break-after: avoid;
  }
  .activity-card,
  .print-card {
    width: 50%;
  }
  ::v-deep .print-fit {
    width: 100% !important;
  }
  ::v-deep .print-vertical {
    display: block;
  }
  ::v-deep .print-vertical thead {
    float: left;
  }
  ::v-deep .print-vertical tbody {
    display: block;
    width: min-content;
  }
  ::v-deep .print-vertical tr {
    display: flex;
    flex-direction: column;
  }
  ::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: none;
    padding-top: 6px !important;
    padding-right: 20px !important;
    text-align: left !important;
  }
  ::v-deep .v-data-table-header {
    min-width: 160px;
  }
}
</style>

import auth from '@/auth/auth';

class AxiosUtils {
  getCsrfToken() {
    const name = 'csrftoken';
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      var cookies = document.cookie.split(';');

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + '=') {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  getDeleteConfig() {
    return {
      'Content-Type': 'application/json',
      headers: { 'X-CSRFToken': this.getCsrfToken() },
    };
  }

  getPostConfig() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': this.getCsrfToken(),
      },
    };
  }

  async setAccessToken(config) {
    let token = await auth.getAccessToken();
    config.headers = config.headers || {};
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  }
}

export default new AxiosUtils();

<template>
  <v-container v-if="patientData" fluid>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row>
      <v-col cols="auto no-print">
        <div>
          <span class="text-h5 font-weight-medium">
            {{ patientData.patient.lastName }}, {{ patientData.patient.firstName }}
          </span>
        </div>

        <div>{{ patientData.patient.birthDate }}</div>
      </v-col>
    </v-row>

    <v-card class="mt-8">
      <div v-if="detailsActivityTypeCodes.some((x) => patientData.patientActivityIotRealtime[x])" class="pa-5">
        <span v-if="hasPatientIotDateTime()" ref="biobeatSyncDate">
          {{ $t('iotRealtime.lastDataReceived') + ' ' }} {{ lastIotDataDatetime }}
        </span>
        <span v-else-if="hasPatientData()">{{ $t('iotRealtime.detailsNotAvailable') }}</span>
        <span v-else>{{ $t('noDataAvailable') }}</span>
      </div>

      <WaitModal :show="showWaitModal" />

      <v-row
        v-if="!showWaitModal && (hasPatientIotDateTime() || (!hasPatientIotDateTime() && !hasPatientData()))"
        class="mb-5"
        :class="{
          'ml-7': detailsActivityTypeCodes.some((x) => patientData.patientActivityIotRealtime[x]),
        }"
      >
        <div v-if="!detailsActivityTypeCodes.some((x) => patientData.patientActivityIotRealtime[x])" class="ma-4 pl-2">
          {{ $t('iotRealtime.noPatientVitalSignActivity') }}
        </div>

        <template v-for="(activityTypeCode, index) in detailsActivityTypeCodes" v-else>
          <v-col
            v-if="
              patientData.patientActivityIotRealtime[activityTypeCode] ||
              (activityTypeCode === realtimeDataTypeCodes.ECG && hasPatientPatch())
            "
            :key="index"
            cols="12"
          >
            <div>
              <div class="d-flex align-center d-print-inline-flex">
                <div
                  class="my-1"
                  :class="{
                    'width-100': $vuetify.breakpoint.smAndDown,
                    'width-60': $vuetify.breakpoint.md,
                    'width-70': $vuetify.breakpoint.lg,
                    'width-80': $vuetify.breakpoint.xl,
                  }"
                >
                  <div class="d-flex align-center">
                    <div class="font-weight-medium pl-1 mt-2 mb-1 text-body-2">
                      {{ $t(activityTypeCode + 'FullName') + ' (' + $t(activityTypeCode + 'Unit') + ')' }}
                    </div>
                  </div>

                  <VitalSignsChart
                    v-if="
                      getObservationValue(activityTypeCode) ||
                      (activityTypeCode === realtimeDataTypeCodes.ECG && getPatientVitalSignData(activityTypeCode))
                    "
                    :patient-data="getPatientVitalSignData(activityTypeCode)"
                    :new-chart-data="patientData.newChartData && patientData.newChartData[activityTypeCode]"
                    :activity-type-code="activityTypeCode"
                    :zoom-level="zoomLevel"
                    :x-max-date="patientData.lastVitalSignsSyncData.lastDataDatetime"
                    @updateZoomLevel="updateZoomLevel"
                  />

                  <div v-else class="ml-1">{{ $t('noDataAvailable') }}</div>
                </div>

                <div v-if="$vuetify.breakpoint.mdAndUp" class="width-350px">
                  <template
                    v-if="activityTypeCode !== realtimeDataTypeCodes.ECG && getPatientVitalSignData(activityTypeCode)"
                  >
                    <div class="d-flex px-7">
                      <div class="d-flex justify-center align-center width-45px">
                        <v-icon
                          v-if="hasValueTriggeredAnAlert(activityTypeCode)"
                          large
                          color="red"
                          class="d-flex align-center pr-2"
                        >
                          mdi-alert-circle</v-icon
                        >
                      </div>

                      <div
                        v-if="getObservationValue(activityTypeCode)"
                        :class="{
                          'red--text': hasValueTriggeredAnAlert(activityTypeCode),
                          'mt-5 no-data text-center': !getObservationValue(activityTypeCode),
                          'grey--text':
                            isDelayedValue(patientData, activityTypeCode) || !getObservationValue(activityTypeCode),
                        }"
                      >
                        <span
                          class="font-weight-bold"
                          :class="{
                            [getDetailsVitalSignsFontColor(activityTypeCode)]:
                              !hasValueTriggeredAnAlert(activityTypeCode) &&
                              getObservationValue(activityTypeCode) &&
                              !isDelayedValue(patientData, activityTypeCode),
                            'value-font-size': getObservationValue(activityTypeCode) && activityTypeCode !== 'APR',
                            'arterial-pressure-font-size':
                              getObservationValue(activityTypeCode) && activityTypeCode === 'APR',
                          }"
                          >{{ getObservationValue(activityTypeCode) }}</span
                        >

                        <span
                          ><span v-if="![ActivityTypes.SAT, ActivityTypes.BDT].includes(activityTypeCode)">&nbsp;</span
                          >{{ $t(`${activityTypeCode}Unit`) }}</span
                        >

                        <div
                          v-if="patientData.patientActivityIotRealtime[activityTypeCode].lastDataDatetime"
                          class="text-center"
                        >
                          <v-tooltip bottom>
                            <template #activator="{ on }">
                              <div class="text--secondary text-body-2 d-inline-block" v-on="on">
                                {{
                                  getRelativeDate(
                                    patientData.patientActivityIotRealtime[activityTypeCode].timeDifference
                                  )
                                }}
                              </div>
                            </template>

                            <span>
                              {{
                                getFormatDate(patientData.patientActivityIotRealtime[activityTypeCode].lastDataDatetime)
                              }}</span
                            >
                          </v-tooltip>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import accessibility from '@/accessibilityMixin';
import iotRealtimeMixin from '@/components/PatientIotRealtime/iotRealtimeMixin.js';
import translationMixin from '@/translationMixin';

import iotRealtimeService from '@/services/iotRealtimeService';
import { addHours, format } from 'date-fns';
import { ActivityTypes, IOTDeviceTypeCodes } from '@/components/PatientMonitoring/constants';
import { RealtimeDataTypeCodes } from '@/components/PatientIotRealtime/constants.js';

import VitalSignsChart from '@/components/PatientIotRealtime/Details/VitalSignsChart.vue';
import vitalSignsChartsEventBus from '@/components/PatientIotRealtime/Details/vitalSignsChartsEventBus.js';

export default {
  name: 'IotRealtimeDetails',
  components: {
    VitalSignsChart,
  },
  mixins: [accessibility, iotRealtimeMixin, translationMixin],

  props: {
    patientData: {
      required: false,
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      error: null,
      showWaitModal: false,
      patientVitalSigns: [],
      zoomLevel: { min: null, max: null },
      ActivityTypes: ActivityTypes,
      realtimeDataTypeCodes: RealtimeDataTypeCodes,
      detailsActivityTypeCodes: [
        ActivityTypes.APR,
        ActivityTypes.CFR,
        ActivityTypes.SAT,
        ActivityTypes.RES,
        ActivityTypes.BDT,
        RealtimeDataTypeCodes.ECG,
      ],
    };
  },

  computed: {
    lastIotDataDatetime: function () {
      return this.getIotDate();
    },
  },

  async created() {
    if (this.patientData?.lastVitalSignsSyncData?.lastDataDatetime) {
      await this.init();
    }

    vitalSignsChartsEventBus.$on('applyZoom', this.updateZoomLevel);
  },

  beforeDestroy() {
    vitalSignsChartsEventBus.$off('applyZoom', this.updateZoomLevel);
  },
  methods: {
    init: async function () {
      this.showWaitModal = true;

      const lastIotDataDatetime = this.patientData?.lastVitalSignsSyncData?.lastDataDatetime;

      const patientInfo = {
        dateTo: lastIotDataDatetime,
        dateFrom: format(addHours(new Date(lastIotDataDatetime), -3), 'yyyy-MM-dd HH:mm'),
        activityTypesCodes: [
          ActivityTypes.APR,
          ActivityTypes.CFR,
          ActivityTypes.SAT,
          ActivityTypes.RES,
          ActivityTypes.BDT,
        ],
      };

      try {
        this.patientVitalSigns = await iotRealtimeService.getPatientVitalSigns(
          this.patientData.patient.id,
          patientInfo
        );
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    updateZoomLevel({ min, max }) {
      this.zoomLevel = { min, max };
    },

    hasValueTriggeredAnAlert: function (activityTypeCode) {
      return this.patientData?.patientActivityIotRealtime[activityTypeCode].hasAlert;
    },

    hasPatientIotDateTime: function () {
      return this.patientData?.lastVitalSignsSyncData?.lastDataDatetime;
    },

    hasPatientData: function () {
      return Object.entries(this.patientData?.patientActivityIotRealtime)?.some(
        ([key, value]) => this.detailsActivityTypeCodes.includes(key) && value?.values?.length > 0
      );
    },

    hasPatientPatch: function () {
      return this.patientData?.lastVitalSignsSyncData?.iotDeviceTypeCode === IOTDeviceTypeCodes.PATCH;
    },

    getObservationValue: function (activityTypeCode) {
      const activityTypeValues = this.patientData?.patientActivityIotRealtime[activityTypeCode]?.values;
      let formattedValue = activityTypeValues?.length ? activityTypeValues.reduce((a, b) => a + '/' + b) : '';

      return formattedValue;
    },

    getPatientVitalSignData(activityTypeCode) {
      return this.patientVitalSigns?.find((x) => x.activityTypeCode === activityTypeCode);
    },

    getIotDate: function () {
      if (this.patientData?.lastVitalSignsSyncData?.lastDataDatetime) {
        const lastVitalSignsSyncData = this.patientData.lastVitalSignsSyncData;
        const lastDataDatetime = new Date(lastVitalSignsSyncData.lastDataDatetime);

        const lastDataDate = format(lastDataDatetime, 'yyyy-MM-dd');
        const lastDataTime = format(lastDataDatetime, this.getLanguage() === 'fr' ? 'HH:mm' : 'hh:mm b');

        const relativeDate = this.getRelativeDate(lastVitalSignsSyncData.timeDifference).toLowerCase();

        return `${this.$t('iotRealtime.lastDateDetails')
          .replace('{{lastDataDate}}', lastDataDate)
          .replace('{{lastDataTime}}', lastDataTime)}
            (${relativeDate})`;
      }
    },
  },
};
</script>

<style scoped>
.value-font-size {
  font-size: clamp(50px, 6.5vw, 90px);
}

.arterial-pressure-font-size {
  font-size: clamp(40px, 4.2vw, 65px);
}

.no-data {
  font-size: 35px;
  max-width: 200px;
}

.width-350px {
  width: 350px;
}

.width-45px {
  width: 45px;
}

.width-100 {
  width: 100%;
}

.width-80 {
  width: 80%;
}

.width-70 {
  width: 70%;
}

.width-60 {
  width: 60%;
}

.magenta--text {
  color: #bb14bb;
}
</style>

<template>
  <div ref="chartContainer" class="pa-4">
    <div ref="chartBody">
      <Bar ref="barChart" :chart-options="chartOptions" :chart-data="chartData" class="barChart" />
    </div>
  </div>
</template>

<script>
import translationMixin, { LanguageVue } from '@/translationMixin';
import chartMixin from './chartMixin';

import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  PointElement,
  TimeScale,
  LinearScale,
  LineController,
} from 'chart.js';
import { Bar } from 'vue-chartjs/legacy';
import {
  format,
  startOfWeek,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import { chartColor } from '../Chart/chartColor';
import 'chartjs-adapter-date-fns';
import { frCA, enCA } from 'date-fns/locale';
import { StatsDuration } from '../constants';

ChartJS.register(
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  annotationPlugin,
  zoomPlugin,
  TimeScale
);

export default {
  name: 'ChartFiltered',
  components: {
    Bar,
  },
  mixins: [translationMixin, chartMixin],
  props: {
    chartTitle: {
      type: String,
      default: '',
    },

    chartUnit: {
      type: String,
      default: '',
    },

    chartUnitLegendary: {
      type: String,
      default: null,
      required: false,
    },

    values: {
      type: Array,
      default: () => [],
    },

    yAxis: {
      type: Object,
      default() {
        return {
          Ymax: 150,
          Ymin: 0,
          YStepSize: 20,
          title: '',
        };
      },
    },

    series: {
      type: Array,
      default: () => [],
    },

    duration: {
      type: String,
      default: null,
    },

    filters: {
      type: Object,
      default: () => {},
    },

    fromNotification: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chartData: {
        datasets: [],
      },
      chartOptions: {
        animation: false,
        scales: {
          y: {
            title: {
              display: true,
            },
            min: this.yAxis.Ymin,
            max: this.yAxis.Ymax,
            ticks: {
              stepSize: this.yAxis.YStepSize,
            },
          },
          x: {
            stacked: true,
            type: 'time',
            offset: this.getOffsetValueOnDuration(),
            time: {
              unit: 'hour',
            },
            min: 0,
            max: 0,
            ticks: {
              source: 'auto',
              callback: (value) => this.formatXAxisValues(value),
            },
          },
          x2: {
            display: true,
            type: 'time',
            offset: this.getOffsetValueOnDuration(),
            time: {
              unit: null,
              displayFormats: {
                day: 'yyyy-MM-dd',
                year: 'yyyy',
              },
            },
            title: {
              display: true,
            },
            min: 0,
            max: 0,
            grid: {
              color: () => chartColor.delimiterBorderColor,
              offset: this.getOffsetValueOnDuration(),
            },
            ticks: {
              callback: (value) => this.formatX2AxisValues(value),
            },
          },
        },
        plugins: {
          tooltip: {
            titleAlign: 'center',
            callbacks: {
              title: (ctx) => this.formatLabelDate(ctx),
              afterTitle: (ctx) => ctx[0].dataset.label,
              afterBody: (ctx) => this.addTooltipStatsInfo(ctx),
              label: () => null,
            },
          },
          datalabels: {
            display: false,
          },
          legend: {
            onClick: () => null,
            title: {
              display: true,
            },
            labels: {
              filter: (label) => !!label.text,
            },
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              mode: 'x',
              onZoom: ({ chart }) => {
                this.setSpaceBetweensBarAndAlertBars(chart.scales.x.options);
              },
            },
            pan: {
              enabled: true,
              mode: 'x',
            },
          },
          autocolors: false,
          annotation: {
            annotations: {},
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      maximumTimeCategoryPercentage: null,
    };
  },

  watch: {
    values: function () {
      this.init();
    },
  },

  created() {
    this.init();
    LanguageVue.$on('projectLanguage', this.userLanguageUpdated);
  },

  beforeDestroy: function () {
    LanguageVue.$off('projectLanguage', this.userLanguageUpdated);
  },

  methods: {
    userLanguageUpdated() {
      this.init();
    },

    init: function () {
      this.setTextLanguage();
      this.showChart();

      if (!this.filters) return;
      this.setxAxis();

      if (!this.series) return;
      this.setThresholds();

      if (this.values?.length > 0) this.insertChartValues();
    },

    showChart: function () {
      this.chartData.datasets = [];

      this.chartOptions.scales.y.min = this.yAxis.Ymin;
      this.chartOptions.scales.y.max = this.yAxis.Ymax;
      this.chartOptions.scales.y.ticks.stepSize = this.yAxis.YStepSize;

      this.chartOptions.scales.x.time.displayFormats = this.getChartDisplayFormats(this.getLanguage());
    },

    setxAxis: function () {
      let xMaxDate;
      let xMinDate;

      if (this.filters.timeFilterType === 'range') {
        xMaxDate = this.filters.dateTo;
        xMinDate = this.filters.dateFrom;
      } else {
        xMaxDate = new Date();
        xMinDate = new Date(
          this.removeChartTime(new Date(), this.filters.timeFilterType, this.filters.timeFilterValue)
        );
      }

      switch (this.duration) {
        case StatsDuration.HOUR:
          this.chartOptions.scales.x.time.unit = 'hour';
          this.chartOptions.scales.x2.time.unit = 'day';

          xMaxDate = new Date(xMaxDate).setMinutes(30, 0);
          xMinDate = new Date(xMinDate).setMinutes(-30, 0);

          this.maximumTimeCategoryPercentage = 9;
          break;

        case StatsDuration.DAY:
          this.chartOptions.scales.x.time.unit = 'day';
          this.chartOptions.scales.x2.time.unit = 'month';

          xMaxDate = new Date(xMaxDate).setHours(12, 0, 0);
          xMinDate = new Date(xMinDate).setHours(-12, 0, 0);

          this.maximumTimeCategoryPercentage = 8;
          break;

        case StatsDuration.WEEK: {
          this.chartOptions.scales.x.time.unit = 'week';
          this.chartOptions.scales.x2.time.unit = 'year';

          xMaxDate = startOfWeek(new Date(xMaxDate).setHours(0, 0, 0));
          xMinDate = startOfWeek(new Date(xMinDate).setHours(0, 0, 0));
          break;
        }

        case StatsDuration.MONTH: {
          this.chartOptions.scales.x.time.unit = 'month';
          this.chartOptions.scales.x2.display = false;

          xMaxDate = new Date(xMaxDate).setDate(1);
          xMinDate = new Date(xMinDate).setDate(1);

          this.maximumTimeCategoryPercentage = 9;
          break;
        }

        case StatsDuration.YEAR:
          this.chartOptions.scales.x.time.unit = 'year';
          this.chartOptions.scales.x2.display = false;

          xMaxDate = new Date(xMaxDate).setMonth(0, 1);
          xMinDate = new Date(xMinDate).setMonth(0, 1);

          this.maximumTimeCategoryPercentage = 15;
          break;
      }

      this.chartOptions.scales.x.offset = this.getOffsetValueOnDuration();
      this.chartOptions.scales.x2.offset = this.getOffsetValueOnDuration();
      this.chartOptions.scales.x2.grid.offset = this.getOffsetValueOnDuration();

      this.chartOptions.scales.x.min = xMinDate;
      this.chartOptions.scales.x.max = xMaxDate;
      this.chartOptions.scales.x2.min = xMinDate;
      this.chartOptions.scales.x2.max = xMaxDate;

      this.chartOptions.plugins.zoom.limits = this.getChartZoomLimits(xMinDate, xMaxDate);
    },

    setThresholds: function () {
      this.chartData.datasets = this.series.map((serie, index) => {
        return {
          label: this.$t(serie.title),
          backgroundColor: index === 0 ? chartColor.correctFirstDataColor : chartColor.correctSecondaryDataColor,
          borderColor: index === 0 ? chartColor.correctFirstDataColor : chartColor.correctSecondaryDataColor,
          borderWidth: 2.5,
        };
      });

      this.chartData.datasets.push(
        ...this.series
          .filter((serie) => serie.thresholds.minThreshold || serie.thresholds.maxThreshold)
          .map((serie, datasetIndex) => {
            return {
              label: this.$t(serie.thresholds.title),
              borderWidth: 1.5,
              borderColor:
                datasetIndex === 0 && serie.thresholds.title !== 'diastolicThresholds'
                  ? chartColor.mainBorderLegendColor
                  : chartColor.secondaryBorderLegendColor,
              backgroundColor: chartColor.backgroundThreshold,
              borderDash: [12, 5],
              type: 'line',
            };
          })
      );

      this.series.forEach((serie, thresholdIndex) => {
        const { minThreshold, maxThreshold } = serie.thresholds;
        const color = thresholdIndex === 0 ? chartColor.mainBorderLegendColor : chartColor.secondaryBorderLegendColor;

        if (minThreshold) {
          this.createAnnotationThreshold(minThreshold, thresholdIndex, '<', color);
        }

        if (maxThreshold) {
          this.createAnnotationThreshold(maxThreshold, thresholdIndex + 2, '>', color);
        }
      });

      if (this.chartData.datasets.length > 0) this.chartData.datasets.push(this.getChartLegendTriggeredAlertValue());
    },

    createAnnotationThreshold: function (threshold, thresholdIndex, lessOrGreater, color) {
      this.chartOptions.plugins.annotation.annotations['limit' + thresholdIndex] =
        this.getAnnotationContentForThreshold(threshold, thresholdIndex, lessOrGreater, color, this.$t(this.chartUnit));
    },

    insertChartValues: function () {
      const valuesLength = this.values.at(0)?.values?.length;
      const datasetsValues = [];

      for (let index = 0; index < valuesLength; index++) {
        datasetsValues.push(this.getDatasetModel());
      }

      this.values.forEach((eachAlert) => {
        for (let alertIndex = 0; alertIndex < eachAlert?.values?.length; alertIndex++) {
          {
            const alertBiggerThanValue = eachAlert.values[alertIndex].thresholdsAlertValues?.biggerThan;
            const alertLesserThanValue = eachAlert.values[alertIndex].thresholdsAlertValues?.lesserThan;

            if ((alertBiggerThanValue || alertLesserThanValue) && alertIndex === 0) {
              datasetsValues[alertIndex].stack = 'Normal';
            }

            this.insertValuesInExistingDataset(datasetsValues, eachAlert, alertIndex);

            let alertConditionIfArterialPressure = true;

            if (alertIndex > 0) {
              alertConditionIfArterialPressure =
                !eachAlert.values[alertIndex - 1].thresholdsAlertValues?.biggerThan &&
                !eachAlert.values[alertIndex - 1].thresholdsAlertValues?.lesserThan;
            }

            if (alertBiggerThanValue) {
              const biggerThanDatasetValues = {
                min: alertBiggerThanValue.minimum,
                max: alertBiggerThanValue.maximum,
                average: alertBiggerThanValue.average,
                count: alertBiggerThanValue.count,
              };

              this.createTriggeredAlertDataset(
                datasetsValues,
                eachAlert,
                biggerThanDatasetValues,
                alertIndex === 1 ? alertConditionIfArterialPressure : true
              );
            }

            if (alertLesserThanValue) {
              const lesserThanDatasetValues = {
                min: alertLesserThanValue.minimum,
                max: alertLesserThanValue.maximum,
                average: alertLesserThanValue.average,
                count: alertLesserThanValue.count,
              };

              this.createTriggeredAlertDataset(
                datasetsValues,
                eachAlert,
                lesserThanDatasetValues,
                !alertBiggerThanValue && alertConditionIfArterialPressure
              );
            }
          }
        }
      });

      this.chartData.datasets.push(...datasetsValues);
      this.setSpaceBetweensBarAndAlertBars(this.chartOptions.scales.x);

      this.$nextTick(() => {
        if (this.$refs.barChart?.getCurrentChart()?.canvas.clientWidth > 0) {
          let visibleData = this.$refs.barChart.getCurrentChart().canvas.clientWidth / this.values.length;

          if (visibleData < 12) {
            this.$refs.barChart.getCurrentChart().zoom(Math.min(1.2 / (visibleData / 9), 2));
          }
        }
      });
    },

    insertValuesInExistingDataset: function (datasetsValues, eachAlert, alertIndex) {
      datasetsValues[alertIndex].data.push({
        x: new Date(eachAlert.time).getTime(),
        y: [eachAlert.values[alertIndex].minimum, eachAlert.values[alertIndex].maximum],
        average: eachAlert.values[alertIndex].average,
        count: eachAlert.values[alertIndex].count,
      });

      this.selectBarColor(datasetsValues[alertIndex], alertIndex, false);

      return datasetsValues;
    },

    createTriggeredAlertDataset: function (datasetsValues, eachAlert, datasetStats, needANewStack) {
      let newTriggeredAlertDataset = this.getDatasetModel();
      newTriggeredAlertDataset.data = [
        {
          x: new Date(eachAlert.time).getTime(),
          y: [datasetStats.min, datasetStats.max],
          average: datasetStats.average,
          count: datasetStats.count,
        },
      ];

      if (needANewStack) {
        newTriggeredAlertDataset.stack = 'Has Triggered Alert';
      }

      this.selectBarColor(newTriggeredAlertDataset, null, true);
      datasetsValues.push(newTriggeredAlertDataset);
    },

    formatLabelDate: function (data) {
      let currentLanguage = this.getLanguage();

      switch (this.duration) {
        case StatsDuration.HOUR:
          return currentLanguage === 'fr'
            ? format(new Date(data[0].raw.x), 'yyyy-MM-dd HH:mm:ss')
            : format(new Date(data[0].raw.x), 'yyyy-MM-dd hh:mm:ss b');

        case StatsDuration.DAY:
          return format(new Date(data[0].raw.x), 'yyyy-MM-dd');

        case StatsDuration.WEEK: {
          let language = this.getLanguage() === 'fr' ? frCA : enCA;
          let dateTimeFormat = this.getLanguage() === 'fr' ? 'd MMM yyyy' : 'MMM d,  yyyy';

          return this.$t('weekOf') + ' ' + format(new Date(data[0].raw.x), dateTimeFormat, { locale: language });
        }

        case StatsDuration.MONTH: {
          let monthTitle = format(new Date(data[0].raw.x), 'MMMM yyyy', {
            locale: currentLanguage === 'fr' ? frCA : enCA,
          });

          return monthTitle.charAt(0).toUpperCase() + monthTitle.slice(1);
        }

        case StatsDuration.YEAR:
          return format(new Date(data[0].raw.x), 'yyyy');
      }
    },

    formatXAxisValues: function (value) {
      let language = this.getLanguage() === 'fr' ? frCA : enCA;
      let valueDate = new Date(value);

      if (valueDate instanceof Date && !isNaN(valueDate)) {
        let dateTimeFormat = this.getLanguage() === 'fr' ? 'd MMM' : 'MMM do';

        if (this.duration === StatsDuration.DAY) {
          return format(new Date(value), dateTimeFormat, { locale: language });
        }

        if (this.duration === StatsDuration.WEEK) {
          return this.$t('weekOf') + ' ' + format(valueDate, dateTimeFormat, { locale: language });
        }
      }

      if (this.duration === StatsDuration.MONTH) {
        let monthFormat = format(valueDate, 'MMMM yyyy', { locale: language });
        return monthFormat.charAt(0).toUpperCase() + monthFormat.slice(1);
      }

      return value;
    },

    formatX2AxisValues: function (value) {
      let language = this.getLanguage() === 'fr' ? frCA : enCA;
      let valueDate = new Date(value);

      if (this.duration === StatsDuration.DAY && valueDate instanceof Date && !isNaN(valueDate)) {
        let monthFormatForX2 = format(valueDate, 'MMMM yyyy', { locale: language });
        return monthFormatForX2.charAt(0).toUpperCase() + monthFormatForX2.slice(1);
      }

      return value;
    },

    selectBarColor: function (dataset, alertIndex, triggeredAnAlert) {
      let backgroundColor = triggeredAnAlert
        ? chartColor.incorrectDataColor
        : alertIndex === 0
        ? chartColor.correctFirstDataColor
        : chartColor.correctSecondaryDataColor;

      let hoverBackgroundColor = triggeredAnAlert
        ? chartColor.hoveredIncorrectDataColor
        : alertIndex === 0
        ? chartColor.hoveredCorrectFirstDataColor
        : chartColor.hoveredCorrectSecondaryDataColor;

      dataset.backgroundColor = backgroundColor;
      dataset.hoverBackgroundColor = hoverBackgroundColor;
    },

    getOffsetValueOnDuration() {
      return (
        this.duration === StatsDuration.WEEK ||
        this.duration === StatsDuration.MONTH ||
        this.duration === StatsDuration.YEAR
      );
    },

    addTooltipStatsInfo: function (data) {
      let yValues = data[0].raw.y;
      let valuesCount = data[0].raw.count;
      let averageValue = data[0].raw.average;
      const spaceLabelValue = this.$t(this.chartUnit) === '%' || this.$t(this.chartUnit) === '°C' ? '' : ' ';

      let statsContent =
        `${this.$t('maximum')}: ${Math.max(...yValues)}${spaceLabelValue}${this.$t(this.chartUnit)}` +
        '\n' +
        `${this.$t('average')}: ${averageValue}${spaceLabelValue}${this.$t(this.chartUnit)}` +
        '\n' +
        `${this.$t('minimum')}: ${Math.min(...yValues)}${spaceLabelValue}${this.$t(this.chartUnit)}` +
        '\n' +
        `${this.$t('valuesNumber')}: ${valuesCount}`;

      return statsContent;
    },

    getDatasetModel: function () {
      return {
        label: null,
        backgroundColor: [],
        hoverBackgroundColor: [],
        data: [],
        minBarLength: 8,
        maxBarThickness: 15,
        borderRadius: 8,
        borderSkipped: false,
        categoryPercentage: 0.15,
      };
    },

    setSpaceBetweensBarAndAlertBars: function (chartXOptions) {
      if (this.values?.length === 0 || this.duration === StatsDuration.WEEK) return;
      let differenceTimeXAxis;

      switch (this.duration) {
        case StatsDuration.HOUR:
          differenceTimeXAxis = differenceInHours(new Date(chartXOptions.max), new Date(chartXOptions.min));
          break;

        case StatsDuration.DAY:
          differenceTimeXAxis = differenceInDays(new Date(chartXOptions.max), new Date(chartXOptions.min));
          break;

        case StatsDuration.MONTH:
          differenceTimeXAxis = differenceInMonths(new Date(chartXOptions.max), new Date(chartXOptions.min));
          break;

        case StatsDuration.YEAR:
          differenceTimeXAxis = differenceInYears(new Date(chartXOptions.max), new Date(chartXOptions.min));
          break;
      }

      this.chartData.datasets
        ?.filter((x) => x.data?.length > 0)
        .forEach((y) => {
          if (differenceTimeXAxis >= this.maximumTimeCategoryPercentage) {
            y.categoryPercentage = 0.6;
          } else {
            y.categoryPercentage = 0.15;
          }
        });
    },

    showOrHideAnnotationTooltip(ctx, event, thresholdIndex, showAnnotTooltip) {
      this.chartOptions.plugins.annotation.annotations['limit' + thresholdIndex].borderWidth = showAnnotTooltip ? 3 : 1;
      this.chartOptions.plugins.annotation.annotations['limit' + thresholdIndex].label.display = showAnnotTooltip;

      this.chartOptions.plugins.annotation.annotations['limit' + thresholdIndex].label.position =
        (event.x / ctx.chart.chartArea.width) * 100 + '%';

      this.$refs.barChart.updateChart();
    },

    setTextLanguage: function () {
      this.chartOptions.scales.x2.title.text = this.$t('time');
      this.chartOptions.scales.y.title.text =
        this.$t(this.yAxis.title) +
        ` (${this.chartUnitLegendary ? this.$t(this.chartUnitLegendary) : this.$t(this.chartUnit)})`;
      this.chartOptions.plugins.legend.title.text = this.$t(this.chartTitle);
    },
  },
};
</script>

<style scoped>
.barChart {
  height: 500px;
}
</style>

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import fr from 'vuetify/lib/locale/fr';
import en from 'vuetify/lib/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { fr, en },
  },
  theme: {
    themes: {
      light: {
        primary: '#dc5f26',
      },
    },
    options: { cspNonce: 'EEAD79A6F622816D5160D0787C86A804' },
  },
});

<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-form ref="prescriptiveLearningForm">
      <v-row>
        <v-col cols="12">
          <v-select
            ref="prescriptiveVideos"
            v-model="selectedVideo"
            :label="$t('video')"
            :items="prescriptiveVideos"
            item-text="name"
            item-value="id"
            filled
            :rules="[validationRules.required]"
            class="required-indicator"
          >
            <template #append-outer>
              <v-btn
                ref="prescriptiveLearningButton"
                :disabled="!selectedVideo || !!showVideoPreview || isLoading"
                @click="showPrescriptiveLearningVideo"
              >
                <span>{{ $t('videoPreview') }}</span>
              </v-btn>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <RequiredFieldsLegend />
    </v-form>

    <v-dialog v-if="showVideoPreview" ref="prescriptiveLearningDialog" v-model="showVideoPreview" max-width="1500">
      <v-card class="pt-5">
        <v-card-text>
          <iframe :src="url" width="100%" height="700" title="Video" />
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn ref="ok" text color="primary" @click="showVideoPreview = null">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import validationRulesMixin from '@/validationRulesMixin';
import activityService from '@/services/activityService';
export default {
  name: 'ActivityPrescriptiveLearning',
  mixins: [translation, validationRulesMixin],
  props: {
    selectedPrescriptiveVideoId: {
      type: Number,
      required: false,
      default: null,
    },

    prescriptiveVideos: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      selectedVideo: this.selectedPrescriptiveVideoId,
      error: null,
      url: null,
      showVideoPreview: false,
      isLoading: false,
    };
  },
  watch: {
    selectedVideo: function (prescriptiveVideoId) {
      this.url = null;
      this.$emit('setPrescriptiveVideoId', prescriptiveVideoId);
    },
  },

  methods: {
    showPrescriptiveLearningVideo: async function () {
      this.showVideoPreview = true;
      if (this.url) return;

      this.isLoading = true;

      try {
        const selectedVideo = await activityService.getPrescriptiveVideo(this.selectedVideo);

        this.url = selectedVideo?.adviceUrl;
      } catch (error) {
        this.error = error;
      }

      this.isLoading = false;
    },
  },
};
</script>

export default {
  en: {
    vocalMessage: 'Vocal Message',
    vocalMessageName: 'Vocal Message Name',
    deleteVocalMessageQuestion: 'Are you sure you want to delete this vocal message?',
    createVocalMessage: 'Add Vocal Message',
    editVocalMessage: 'Edit Vocal Message',
    warning: 'Warning',
    translationMissingLegend: 'indicates a missing translation',
  },
  fr: {
    vocalMessage: 'Message vocal',
    vocalMessageName: 'Nom de du message vocal',
    deleteVocalMessageQuestion: 'Êtes-vous certain de vouloir supprimer ce message vocal ?',
    createVocalMessage: 'Ajouter un message vocal',
    editVocalMessage: 'Modifier le message vocal',
    warning: 'Avertissement',
    translationMissingLegend: 'indique une traduction manquante',
  },
};

import VocalMessageHome from './VocalMessageHome.vue';

export default {
  moduleName: 'vocalMessage',
  routes: [
    {
      path: '/vocalmessage',
      name: 'VocalMessageHome',
      component: VocalMessageHome,
    },
  ],
};

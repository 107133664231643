<template>
  <div>
    <v-badge
      v-if="isBtn && !noBadge"
      ref="badge"
      v-model="hasBadge"
      overlap
      bordered
      color="primary"
      offset-x="6"
      offset-y="12"
      top
    >
      <span ref="alertCount" slot="badge">{{ alertCount }}</span>
      <v-icon ref="icon" :color="iconColor">{{ iconName }}</v-icon>
      <img
        v-if="itemValue.valueCode === statusLevels.CH"
        :src="changeEssity"
        class="mt-2"
        alt="change-incontinence-icon"
      />
    </v-badge>
    <v-icon v-if="hasNoValueCode" ref="icon" :color="iconColor">{{ iconName }}</v-icon>
  </div>
</template>

<script>
import { StatusLevels } from './constants';
import iconMixin from './iconMixin';
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
export default {
  name: 'PatientMonitoringIcon',
  components: {},
  mixins: [translationMixin, accessibility, iconMixin],

  props: {
    item: {
      type: Object,
      required: true,
    },
    itemType: {
      type: Object,
      required: true,
    },
    noBadge: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      statusLevels: StatusLevels,
      changeEssity: '/static/incontinenceicon/change_essity.svg',
    };
  },

  computed: {
    itemValue() {
      return this.item.monitoring[`_${this.itemType.id}`];
    },
    hasNoValueCode: function () {
      const item = this.item.monitoring[`_${this.itemType.id}`];
      return item == undefined || item == null || Object.hasOwn(item, 'valueCode') == false;
    },

    hasBadge: function () {
      return !this.hasNoValueCode && this.itemValue.alertCount > 0;
    },

    alertCount: function () {
      return this.hasNoValueCode ? 0 : this.itemValue.alertCount;
    },

    isBtn: function () {
      let result = true;

      if (this.hasNoValueCode || (this.hasNoValueCode == false && this.itemValue.valueCode == StatusLevels.NF)) {
        result = false;
      }

      return result;
    },

    iconCode: function () {
      return this.hasNoValueCode ? StatusLevels.NF : this.itemValue.valueCode;
    },

    iconColor: function () {
      return this.getIconColor(this.iconCode);
    },

    iconName: function () {
      return this.getIconName(this.iconCode);
    },
  },

  methods: {},
};
</script>

<style scoped></style>

<template>
  <div :class="{ 'page-title--xs': $vuetify.breakpoint.xs }">
    <span
      ref="title"
      :aria-label="title"
      class="page-title--text"
      :class="$vuetify.breakpoint.xs ? ['text-h5'] : ['text-h3', 'text-uppercase']"
      >{{ title }}</span
    >
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.page-title--xs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 36px;
}

.page-title--text {
  color: var(--background-dark);
  font-weight: 700 !important;
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    proximiteIsAvailable: false,
  },
  getters: {
    proximiteIsAvailable: (state) => state.proximiteIsAvailable,
  },
  mutations: {
    setProximiteIsAvailable(state, proximiteIsAvailable) {
      state.proximiteIsAvailable = proximiteIsAvailable;
    },
  },
  actions: {},
});

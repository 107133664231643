<template>
  <a ref="anchorRef" :href="href" role="button" @keyup="emitEvent($event)" @click="emitEvent($event)">
    {{ linkText }}
  </a>
</template>

<script>
export default {
  name: 'ButtonLink',

  props: {
    href: {
      type: String,
      default: null,
    },
    linkedObject: {
      type: Object,
      default: null,
    },
    linkText: {
      type: String,
      required: true,
    },
  },

  mounted: function () {
    this.$nextTick(function () {
      this.$refs.anchorRef.onkeydown = this.defaultPrevent;
      this.$refs.anchorRef.onkeypress = this.defaultPrevent;
      this.$refs.anchorRef.onkeyup = this.defaultPrevent;
    });
  },

  methods: {
    defaultPrevent: function (event) {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    },

    emitEvent: function (event) {
      if (event.type == 'click' || (event.type == 'keyup' && event.keyCode == 32)) {
        this.$emit('click', this.linkedObject);
      }
    },
  },
};
</script>

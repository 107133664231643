import PatientIotRealtime from './PatientIotRealtime.vue';

export default {
  moduleName: 'patientIotRealtime',
  routes: [
    {
      path: '/patientiotrealtime/:patientId?',
      name: 'patientIotRealtime',
      component: PatientIotRealtime,

      props: (route) => {
        let props = {};

        if (route.params?.patientId) {
          props.patientId = Number.parseInt(route.params.patientId);
        }

        return props;
      },
    },
  ],
};

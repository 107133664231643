<template>
  <div ref="chartContainer" class="pa-4">
    <div ref="chartBody">
      <LineChart ref="lineChart" :chart-options="chartOptions" :chart-data="chartData" class="lineChart" />
    </div>
  </div>
</template>

<script>
import translationMixin, { LanguageVue } from '@/translationMixin';
import chartMixin from './chartMixin';

import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  PointElement,
  TimeScale,
} from 'chart.js';
import { Line as LineChart } from 'vue-chartjs/legacy';
import { addMinutes, format, addHours, subHours, parse } from 'date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
import { chartColor } from './chartColor';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  PointElement,
  annotationPlugin,
  ChartDataLabels,
  zoomPlugin,
  TimeScale
);

export default {
  name: 'ChartDataLabels',
  components: {
    LineChart,
  },
  mixins: [translationMixin, chartMixin],
  props: {
    chartTitle: {
      type: String,
      default: '',
    },

    chartUnit: {
      type: String,
      default: '',
    },

    yAxis: {
      type: Object,
      default() {
        return {
          Ymax: 100,
          Ymin: 60,
          YStepSize: 5,
          title: '',
        };
      },
    },

    values: {
      type: Array,
      default: () => [],
    },

    series: {
      type: Array,
      default: () => [],
    },

    filters: {
      type: Object,
      default: () => {},
    },

    fromNotification: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      spacingMinutes: this.filters?.timeFilterType === 'days' && this.filters?.timeFilterValue > 5 ? 180 : 30,
      chartData: {
        datasets: [],
      },
      chartOptions: {
        animation: false,
        scales: {
          y: {
            title: {
              display: true,
            },
            min: this.yAxis.Ymin,
            max: this.yAxis.Ymax,
            ticks: {
              stepSize: this.yAxis.YStepSize,
            },
          },
          x: {
            type: 'time',
            time: {
              unit: 'hour',
            },
            min: 0,
            max: 0,
            ticks: { source: 'auto', maxTicksLimit: this.getMaxTicksLimit() },
          },
          x2: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'yyyy-MM-dd',
              },
            },
            title: {
              display: true,
            },
            min: 0,
            max: 0,

            grid: {
              color: () => chartColor.delimiterBorderColor,
            },
          },
        },
        plugins: {
          tooltip: {
            titleAlign: 'center',
            footerAlign: 'center',
            caretPadding: 9,
            callbacks: {
              title: (data) => this.getChartTooltipTitle(data),
              label: (ctx) => this.getChartTooltipLabel(ctx.dataset.vitalSignLabel, ctx.formattedValue, this.chartUnit),
              labelColor: (context) => {
                return {
                  backgroundColor: context.dataset?.backgroundColor[context.dataIndex],
                  borderColor: context.dataset?.backgroundColor[context.dataIndex],
                };
              },
              afterLabel: (ctx) => this.getVariationDetails(ctx.raw?.alertDetails),
              footer: (ctx) => {
                if (ctx[0]?.raw?.isManualEntryValueByPatient) {
                  return this.$t('valueManualyEnteredByPatient');
                } else if (ctx[0]?.raw?.userManualEntry) {
                  return this.$t('valueManualyEnteredByUser').replace('{{userName}}', ctx[0]?.raw?.userManualEntry);
                }
              },
            },
          },
          legend: {
            onClick: () => null,
            title: {
              display: true,
            },
            labels: {
              filter: (label) => !!label.text,
            },
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              mode: 'x',
            },
            pan: {
              enabled: true,
              mode: 'x',
            },
          },
          autocolors: false,
          annotation: {
            annotations: {},
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  watch: {
    values: function () {
      this.init();
    },

    '$vuetify.breakpoint.mdAndUp'() {
      this.chartOptions.scales.x.ticks.maxTicksLimit = this.getMaxTicksLimit();
    },
  },

  created() {
    this.init();
    LanguageVue.$on('projectLanguage', this.userLanguageUpdated);
  },

  beforeDestroy: function () {
    LanguageVue.$off('projectLanguage', this.userLanguageUpdated);
  },

  methods: {
    userLanguageUpdated() {
      this.init();
    },

    init: function () {
      this.setTextLanguage();
      this.showChart();

      if (!this.filters) return;
      this.setxAxis();

      if (!this.series) return;
      this.setThresholds();

      if (this.values?.length > 0) this.insertChartValues();
    },

    showChart: function () {
      this.chartData.datasets = [];

      this.chartOptions.scales.y.min = this.yAxis.Ymin;
      this.chartOptions.scales.y.max = this.yAxis.Ymax;
      this.chartOptions.scales.y.ticks.stepSize = this.yAxis.YStepSize;

      this.chartOptions.scales.x.time.displayFormats = this.getChartDisplayFormats(this.getLanguage());
    },

    adjustDateRange(dateFrom, dateTo) {
      let adjustedMinDate;
      let adjustedMaxDate;

      if (this.fromNotification) {
        let parsedMinDate = parse(dateFrom, 'yyyy-MM-dd HH:mm:ss', new Date());
        let parsedMaxDate = parse(dateTo, 'yyyy-MM-dd HH:mm:ss', new Date());

        parsedMinDate = addHours(parsedMinDate, 11);
        parsedMaxDate = subHours(parsedMaxDate, 11);

        adjustedMinDate = format(parsedMinDate, 'yyyy-MM-dd HH:mm:ss');
        adjustedMaxDate = format(parsedMaxDate, 'yyyy-MM-dd HH:mm:ss');
      } else {
        adjustedMinDate = dateFrom;
        adjustedMaxDate = dateTo;
      }

      return { adjustedMinDate, adjustedMaxDate };
    },

    setxAxis: function () {
      let xMinDate;
      let xMaxDate;
      let originalXMinDate;
      let originalXMaxDate;
      const currentDate = new Date();
      const currentDateFormatted = format(currentDate, 'yyyy-MM-dd HH:mm:ss');

      if (this.filters.timeFilterType === 'range') {
        originalXMinDate = this.filters.dateFrom;
        originalXMaxDate = this.filters.dateTo;
        ({ adjustedMinDate: xMinDate, adjustedMaxDate: xMaxDate } = this.adjustDateRange(
          originalXMinDate,
          originalXMaxDate
        ));

        if (this.fromNotification && originalXMaxDate > currentDateFormatted) {
          originalXMaxDate = format(addMinutes(currentDate, this.spacingMinutes), 'yyyy-MM-dd HH:mm:ss');
        }
      } else {
        originalXMaxDate = currentDateFormatted;

        let timeFilterValue;
        let timeFilterType;

        if (!this.filters.timeFilterType || this.filters.timeFilterType === '24h') {
          timeFilterValue = 24;
          timeFilterType = 'hours';
        } else {
          timeFilterValue = this.filters.timeFilterValue;
          timeFilterType = this.filters.timeFilterType;
        }

        originalXMinDate = this.removeChartTime(currentDate, timeFilterType, timeFilterValue);
        xMinDate = originalXMinDate;
        xMaxDate = originalXMaxDate;
      }

      xMaxDate = format(addMinutes(new Date(xMaxDate), this.spacingMinutes), 'yyyy-MM-dd HH:mm:ss');
      xMinDate = format(addMinutes(new Date(xMinDate), this.spacingMinutes * -1), 'yyyy-MM-dd HH:mm:ss');

      this.chartOptions.scales.x.min = xMinDate;
      this.chartOptions.scales.x.max = xMaxDate;
      this.chartOptions.scales.x2.min = xMinDate;
      this.chartOptions.scales.x2.max = xMaxDate;

      this.chartOptions.plugins.zoom.limits = this.getChartZoomLimits(
        this.chartOptions.scales.x.min,
        this.chartOptions.scales.x.max
      ); // The zoom limits must always be the same as xMax and xMin
    },

    setThresholds: function () {
      this.series.forEach((serie) => {
        this.chartData.datasets.push(
          {
            label: null,
            vitalSignLabel: this.$t(serie.title),
            borderWidth: 2,
            pointRadius: 6,
            data: [],
            datalabels: {
              display: true,
              offset: 4,
              formatter: (val) => val.y,
              clip: true,
              clamp: true,
              backgroundColor: (ctx) => ctx.dataset.backgroundColor,
              borderRadius: 4,
              color: chartColor.textLabel,
              font: {
                weight: 'bold',
              },
              padding: 2,
            },
            backgroundColor: [],
            borderColor: chartColor.backgroundThreshold,
            normalized: true,
            spanGaps: true,
            fill: false,
            parsing: false,
            segment: {
              borderColor: (ctx) =>
                ctx.p0.options.backgroundColor === chartColor.incorrectDataColor &&
                ctx.p1.options.backgroundColor === chartColor.incorrectDataColor
                  ? chartColor.incorrectDataColor
                  : chartColor.correctFirstDataColor,
            },
          },
          {
            label: this.$t(serie.title),
            backgroundColor: chartColor.correctFirstDataColor,
            borderColor: chartColor.correctFirstDataColor,
          }
        );
      });

      this.chartData.datasets.push(
        ...this.series
          .filter((serie) => serie.thresholds.minThreshold || serie.thresholds.maxThreshold)
          .map((serie) => {
            return {
              label: this.$t(serie.thresholds.title),
              borderWidth: 1.5,
              borderColor: chartColor.correctFirstDataColor,
              backgroundColor: chartColor.backgroundThreshold,
              borderDash: [12, 5],
            };
          })
      );

      this.series.forEach((serie, thresholdIndex) => {
        const { minThreshold, maxThreshold } = serie.thresholds;

        if (minThreshold) {
          this.createAnnotationThreshold(minThreshold, thresholdIndex, '<', chartColor.mainBorderLegendColor);
        }

        if (maxThreshold) {
          this.createAnnotationThreshold(maxThreshold, thresholdIndex + 2, '>', chartColor.mainBorderLegendColor);
        }
      });

      if (this.chartData.datasets.length > 0) this.chartData.datasets.push(this.getChartLegendTriggeredAlertValue());
    },

    createAnnotationThreshold: function (threshold, thresholdIndex, lessOrGreater, color) {
      this.chartOptions.plugins.annotation.annotations['limit' + thresholdIndex] =
        this.getAnnotationContentForThreshold(threshold, thresholdIndex, lessOrGreater, color, this.$t(this.chartUnit));
    },

    insertChartValues: function () {
      this.values.forEach((eachAlert) => {
        for (let alertIndex = 0; alertIndex < eachAlert?.values?.length; alertIndex++) {
          const alert = eachAlert.values?.[alertIndex];

          this.chartData.datasets[alertIndex].data.push({
            x: new Date(eachAlert.time).getTime(),
            y: alert.value,
            alertDetails: alert.alertDetails,
            isManualEntryValueByPatient: alert.isManualEntryValueByPatient,
            userManualEntry: alert.userManualEntry,
          });

          const pointColor = alert.hasTriggeredAnAlert
            ? chartColor.incorrectDataColor
            : chartColor.correctFirstDataColor;

          this.chartData.datasets[alertIndex].backgroundColor.push(pointColor);
        }
      });
    },

    showOrHideAnnotationTooltip(ctx, event, thresholdIndex, showAnnotTooltip) {
      this.chartOptions.plugins.annotation.annotations['limit' + thresholdIndex].borderWidth = showAnnotTooltip ? 3 : 1;
      this.chartOptions.plugins.annotation.annotations['limit' + thresholdIndex].label.display = showAnnotTooltip;

      this.chartOptions.plugins.annotation.annotations['limit' + thresholdIndex].label.position =
        (event.x / ctx.chart.chartArea.width) * 100 + '%';

      this.$refs.lineChart.updateChart();
    },

    getMaxTicksLimit: function () {
      return this.$vuetify.breakpoint.mdAndUp ? 50 : 20;
    },

    getVariationDetails: function (alertDetails) {
      if (!alertDetails || alertDetails?.length === 0) return null;

      let variationAlertsArray = [];

      alertDetails.forEach((alert) => {
        let unitType;

        if (alert.unitType === 'percentageVariation') {
          unitType = '%';
        } else {
          unitType = ' ';

          if (alert.unitType === 'lbs' && (this.getLanguage() === 'fr' || alert.threshold <= 1)) {
            unitType += 'lb';
          } else {
            unitType += alert.unitType;
          }
        }

        const thresholdValue = alert.threshold + unitType;
        const length = alert.length;
        const lengthType = alert.lengthType;

        let duration;

        if (lengthType === 'days') {
          duration = length === 1 ? this.$t('day') : this.$t('days');
        } else {
          duration = this.$t(lengthType);
        }

        const alertDuration = length + ' ' + duration.toLowerCase();

        variationAlertsArray.push(
          this.$t('variationAlertMessage')
            .replace('{{threshold}}', thresholdValue)
            .replace('{{duration}}', alertDuration)
        );
      });

      return variationAlertsArray;
    },

    setTextLanguage: function () {
      this.chartOptions.scales.x2.title.text = this.$t('time');
      this.chartOptions.scales.y.title.text = this.$t(this.yAxis.title) + ` (${this.$t(this.chartUnit)})`;
      this.chartOptions.plugins.legend.title.text = this.$t(this.chartTitle);
    },
  },
};
</script>

<style scoped>
.lineChart {
  height: 500px;
}
</style>

<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="showWaitModal" />
    <v-data-table
      :items="items"
      :headers="headers"
      :footer-props="footerProps"
      :items-per-page="25"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      item-key="alertDate"
      class="elevation-1 mt-4"
      mobile-breakpoint="0"
    >
      <template #item.statusLevelId="{ item }">
        <div>
          <v-icon :color="getIconColor(item.statusLevelCode)">
            {{ getIconName(item.statusLevelCode) }}
          </v-icon>
        </div>
      </template>
    </v-data-table>
  </span>
</template>

<script>
import translationMixin from '@/translationMixin';
import patientMonitoringService from '@/services/patientMonitoringService';
import { format } from 'date-fns';
import iconMixin from '../iconMixin';
import filterMixin from '../filterMixin';
import { ActivityTypes } from '@/components/PatientMonitoring/constants';

export default {
  name: 'PatientPrescriptiveLearning',
  mixins: [translationMixin, iconMixin, filterMixin],
  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },
    filters: {
      type: Object,
      required: false,
      default: null,
      validator(value) {
        return value == null || (typeof value === 'object' && !Array.isArray(value));
      },
    },
    activityId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    items: [],
    statusLevels: [],
    sortBy: 'alertDate',
    sortDesc: true,
    error: null,
    showWaitModal: false,
    requestFilters: null,
  }),
  computed: {
    footerProps: function () {
      return {
        itemsPerPageOptions: [5, 10, 25, 50, -1],
        showFirstLastPage: true,
        'items-per-page-text': this.$t('rowsByPage'),
      };
    },
    headers() {
      return [
        {
          text: this.$t('statusLevelId'),
          textalign: 'center',
          value: 'statusLevelId',
          align: 'center',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t('alertDate'),
          value: 'alertDate',
          sortable: false,
          width: '110px',
          order: 'desc',
        },
        {
          text: '',
          value: 'executedData',
          sortable: false,
          order: 'desc',
        },
      ];
    },
    dateFrom() {
      return this.calculateNonChartDate('start');
    },
    dateTo() {
      return this.calculateNonChartDate('end');
    },
  },
  watch: {
    patientId: function () {
      this.getPatientPrescriptiveLearningData();
    },

    filters: function () {
      this.getPatientPrescriptiveLearningData();
    },

    requestFilters: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.$emit('update:request-filters', newVal);
        }
      },
      deep: true,
    },
  },

  created: function () {
    this.getPatientPrescriptiveLearningData();
  },
  methods: {
    async getPatientPrescriptiveLearningData() {
      this.showWaitModal = true;

      const newFilters = this.setRequestFilters(ActivityTypes.PRE);
      this.requestFilters = newFilters;

      try {
        const preLearningResponse = await patientMonitoringService.getPatientPrescriptiveLearning(this.requestFilters);

        this.statusLevels = preLearningResponse.statusLevels;
        let statusLevelMap = this.statusLevels.reduce((map, level) => map.set(level.id, level.code), new Map());

        this.items = preLearningResponse.results.map((item) => {
          const executedAt = item.executedAt
            ? format(new Date(item.executedAt), this.getLanguage() === 'fr' ? 'HH:mm' : 'HH:mm b')
            : null;

          return {
            ...item,
            executedData: executedAt
              ? this.$t('trainingCompletedAt') + ' ' + executedAt
              : this.$t('trainingNotCompleted'),
            statusLevelCode: statusLevelMap.get(item.statusLevelId),
          };
        });
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },
  },
};
</script>

<template>
  <div ref="chartContainer" class="pa-4">
    <div ref="chartBody">
      <LineChart ref="lineChart" :chart-options="chartOptions" :chart-data="chartData" class="lineChart" />
    </div>
  </div>
</template>

<script>
import translationMixin, { LanguageVue } from '@/translationMixin';
import chartMixin from './chartMixin';

import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  PointElement,
  TimeScale,
} from 'chart.js';
import { Line as LineChart } from 'vue-chartjs/legacy';
import { addMinutes, differenceInHours, format, parse, addHours } from 'date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
import { chartColor } from './chartColor';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  PointElement,
  annotationPlugin,
  ChartDataLabels,
  zoomPlugin,
  TimeScale
);

export default {
  name: 'ChartDetails',
  components: {
    LineChart,
  },
  mixins: [translationMixin, chartMixin],
  props: {
    chartTitle: {
      type: String,
      default: '',
    },

    filters: {
      type: Object,
      default: null,
    },

    chartUnit: {
      type: String,
      default: '',
    },

    chartUnitLegendary: {
      type: String,
      default: null,
      required: false,
    },

    values: {
      type: Array,
      default: () => [],
    },

    lastHoursValue: {
      type: Number,
      default: 24,
    },

    yAxis: {
      type: Object,
      default() {
        return {
          Ymax: 150,
          Ymin: 0,
          YStepSize: 20,
          title: '',
        };
      },
    },

    series: {
      type: Array,
      default: () => [],
    },

    fromNotification: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      spacingMinutes: 30,
      chartData: {
        datasets: [],
      },
      chartOptions: {
        animation: false,
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
            },
            min: this.yAxis.Ymin ? this.yAxis.Ymin : 0,
            max: this.yAxis.Ymax,
            ticks: {
              stepSize: this.yAxis.YStepSize,
            },
          },
          x: {
            type: 'time',
            offset: false,
            time: {
              unit: 'hour',
            },
            min: 0,
            max: 0,
            ticks: { source: 'auto' },
          },
          x2: {
            type: 'time',
            offset: false,
            time: {
              unit: 'day',
              displayFormats: {
                day: 'yyyy-MM-dd',
              },
            },
            title: {
              display: true,
            },
            min: 0,
            max: 0,
            grid: {
              color: () => chartColor.delimiterBorderColor,
            },
          },
        },
        plugins: {
          tooltip: {
            titleAlign: 'center',
            footerAlign: 'center',
            callbacks: {
              title: (data) => this.getChartTooltipTitle(data),
              label: (ctx) => this.getChartTooltipLabel(ctx.dataset.tooltipLabel, ctx.formattedValue, this.chartUnit),
              footer: (ctx) => {
                if (ctx[0]?.raw?.isManualEntryValueByPatient) {
                  return this.$t('valueManualyEnteredByPatient');
                } else if (ctx[0]?.raw?.userManualEntry) {
                  return this.$t('valueManualyEnteredByUser').replace('{{userName}}', ctx[0]?.raw?.userManualEntry);
                }
              },
            },
          },
          datalabels: {
            display: false,
          },
          legend: {
            onClick: () => null,
            title: {
              display: true,
            },
            labels: {
              filter: (label) => !!label.text,
            },
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              mode: 'x',
              onZoom: ({ chart }) => {
                this.showPointRadiusOnZoom(chart.scales.x.options);
              },
            },
            pan: {
              enabled: true,
              mode: 'x',
            },
          },
          autocolors: false,
          annotation: {
            annotations: {},
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      radiusPointsSize: 1.8,
    };
  },

  watch: {
    values: function () {
      this.init();
    },
  },

  created() {
    this.init();
    LanguageVue.$on('projectLanguage', this.userLanguageUpdated);
  },

  beforeDestroy: function () {
    LanguageVue.$off('projectLanguage', this.userLanguageUpdated);
  },

  methods: {
    userLanguageUpdated() {
      this.init();
    },

    init: function () {
      this.setTextLanguage();
      this.showChart();

      if (!this.series) return;
      this.setThresholds();

      if (this.values?.length > 0) {
        this.insertChartValues();

        this.$nextTick(() => {
          this.showPointRadiusOnZoom(this.$refs.lineChart.chartOptions.scales.x);
          this.$refs.lineChart.updateChart();
        });
      }
    },

    adjustDateRange(dateFrom, dateTo) {
      let adjustedMinDate;
      let adjustedMaxDate;

      if (this.fromNotification) {
        let parsedMinDate = parse(dateFrom, 'yyyy-MM-dd HH:mm:ss', new Date());
        let parsedMaxDate = parse(dateTo, 'yyyy-MM-dd HH:mm:ss', new Date());

        if (this.filters?.timeFilterType !== '24h') {
          parsedMinDate = addHours(parsedMinDate, 11);
          parsedMaxDate = addHours(parsedMaxDate, -11);
        }

        adjustedMinDate = format(addMinutes(parsedMinDate, -this.spacingMinutes), 'yyyy-MM-dd HH:mm:ss');
        adjustedMaxDate = format(addMinutes(parsedMaxDate, this.spacingMinutes), 'yyyy-MM-dd HH:mm:ss');
      } else {
        adjustedMinDate = format(
          addMinutes(parse(dateFrom, 'yyyy-MM-dd HH:mm:ss', new Date()), -this.spacingMinutes),
          'yyyy-MM-dd HH:mm:ss'
        );
        adjustedMaxDate = format(
          addMinutes(parse(dateTo, 'yyyy-MM-dd HH:mm:ss', new Date()), this.spacingMinutes),
          'yyyy-MM-dd HH:mm:ss'
        );
      }

      return { adjustedMinDate, adjustedMaxDate };
    },

    showChart: function () {
      this.chartData.datasets = [];
      const isFilterTypeRange = this.filters?.timeFilterType === 'range';

      let dateTo =
        this.filters?.dateTo && isFilterTypeRange ? this.filters.dateTo : format(new Date(), 'yyyy-MM-dd HH:mm:ss');

      let dateFrom =
        this.filters?.dateFrom && isFilterTypeRange
          ? this.filters.dateFrom
          : this.removeChartTime(new Date(), 'hours', this.lastHoursValue);

      const { adjustedMinDate, adjustedMaxDate } = this.adjustDateRange(dateFrom, dateTo);

      const currentDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

      if (this.fromNotification && dateTo > currentDate) {
        dateTo = format(addMinutes(new Date(currentDate), this.spacingMinutes), 'yyyy-MM-dd HH:mm:ss');
      } else {
        dateTo = format(addMinutes(new Date(dateTo), this.spacingMinutes), 'yyyy-MM-dd HH:mm:ss');
      }

      dateFrom = format(addMinutes(new Date(dateFrom), this.spacingMinutes * -1), 'yyyy-MM-dd HH:mm:ss');

      this.chartOptions.scales.x.min = adjustedMinDate;
      this.chartOptions.scales.x.max = adjustedMaxDate;
      this.chartOptions.scales.x2.min = adjustedMinDate;
      this.chartOptions.scales.x2.max = adjustedMaxDate;

      this.chartOptions.scales.x.time.displayFormats = this.getChartDisplayFormats(this.getLanguage());
      this.chartOptions.plugins.zoom.limits = this.getChartZoomLimits(dateFrom, dateTo);
    },

    setThresholds: function () {
      this.chartData.datasets = this.series.map((serie, index) => {
        return {
          label: this.$t(serie.title),
          backgroundColor: index === 0 ? chartColor.correctFirstDataColor : chartColor.correctSecondaryDataColor,
          borderColor: index === 0 ? chartColor.correctFirstDataColor : chartColor.correctSecondaryDataColor,
        };
      });

      this.chartData.datasets.push(
        ...this.series
          .filter((serie) => serie.thresholds.minThreshold || serie.thresholds.maxThreshold)
          .map((serie, datasetIndex) => {
            return {
              label: this.$t(serie.thresholds.title),
              borderWidth: 1.5,
              borderColor:
                datasetIndex === 0 && serie.thresholds.title !== 'diastolicThresholds'
                  ? chartColor.correctFirstDataColor
                  : chartColor.correctSecondaryDataColor,
              backgroundColor: chartColor.backgroundThreshold,
              borderDash: [12, 5],
            };
          })
      );

      this.series.forEach((serie, thresholdIndex) => {
        const { minThreshold, maxThreshold } = serie.thresholds;
        const color = thresholdIndex === 0 ? chartColor.mainBorderLegendColor : chartColor.secondaryBorderLegendColor;

        if (minThreshold) {
          this.createAnnotationThreshold(minThreshold, thresholdIndex, '<', color);
        }

        if (maxThreshold) {
          this.createAnnotationThreshold(maxThreshold, thresholdIndex + 2, '>', color);
        }
      });

      if (this.chartData.datasets.length > 0) this.chartData.datasets.push(this.getChartLegendTriggeredAlertValue());
    },

    createAnnotationThreshold: function (threshold, thresholdIndex, lessOrGreater, color) {
      this.chartOptions.plugins.annotation.annotations['limit' + thresholdIndex] =
        this.getAnnotationContentForThreshold(threshold, thresholdIndex, lessOrGreater, color, this.$t(this.chartUnit));
    },

    insertChartValues: function () {
      let valuesLength = this.values?.[0][0].values.length;

      this.values.forEach((alert) => {
        for (let alertIndex = 0; alertIndex < valuesLength; alertIndex++) {
          let datasetValues = this.getDatasetValues(alert, alertIndex);

          this.chartData.datasets.push({
            label: null,
            data: datasetValues.data,
            borderWidth: 2,
            pointStyle: 'circle',
            pointRadius: datasetValues.radiusPoints,
            pointHoverRadius: 5,
            borderColor: datasetValues.color,
            backgroundColor: datasetValues.color,
            tooltipLabel: this.$t(this.series[alertIndex].title),
            segment: {
              borderColor: (ctx) =>
                ctx.p0.options.backgroundColor === chartColor.incorrectDataColor &&
                ctx.p1.options.backgroundColor === chartColor.incorrectDataColor
                  ? chartColor.incorrectDataColor
                  : alertIndex === 0
                  ? chartColor.correctFirstDataColor
                  : chartColor.correctSecondaryDataColor,
            },
            normalized: true,
            spanGaps: true,
            fill: false,
            parsing: false,
          });
        }
      });
    },

    getDatasetValues: function (alerts, alertIndex) {
      let radiusPoints = [];
      let color = [];

      let data = alerts.map((alert) => {
        const alertValue = alert.values[alertIndex];
        const hasTriggeredAnAlert = alertValue.hasTriggeredAnAlert;

        color.push(
          hasTriggeredAnAlert
            ? chartColor.incorrectDataColor
            : alertIndex === 0
            ? chartColor.correctFirstDataColor
            : chartColor.correctSecondaryDataColor
        );

        radiusPoints.push((alerts.length === 1 || hasTriggeredAnAlert) && this.radiusPointsSize);

        return {
          x: new Date(alert.time).getTime(),
          y: alertValue.value,
          isManualEntryValueByPatient: alertValue.isManualEntryValueByPatient,
          userManualEntry: alertValue.userManualEntry,
        };
      });

      return {
        data: data,
        color: color,
        radiusPoints: radiusPoints,
      };
    },

    setTextLanguage: function () {
      this.chartOptions.scales.x2.title.text = this.$t('time');
      this.chartOptions.scales.y.title.text =
        this.$t(this.yAxis.title) +
        ` (${this.chartUnitLegendary ? this.$t(this.chartUnitLegendary) : this.$t(this.chartUnit)})`;
      this.chartOptions.plugins.legend.title.text = this.$t(this.chartTitle);
    },

    showPointRadiusOnZoom: function (chartXOptions) {
      if (!chartXOptions || this.values?.length === 0) return;
      const differenceHoursXAxis = differenceInHours(new Date(chartXOptions.max), new Date(chartXOptions.min));

      this.chartData.datasets
        ?.filter((x) => x.data?.length > 0)
        .forEach((y) => {
          for (let pointIndex = 0; pointIndex < y.pointRadius.length; pointIndex++) {
            if (y.backgroundColor[pointIndex] !== chartColor.incorrectDataColor) {
              y.pointRadius[pointIndex] =
                (differenceHoursXAxis < (this.lastHoursValue === 24 ? 8 : 5) || y.data.length === 1) &&
                this.radiusPointsSize;
            }
          }
        });
    },

    showOrHideAnnotationTooltip: function (ctx, event, thresholdIndex, showAnnotTooltip) {
      this.chartOptions.plugins.annotation.annotations['limit' + thresholdIndex].borderWidth = showAnnotTooltip ? 2 : 1;
      this.chartOptions.plugins.annotation.annotations['limit' + thresholdIndex].label.display = showAnnotTooltip;

      this.chartOptions.plugins.annotation.annotations['limit' + thresholdIndex].label.position =
        (event.x / ctx.chart.chartArea.width) * 100 + '%';

      this.$refs.lineChart.updateChart();
    },
  },
};
</script>

<style scoped>
.lineChart {
  height: 500px;
}
</style>

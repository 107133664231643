export const DialogueParameterTypes = {
  QUESTION: 'question',
  RESPONSE: 'response',
};

export const QuestionTypes = {
  YESNO: 'yesno',
  RANGE: 'range',
  CUSTOM: 'custom',
};

export const YesNoValues = {
  YES: 'yes',
  NO: 'no',
};

export const DialogueLanguages = {
  FRENCH: 'fr',
  ENGLISH: 'en',
};

export const EmoticonCodes = {
  EXCITED: 'excited',
  HAPPY: 'happy',
  NEUTRAL: 'neutral',
  SAD: 'sad',
  SICK: 'sick',
  ANGRY: 'angry',
};

export const MinResponses = 2;
export const MaxCustomResponses = 5;

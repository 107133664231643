<template>
  <div class="print-fit">
    <WaitModal :show="isDataGridLoading" />

    <v-row align="center" class="search-filter">
      <v-col>
        <v-text-field
          v-model="search"
          :disabled="isDataGridLoading"
          dense
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          clearable
          outlined
          @input="filterFunc()"
        >
        </v-text-field>
      </v-col>

      <v-col cols="auto">
        <v-menu ref="settingsMenu" offset-y>
          <template #activator="{ on, attrs }">
            <v-btn ref="configButton" :disabled="isDataGridLoading" icon v-bind="attrs" v-on="on">
              <v-icon v-if="isDefaultConfig()"> mdi-cog-outline </v-icon>
              <v-icon v-else color="primary"> mdi-cog </v-icon>
            </v-btn>
          </template>

          <v-list dense @click.stop>
            <div class="field-list-container">
              <draggable v-model="allColumns" group="people" draggable=".item" handle=".handle" @end="dragColumnEnd">
                <v-list-item v-for="item in allColumns" :key="item.value" class="item">
                  <v-list-item-content @click.stop>
                    <div>
                      <v-icon class="handle mr-2">mdi-drag-horizontal-variant</v-icon>
                      <v-icon
                        v-if="item.visible"
                        ref="columnVisibility"
                        class="mx-2"
                        color="primary"
                        @click="toggleColumnVisibility(item)"
                      >
                        mdi-eye-outline
                      </v-icon>
                      <v-icon v-else ref="columnVisibility" class="mx-2" @click="toggleColumnVisibility(item)"
                        >mdi-eye-off-outline</v-icon
                      >
                      <span>{{ $t(item.text) }}</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </draggable>
            </div>

            <v-list-item class="mt-2">
              <v-list-item-title>
                <v-btn :disabled="isDataGridLoading" depressed @click.stop="resetColumns">
                  <v-icon left> mdi-eye-refresh-outline </v-icon>
                  {{ $t('reset') }}
                </v-btn>

                <v-btn :disabled="isDataGridLoading" class="ml-2" depressed @click.stop="clearFilters()">
                  <v-icon left> mdi-filter-remove-outline </v-icon>
                  {{ $t('clearFilters') }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row ref="gridContent">
      <v-col cols="12">
        <v-sheet elevation="2" :class="headers.length > maxColumns ? 'no-print' : []">
          <v-data-table
            ref="dataTable"
            v-model="selected"
            hide-default-footer
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :headers="headers"
            :items="filteredItems"
            :search="search"
            mobile-breakpoint="0"
            :show-select="showSelect"
            :single-select="singleSelect"
            :item-key="itemKey"
            :items-per-page="-1"
            :no-data-text="isDataGridLoading ? '' : $t('noDataInTable')"
            :height="!!tableHeight ? `${tableHeight}` : undefined"
            :fixed-header="!!tableHeight"
            :show-expand="showExpand"
            :custom-sort="customSort"
            @update:sort-by="onSortChange"
            @click:row="rowClick"
            @item-expanded="itemExpanded"
          >
            <template v-for="h in headers" #[`header.${h.value}`]="{ header }">
              <span :key="h.value" class="datagrid-header" :class="h.value === 'actions' ? ['no-print'] : []">
                <span>{{ $t(header.text) }}</span>
                <v-menu ref="menuFilters" v-model="filters[h.value].visible" offset-y>
                  <template #activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <template v-if="h.filterable">
                        <v-icon
                          v-if="filters[h.value].filtered"
                          ref="showFilters"
                          class="mx-2 datagrid-header__filter-icon datagrid-header__filter-icon--active"
                          small
                          color="primary"
                        >
                          mdi-filter-check
                        </v-icon>
                        <v-icon v-else ref="showFilters" class="mx-2 datagrid-header__filter-icon" small>
                          mdi-filter
                        </v-icon>
                      </template>
                    </span>
                  </template>

                  <div>
                    <data-grid-column-filter
                      v-if="filters[h.value].visible"
                      :property-name="h.value"
                      :selected-values="filters[h.value].values"
                      :items="items"
                      @cancel="filters[h.value].visible = false"
                      @update-filters="(e) => updateFilters(h.value, e)"
                    >
                    </data-grid-column-filter>
                  </div>
                </v-menu>
              </span>
            </template>

            <template v-for="h in headersWithItemSlot" #[`item.${h.value}`]="props">
              <slot v-bind="props" :name="`item.${h.value}`"></slot>
            </template>

            <template #expanded-item="{ item }">
              <slot :item="item" name="expanded-item"></slot>
            </template>

            <template #item.data-table-select="{ item, isSelected, select }">
              <v-simple-checkbox v-if="!item.disabled" :value="isSelected" @input="select($event)"></v-simple-checkbox>
            </template>

            <template #footer>
              <div class="footer-separator no-print"></div>

              <div class="d-flex justify-end align-center flex-wrap mr-2 no-print">
                <div class="d-flex align-center mr-7">
                  <div class="text-caption">{{ $t('resultByPage') }}</div>

                  <div class="max-width-65 mx-7">
                    <v-select
                      v-model="selectedItemsPerPage"
                      :disabled="isDataGridLoading"
                      item-text="name"
                      item-value="value"
                      style="font-size: 12px"
                      :items="itemsPerPageOptions"
                      @change="changeItemsPerPage"
                    />
                  </div>

                  <div class="text-body-2">{{ getPaginationText() }}</div>
                </div>

                <div class="d-flex">
                  <div class="d-flex mr-4">
                    <v-btn color="primary" icon :disabled="disablePaginationPageButton(1)" @click="changePageTo(1)">
                      <v-icon>mdi-page-first</v-icon>
                    </v-btn>
                    <v-btn icon :disabled="disablePaginationPageButton(1)" @click="changePageTo(selectedPage - 1)">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                  </div>

                  <div class="d-flex">
                    <v-btn
                      icon
                      :disabled="disablePaginationPageButton(paginationTotalPages())"
                      @click="changePageTo(selectedPage + 1)"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>

                    <v-btn
                      icon
                      :disabled="disablePaginationPageButton(paginationTotalPages())"
                      @click="changePageTo(paginationTotalPages())"
                    >
                      <v-icon>mdi-page-last</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-sheet>

        <v-sheet elevation="2" :class="headers.length > maxColumns ? 'd-none d-print-block' : 'd-none'">
          <v-data-table
            v-for="(headerChunk, index) in headerChunks"
            :ref="`dataTable_${index}`"
            :key="index"
            v-model="selected"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :headers="headerChunk"
            :items="filteredItems"
            :search="search"
            mobile-breakpoint="0"
            :show-select="showSelect"
            :single-select="singleSelect"
            :item-key="itemKey"
            :items-per-page="-1"
            :height="!!tableHeight ? `${tableHeight}` : undefined"
            :fixed-header="!!tableHeight"
            :show-expand="showExpand"
            @click:row="rowClick"
            @item-expanded="itemExpanded"
          >
            <template v-for="h in headerChunk" #[`header.${h.value}`]="{ header }">
              <span :key="h.value" class="datagrid-header">
                <span>{{ $t(header.text) }}</span>
              </span>
            </template>

            <template v-for="h in headersWithItemSlot" #[`item.${h.value}`]="props">
              <slot v-bind="props" :name="`item.${h.value}`"></slot>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import translation, { LanguageVue } from '@/translationMixin';
import DataGridColumnFilterVue from './DataGridColumnFilter.vue';
import userService from '@/services/userService';
import virtuoseMixin from '@/virtuoseMixin';
import { containsString } from '@/utils/stringUtils';

export default {
  components: {
    draggable,
    DataGridColumnFilter: DataGridColumnFilterVue,
  },
  mixins: [translation, virtuoseMixin],
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: undefined,
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 50,
    },
    gridName: {
      type: String,
      required: false,
      default: null,
    },
    tableHeight: {
      type: String,
      required: false,
      default: undefined,
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    dataLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      sortBy: '',
      defaultSortBy: '',
      sortDesc: false,
      headers: [],
      allColumns: [],
      filters: {},
      filteredItems: [],
      selected: [],
      configurations: null,
      maxColumns: 12,
      allFilteredData: [],
      itemsPerPageOptions: [
        {
          name: 5,
          value: 5,
        },
        {
          name: 10,
          value: 10,
        },
        {
          name: 25,
          value: 25,
        },
        {
          name: 50,
          value: 50,
        },
        {
          name: this.$t('all'),
          value: -1,
        },
      ],
      vDataTableLoading: false,
      defaultVisibility: [],

      paginationStartIndex: 0,
      paginationEndIndex: 5,

      selectedPage: 1,
      selectedItemsPerPage: this.itemsPerPage,
      previousItemPerPageValue: this.itemsPerPage,
    };
  },

  computed: {
    isDataGridLoading() {
      return this.dataLoading || this.vDataTableLoading;
    },

    headersWithItemSlot() {
      return this.headers.filter((h) => this.hasSlot(`item.${h.value}`));
    },

    headerChunks() {
      const chunkSize = this.maxColumns;
      const chunks = [];
      const headers = this.headers.filter((h) => h.value !== 'newMessageCount' && h.value !== 'actions');
      const lastNameHeader = headers.find((h) => h.value === 'lastName');
      const firstNameHeader = headers.find((h) => h.value === 'firstName');

      for (let i = 0; i < headers.length; i += chunkSize) {
        let chunk = headers.slice(i, i + chunkSize);

        if (
          !chunk.some((h) => h.value === 'firstName' || h.value === 'lastName') &&
          lastNameHeader &&
          firstNameHeader
        ) {
          chunk = [lastNameHeader, firstNameHeader, ...chunk.slice(0, chunkSize - 2)];
        }

        chunks.push(chunk);
      }

      return chunks;
    },
  },
  watch: {
    columns: {
      handler: function () {
        this.configurations = this.configurations ?? {};
        this.configurations.visibility = this.defaultVisibility?.filter((column) =>
          this.columns.some((c) => c.value === column.key)
        );

        this.init();
      },
    },

    items: {
      handler: function () {
        this.updateFilteredItems();
        this.updateHeaders();
      },
    },

    filters: {
      handler: function () {
        this.updateFilteredItems();
      },
    },

    sortBy() {
      this.configurationChanged();
    },

    sortDesc() {
      this.configurationChanged();
    },

    selectedItems: {
      handler(newValue) {
        this.selected = newValue;
      },
    },

    selected(newValue) {
      this.$emit('update:selectedItems', newValue);

      // If there's disabled items, we must exclude them from selection

      if (newValue) {
        let newSelection = newValue.filter((item) => !item.disabled);
        if (newSelection.length !== newValue.length) {
          this.selected = newSelection;
        }
      }
    },

    configurations(newValue) {
      this.emitConfigurationChanged(newValue ?? this.getCurrentConfiguration());
    },
  },

  created: function () {
    this.vDataTableLoading = true;
    this.init();

    window.addEventListener('beforeunload', this.updateGridConfig); // Listener to save the config when the user closes the page
    LanguageVue.$on('projectLanguage', this.userLanguageUpdated);
  },

  destroyed: function () {
    window.removeEventListener('beforeunload', this.updateGridConfig);
  },

  beforeDestroy: function () {
    this.updateGridConfig();
    LanguageVue.$off('projectLanguage', this.userLanguageUpdated);
  },

  methods: {
    userLanguageUpdated: function () {
      this.itemsPerPageOptions.at(-1).name = this.$t('iotRealtime.all');
    },

    init: async function () {
      this.initColumns();
      this.updateFilteredItems();
      this.emitConfigurationChanged(this.getCurrentConfiguration());

      if (this.vDataTableLoading && this.items) {
        await this.getGridConfig();
      }
    },

    hasSlot(slotName) {
      return !!this.$scopedSlots[slotName];
    },

    updateHeaders() {
      this.headers = this.allColumns
        .filter((c) => c.visible)
        .map((c) => {
          return {
            text: c.text,
            value: c.value,
            // divider: true,
            width: c.width,
            filterable: c.filterable,
            sortable: c.sortable,
            align: c.align,
          };
        });

      let filters = {};

      this.headers.forEach((h) => {
        filters[h.value] = this.filters[h.value] || {
          visible: false,
          filtered: false,
          values: [],
        };
      });

      this.filters = filters;
    },

    updateFilteredItems() {
      if (this.vDataTableLoading) return;

      let filtered = this.items ?? [];

      if (this.search) {
        filtered = filtered.filter((obj) =>
          Object.entries(obj).some(
            ([key, value]) =>
              containsString(value, this.search) && this.allColumns.some((x) => x.value === key && !!x.visible)
          )
        );
      }

      for (let name in this.filters) {
        if (Object.prototype.hasOwnProperty.call(this.filters, name) && this.filters[name].filtered) {
          filtered = filtered.filter((x) => this.filters[name].values.includes(x[name]));
        }
      }

      this.allFilteredData = filtered;

      if (this.selectedItemsPerPage === -1) {
        this.filteredItems = this.allFilteredData;
        return;
      }

      this.setPaginationStartAndEndIndexes();
      this.filteredItems = filtered.slice(this.paginationStartIndex, this.paginationEndIndex);

      if (this.allFilteredData.length !== 0 && this.filteredItems.length === 0 && !this.vDataTableLoading) {
        const lastPage = this.paginationTotalPages();
        this.changePageTo(lastPage > 0 ? lastPage : 1);
      }
    },

    dragColumnEnd() {
      this.updateHeaders();
      this.configurationChanged();
    },

    toggleColumnVisibility(column) {
      column.visible = !column.visible;
      this.updateHeaders();
      this.updateFilteredItems();
      this.configurationChanged();
    },

    updateFilters(name, values) {
      this.filters[name].values = values || [];
      this.filters[name].filtered = !!values;
      this.updateFilteredItems();
      this.configurationChanged();
    },

    clearFilters() {
      this.allColumns.forEach((c) => {
        if (this.filters[c.value]) {
          this.filters[c.value].filtered = false;
          this.filters[c.value].values = [];
        }
      });

      this.updateFilteredItems();
      this.configurationChanged();
    },

    resetColumns() {
      this.allColumns = (this.columns ?? []).map((column) => {
        return {
          text: column.text,
          value: column.value,
          visible: column.visible === undefined || !!column.visible,
          filterable: column.filterable === undefined || !!column.filterable,
          sortable: column.sortable === undefined || !!column.sortable,
          filtered: false,
          align: column.align,
          width: column.width,
        };
      });

      this.updateHeaders();
      this.configurationChanged();
    },

    initColumns() {
      this.allColumns = (this.columns ?? []).map((column) => {
        const savedVisibleColumn = this.configurations?.visibility?.find((x) => x.key === column.value)?.visible;

        return {
          text: column.text,
          value: column.value,
          visible:
            !this.configurations?.visibility?.length > 0 || savedVisibleColumn === undefined
              ? column.visible === undefined || !!column.visible
              : savedVisibleColumn,
          filterable: column.filterable === undefined || !!column.filterable,
          sortable: column.sortable === undefined || !!column.sortable,
          filtered: false,
          align: column.align,
          width: column.width,
        };
      });

      if (this.configurations?.visibility?.length > 0) {
        const orderedColumns = [];
        const existingColumns = this.configurations?.visibility?.map((column) => column.key);

        for (const column of this.configurations?.visibility ? this.configurations.visibility : []) {
          const matchingColumn = this.allColumns.find((c) => c.value === column.key);

          if (matchingColumn) {
            matchingColumn.visible = column.visible;
            orderedColumns.push(matchingColumn);
          }
        }

        const newColumns = this.allColumns.filter((column) => !existingColumns?.includes(column.value));

        if (newColumns.length > 0) {
          const insertIndex = orderedColumns.length - 1;
          orderedColumns.splice(insertIndex, 0, ...newColumns);
        }

        this.allColumns = orderedColumns;
      }

      this.updateHeaders();
    },

    getCurrentConfiguration() {
      let visibility = this.allColumns.map((x) => {
        return {
          key: x.value,
          visible: x.visible,
        };
      });

      const visibilityIsSameAsOrignal =
        visibility.length === this.columns.length &&
        visibility.every(
          (item, idx) => this.columns[idx].value === item.key && !!(this.columns[idx].visible ?? true) === item.visible
        );

      if (visibilityIsSameAsOrignal) {
        // If the order / visibility status is the same as the default one, we don't save it in the config
        visibility = [];
      }

      return {
        pagination: this.configurations?.pagination ?? null,
        sortBy: this.sortBy,
        sortAscending: !this.sortDesc,
        visibility: visibility,
        filters: this.allColumns
          .filter((x) => this.filters[x.value] && this.filters[x.value].filtered)
          .map((x) => {
            return {
              key: x.value,
              values: this.filters[x.value].values,
            };
          }),
      };
    },

    configurationChanged() {
      this.configurations = this.getCurrentConfiguration();
    },

    setCurrentConfiguration: function () {
      if (this.configurations) {
        this.selectedItemsPerPage = this.configurations?.pagination?.itemsPerPage ?? this.itemsPerPage;
        this.selectedPage = this.configurations?.pagination?.page ?? 1;

        this.defaultVisibility = this.configurations?.visibility;

        this.configurations.visibility = this.defaultVisibility?.filter((column) =>
          this.columns.some((c) => c.value === column.key)
        );

        this.sortDesc = this.configurations.sortAscending === false;

        this.sortBy = this.configurations.sortBy;
        this.defaultSortBy = this.sortBy;

        this.filters = this.configurations?.filters ?? {};

        const orderedColumns = [];
        const existingColumns = this.configurations?.visibility?.map((column) => column.key);

        for (const column of this.configurations?.visibility ? this.configurations.visibility : []) {
          const matchingColumn = this.allColumns.find((c) => c.value === column.key);

          if (matchingColumn) {
            matchingColumn.visible = column.visible;
            orderedColumns.push(matchingColumn);
          }
        }

        const newColumns = this.allColumns.filter((column) => !existingColumns?.includes(column.value));

        if (newColumns.length > 0) {
          // Will insert new activity column before communication column in PatientMonitoringGrid
          const insertIndex = orderedColumns.length - 1;
          orderedColumns.splice(insertIndex, 0, ...newColumns);
        }

        this.allColumns = orderedColumns;
      }
    },

    onSortChange(updatedSortBy) {
      if (updatedSortBy !== this.defaultSortBy) {
        this.defaultSortBy = null;

        this.configurations = this.configurations ?? {};
        this.configurations.sortBy = updatedSortBy;

        this.setPaginationStartAndEndIndexes();
        this.filteredItems = this.allFilteredData.slice(this.paginationStartIndex, this.paginationEndIndex);
      }
    },

    customSort(_, sortBy, sortDesc) {
      if ((this.sortBy && sortBy) || (this.sortDesc && sortDesc)) {
        this.allFilteredData = this.allFilteredData.slice().sort((a, b) => {
          const sortA = a[sortBy];
          const sortB = b[sortBy];

          if (typeof sortA === 'string' && typeof sortB === 'string') {
            return this.sortDesc ? sortB.localeCompare(sortA) : sortA.localeCompare(sortB);
          }

          if (typeof sortA === 'boolean' && typeof sortB === 'boolean') {
            return this.sortDesc ? sortB - sortA : sortA - sortB;
          }

          if (typeof sortA === 'number' && typeof sortB === 'number') {
            return this.sortDesc ? sortB - sortA : sortA - sortB;
          }

          return 0;
        });
      } else {
        this.allFilteredData = this.allFilteredData.slice().sort((a, b) => {
          const primarySortA = a?.['active'];
          const primarySortB = b?.['active'];

          const secondarySortA = new Date(a[a.createdAt ? 'createdAt' : 'created_at']);
          const secondarySortB = new Date(b[b.createdAt ? 'createdAt' : 'created_at']);

          if ((primarySortA && primarySortB) || (secondarySortA && secondarySortB)) {
            return primarySortA === primarySortB ? secondarySortB - secondarySortA : primarySortA ? -1 : 1;
          }

          return 0;
        });
      }

      this.setPaginationStartAndEndIndexes();
      return this.allFilteredData.slice(this.paginationStartIndex, this.paginationEndIndex);
    },

    filterFunc() {
      this.updateFilteredItems();

      if (this.filteredItems.length === 0) {
        this.changePageTo(1);
      }
    },

    getGridConfig: async function () {
      const userGridConfigData = await userService.getUserGridConfiguration(this.gridName);
      this.configurations = userGridConfigData.configuration;

      this.setCurrentConfiguration();
      this.updateHeaders();
      this.vDataTableLoading = false;
    },

    paginationTotalPages() {
      return !this.allFilteredData || Math.ceil(this.allFilteredData.length / this.selectedItemsPerPage);
    },

    updateGridConfig: async function () {
      if (!this.gridName || !this.configurations) return;

      this.configurations = this.configurations ?? {};
      this.configurations.filters = this.filters;

      if (this.gridName === 'patient_monitoring_grid') {
        this.$emit('updatePatientMonitoringGridConfig', this.configurations);
      } else {
        try {
          await userService.updateUserGridConfiguration(this.gridName, this.configurations);
        } catch (error) {
          console.log(`Error while saving using configuration in ${this.gridName}: ${error}`);
        }
      }
    },

    changeItemsPerPage: function (itemsPerPageValue) {
      this.selectedPage = 1;

      this.setPaginationStartAndEndIndexes();

      this.filteredItems = this.allFilteredData.slice(this.paginationStartIndex, this.paginationEndIndex);

      this.configurations = this.configurations ?? {};
      this.configurations.pagination = {
        itemsPerPage: this.selectedItemsPerPage,
        page: this.selectedPage,
      };

      if (itemsPerPageValue < this.previousItemPerPageValue) {
        if (itemsPerPageValue <= 10 && this.gridName !== 'notes_grid') {
          window.scrollTo(0, 0); // Without this line, when we go from selecting 25 or 50 items to 5 or 10 there's a glitch
        } else {
          this.$refs?.gridContent?.scrollIntoView({
            behavior: 'instant',
            block: 'end',
          });
        }
      }

      this.previousItemPerPageValue =
        this.selectedItemsPerPage === -1 ? this.allFilteredData.length : this.selectedItemsPerPage;
    },

    changePageTo: function (page) {
      this.selectedPage = page;

      this.setPaginationStartAndEndIndexes();
      this.filteredItems = this.allFilteredData.slice(this.paginationStartIndex, this.paginationEndIndex);

      this.configurations = this.configurations ?? {};
      this.configurations.pagination = {
        itemsPerPage: this.selectedItemsPerPage,
        page: this.selectedPage,
      };
    },

    isDefaultConfig() {
      return !this.configurations || !this.configurations?.visibility || this.configurations?.visibility?.length === 0;
    },

    disablePaginationPageButton: function (limitPage) {
      return (
        !this.filteredItems ||
        this.filteredItems.length === 0 ||
        !this.items ||
        this.items.length === 0 ||
        this.selectedPage === limitPage ||
        this.selectedItemsPerPage === -1
      );
    },

    setPaginationStartAndEndIndexes: function () {
      const showedItemPerPage =
        this.selectedItemsPerPage === -1 ? this.allFilteredData?.length : this.selectedItemsPerPage;

      this.paginationStartIndex = (this.selectedPage - 1) * showedItemPerPage;
      this.paginationEndIndex = Math.min(this.paginationStartIndex + showedItemPerPage, this.allFilteredData?.length);
    },

    getPaginationText: function () {
      if (!this.allFilteredData || this.allFilteredData?.length === 0) return '-';

      this.setPaginationStartAndEndIndexes();

      return this.$t('paginationPagesNumber')
        .replace('{{startIndex}}', this.paginationStartIndex + 1)
        .replace('{{endIndex}}', this.paginationEndIndex)
        .replace('{{totalData}}', this.allFilteredData.length);
    },

    rowClick(item) {
      this.$emit('row:click', item);
    },

    itemExpanded(data) {
      this.$emit('item-expanded', data);
    },

    emitConfigurationChanged(configuration) {
      const newConfig = { ...configuration };
      // if there's not visibility, it means it's the default values, so we're goin to pass them here

      if (newConfig?.visibility?.length === 0) {
        newConfig.visibility = this.allColumns.map((x) => {
          return {
            key: x.value,
            visible: x.visible,
          };
        });
      }

      this.$emit('configuration-changed', newConfig);
    },
  },
};
</script>

<style lang="scss" scoped>
.datagrid-header__filter-icon {
  opacity: 0.3;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
}

.datagrid-header__filter-icon--active {
  opacity: 1;
}

th:hover .datagrid-header__filter-icon {
  opacity: 1;
}

.handle {
  cursor: move;
}

.field-list-container {
  max-height: min(500px, 100vh - 100px);
  overflow-y: auto;
}

.max-width-65 {
  max-width: 65px;
}

.footer-separator {
  height: 1px;
  background-color: #e0e0e0;
}

@media print {
  .no-print {
    display: none !important;
  }
}
</style>

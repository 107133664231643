<template>
  <div>
    <detail-field
      v-if="isIotVirtualAssistantNotConfigured || iotDeviceTypeCode === 'XP'"
      :id="getIdByName('patientAssociationKey')"
      ref="patientAssociationKey"
      v-model="associationKeyOrCodeValue"
      :label="$t(getAssociationLabelOnIotType(iotDeviceTypeCode))"
    />

    <detail-field
      v-if="isIotVirtualAssistantNotConfigured"
      :id="getIdByName('associationTenant')"
      ref="patientAssociationTenant"
      v-model="associationTenantValue"
      :label="$t('patientDevice.associationTenantName ')"
    />

    <qrcode v-if="iotDeviceTypeCode === 'BA'" ref="patientAssociationQrCode" :value-input="associationKey" />

    <div v-if="isVaConfigurationCompleted" ref="iotIsConfigured">
      <p>{{ $t('patientDevice.VaAlreadyConfigured') }}</p>
    </div>
    <div v-else ref="iotIsNotConfigured">
      <p>{{ getIotNotConfiguredLabel(iotDeviceTypeCode) }}</p>
    </div>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import Qrcode from '../Qrcode.vue';
import { IOTDeviceTypeCodes } from '../PatientMonitoring/constants';

export default {
  name: 'IotPatientConfigurationInfo',
  components: { Qrcode },
  mixins: [translation, accessibility],

  props: {
    iotDeviceTypeCode: {
      type: String,
      required: true,
    },
    associationKey: {
      type: String,
      required: false,
      default: '',
    },
    associationTenant: {
      type: String,
      required: false,
      default: '',
    },
    associationCode: {
      type: String,
      required: false,
      default: '',
    },
    isVaConfigurationCompleted: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      associationKeyOrCodeValue:
        this.iotDeviceTypeCode === IOTDeviceTypeCodes.VIRTUALASSISTANT ? this.associationCode : this.associationKey,
      associationTenantValue: this.associationTenant,
    };
  },

  computed: {
    isIotVirtualAssistantNotConfigured() {
      return this.iotDeviceTypeCode === IOTDeviceTypeCodes.VIRTUALASSISTANT && !this.isVaConfigurationCompleted;
    },
  },

  methods: {
    getIotNotConfiguredLabel(iotTypeCode) {
      switch (iotTypeCode) {
        case IOTDeviceTypeCodes.BALANCE:
          return this.$t('patientDevice.scanQrCodeToAssociateBalance');
        case IOTDeviceTypeCodes.XPILL:
          return this.$t('patientDevice.enterAssociationKeyInxPill');
        case IOTDeviceTypeCodes.VIRTUALASSISTANT:
          if (this.isIotVirtualAssistantNotConfigured) {
            return this.$t('patientDevice.enterAssociationCodeInVA');
          }
          break;
      }

      return '';
    },

    getAssociationLabelOnIotType(iotTypeCode) {
      switch (iotTypeCode) {
        case IOTDeviceTypeCodes.XPILL:
          return this.$t('patientDevice.patientAssociationKey');
        case IOTDeviceTypeCodes.VIRTUALASSISTANT:
          return this.$t('patientDevice.patientAssociationCode');
      }

      return '';
    },
  },
};
</script>

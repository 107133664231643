<template>
  <div>
    <v-dialog v-if="show" v-model="show" scrollable max-width="500" eager>
      <div ref="dialogRef">
        <v-card class="eb-white-bg">
          <ModalTitle :title="$t('success')" @btn-clicked="closeDialog" />

          <v-card-text class="eb-white-bg">
            <span ref="successMessage">{{ successMessage }}</span>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import translation from '@/translationMixin';
export default {
  name: 'SuccessModal',
  mixins: [translation],
  props: {
    isShowSuccessModal: {
      type: Boolean,
      required: true,
    },
    successMessage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },

  watch: {
    isShowSuccessModal: function () {
      if (this.isShowSuccessModal) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },

  created: function () {},
  methods: {
    closeDialog: function () {
      this.$emit('close-success-modal', true);
    },
  },
};
</script>

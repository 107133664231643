import ProtocolHome from './ProtocolHome';

export default {
  moduleName: 'protocol',
  routes: [
    {
      path: '/protocol',
      name: 'ProtocolHome',
      component: ProtocolHome,
    },
  ],
};

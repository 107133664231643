import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Message from './components/Message.vue';
import Login from './components/Accounts/Login.vue';

import ActivityRoutes from '@/components/Activity/routes.js';
import CohortsRoutes from '@/components/Cohorts/routes.js';
import DialogueRoutes from '@/components/Dialogue/routes.js';
import HealthcareProfessionalRoutes from '@/components/HealthcareProfessional/routes.js';
import IotRoutes from '@/components/Iot/routes.js';
import NotificationRoutes from '@/components/Notification/routes.js';
import PatientIotRealTimeRoutes from '@/components/PatientIotRealtime/routes.js';
import ProtocolRoutes from '@/components/Protocol/routes.js';
import ResidentRoutes from '@/components/Patient/routes.js';
import UserRoutes from '@/components/User/routes.js';
import VocalMessagesRoutes from '@/components/VocalMessages/routes.js';
import VitalSignsRoutes from '@/components/VitalSigns/routes.js';
import PatientVideoCallRoutes from '@/components/PatientMonitoring/PatientMonitoringTabs/PatientMessageTab/routes.js';
import ResourcesRoutes from '@/components/Resources/routes.js';

import auth from '@/auth/auth';
import authConfig from '@/auth/authConfig';

Vue.use(Router);

var getRoutes = function () {
  var routes = [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/error/:message',
      name: 'ErrorMessage',
      component: Message,
    },
    {
      path: '/login/:step?',
      props: true,
      name: 'Login',
      component: Login,
    },
  ];

  routes.push(...ActivityRoutes.routes);
  routes.push(...CohortsRoutes.routes);
  routes.push(...DialogueRoutes.routes);
  routes.push(...HealthcareProfessionalRoutes.routes);
  routes.push(...IotRoutes.routes);
  routes.push(...NotificationRoutes.routes);
  routes.push(...PatientIotRealTimeRoutes.routes);
  routes.push(...ProtocolRoutes.routes);
  routes.push(...ResidentRoutes.routes);
  routes.push(...UserRoutes.routes);
  routes.push(...VocalMessagesRoutes.routes);
  routes.push(...VitalSignsRoutes.routes);
  routes.push(...PatientVideoCallRoutes.routes);
  routes.push(...ResourcesRoutes.routes);

  return routes;
};

let router = new Router({
  mode: 'hash',
  base: window.location.pathname,
  routes: getRoutes(),
});

let authInitDone = false;

router.beforeEach(async (to, from, next) => {
  if (!authInitDone && from === Router.START_LOCATION) {
    await authConfig.configure();

    if (!auth.isAuthenticated()) {
      await auth.silentSignIn();
    }
    authInitDone = true;
  }

  if (to.name !== 'Login' && !auth.isAuthenticated()) {
    next({ name: 'Login', params: { redirect: { name: to.name, params: to.params } } });
  } else if (from === Router.START_LOCATION && to.name === 'Login' && auth.isAuthenticated()) {
    next({ name: 'Home' });
  } else {
    next();
  }
});
export default router;

<template>
  <v-container>
    <ConfirmationModal
      :question="$t('deleteVocalMessageQuestion')"
      :title="$t('delete')"
      :show="showDelete"
      @cancel="showDelete = false"
      @ok="deleteSelectedVocalMessage"
    />

    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row>
      <v-col>
        <div class="page-title">
          {{ $t('vocalMessages') }}
        </div>
      </v-col>
    </v-row>

    <v-row v-if="$can(permissions.actions.add, permissions.subjects.vocalMessage)">
      <v-col>
        <v-btn ref="addVocalMessage" color="primary" @click="editVocalMessageAction(null)">
          {{ $t('add') }}
        </v-btn>
      </v-col>
    </v-row>

    <WaitModal :show="showWaitModal" />

    <v-row>
      <v-col>
        <DataGrid
          ref="dataGrid"
          grid-name="vocalMessages_grid"
          :items="vocalMessages"
          :columns="columns"
          :show-select="false"
          :items-per-page="25"
          :data-loading="showWaitModal"
        >
          <!-- <template #[`item.active`]="{ item }">
            <v-simple-checkbox v-model="item.active" disabled />
          </template> -->

          <template #[`item.normative`]="{ item }">
            <v-simple-checkbox v-model="item.normative" disabled />
          </template>

          <template #[`item.actions`]="{ item }">
            <v-menu v-if="!item.normative || $can(permissions.actions.manage, permissions.subjects.normative)">
              <template #activator="{ on }">
                <v-btn :ref="`actions_${item.id}`" icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="$can(permissions.actions.change, permissions.subjects.vocalMessage)"
                  :ref="`editVocalMessage_${item.id}`"
                  text
                  @click="editVocalMessageAction(item.id)"
                >
                  <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                </v-list-item>
                <v-divider
                  v-if="
                    $can(permissions.actions.change, permissions.subjects.vocalMessage) &&
                    $can(permissions.actions.delete, permissions.subjects.vocalMessage)
                  "
                />
                <v-list-item
                  v-if="$can(permissions.actions.delete, permissions.subjects.vocalMessage)"
                  :ref="`deleteVocalMessage_${item.id}`"
                  text
                  @click="deleteAction(item.id)"
                >
                  <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </DataGrid>
      </v-col>
    </v-row>

    <VocalMessageModal
      :vocal-message-id="selectedVocalMessageId"
      :show.sync="showEditModal"
      @refresh="getVocalMessagesAction()"
    />
  </v-container>
</template>

<script>
import translationMixin from '@/translationMixin';
import DataGrid from '@/components/DataGrid.vue';
import vocalMessageService from '@/services/vocalMessageService';
import VocalMessageModal from './VocalMessageModal';
import permissionMixin from '@/auth/permissionMixin';

export default {
  name: 'VocalMessageGrid',

  components: {
    DataGrid,
    VocalMessageModal,
  },

  mixins: [translationMixin, permissionMixin],

  data() {
    return {
      showDelete: false,
      showEditModal: false,
      showWaitModal: false,
      error: null,
      selectedVocalMessageId: null,
      vocalMessages: [],
    };
  },

  computed: {
    columns: function () {
      let columns = [
        {
          text: 'name',
          value: 'name',
          filterable: true,
        },
        {
          text: 'description',
          value: 'description',
          filterable: true,
        },
        {
          text: 'iotDeviceType',
          value: 'iotDeviceTypeName',
          filterable: true,
        },
        {
          text: 'message',
          value: 'messageContent',
          filterable: true,
        },
        // {
        //   text: 'active',
        //   value: 'active',
        //   filterable: true,
        // },
        {
          text: 'normative',
          value: 'normative',
          filterable: true,
        },
        {
          text: 'createdDate',
          value: 'createdDate',
          filterable: true,
        },
        {
          text: 'createdBy',
          value: 'createdBy',
          filterable: true,
        },
        {
          text: 'modifiedDate',
          value: 'modifiedDate',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedBy',
          value: 'modifiedBy',
          filterable: true,
          visible: false,
        },
      ];

      if (
        this.$can(this.permissions.actions.change, this.permissions.subjects.vocalMessage) ||
        this.$can(this.permissions.actions.delete, this.permissions.subjects.vocalMessage)
      ) {
        columns.push({
          text: 'actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        });
      }

      return columns;
    },
  },

  created: function () {
    this.getVocalMessagesAction();
  },

  methods: {
    async getVocalMessagesAction() {
      this.vocalMessages = [];
      this.showDelete = false;
      this.showEditModal = false;
      this.showWaitModal = true;

      try {
        let vocalMessagesData = await vocalMessageService.getVocalMessages();

        this.vocalMessages = vocalMessagesData.map((item) => {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
            // active: item.active,
            normative: item.normative,
            iotDeviceTypeName: item.iotDeviceType.name,
            messageContent: this.getValueOnUserLanguage(item),
            createdDate: item.createdAt,
            createdBy: item.createdBy.username,
            modifiedDate: item.updatedAt,
            modifiedBy: item.updatedBy !== null ? item.updatedBy.username : '',
          };
        });
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    editVocalMessageAction: function (id) {
      this.selectedVocalMessageId = id;
      this.showEditModal = true;
    },

    deleteAction: function (id) {
      this.selectedVocalMessageId = id;
      this.showDelete = true;
    },

    getValueOnUserLanguage: function (vocalMessage) {
      if (!vocalMessage.parameters || !vocalMessage.parameters.messages) return;

      for (const message of vocalMessage.parameters.messages) {
        if (message.language === this.getLanguage()) return message.value;
      }
    },

    async deleteSelectedVocalMessage() {
      this.showDelete = false;
      this.showWaitModal = true;

      try {
        let vocalMessageId = this.selectedVocalMessageId;
        await vocalMessageService.deleteVocalMessage(vocalMessageId);
        this.getVocalMessagesAction();
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },
  },
};
</script>

<style scoped></style>

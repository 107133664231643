<template>
  <v-col v-if="selectedChat !== null" class="selected-chat">
    <ConfirmationModal
      :question="$t('confirmEndChat')"
      :show="showEndChat"
      :title="$t('endChat')"
      @cancel="showEndChat = false"
      @ok="endChat(selectedChat)"
    />
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <v-form ref="chatForm" class="chat-container" @submit.prevent>
      <div>
        <div class="d-flex text-h6 align-center" :class="{ 'mb-2': !selectedChat.subject || editingSubject }">
          <span class="mr-4">{{ participantFullNames }}</span>
          <v-btn
            v-if="!selectedChat.completed && isHealthWorkerAssignedToPatient"
            ref="createVideoCallRef"
            :disabled="editingSubject || isProcessing"
            class="ma-2"
            small
            :right="true"
            :absolute="true"
            @click="createVideoCall()"
            ><v-icon class="pr-1">mdi-video</v-icon> {{ $t('patientVideoCall') }}</v-btn
          >
        </div>
        <v-text-field
          v-if="!selectedChat.completed && editingSubject"
          ref="subjectField"
          v-model="formData.conversationSubject"
          class="mr-2"
          filled
          dense
          :placeholder="$t('subject')"
          append-outer-icon="mdi-check"
          :rules="[validationRules.required, validationRules.maxLength(200)]"
          @click:append-outer="updateSubject"
        ></v-text-field>
        <div v-else class="py-1" style="word-break: break-all">
          <span>{{ selectedChat.subject }}</span>
          <v-btn
            v-if="!selectedChat.completed && isHealthWorkerAssignedToPatient"
            ref="editButton"
            icon
            small
            class="mx-2"
            :disabled="isProcessing"
            @click="editingSubject = !editingSubject"
            ><v-icon small>mdi-pencil</v-icon></v-btn
          >
        </div>
      </div>

      <div id="chatContent" ref="chatContent" class="content-scrollable">
        <div v-if="selectedChat.id" class="flex-grow-1 overflow-y-auto mr-4">
          <div v-for="(message, index) in formData.messages" :key="index">
            <div
              v-if="message.type === 'message'"
              :ref="`message_${message.id}`"
              :class="{ 'd-flex flex-row-reverse': isSenderHealthworker(message.from) }"
            >
              <v-card
                flat
                :color="isSenderHealthworker(message.from) ? '#E3F2FD' : '#EEEEEE'"
                dark
                style="height: auto; white-space: normal; max-width: 50%"
                class="pa-4 mb-4 black--text"
              >
                <div class="d-flex justify-space-between">
                  <p class="pr-4 font-weight-black">{{ senderFullName(message.from) }}</p>
                  <p class="text--secondary">{{ message.formattedDateTime }}</p>
                </div>
                <div class="wrap-newline">{{ message.content }}</div>
              </v-card>
            </div>
            <div v-if="message.type === 'videocallevent'" :ref="`message_${message.id}`" class="my-2">
              <v-icon class="px-1">mdi-video-outline</v-icon>
              <span class="text--secondary text-subtitle-2 px-1">{{ message.formattedDateTime }}</span>
              <span v-if="message.content == 'connected'" class="px-2">{{
                $t('joinedTheCall').replace('{name}', senderFullName(message.from))
              }}</span>
              <span v-else class="px-2">{{ $t('leftTheCall').replace('{name}', senderFullName(message.from)) }}</span>
            </div>

            <v-container v-if="showDivider(message, index)" :ref="'separator'" class="my-2">
              <v-row align="center">
                <v-divider class="mr-4" />{{ $t('lastReadMessage') }}<v-divider class="ml-4" />
              </v-row>
            </v-container>
            <div id="anchor"></div>
          </div>
        </div>
      </div>

      <div class="mr-4 send-message">
        <v-text-field
          v-if="!selectedChat.completed && isHealthWorkerAssignedToPatient && !editingSubject"
          ref="sendMessageContainer"
          v-model="newMessageContent"
          :append-icon="isMessageEmpty ? '' : 'mdi-send'"
          :label="$t('typeMessage')"
          type="text"
          filled
          clearable
          solo
          :rules="[validationRules.maxLength(1000)]"
          :disabled="isProcessing"
          @click:append="sendNewMessage()"
          @keyup.enter="isMessageEmpty ? '' : sendNewMessage()"
          @click:clear="clearMessage"
        ></v-text-field>
      </div>
      <v-card
        v-show="!selectedChat.completed && isHealthWorkerAssignedToPatient && 'id' in selectedChat && !editingSubject"
        flat
        align="center"
        justify="space-around"
      >
        <v-btn tile color="grey lighten-2" :disabled="isProcessing" @click="deleteAction()">
          <v-icon left> mdi-message-text-lock </v-icon>
          {{ $t('endChat') }}
        </v-btn>
      </v-card>
    </v-form>
  </v-col>
</template>

<script>
import translationMixin from '@/translationMixin';
import translation, { LanguageVue } from '@/translationMixin';

import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import messageService from '@/services/messageService';

import { format } from 'date-fns';
import { frCA, enCA } from 'date-fns/locale';
import { isDateToday } from '@/utils/dateUtils';
import { ConversationParticipantTypes } from '@/components/PatientMonitoring/constants';

export default {
  name: 'PatientMessageChatConversation',
  components: {},

  mixins: [translationMixin, accessibility, validationRulesMixin, translation],

  props: {
    selectedChat: {
      type: Object,
      default: null,
    },
    participantFullNames: {
      type: String,
      default: '',
    },
    patient: {
      type: null,
      required: true,
    },
    isHealthWorkerAssignedToPatient: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      userLanguage: LanguageVue.getLanguage(),

      editingSubject: false,
      showEndChat: false,
      isProcessing: false,

      formData: {
        conversationSubject: '',
        messages: [],
        lastExchangeReadId: null,
      },
      newMessageContent: '',
      error: null,
    };
  },

  computed: {
    isMessageEmpty() {
      if (this.newMessageContent && !this.editingSubject) {
        return this.newMessageContent.trim() === '';
      }
      return true;
    },
  },

  watch: {
    selectedChat(newselectedChat) {
      this.isProcessing = false;
      this.formData.conversationSubject = newselectedChat || null;
      if (newselectedChat) {
        if (!newselectedChat.subject) {
          this.editingSubject = true;
          this.formData.conversationSubject = this.selectedChat.subject;
        } else {
          this.editingSubject = false;
          this.formData.conversationSubject = this.selectedChat.subject;
          newselectedChat.hasUnreadMessages = false;
        }
      } else {
        this.editingSubject = false;
      }
    },

    async 'selectedChat.id'(newChatId, oldChatId) {
      if ((newChatId ?? null) !== null) {
        await this.loadChatMessages(newChatId);
        this.scrollToMessage();
      } else if (newChatId !== null && oldChatId === null) {
        await this.sendNewMessage();
      } else {
        this.init();
      }
    },
  },

  created: function () {
    LanguageVue.$on('projectLanguage', this.userLanguageUpdated);
  },

  beforeDestroy: function () {
    LanguageVue.$off('projectLanguage', this.userLanguageUpdated);
  },

  methods: {
    init: function () {
      this.isProcessing = false;
      this.formData = {
        conversationSubject: '',
        messages: [],
        lastExchangeReadId: null,
      };
      this.newMessageContent = '';
    },

    formatMessage(message) {
      let result = {
        id: `${message.type}_${message.id}`,
        type: message.type,
        conversationId: message.conversationId,
        from: message.from,
        receivedAt: message.receivedAt,
        formattedDateTime: this.getFormattedDateTime(message.receivedAt),
      };
      switch (message.type) {
        case 'message':
          result.content = message.content;
          break;
        case 'videocallevent':
          result.content = message.event;
          break;
      }
      return result;
    },

    async loadChatMessages(chatId) {
      try {
        let chatMessages = await messageService.getConversationMessages(chatId);

        let messagesAndVideocallevents = [...chatMessages.messages, ...chatMessages.videocallevents];

        let sortedMessagesAndEvents = messagesAndVideocallevents.sort(
          (a, b) => new Date(a.receivedAt) - new Date(b.receivedAt)
        );

        this.formData.messages = sortedMessagesAndEvents.map((message) => {
          return this.formatMessage(message);
        });

        let lastMessageReadId = null;
        if (chatMessages.lastMessageReadId) {
          lastMessageReadId = `message_${chatMessages.lastMessageReadId}`;
        }

        this.formData.lastExchangeReadId = this.getLastExchangeReadId(this.formData.messages, lastMessageReadId);
      } catch (error) {
        this.error = error;
      }
    },

    getLastExchangeReadId(exchanges, lastMessageReadId) {
      const exchangesIterator = exchanges[Symbol.iterator]();
      let currentExchangeData = null;

      let isLastMessageReadReached = false;
      while (!isLastMessageReadReached) {
        currentExchangeData = exchangesIterator.next();
        if (currentExchangeData.done || currentExchangeData.value.id === lastMessageReadId) {
          isLastMessageReadReached = true;
        }
      }

      let lastExchangeReadId = null;
      let lastExchangeReadIsFound = false;
      while (!lastExchangeReadIsFound) {
        lastExchangeReadId = currentExchangeData.value ? currentExchangeData.value.id : null;
        currentExchangeData = exchangesIterator.next();
        if (currentExchangeData.done || currentExchangeData.value.type === 'message') {
          lastExchangeReadIsFound = true;
        }
      }

      return lastExchangeReadId;
    },

    async endChat(chat) {
      this.showEndChat = false;
      this.isProcessing = true;

      let data = true;
      try {
        await messageService.endConversation(chat.id, data);
        this.$emit('endChatWithPatient');
      } catch (error) {
        this.error = error;
      }
    },

    deleteAction() {
      this.showEndChat = true;
    },

    createNewChat(chatId) {
      this.$emit('createNewChat', chatId);
    },

    isSenderHealthworker(sender) {
      return sender.type === ConversationParticipantTypes.HEALTHWORKER;
    },

    async sendNewMessage() {
      if (this.isProcessing) return;

      const content = this.newMessageContent.trim();
      if (content !== '') {
        const conversationId = this.selectedChat.id;

        if (!this.$refs.chatForm.validate()) {
          return;
        }

        this.isProcessing = true;

        try {
          if (!conversationId) {
            const participants = this.getFormattedParticipantsForRequest(this.selectedChat.participants);

            let chatData = {
              subject: this.formData.conversationSubject,
              patientId: this.patient.id,
              participants: participants,
            };
            const chatId = await messageService.createConversation(chatData);
            await messageService.sendMessage(chatId, { conversationId: chatId, content: content });
            this.createNewChat(chatId);
            this.clearMessage();
          } else {
            let messageData = {
              conversationId: conversationId,
              content: content,
            };
            const message = await messageService.sendMessage(conversationId, messageData);
            const data = this.formatMessage(message);
            this.formData.messages.push(data);
            this.updateLastMessage(data);
          }
        } catch (error) {
          this.error = error;
        }
      }

      this.isProcessing = false;
    },
    getFormattedParticipantsForRequest(selectedChatParticipants) {
      const includePatient = !!selectedChatParticipants.find(
        (participant) => participant.type === ConversationParticipantTypes.PATIENT
      );
      const caregiverIds = selectedChatParticipants
        .filter((participant) => participant.type === ConversationParticipantTypes.CAREGIVER)
        .map((participant) => participant.id);
      return {
        includePatient: includePatient,
        caregiverIds: caregiverIds,
      };
    },
    senderFullName(sender) {
      return sender ? sender.firstName + ' ' + sender.lastName : '';
    },

    scrollToMessage() {
      if (this.formData.messages.length == 0) {
        return;
      }
      const lastMessage = this.formData.messages[this.formData.messages.length - 1];
      const lastMessageElement = this.$refs[`message_${lastMessage.id}`][0];

      const lastReadSeparatorElement = this.$refs['separator'] ? this.$refs['separator'][0] : null;
      const nextMessageIndex = this.formData.messages.findIndex(
        (message, index) =>
          message.id === this.formData.lastExchangeReadId && index !== this.formData.messages.length - 1
      );
      const nextMessageElement =
        nextMessageIndex !== -1 ? this.$refs[`message_${this.formData.messages[nextMessageIndex + 1].id}`][0] : null;

      if (lastMessageElement && !lastReadSeparatorElement) {
        this.$vuetify.goTo(lastMessageElement, { container: this.$refs['chatContent'] });
      } else if (lastReadSeparatorElement && nextMessageElement) {
        this.$vuetify.goTo(lastReadSeparatorElement, { container: this.$refs['chatContent'] });
      }
    },

    async updateSubject() {
      if (this.$refs.chatForm.validate()) {
        let chatData = {
          conversationId: this.selectedChat.id,
          subject: this.formData.conversationSubject,
        };
        this.editingSubject = false;
        this.$emit('selectedChatSubject', chatData.subject);

        try {
          if (this.selectedChat.id !== undefined) {
            await messageService.updateChatSubject(chatData.conversationId, chatData);
          }
        } catch (error) {
          this.error = error;
        }
      }
    },

    async updateLastMessage(data) {
      if (this.$refs.chatForm.validate()) {
        this.$emit('newMessageSent');
        this.formData.lastExchangeReadId = `message_${data.id}`;
      }
      await this.$nextTick();
      this.scrollToMessage();
      this.clearMessage();
    },

    clearMessage() {
      this.newMessageContent = '';
    },

    userLanguageUpdated(newLanguage) {
      this.userLanguage = newLanguage;
      this.formData.messages.forEach((message) => {
        message.formattedDateTime = this.getFormattedDateTime(message.receivedAt);
      });
    },

    getFormattedDateTime(dateTime) {
      const date = new Date(dateTime);
      const today = new Date();
      const dayDifference = Math.floor((today - date) / (1000 * 60 * 60 * 24));
      const options = {
        hour: 'numeric',
        minute: 'numeric',
      };

      if (this.userLanguage === 'en') {
        options.locale = enCA;
      } else {
        options.locale = frCA;
      }
      const formattedTime = format(date, 'p', options);

      if (isDateToday(dateTime)) {
        return `${this.$t('today')} ${formattedTime}`;
      } else if (dayDifference >= 0 && dayDifference < 7) {
        const dayOfWeek = this.getDayOfWeekName(date, this.userLanguage);
        return `${dayOfWeek} ${formattedTime}`;
      } else if (date.getFullYear() === today.getFullYear()) {
        const formatString = this.userLanguage === 'en' ? 'MM-dd' : 'dd-MM';
        return `${format(date, formatString)} ${formattedTime}`;
      } else {
        return `${format(date, 'yyyy-MM-dd')} ${formattedTime}`;
      }
    },

    getDayOfWeekName(date, userLanguage) {
      const options = { weekday: 'long' };
      let dayOfWeekName = new Intl.DateTimeFormat(userLanguage === 'en' ? 'en-US' : 'fr-FR', options).format(date);
      return dayOfWeekName.charAt(0).toUpperCase() + dayOfWeekName.slice(1);
    },

    async createVideoCall() {
      if (this.isProcessing) return;
      let chatId = this.selectedChat?.id;
      if (!chatId) {
        const participants = this.getFormattedParticipantsForRequest(this.selectedChat.participants);
        let chatData = {
          subject: this.formData.conversationSubject,
          patientId: this.patient.id,
          participants: participants,
        };
        chatId = await messageService.createConversation(chatData);
      }
      this.emitCreateVideoCall(chatId);
    },
    emitCreateVideoCall(chatId) {
      this.$emit('createVideoCall', { conversationId: chatId });
      this.isProcessing = true;
    },
    showDivider(message, index) {
      return this.formData.lastExchangeReadId === message.id && index !== this.formData.messages.length - 1;
    },
  },
};
</script>

<style scoped>
.selected-chat {
  flex-grow: 1;
  max-height: 100%;
}
.chat-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-x: hidden;
  height: 100%;
}

.content-scrollable {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
}
</style>

<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-data-table
      :items="items"
      :headers="headers"
      :footer-props="footerProps"
      :items-per-page="25"
      show-expand
      item-key="date"
      class="elevation-1 mt-4"
      mobile-breakpoint="0"
    >
      <template #[`item.statusLevel`]="{ item }">
        <div>
          <v-icon :color="getIconColor(item.statusLevel)">
            {{ getIconName(item.statusLevel) }}
          </v-icon>
        </div>
      </template>

      <template #expanded-item="{ item }">
        <td :colspan="headers.length + 1">
          <div
            v-for="(prescription, prescriptionIndex) in item.results"
            :key="prescriptionIndex"
            class="my-7 text-body-1 black--text mx-2 ml-md-4 mr-md-4"
            :class="{ 'text-body-2': $vuetify.breakpoint.sm }"
          >
            <div class="d-flex align-center">
              <v-icon :color="getPrescriptionAlertColor(prescription.statusLevelCode)" class="me-4">
                {{ getPrescriptionAlertIcon(prescription.statusLevelCode, prescription.allTaken) }}
              </v-icon>
              <div :class="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'd-block' : 'd-flex align-center'">
                <div class="d-flex">
                  <span
                    v-if="prescription.isPRN"
                    class="ml-1 text-body-2 font-weight-bold text--secondary grey lighten-2 rounded pa-1"
                  >
                    {{ $t('PRN') }}
                  </span>

                  <span v-else class="font-weight-black ml-5 ml-md-6">{{ prescription.time }}</span>
                  <span v-if="prescription.allTaken" class="ml-md-4 ml-5 text--secondary">{{
                    $t('takenAt') + ' ' + getEffectiveAtDate(prescription)
                  }}</span>
                </div>
              </div>
            </div>

            <div v-for="(child, index) in prescription.details" :key="index">
              <v-alert
                dense
                :class="[
                  'v-alert v-sheet theme--light mb-1 mt-0  d-table',
                  isAlertTriggered(child) ? 'v-alert--dense v-alert--text red--text ml-11' : 'pa-0  ml-15 ',
                ]"
              >
                <div class="d-flex align-center text-body-2">
                  <v-icon :color="getPrescriptionChildColor(child.alert_triggered)" class="me-4">
                    {{ getPrescriptionChildIcon(child.taken, child.alert_triggered) }}
                  </v-icon>
                  <div class="text--secondary">{{ child.drug_name }}</div>
                  <div class="px-1 text--secondary text-right">{{ child.strength }}</div>
                  <div class="px-1">
                    <v-tooltip right class="p-6">
                      <template #activator="{ on, attrs }">
                        <v-icon ref="adherenceDetails" small icon v-bind="attrs" v-on="on">
                          mdi-information-outline
                        </v-icon>
                      </template>

                      <v-row align="center">
                        <v-col class="py-4">
                          {{
                            $t('takenMedication') +
                            ': ' +
                            (child.taken !== null ? $t(child.taken) : $t('noInformation'))
                          }}
                          <span v-if="child.taken">{{
                            ` - ${$t('takenAt')} ${getPrescriptionDetailTime(child)}`
                          }}</span>
                          <div v-if="child.status_reason">
                            {{ $t('reason') + ': ' + child.status_reason }}
                          </div>
                          <div>{{ $t('dosage') + ': ' + child.dosage }}</div>
                          <div>{{ $t('form') + ': ' + child.form }}</div>
                        </v-col>
                      </v-row>
                    </v-tooltip>
                  </div>
                </div>
              </v-alert>
            </div>
          </div>
        </td>
      </template>
    </v-data-table>
  </span>
</template>

<script>
import patientMonitoringService from '@/services/patientMonitoringService';
import translationMixin, { LanguageVue } from '@/translationMixin';
import iconMixin from '../iconMixin';
import filterMixin from '../filterMixin';
import { getFormattedTime } from '@/utils/dateUtils';
import { StatusLevels } from '../constants';

export default {
  name: 'PatientPrescription',
  mixins: [translationMixin, iconMixin, filterMixin],
  props: {
    patientId: {
      type: Number,
      default: null,
    },

    filters: {
      type: Object,
      default: null,
    },

    activityId: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    items: [],
    prescriptionData: [],
    sortBy: 'at',
    sortDesc: true,
    error: null,
    alertCode: StatusLevels.AL,
    requestFilters: null,
  }),

  computed: {
    footerProps: function () {
      return {
        itemsPerPageOptions: [5, 10, 25, 50, -1],
        showFirstLastPage: true,
        'items-per-page-text': this.$t('rowsByPage'),
      };
    },
    headers: function () {
      return [
        {
          text: this.$t('statusLevelId'),
          textalign: 'center',
          value: 'statusLevel',
          align: 'center',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t('at'),
          value: 'date',
          sortable: false,
          order: 'desc',
        },
      ];
    },
    dateFrom() {
      return this.calculateNonChartDate('start');
    },
    dateTo() {
      return this.calculateNonChartDate('end');
    },
  },

  watch: {
    patientId: function () {
      this.getPatientPrescriptionResults();
    },
    filters: function () {
      this.getPatientPrescriptionResults();
    },
    requestFilters: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.$emit('update:request-filters', newVal);
        }
      },
      deep: true,
    },
  },

  created: function () {
    this.init();
  },

  methods: {
    init: function () {
      this.getPatientPrescriptionResults();
    },

    async getPatientPrescriptionResults() {
      const newFilters = this.setRequestFilters();
      this.requestFilters = newFilters;

      try {
        let patientData = await patientMonitoringService.getPatientPrescription(this.requestFilters);
        this.items = patientData?.results;
      } catch (error) {
        this.error = error;
      }
    },
    getEffectiveAtDate(prescription) {
      let times = prescription.details.filter((x) => !!x.effective_at).map(this.getPrescriptionDetailTime);
      if (!times.length) {
        return '';
      }
      let identical = times.every((x) => x === times[0]);
      if (identical) {
        return times[0];
      }
      return this.$t('multipleHours');
    },
    getPrescriptionDetailTime(detail) {
      return getFormattedTime(detail.effective_at, LanguageVue.getLanguage());
    },
    isAlertTriggered(child) {
      return child.alert_triggered;
    },
  },
};
</script>

<style scoped></style>

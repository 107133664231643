export const chartColor = {
  correctFirstDataColor: '#4A94E6',
  correctSecondaryDataColor: '#F39308',
  hoveredCorrectFirstDataColor: '#327BCE',
  hoveredCorrectSecondaryDataColor: '#E38A07',
  mainBorderLegendColor: '#5A9CE5',
  secondaryBorderLegendColor: '#F2B04E',
  incorrectDataColor: '#ff0000',
  hoveredIncorrectDataColor: '#DE0000',
  textLabel: '#fff',
  backgroundThreshold: 'transparent',
  delimiterBorderColor: 'rgba(0, 0, 0, 0.25)',
};

<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog
      v-if="show"
      ref="dialogRef"
      value="show"
      scrollable
      persistent
      :max-width="iotId !== null ? '1000px' : '800px'"
    >
      <v-card>
        <ModalTitle :title="title" />

        <WaitModal :show="showWaitModal" />

        <v-card-text>
          <v-form ref="IOTModalForm">
            <v-row>
              <v-col cols="12" :md="iotId !== null ? 6 : 12">
                <v-select
                  :id="getIdByName('types')"
                  ref="iotDeviceType"
                  v-model="formData.typeId"
                  :label="$t('iotDeviceType')"
                  :items="types"
                  item-text="name"
                  item-value="id"
                  filled
                  :no-data-text="$t('noDataAvailable')"
                  @input="companiesScopeChanged"
                />

                <v-select
                  :id="getIdByName('companies')"
                  ref="iotDeviceCompany"
                  v-model="formData.companyId"
                  :label="$t('iotDeviceCompany')"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  filled
                  :no-data-text="$t('noDataAvailable')"
                  @input="modelsScopeChanged"
                />

                <v-select
                  :id="getIdByName('models')"
                  ref="iotDeviceModel"
                  v-model="formData.modelId"
                  :label="$t('iotDeviceModel')"
                  :items="models"
                  item-text="name"
                  item-value="id"
                  filled
                  :no-data-text="$t('noDataAvailable')"
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-text-field
                  :id="getIdByName('modelNumber')"
                  ref="iotDeviceModelNumber"
                  v-model="formData.modelNumber"
                  :label="$t('iotDeviceModelNumber')"
                  filled
                />

                <v-text-field
                  :id="getIdByName('serie')"
                  ref="iotDeviceSerialNumber"
                  v-model="formData.serie"
                  :label="serialNumberLabel"
                  class="required-indicator"
                  :rules="[validationRules.required]"
                  filled
                />

                <v-text-field
                  :id="getIdByName('version')"
                  ref="version"
                  v-model="formData.version"
                  :label="$t('iotDeviceVersion')"
                  filled
                />

                <v-select
                  v-if="statuses.length > 0"
                  :id="getIdByName('statuses')"
                  ref="iotDeviceStatus"
                  v-model="formData.statusId"
                  class="my-2 required-indicator"
                  :label="$t('iotDeviceStatus')"
                  :items="statuses"
                  item-text="name"
                  item-value="id"
                  filled
                  :no-data-text="$t('noDataAvailable')"
                  :rules="[validationRules.required]"
                />

                <RequiredFieldsLegend />
              </v-col>

              <v-col v-if="iotId !== null" cols="12" md="6">
                <v-text-field
                  v-if="iotId != null"
                  :id="getIdByName('createdDate')"
                  ref="createdDate"
                  v-model="formData.createdDate"
                  :label="$t('createdDate')"
                  filled
                  readonly
                />

                <v-text-field
                  v-if="iotId != null"
                  :id="getIdByName('createdBy')"
                  ref="createdBy"
                  v-model="formData.createdBy"
                  :label="$t('createdBy')"
                  filled
                  readonly
                />

                <v-text-field
                  v-if="iotId != null"
                  :id="getIdByName('modifiedDate')"
                  ref="modifiedDate"
                  v-model="formData.modifiedDate"
                  :label="$t('modifiedDate')"
                  filled
                  readonly
                />

                <v-text-field
                  v-if="iotId != null"
                  :id="getIdByName('modifiedBy')"
                  ref="modifiedBy"
                  v-model="formData.modifiedBy"
                  :label="$t('modifiedBy')"
                  filled
                  readonly
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="createOrUpdateAction"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import iotService from '@/services/iotService';
import { IOTDeviceTypeCodes } from '@/components/PatientMonitoring/constants';
import SaveButton from '@/components/SaveButton.vue';

export default {
  name: 'IotModal',

  components: { SaveButton },
  mixins: [translation, accessibility, validationRulesMixin],

  props: {
    iotId: {
      type: Number,
      required: false,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      title: '',
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      types: [],
      allCompanies: [],
      companies: [],
      allModels: [],
      models: [],
      statuses: [],
      formData: {
        typeId: null,
        companyId: null,
        modelId: null,
        modelNumber: '',
        serie: '',
        version: '',
        statusId: null,
        createdDate: '',
        createdBy: '',
        modifiedDate: '',
        modifiedBy: '',
      },
      originalFormData: {},
    };
  },
  computed: {
    serialNumberLabel() {
      const deviceTypeCode = this.types?.find(
        (type) => typeof type?.id === 'number' && type?.id === this.formData?.typeId
      )?.code;
      switch (deviceTypeCode) {
        case IOTDeviceTypeCodes.CAMERA:
          return this.$t('iotDeviceURL');
        default:
          return this.$t('iotDeviceSerialNumber');
      }
    },
  },
  watch: {
    show: function () {
      if (this.show) {
        if (this.iotId != null) {
          this.editClicked();
        } else {
          this.init();
        }
      }
    },
  },

  methods: {
    init: function () {
      this.title = this.$t('createIot');
      this.formData = {
        typeId: null,
        companyId: null,
        modelId: null,
        modelNumber: '',
        serie: '',
        version: '',
        statusId: null,
        createdDate: '',
        createdBy: '',
        modifiedDate: '',
        modifiedBy: '',
      };
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      this.getAllDropDownDataAction();
    },

    async getAllDropDownDataAction() {
      this.showWaitModal = true;

      try {
        let data = await iotService.getIOTDeviceParametersInformations();

        this.types = data.iotdeviceTypes.sort((a, b) => a.name.localeCompare(b.name));
        this.allCompanies = data.companies;
        this.allModels = data.iotdeviceModels;
        this.statuses = data.statuses;
        this.modelsScopeChanged();
        this.companiesScopeChanged();
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    async editClicked() {
      this.title = this.$t('editIot');
      this.showWaitModal = true;

      try {
        var iot = await iotService.getIotById(this.iotId);
        this.formData.typeId = iot.iotdeviceModel.type.id;
        this.formData.companyId = iot.iotdeviceModel.company.id;
        this.formData.modelId = iot.iotdeviceModel.id;
        this.formData.modelNumber = iot.modelNumber;
        this.formData.serie = iot.serie;
        this.formData.version = iot.version;
        this.formData.statusId = iot.status.id;
        this.formData.createdDate = iot.createdAt;
        this.formData.createdBy = iot.createdBy != null ? iot.createdBy.username : '';
        this.formData.modifiedDate = iot.updatedAt;
        this.formData.modifiedBy = iot.updatedBy != null ? iot.updatedBy.username : '';
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
        await this.getAllDropDownDataAction();
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    async createOrUpdateAction() {
      let isValid = this.$refs.IOTModalForm.validate();

      if (!isValid || this.isProcessing) return;
      var data = {
        iotdeviceModelId: this.formData.modelId,
        modelNumber: this.formData.modelNumber,
        serie: this.formData.serie,
        version: this.formData.version,
        statusId: this.formData.statusId,
      };
      this.showWaitModal = true;
      this.isProcessing = true;

      try {
        if (this.iotId == null) {
          await iotService.createIot(data);
        } else {
          await iotService.updateIot(this.iotId, data);
        }

        this.closeDialog();
        this.$emit('refresh');
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
      this.isProcessing = false;
    },

    modelsScopeChanged: function () {
      this.models = this.allModels
        .filter((model) => model.type.id === this.formData.typeId && model.company.id === this.formData.companyId)
        .sort((a, b) => a.name.localeCompare(b.name));
    },

    companiesScopeChanged: function () {
      this.models = this.allModels.filter((model) => model.type.id === this.formData.typeId);
      let companies_ids = [this.models.map((model) => model.company.id)];
      this.companies = this.allCompanies
        .filter((company) => companies_ids[0].includes(company.id))
        .sort((a, b) => a.name.localeCompare(b.name));
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <v-row no-gutters>
      <v-col cols="auto">
        <span class="text-h6">{{ patientInfo.lastName }}, {{ patientInfo.firstName }}</span>
        <v-row no-gutters class="patient-details-row">
          <v-col cols="auto">
            <detail-field ref="establishment" class="my-0" :value="patientInfo.facility" :label="$t('facility')" />
          </v-col>
          <v-col cols="auto">
            <detail-field ref="roombed" class="my-0" :value="patientInfo.roombed" :label="$t('roombed')" />
          </v-col>
          <v-col cols="auto">
            <detail-field ref="age" class="my-0" :value="patientInfo.birthDate" :label="$t('birthDate')" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import virtuoseMixin from '@/virtuoseMixin';

export default {
  name: 'PatientAssignDetails',

  mixins: [translation, virtuoseMixin],

  props: {
    patientInfo: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.patient-details-row {
  column-gap: 24px;
}
</style>

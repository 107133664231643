class CaregiverFormData {
  constructor() {
    this.reset();
  }

  reset() {
    this.caregiverId = null;
    this.caregiverLastName = null;
    this.caregiverFirstName = null;
    this.caregiverPhone = null;
    this.caregiverEmail = null;
    this.caregiverRelation = null;
    this.caregiverRole = null;
  }

  setData(caregiver) {
    this.caregiverId = caregiver.caregiverId;
    this.caregiverLastName = caregiver.caregiverLastName;
    this.caregiverFirstName = caregiver.caregiverFirstName;
    this.caregiverPhone = caregiver.caregiverPhone;
    this.caregiverEmail = caregiver.caregiverEmail;
    this.caregiverRelation = caregiver.caregiverRelation;
    this.caregiverRole = caregiver.caregiverRole;
  }
}

export { CaregiverFormData };

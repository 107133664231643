<template>
  <div>
    <v-row>
      <v-col>
        <div v-if="assignedTitle" class="my-2">{{ assignedTitle }}</div>
        <DataGrid
          :items="internalAssignedItems"
          :columns="columnsAssigned"
          :items-per-page="25"
          :single-select="false"
          :selected-items.sync="assignedSelected"
          item-key="dialogueId"
          table-height="max(calc(100vh - 550px), 100px)"
          show-select
          :data-loading="dataLoading"
        >
          <template v-for="c in assignedColumnsWithItemSlot" #[`item.${c.value}`]="props">
            <slot :name="`assignedItem.${c.value}`" v-bind="props"></slot>
          </template>

          <template #[`item.normative`]="{ item }">
            <v-simple-checkbox v-model="item.dialogueNormative" disabled />
          </template>
        </DataGrid>
      </v-col>
      <v-col cols="auto">
        <div class="buttons-container">
          <v-btn :disabled="availableSelected.length === 0" depressed color="primary" @click="addItems">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn :disabled="assignedSelected.length === 0" depressed color="primary" @click="removeItems">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col>
        <div v-if="availableTitle" class="my-2">{{ availableTitle }}</div>
        <DataGrid
          :items="availableItems"
          :columns="columnsAvailable"
          :items-per-page="25"
          :single-select="false"
          :selected-items.sync="availableSelected"
          item-key="dialogueId"
          table-height="max(calc(100vh - 550px), 100px)"
          show-select
          :data-loading="dataLoading"
        >
          <template #[`item.dialogueNormative`]="{ item }">
            <v-simple-checkbox v-model="item.dialogueNormative" disabled />
          </template>

          <template v-for="c in availableColumnsWithItemSlot" #[`item.${c.value}`]="props">
            <slot :name="`availableItem.${c.value}`" v-bind="props"></slot>
          </template>
        </DataGrid>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DataGrid from '@/components/DataGrid.vue';

export default {
  name: 'ActivityDialogue',

  components: {
    DataGrid,
  },

  props: {
    columnsAvailable: {
      type: Array,
      default: () => [],
    },
    columnsAssigned: {
      type: Array,
      default: () => [],
    },
    allItems: {
      type: Array,
      default: () => [],
    },
    assignedItems: {
      type: Array,
      default: () => [],
    },
    assignedTitle: {
      type: String,
      default: undefined,
    },
    availableTitle: {
      type: String,
      default: undefined,
    },
    dataLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      availableSelected: [],
      assignedSelected: [],
      internalAssignedItems: [],
    };
  },
  computed: {
    availableItems() {
      let availableItemsList = [];
      for (let i = 0; i < this.allItems.length; i++) {
        if (!this.internalAssignedItems.find((x) => x.dialogueId === this.allItems[i]['dialogueId']))
          availableItemsList.push(this.allItems[i]);
      }
      return availableItemsList;
    },
    assignedColumnsWithItemSlot() {
      return this.columnsAssigned.filter((c) => this.hasSlot(`assignedItem.${c.value}`));
    },
    availableColumnsWithItemSlot() {
      return this.columnsAvailable.filter((c) => this.hasSlot(`availableItem.${c.value}`));
    },
  },
  watch: {
    assignedItems: {
      handler(newValue) {
        if (Array.isArray(newValue)) {
          this.internalAssignedItems = newValue;
        } else {
          this.internalAssignedItems = [];
        }
      },
      immediate: true,
    },
    assignedSelected() {
      this.$emit('update:assigned-selected', this.assignedSelected);
    },
  },
  methods: {
    resetOrder: function () {
      this.internalAssignedItems.forEach((item, index) => (item.dialogueOrder = index + 1));
    },
    addItems: function () {
      if (this.availableSelected.length === 0) {
        return;
      }
      this.internalAssignedItems = [...this.internalAssignedItems, ...this.availableSelected];
      this.availableSelected = [];
      this.resetOrder();
      this.$emit('update:assignedItems', this.internalAssignedItems);
    },
    removeItems() {
      if (this.assignedSelected.length === 0) {
        return;
      }
      this.internalAssignedItems = this.internalAssignedItems.filter((x) => !this.assignedSelected.includes(x));
      this.assignedSelected = [];
      this.resetOrder();
      this.$emit('update:assignedItems', this.internalAssignedItems);
    },
    hasSlot(slotName) {
      return !!this.$scopedSlots[slotName];
    },
  },
};
</script>

<style scoped>
.buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  row-gap: 24px;
}
</style>

<template>
  <v-sheet>
    <v-row dense>
      <v-col>
        <v-list dense class="values-list">
          <v-list-item @click.stop="selectAll">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon v-if="allSelected" class="mx-2" dense color="primary"> mdi-checkbox-marked</v-icon>
                <v-icon v-else-if="allSelected === false" class="mx-2" dense> mdi-checkbox-blank-outline</v-icon>
                <v-icon v-else class="mx-2" dense color="primary"> mdi-checkbox-intermediate </v-icon>
                <span>{{ $t('selectAll') }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item v-for="value in values" :key="value.value" @click.stop="toggleItem(value)">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon v-if="value.selected" class="mx-2" dense color="primary"> mdi-checkbox-marked</v-icon>
                <v-icon v-else class="mx-2" dense> mdi-checkbox-blank-outline</v-icon>
                <span>{{ value.displayValue }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-container>
      <v-row justify="start" dense>
        <v-col cols="auto">
          <v-btn depressed :disabled="allSelected === false" color="primary" @click="okClick">OK</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn depressed @click="cancelClick">{{ $t('cancel') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import translation from '@/translationMixin';
export default {
  mixins: [translation],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    propertyName: {
      type: String,
      required: true,
    },
    selectedValues: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      values: [],
    };
  },
  computed: {
    allSelected() {
      let nbSelected = this.values.filter((x) => x.selected).length;
      return nbSelected === 0 ? false : nbSelected === this.values.length ? true : null;
    },
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.updateValues();
      },
    },
    propertyName: {
      immediate: true,
      handler() {
        this.updateValues();
      },
    },
    selectedValues: {
      immediate: true,
      handler() {
        this.updateValues();
      },
    },
  },
  methods: {
    updateValues() {
      if (!this.items || !this.propertyName) {
        this.values = [];
      }

      let set = new Set(this.items.map((x) => x[this.propertyName]));

      let getDisplayValue = (x) => {
        if (x === undefined || x === null) {
          return '';
        }

        if (x === true || x === false) {
          return this.$t(x);
        }

        return String(x);
      };

      this.values = Array.from(set)
        .map((x) => {
          return {
            selected: this.selectedValues.length === 0 || this.selectedValues.includes(x),
            value: x,
            displayValue: getDisplayValue(x),
          };
        })
        .sort((a, b) =>
          a.displayValue.localeCompare(b.displayValue, undefined, {
            sensitivity: 'base',
          })
        );
    },
    toggleItem(item) {
      item.selected = !item.selected;
    },
    cancelClick() {
      this.$emit('cancel');
    },
    okClick() {
      let selectedItems = this.allSelected ? null : this.values.filter((x) => x.selected).map((x) => x.value);

      this.$emit('update-filters', selectedItems);
    },
    selectAll() {
      let allSelected = this.allSelected;

      this.values.forEach((x) => {
        x.selected = !allSelected;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.values-list {
  max-height: 300px;
  overflow-y: auto;
}
</style>

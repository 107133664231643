<template>
  <v-container>
    <PatientGrid />
  </v-container>
</template>

<script>
import translationMixin from '@/translationMixin';
import PatientGrid from './PatientGrid';

export default {
  name: 'PatientHome',
  components: {
    PatientGrid,
  },
  mixins: [translationMixin],
  props: {},
  data() {
    return {};
  },
  created: function () {
    this.init();
  },
  methods: {
    init: function () {},
  },
};
</script>

<style scoped></style>

<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-dialog
      v-if="show"
      ref="dialogRef"
      value="show"
      scrollable
      persistent
      :max-width="noteId != null ? '1000px' : '800px'"
    >
      <v-card class="eb-white-bg">
        <ModalTitle :title="title" />

        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <v-form ref="noteModalForm">
            <v-row>
              <v-col cols="12" :md="noteId != null ? 6 : 12">
                <v-combobox
                  :id="getIdByName('noteType')"
                  ref="noteType"
                  v-model="formData.noteType"
                  :items="noteTypesList"
                  filled
                  clearable
                  :disabled="!!noteId"
                  :no-data-text="$t('noDataAvailable')"
                  :label="$t('patientNote.noteType')"
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />
                <v-row v-if="formData.noteType ? formData.noteType.value === noteTypesConst.intervention : false">
                  <v-col cols="12" sm="4">
                    <v-menu
                      ref="menuDate"
                      v-model="menuDatePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          :id="getIdByName('menuDate')"
                          v-model="formData.interventionDate"
                          :label="$t('patientNote.dateIntervention')"
                          prepend-icon="mdi-calendar"
                          class="required-indicator"
                          :rules="[validationRules.required]"
                          :disabled="!!noteId"
                          filled
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.interventionDate"
                        :max="maxDate"
                        @input="menuDatePicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row
                  v-if="formData.noteType ? formData.noteType.value === noteTypesConst.intervention : false"
                  justify="space-around"
                  align="center"
                >
                  <v-col cols="11" sm="4">
                    <v-menu
                      ref="menuStart"
                      v-model="menuStartTimePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="formData.interventionStartTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          :id="getIdByName('startTime')"
                          v-model="formData.interventionStartTime"
                          :label="$t('patientNote.startTime')"
                          prepend-icon="mdi-clock-start"
                          class="required-indicator"
                          :rules="[validationRules.required]"
                          :disabled="!!noteId"
                          filled
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menuStartTimePicker"
                        v-model="formData.interventionStartTime"
                        :max="formData.interventionEndTime"
                        full-width
                        :disabled="!!noteId"
                        @click:minute="$refs.menuStart.save(formData.interventionStartTime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="11" sm="4">
                    <v-menu
                      ref="menuEnd"
                      v-model="menuEndTimePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="formData.interventionEndTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          :id="getIdByName('endTime')"
                          v-model="formData.interventionEndTime"
                          :label="$t('patientNote.endTime')"
                          prepend-icon="mdi-clock-end"
                          class="required-indicator"
                          :rules="[validationRules.required]"
                          :disabled="!!noteId"
                          filled
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menuEndTimePicker"
                        v-model="formData.interventionEndTime"
                        :min="formData.interventionStartTime"
                        full-width
                        :disabled="!!noteId"
                        @click:minute="$refs.menuEnd.save(formData.interventionEndTime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="11" sm="4">
                    <v-text-field
                      :id="getIdByName('time')"
                      v-model="formData.interventionTime"
                      :label="$t('patientNote.time')"
                      :hint="$t('patientNote.timeHint')"
                      persistent-hint
                      prepend-icon="mdi-clock"
                      readonly
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-textarea
                  :id="getIdByName('noteContent')"
                  ref="note_content"
                  v-model="formData.noteContent"
                  :label="$t('patientNote.note')"
                  filled
                  :disabled="!!noteId"
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />
                <RequiredFieldsLegend />
              </v-col>

              <v-col v-if="noteId !== null" :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'">
                <detail-field
                  v-if="noteId !== null"
                  :id="getIdByName('createdDate')"
                  ref="createdDate"
                  v-model="formData.createdAt"
                  :label="$t('createdDate')"
                />
                <detail-field
                  v-if="noteId !== null"
                  :id="getIdByName('createdBy')"
                  ref="createdBy"
                  v-model="formData.createdBy"
                  :label="$t('createdBy')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t(noteId ? 'close' : 'cancel') }}
          </v-btn>
          <SaveButton
            v-if="!noteId"
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="createAction"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import PatientService from '@/services/patientService';
import SaveButton from '@/components/SaveButton.vue';
import { PatientNoteFormData } from './NoteFormData';

export default {
  name: 'NotesModal',
  components: { SaveButton },
  mixins: [translation, accessibility, validationRulesMixin],
  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },
    noteId: {
      type: Number,
      required: false,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      title: '',
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      formData: new PatientNoteFormData({}),
      noteTypesConst: {
        proNote: 'professional_note',
        intervention: 'intervention',
      },
      menuStartTimePicker: false,
      menuEndTimePicker: false,
      menuDatePicker: false,
    };
  },
  computed: {
    noteTypesList() {
      return [
        {
          text: this.$t('patientNote.proNote'),
          value: this.noteTypesConst.proNote,
        },
        {
          text: this.$t('patientNote.intervention'),
          value: this.noteTypesConst.intervention,
        },
      ].sort((a, b) => a.text.localeCompare(b.text));
    },
    maxDate() {
      return new Date().toISOString().substring(0, 10); //Today's date in yyyy-mm-dd format
    },
  },
  watch: {
    'formData.interventionStartTime'() {
      if (this.formData.interventionEndTime) {
        const duration = this.calculateDuration(this.formData.interventionStartTime, this.formData.interventionEndTime);
        this.formData.interventionTime = duration;
      }
    },
    'formData.interventionEndTime'() {
      if (this.formData.interventionStartTime) {
        const duration = this.calculateDuration(this.formData.interventionStartTime, this.formData.interventionEndTime);
        this.formData.interventionTime = duration;
      }
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    async init() {
      this.title = this.$t('patientNote.createNote');
      this.formData.reset();

      if (this.noteId !== null) {
        await this.previewClicked();
      }
    },

    async previewClicked() {
      this.title = this.$t('patientNote.consultNote');
      this.showWaitModal = true;

      try {
        let note = await PatientService.getSinglePatientNote(this.noteId);
        this.formData.setData({
          ...note,
          noteType: this.noteTypesList.find((el) => el.value === note.noteType),
          createdBy: `${note.createdBy.firstName} ${note.createdBy.lastName}`,
        });
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    async createAction() {
      let isValid = this.$refs.noteModalForm.validate();

      if (!isValid || this.isProcessing) return;

      let data = {
        noteType: this.formData.noteType?.value,
        noteContent: this.formData.noteContent,
        interventionDate: this.formData.interventionDate,
        interventionStartTime: this.formData.interventionStartTime,
        interventionEndTime: this.formData.interventionEndTime,
        interventionTime: this.formData.interventionTime,
      };

      this.showWaitModal = true;
      this.isProcessing = true;

      try {
        await PatientService.createPatientNote(data, this.patientId);
        this.$emit('refresh');
        this.closeDialog();
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
      this.isProcessing = false;
    },
    closeDialog: function () {
      this.$emit('update:show', false);
    },
    calculateDuration: function (start, end) {
      start = start.split(':');
      end = end.split(':');

      let startDate = new Date(0, 0, 0, start[0], start[1], 0);
      let endDate = new Date(0, 0, 0, end[0], end[1], 0);

      let diffTime = endDate.getTime() - startDate.getTime();

      // return difference in minutes
      return diffTime / 1000 / 60;
    },
  },
};
</script>

<style scoped></style>

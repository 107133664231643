<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="showWaitModal" />

    <v-data-table
      :items="items"
      :headers="headers"
      :footer-props="footerProps"
      :items-per-page="25"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-expand
      item-key="at"
      class="elevation-1 mt-4"
      mobile-breakpoint="0"
      :expanded.sync="expandedItems"
      @item-expanded="itemExpanded"
    >
      <template #item.statusLevelId="{ item }">
        <div>
          <v-icon :color="getIconColor(item.statusLevelCode)">
            {{ getIconName(item.statusLevelCode) }}
          </v-icon>
        </div>
      </template>

      <template #expanded-item="{ item }">
        <td :colspan="headers.length + 1">
          <div class="my-2">
            <div
              v-for="(executionDetail, executionIndex) in item.executionResults"
              :key="executionIndex"
              class="my-7 text-body-1 black--text mx-2 ml-md-4 mr-md-4"
              :class="{ 'text-body-2': $vuetify.breakpoint.sm }"
            >
              <div class="d-flex align-center">
                <v-icon
                  v-if="executionDetail.statusLevelCode !== warningCode"
                  :color="getAnamExecutionColor(executionDetail.statusLevelCode)"
                >
                  {{ getAnamExecutionIcon(executionDetail.statusLevelCode) }}
                </v-icon>

                <v-icon v-else :color="getIconColor(executionDetail.statusLevelCode)">
                  {{ getIconName(executionDetail.statusLevelCode) }}
                </v-icon>

                <div :class="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'd-block' : 'd-flex align-center'">
                  <div class="d-flex">
                    <div class="font-weight-black ml-5 ml-md-9">{{ executionDetail.scheduledDateTime }}</div>
                    <div v-if="executionDetail.executionDateTime == null" class="ml-md-7 ml-5 text--secondary">
                      {{ $t('patientPicture.notTaken') }}
                    </div>
                    <div v-if="executionDetail.executionDateTime != null" class="ml-md-7 ml-5 text--secondary">
                      {{ $t('patientPicture.takenAt') + ' ' + executionDetail.executionDateTime }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-for="(photoResult, photoResultIndex) in executionDetail.resultsDetails"
                :key="photoResultIndex"
                class="ml-md-15 ml-sm-4 ml-2 my-2 patient-picture-container"
              >
                <v-img
                  min-height="50px"
                  max-height="300px"
                  max-width="300px"
                  contain
                  position="left top"
                  class="d-print-none"
                  :src="photoResult.url"
                  @click="selectedPicture = photoResult"
                >
                  <template #placeholder>
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </template>
                </v-img>
                <img :src="photoResult.url" class="d-none d-print-inline-flex print-image" alt="photo" />
              </div>
            </div>
          </div>
        </td>
      </template>
    </v-data-table>

    <v-overlay v-if="selectedPicture" z-index="9999" @click="selectedPicture = null">
      <v-img :src="selectedPicture ? selectedPicture.url : ''" contain max-height="100vh" max-width="100vw"></v-img>
    </v-overlay>
  </span>
</template>

<script>
import translationMixin from '@/translationMixin';
import iconMixin from '../iconMixin';
import filterMixin from '../filterMixin';
import { StatusLevels } from '../constants';
import patientMonitoringService from '@/services/patientMonitoringService';
export default {
  name: 'PatientPicture',
  mixins: [translationMixin, iconMixin, filterMixin],
  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },

    activityId: {
      type: Number,
      default: null,
    },

    filters: {
      type: Object,
      required: false,
      default: null,
      validator(value) {
        return value == null || (typeof value === 'object' && !Array.isArray(value));
      },
    },
  },
  data: () => ({
    items: [],
    sortBy: 'at',
    sortDesc: true,
    error: null,
    showWaitModal: false,
    alertCode: StatusLevels.AL,
    warningCode: StatusLevels.WA,
    normalCode: StatusLevels.NO,
    selectedPicture: null,
    expandedItems: [],
  }),

  computed: {
    footerProps: function () {
      return {
        itemsPerPageOptions: [5, 10, 25, 50, -1],
        showFirstLastPage: true,
        'items-per-page-text': this.$t('rowsByPage'),
      };
    },

    headers() {
      return [
        {
          text: this.$t('statusLevelId'),
          textalign: 'center',
          value: 'statusLevelId',
          align: 'center',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t('at'),
          value: 'at',
          sortable: false,
          order: 'desc',
        },
      ];
    },
  },

  watch: {
    patientId: function () {
      this.init();
    },

    filters: function () {
      this.init();
    },

    activityId: function () {
      this.init();
    },

    requestFilters: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.$emit('update:request-filters', newVal);
        }
      },
      deep: true,
    },
  },

  created: function () {
    this.init();
  },
  methods: {
    init: async function () {
      await this.getPatientPhotoResults();
    },

    getPatientPhotoResults: async function () {
      const newFilters = this.setRequestFilters();
      this.requestFilters = newFilters;

      try {
        this.showWaitModal = true;
        this.selectedPicture = null;
        this.expandedItems = [];
        const patientData = await patientMonitoringService.getPatientPhotosResults(this.requestFilters);
        patientData.results.forEach((item) => {
          item.executionResults.forEach((result) => {
            result.resultsDetails.forEach((detail) => {
              // we create those properties now in order for them to be reactive
              detail.url = '';
              detail.fetchingUrl = false;
            });
          });
        });

        this.items = patientData.results;
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    itemExpanded(data) {
      if (data.value && data.item) {
        data.item.executionResults.forEach((result) => {
          result.resultsDetails.forEach((detail) => {
            if (!detail.url && !detail.fetchingUrl) {
              detail.fetchingUrl = true;
              this.loadPictureUrl(detail);
            }
          });
        });
      }
    },
    async loadPictureUrl(item) {
      const url = await patientMonitoringService.getPatientPhotoResultsPictureUrl(item.resultId);
      item.fetchingUrl = false;
      item.url = url.url;
    },
  },
};
</script>

<style scoped>
.patient-picture-container {
  min-height: 50px;
}
.patient-picture {
  max-height: 300px;
  max-width: 300px;
}
</style>

export default {
  en: {
    'resources.Resources': 'Patients resources',
    'resources.confirmDeleteResource': 'Do you really want to delete this resource ?',
    'resources.resourceName': 'Resource name',
    'resources.resourceDescription': 'Resource description',
    'resources.delete': 'Delete resource',
    'resources.resourceFile': 'File',
    'resources.editResource': 'Edit resource',
    'resources.pdfOnly': 'Only PDF files are allowed',
    'resources.addNewResource': 'Add a Resource',
    'resources.openResource': 'View',
    errorResourceNameMustBeProvided: 'Resource name must be provided',
    errorResourceFormatMustBeProvided: 'Resource type must be specified',
    errorResourceFileMustBeProvided: 'Resource file must be provided',
    errorResourceDescriptionMustBeProvided: 'Resource description must be provided',
    errorResourceDoesNotExist: "Resource doesn't exist",
    errorResourceFailedToUpload: "Resource couldn't be added, please try again.",
    resourceName: 'Resource name',
    resourceFormat: 'Resource type',
    resourceDescription: 'Resource description',
    resourceFileType: 'File type',
    assignResource: 'Training and documentation',
    availableResource: 'Available resource(s)',
    assignedResource: 'Assigned resource(s)',
    fileSupportedText: 'Supported Formats: PDF',
  },
  fr: {
    'resources.Resources': 'Ressources patients',
    'resources.confirmDeleteResource': 'Voulez vous vraiment supprimer cette ressource ?',
    'resources.resourceName': 'Nom de la ressource',
    'resources.resourceDescription': 'Description de la ressource',
    'resources.delete': 'Supprimer ressource',
    'resources.resourceFile': 'Fichier',
    'resources.editResource': 'Modifier une ressource',
    'resources.pdfOnly': 'Seuls les fichiers PDF sont permis',
    'resources.addNewResource': 'Ajouter une ressource',
    'resources.openResource': 'Consulter',
    errorResourceNameMustBeProvided: 'Le nom de la ressource est requis',
    errorResourceFormatMustBeProvided: 'Le type de ressource doit être spécifié',
    errorResourceFileMustBeProvided: 'Le fichier doit être spécifié',
    errorResourceDescriptionMustBeProvided: 'La description de la ressource est requise',
    errorResourceDoesNotExist: "La ressource n'existe pas",
    errorResourceFailedToUpload: "La ressource n'as pas pu être enregistrée, veuillez réessayer.",
    resourceName: 'Nom de la ressource',
    resourceFormat: 'Type de ressource',
    resourceDescription: 'Description de la ressource',
    resourceFile: 'Fichier de la ressource',
    resourceFileType: 'Type de fichier',
    fileSupportedText: 'Formats supportés: PDF',
    assignResource: 'Formation et documentation',
    availableResource: 'Ressource(s) disponible(s)',
    assignedResource: 'Ressource(s) assignée(s)',
  },
};

<template>
  <div>
    <v-snackbar v-model="internalShow" :timeout="-1" vertical>
      <span>{{ message }}</span>
      <template #action>
        <v-btn icon @click.stop="ok">
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <v-btn icon @click.stop="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import translation from '@/translationMixin';
export default {
  name: 'ConfirmationMessage',
  mixins: [translation],
  props: {
    message: {
      type: String,
      required: true,
    },
    objectToConfirm: {
      type: Object,
      required: false,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      internalShow: false,
    };
  },

  watch: {
    show: function () {
      this.internalShow = this.show;
    },
  },

  created: function () {
    this.internalShow = this.show;
  },

  methods: {
    cancel: function () {
      this.$emit('cancel', true);
    },
    ok: function () {
      this.$emit('ok', this.objectToConfirm);
    },
  },
};
</script>

<style scoped>
.snack-display {
  display: inline !important;
}
</style>

<template>
  <v-dialog value="true" class="max abs-top abs-left pa-0">
    <div class="prevent-bug-anim">.</div>

    <div class="footer absolute abs-bottom abs-left d-flex flex-column align-center">
      <div
        v-if="connected"
        :style="{ gridTemplateColumns: isMoreThanOneCamera ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)' }"
        class="video-toolbar medium-blur"
      >
        <div class="d-flex flex-column align-center">
          <v-btn
            ref="toggleVideo"
            fab
            class="rounded-pill state-v-btn size-48px elevation-0"
            :class="{ 'state-v-btn--active': localParticipantState.videoEnabled }"
            :disabled="localParticipantState.updating"
            @click="toggleVideo"
          >
            <v-icon>mdi-video</v-icon>
          </v-btn>
          <p class="text-body-2 my-2 btn-text text-center">
            {{ localParticipantState.videoEnabled ? $t('patientVideoCall.cameraOn') : $t('patientVideoCall.camera') }}
          </p>
        </div>

        <div class="d-flex flex-column align-center">
          <v-btn
            ref="toggleMicrophone"
            fab
            class="rounded-pill state-v-btn size-48px elevation-0"
            :class="{ 'state-v-btn--active': localParticipantState.microphoneEnabled }"
            :disabled="localParticipantState.updating"
            @click="toggleMicrophone"
          >
            <v-icon>mdi-microphone</v-icon>
          </v-btn>
          <p class="text-body-2 my-2 btn-text text-center">
            {{
              localParticipantState.microphoneEnabled
                ? $t('patientVideoCall.microphoneOn')
                : $t('patientVideoCall.microphone')
            }}
          </p>
        </div>

        <div v-if="isMoreThanOneCamera" class="d-flex flex-column align-center">
          <v-btn
            ref="switchVideoDevice"
            fab
            class="rounded-pill state-v-btn size-48px elevation-0"
            :disabled="localParticipantState.updating"
            @click="switchVideoDevice"
          >
            <v-icon color="white">mdi-video-switch</v-icon>
          </v-btn>
          <p class="text-body-2 my-2 btn-text text-center">{{ $t('patientVideoCall.RotateCamera') }}</p>
        </div>

        <div class="d-flex flex-column align-center">
          <v-btn ref="hangUp" fab class="rounded-pill state-btn-end size-48px elevation-0" @click="disconnect">
            <v-icon color="white">mdi-phone-hangup</v-icon>
          </v-btn>
          <p class="text-body-2 my-2 btn-text text-center">{{ $t('patientVideoCall.endCall') }}</p>
        </div>
      </div>

      <div
        v-if="localParticipantState.microphoneError || localParticipantState.videoError"
        class="device-error d-flex justify-space-between align-center"
      >
        <div>
          <span v-if="localParticipantState.videoError" class="white--text text-body-2">
            {{ $t('patientVideoCall.videoError') }}
          </span>
          <span v-if="localParticipantState.microphoneError" class="white--text text-body-2">
            {{ $t('patientVideoCall.microphoneError') }}
          </span>
        </div>
        <v-btn ref="clearErrors" class="rounded-pill transparent" @click="clearErrors">
          <v-icon color="white">mdi-window-close</v-icon>
        </v-btn>
      </div>
    </div>

    <div ref="localMediaRef" align="right" class="local-participant absolute abs-top pr-6"></div>

    <div id="remote-media" class="black d-flex flex-wrap flex-column align-stretch">
      <div
        v-for="participant in remoteParticipants"
        :ref="`remote-participant-${participant.sid}`"
        :key="participant.sid"
        class="remote-participant black"
      >
        <div class="absolute center-element">
          <v-icon color="white" class="user-icon user-connected-icon">mdi-account</v-icon>
        </div>

        <div class="user-reconnecting-icon absolute center-element mt-2">
          <v-icon class="user-icon">mdi-account-off</v-icon>
          <span class="white--text text-body-2">{{ $t('patientVideoCall.userDisconnected') }}</span>
        </div>

        <div class="user-name absolute abs-bottom ma-2 pa-2 d-flex align-center">
          <span class="white--text text-body-2">{{ getParticipantName(participant) }}</span>
          <v-icon small color="white">mdi-microphone-off</v-icon>
        </div>
      </div>

      <v-container
        v-if="remoteParticipants.length === 0 && connected"
        class="no-participant fill-height d-flex justify-center align-center"
      >
        <span class="text-body-2">{{ $t('patientVideoCall.waitingForParticipant') }}</span>
      </v-container>
    </div>

    <v-dialog v-if="fatalError" v-model="fatalError" scrollable max-width="400px" persistent eager>
      <v-card>
        <ModalTitle :title="$t('error')" />

        <v-card-text>
          <div>
            <p v-if="fatalError.kind === 'connect'">{{ $t('patientVideoCall.connectionMessage') }}</p>
            <p v-if="fatalError.kind === 'disconnect'">{{ $t('patientVideoCall.disconnectedMessage') }}</p>
            <p v-if="fatalError.message">
              {{ $t('error') + ' ' + fatalError.message }}
            </p>
          </div>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn ref="ok" text color="primary" @click="closeFatalErrorDialog()">{{ $t('ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { connect, createLocalAudioTrack, createLocalVideoTrack } from 'twilio-video';
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import videoCallService from '@/services/videoCallService';
import { MonitoringTypes } from '@/components/PatientMonitoring/constants';
export default {
  name: 'PatientVideoCall',
  components: {},
  mixins: [translation, accessibility],

  props: {
    callId: {
      type: Number,
      required: true,
    },
    patientId: {
      type: Number,
      default: null,
    },
    conversationId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      room: null,
      connected: false,
      videoDevices: [],
      remoteParticipants: [],
      participants: [],
      fatalError: null,
      localParticipantState: {
        videoEnabled: false,
        microphoneEnabled: false,
        videoDeviceId: '',
        videoError: false,
        microphoneError: false,
        updating: false,
      },
    };
  },

  computed: {
    participantNames() {
      const map = new Map();

      this.participants.forEach((p) => {
        map.set(p.identity, p.name);
      });

      return map;
    },

    isMoreThanOneCamera() {
      return this.videoDevices.length > 1;
    },
  },

  async mounted() {
    if (this.callId) {
      await this.connectToRoom();

      if (this.room) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        this.videoDevices = devices.filter((x) => x.kind === 'videoinput').map((x) => x.deviceId);
      }
    }
  },

  methods: {
    async connectToRoom() {
      const roomInfo = await videoCallService.getRoomInfo(this.callId);
      const token = roomInfo.token;

      this.participants = roomInfo.participants;

      try {
        this.room = await connect(token, { audio: false, video: false });
      } catch (e) {
        this.fatalError = {
          kind: 'connect',
          message: e?.message,
        };
        return;
      }

      this.connectLocalParticipant(this.room);
      this.connectRemoteParticipant(this.room);

      this.room.on('disconnected', this.roomDisconnected);
      this.connected = true;

      this.createVideoCallEvent('connected');
    },

    async disconnect() {
      if (this.room) {
        this.room.disconnect();
      }

      this.$router.replace({
        name: 'PatientMonitoringGrid',
        params: { patientId: this.patientId, detailType: MonitoringTypes.CONVERSATIONS, detailId: this.conversationId },
      });
    },

    roomDisconnected(room, error) {
      try {
        room.localParticipant.tracks.forEach((publication) => {
          if (publication.track && (publication.track.kind === 'audio' || publication.track.kind === 'video')) {
            publication.track.stop();

            const attachedElements = publication.track.detach();
            attachedElements.forEach((element) => element.remove());
            publication.unpublish();
          }
        });
      } catch {
        // do nothing
      }

      this.remoteParticipants = [];
      this.connected = false;

      if (error) {
        this.fatalError = {
          kind: 'disconnect',
          message: error.message,
        };
      } else {
        this.createVideoCallEvent('disconnected');
      }
    },

    async connectLocalParticipant(connectedRoom) {
      this.room = connectedRoom;

      if (this.localParticipantState.videoEnabled) {
        const videoActivated = await this.updateLocalParticipantVideoState(
          true,
          this.localParticipantState.videoDeviceId
        );
        if (!videoActivated) {
          this.localParticipantState.videoEnabled = false;
        }
      }
      const microphoneActivated = await this.updateLocalParticipantMicrophoneState(true);
      this.localParticipantState.microphoneEnabled = microphoneActivated;
    },

    async updateLocalParticipantVideoState(videoEnabled, videoDeviceId) {
      if (!this.room) {
        return false;
      }

      if (videoEnabled) {
        if (this.room.localParticipant.videoTracks.size === 0) {
          try {
            const localVideoTrack = await createLocalVideoTrack({
              deviceId: videoDeviceId,
            });

            await this.room.localParticipant.publishTrack(localVideoTrack);
            this.localParticipantState.videoError = false;
          } catch {
            this.localParticipantState.videoError = true;
            return false;
          }
        }
        this.room.localParticipant.videoTracks.forEach((publication) => {
          const localMediaContainer = this.$refs.localMediaRef;
          localMediaContainer?.appendChild(publication.track.attach());
        });
      } else {
        this.room.localParticipant.videoTracks.forEach((publication) => {
          publication.track.stop();
          const attachedElements = publication.track.detach();
          attachedElements.forEach((element) => element.remove());
          publication.unpublish();
        });
      }

      return true;
    },

    async updateLocalParticipantMicrophoneState(microphoneEnabled) {
      if (!this.room) {
        return false;
      }
      if (microphoneEnabled) {
        if (this.room.localParticipant.audioTracks.size === 0) {
          try {
            const localAudioTrack = await createLocalAudioTrack();
            await this.room.localParticipant.publishTrack(localAudioTrack);
            this.localParticipantState.microphoneError = false;
          } catch (error) {
            this.localParticipantState.microphoneError = true;
            return false;
          }
        }

        this.room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.enable();
        });
      } else {
        this.room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable();
        });
      }

      return true;
    },

    connectRemoteParticipant(room) {
      room.participants.forEach(this.roomParticipantConnected);

      room.on('participantConnected', this.roomParticipantConnected);
      room.on('participantDisconnected', this.roomParticipantDisconnected);
      room.on('participantReconnecting', this.roomParticipantReconnecting);
      room.on('participantReconnected', this.roomParticipantReconnected);

      room.on('trackSubscribed', (track, _publication, participant) => this.roomTrackSubscribed(track, participant));
      room.on('trackUnsubscribed', (track, _publication, participant) =>
        this.roomTrackUnsubscribed(track, participant)
      );

      room.on('trackEnabled', this.roomTrackEnabled);
      room.on('trackDisabled', this.roomTrackDisabled);
    },

    async roomParticipantConnected(participant) {
      this.remoteParticipants.push(participant);

      this.updateRemoteParticipantMicState(participant, false);

      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed && publication.track) {
          this.roomTrackSubscribed(publication.track, participant);
        }
      });
    },

    async roomParticipantDisconnected(participant) {
      participant.tracks.forEach((publication) => {
        if (publication.kind === 'video' && publication.track?.kind === 'video') {
          publication.track.detach();
        }
      });

      const index = this.remoteParticipants.findIndex((x) => x.sid === participant.sid);

      if (index >= 0) {
        this.remoteParticipants.splice(index, 1);
      }
    },

    async roomParticipantReconnecting(participant) {
      const element = this.getParticipantHTMLElement(participant);

      if (element) {
        element.classList.add('remote-participant--reconnecting');
      }
    },

    async roomParticipantReconnected(participant) {
      const element = this.getParticipantHTMLElement(participant);

      if (element) {
        element.classList.remove('remote-participant--reconnecting');
      }
    },

    async toggleMicrophone() {
      if (this.localParticipantState.updating) {
        return;
      }

      this.localParticipantState.updating = true;
      try {
        const stateChanged = await this.updateLocalParticipantMicrophoneState(
          !this.localParticipantState.microphoneEnabled
        );
        if (stateChanged) {
          this.localParticipantState.microphoneEnabled = !this.localParticipantState.microphoneEnabled;
        }
      } finally {
        this.localParticipantState.updating = false;
      }
    },

    async toggleVideo() {
      if (this.localParticipantState.updating) {
        return;
      }

      this.localParticipantState.updating = true;
      try {
        const stateChanged = await this.updateLocalParticipantVideoState(
          !this.localParticipantState.videoEnabled,
          this.localParticipantState.videoDeviceId
        );
        if (stateChanged) {
          this.localParticipantState.videoEnabled = !this.localParticipantState.videoEnabled;
        }
      } finally {
        this.localParticipantState.updating = false;
      }
    },

    updateRemoteParticipantMicState(participant, microphoneEnabled) {
      const element = this.getParticipantHTMLElement(participant);

      if (!element) {
        return;
      }

      if (microphoneEnabled) {
        element.classList.remove('remote-participant--muted');
      } else {
        element.classList.add('remote-participant--muted');
      }
    },

    updateRemoteParticipantVideoState(participant, videoEnabled) {
      const element = this.getParticipantHTMLElement(participant);

      if (!element) {
        return;
      }

      if (videoEnabled) {
        element.classList.add('remote-participant--video');
      } else {
        element.classList.remove('remote-participant--video');
      }
    },

    async switchVideoDevice() {
      if (this.videoDevices.length <= 1 || this.localParticipantState.updating) {
        return;
      }

      this.localParticipantState.updating = true;

      try {
        const currentIndex =
          this.localParticipantState.videoDeviceId === ''
            ? 0
            : this.videoDevices.indexOf(this.localParticipantState.videoDeviceId ?? '');
        const newDevice =
          currentIndex < 0 || currentIndex >= this.videoDevices.length - 1
            ? this.videoDevices[0]
            : this.videoDevices[currentIndex + 1];

        this.localParticipantState.videoDeviceId = newDevice;

        await this.updateLocalParticipantVideoState(false);

        const newState = await this.updateLocalParticipantVideoState(true, newDevice);
        if (newState) {
          this.localParticipantState.videoEnabled = true;
        }
      } finally {
        this.localParticipantState.updating = false;
      }
    },

    roomTrackEnabled(publication, participant) {
      if (publication.kind === 'audio') {
        this.updateRemoteParticipantMicState(participant, true);
      }
    },

    roomTrackDisabled(publication, participant) {
      if (publication.kind === 'audio') {
        this.updateRemoteParticipantMicState(participant, false);
      }
    },

    async roomTrackSubscribed(track, participant) {
      const element = this.getParticipantHTMLElement(participant);

      if (element && (track.kind === 'video' || track.kind === 'audio')) {
        element.appendChild(track.attach());
      }

      if (track.kind === 'video') {
        this.updateRemoteParticipantVideoState(participant, true);
      }

      if (track.kind === 'audio') {
        this.updateRemoteParticipantMicState(participant, track.isEnabled);
      }
    },

    async roomTrackUnsubscribed(track, participant) {
      if (track.kind === 'audio' || track.kind === 'video') {
        const attachedElements = track.detach();
        attachedElements.forEach((element) => element.remove());
      }

      if (track.kind === 'video') {
        this.updateRemoteParticipantVideoState(participant, false);
      }

      if (track.kind === 'audio') {
        this.updateRemoteParticipantMicState(participant, false);
      }
    },

    async createVideoCallEvent(event) {
      await videoCallService.createVideoCallEvent(this.callId, event);
    },

    getParticipantName(participant) {
      return this.participantNames.get(participant.identity) ?? participant.identity;
    },

    closeFatalErrorDialog() {
      if (this.fatalError?.kind === 'disconnect') {
        this.createVideoCallEvent('disconnected');
      }

      this.fatalError = null;

      this.$router.replace({
        name: 'PatientMonitoringGrid',
        params: { patientId: this.patientId, detailType: MonitoringTypes.CONVERSATIONS, detailId: this.conversationId },
      });
    },

    clearErrors() {
      this.localParticipantState.videoError = false;
      this.localParticipantState.microphoneError = false;
    },

    getParticipantHTMLElement(participant) {
      return this.$refs[`remote-participant-${participant.sid}`]?.at(0);
    },
  },
};
</script>

<style scoped lang="scss">
#remote-media {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.local-participant {
  z-index: 100;

  :deep(video) {
    background-color: black;
    margin: 10px;
    border-radius: 10px;
    max-height: 200px;
    max-width: 200px;
    box-shadow: var(--elevate2) !important;
  }
}

.no-participant {
  color: rgb(225, 225, 225);
  & i {
    font-size: 48px;
    height: 100px;
    width: 100px;
  }
}

.remote-participant {
  max-height: 100%;
  max-width: 100%;
  flex-grow: 1;

  :deep(video) {
    height: 100%;
    width: 100%;
  }
  .user-icon {
    font-size: 72px;
    height: 100px;
  }
  &.remote-participant--video {
    & .user-connected-icon {
      display: none;
    }
  }
  &.remote-participant--reconnecting {
    .user-connected-icon {
      display: none !important;
    }
    :deep(video) {
      display: none !important;
    }
  }
  &:not(.remote-participant--reconnecting) {
    .user-reconnecting-icon {
      display: none;
    }
  }

  &:not(.remote-participant--muted) {
    .user-name i {
      display: none;
    }
  }
}

.user-reconnecting-icon {
  i {
    color: rgb(236, 63, 63);
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-name {
  z-index: 50;
  background: rgb(0 0 0 / 60%);
  column-gap: 8px;
}
.video-toolbar {
  border-radius: 8px;
  margin: 8px 8px 40px 8px;
  padding: 8px 3px 3px 3px !important;
  display: grid;
  column-gap: 2px;

  .btn-text {
    white-space: pre-line;
  }

  & .state-v-btn {
    color: white;
    background-color: rgb(91, 91, 91);
  }

  & .state-v-btn--active {
    color: rgb(35, 35, 35);
    background-color: white;
  }
  & .state-btn-end {
    background-color: rgb(217, 0, 0);
  }
}

.footer {
  z-index: 100;
  width: 100%;
  .device-error {
    padding: 0 12px;
    width: 100%;
    background: rgb(186, 27, 27);
  }
}

.max {
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  transform: translateY(4rem);
}
.absolute {
  position: absolute;
}

.abs-top {
  top: 0;
}
.abs-bottom {
  bottom: 0;
}

.abs-left {
  left: 0;
}

.center-element {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.transparent {
  background-color: transparent !important;
  box-shadow: none !important;
  color: inherit !important;
}

.medium-blur {
  -webkit-backdrop-filter: blur(var(1rem));
  backdrop-filter: blur(var(1rem));
  color: var(--on-background);
  background-color: rgb(255 255 255 / 0.5);
}

.size-48px {
  width: 48px;
  height: 48px;
}

@keyframes mymove {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.01;
  }
}

.prevent-bug-anim {
  animation: mymove 1s alternate infinite linear;
  position: fixed;
}
</style>

import api from './baseApiService';

class VideoCallService {
  async createVideoCall(data) {
    return await api.post('videocall/', data);
  }

  async getRoomInfo(callId) {
    return await api.get(`videocall/${callId}/token`);
  }

  async createVideoCallEvent(callId, data) {
    return await api.post(`videocall/${callId}/event/`, data);
  }
}

export default new VideoCallService();

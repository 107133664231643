<template>
  <v-container>
    <CohortGrid />
  </v-container>
</template>

<script>
import CohortGrid from './CohortGrid.vue';
export default {
  name: 'CohortHome',
  components: {
    CohortGrid,
  },
};
</script>

<style scoped></style>

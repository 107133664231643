<template>
  <div ref="gridContent">
    <v-row v-for="(data, pageItemIndex) in pageItems" :key="pageItemIndex">
      <v-col>
        <v-card
          class="fill-height d-print-inline-flex"
          hover
          min-height="105"
          @click="viewPatientIotRealtimeDetail(data)"
        >
          <div
            class="fill-height d-flex"
            :class="{
              'ml-2': $vuetify.breakpoint.xl,
              'align-center': $vuetify.breakpoint.lgAndUp,
              'flex-column': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <div class="d-flex align-center mr-4" :class="$vuetify.breakpoint.lgAndUp ? 'width-120 ml-1' : ''">
              <div
                class="d-flex align-center font-weight-bold"
                :class="{
                  'break-word': $vuetify.breakpoint.xl,
                  'pt-3 pl-3': $vuetify.breakpoint.lgAndDown,
                }"
              >
                {{ `${data.patient.lastName}  ${data.patient.firstName}` }}
              </div>
            </div>

            <div
              class="d-flex"
              :class="{
                'flex-column': $vuetify.breakpoint.mdAndDown,
                'py-2': $vuetify.breakpoint.lgAndDown,
              }"
            >
              <div class="d-flex">
                <v-divider v-if="$vuetify.breakpoint.lgAndUp" vertical class="divider-height ma-auto" />

                <div
                  class="mr-1"
                  :class="{
                    'd-flex flex-column justify-center': $vuetify.breakpoint.xl,
                    'ml-3 order-1 width-110': $vuetify.breakpoint.lgAndUp,
                    'order-2 ml-1': $vuetify.breakpoint.mdAndDown,
                  }"
                >
                  <div v-if="data.lastVitalSignsSyncData">
                    <div
                      v-if="data.lastVitalSignsSyncData.lastDataDatetime"
                      class="d-flex align-center text--secondary text-body-2"
                    >
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <div v-on="on">
                            {{ getRelativeDate(data.lastVitalSignsSyncData.timeDifference) }}
                          </div>
                        </template>

                        <span> {{ getFormatDate(data.lastVitalSignsSyncData.lastDataDatetime) }}</span>
                      </v-tooltip>
                    </div>

                    <div v-if="data.lastVitalSignsSyncData.lastDataDatetime" class="d-flex align-center">
                      <img
                        v-if="data.lastVitalSignsSyncData.iotDeviceTypeCode === iotDeviceTypeCodes.PATCH"
                        src="/static/healthIcons/chest_monitor.svg"
                        height="19px"
                        alt="chest-monitor-icon"
                      />

                      <v-icon v-else size="19" color="black">
                        {{ getIotIconOnDeviceType(data.lastVitalSignsSyncData.iotDeviceTypeCode) }}
                      </v-icon>

                      <BatteryIcon
                        v-if="typeof data.lastVitalSignsSyncData.remainingBattery === 'number'"
                        :battery-level="data.lastVitalSignsSyncData.remainingBattery"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="d-flex"
                  :class="{
                    'flex-column justify-center': $vuetify.breakpoint.lgAndDown,
                    'align-center': $vuetify.breakpoint.xl,
                    'order-1': $vuetify.breakpoint.mdAndDown,
                    'order-2': $vuetify.breakpoint.lgAndUp,
                  }"
                >
                  <div
                    v-for="(activityTypeCode, activityTypeIndex) in activityTypesCodes.filter((activityTypeCode) =>
                      [
                        activityTypes.APR,
                        activityTypes.CFR,
                        activityTypes.SAT,
                        activityTypes.RES,
                        activityTypes.BDT,
                      ].includes(activityTypeCode)
                    )"
                    :key="activityTypeIndex"
                  >
                    <IotRealtimeGridData :activity-type-code="activityTypeCode" :data="data" />
                  </div>
                </div>
              </div>

              <div class="d-flex" :class="$vuetify.breakpoint.mdAndDown ? 'flex-column ' : ''">
                <v-divider
                  :vertical="$vuetify.breakpoint.lgAndUp"
                  :class="$vuetify.breakpoint.mdAndDown ? 'second-divider-width ml-2 my-4' : ''"
                />

                <div class="d-flex" :class="$vuetify.breakpoint.lgAndDown ? 'flex-column ' : 'align-center'">
                  <div
                    v-for="(activityTypeCode, activityTypeIndex) in activityTypesCodes.filter((x) =>
                      [activityTypes.WEI, activityTypes.GLY, activityTypes.FDE, activityTypes.INC].includes(x)
                    )"
                    :key="activityTypeIndex"
                  >
                    <IotRealtimeGridData :activity-type-code="activityTypeCode" :data="data" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <div class="d-flex justify-end align-center flex-wrap mt-4">
      <div class="d-flex align-center mr-7">
        <div class="text-body-2">{{ $t('resultByPage') }}</div>

        <div class="max-width-70 mx-7">
          <v-select
            v-model="paginationData.itemsPerPage"
            style="font-size: 14px"
            item-text="name"
            item-value="value"
            :items="itemsPerPageOptions"
            @change="changeItemsPerPage"
          />
        </div>

        <div class="text-body-2">{{ getPaginationValuesText() }}</div>
      </div>

      <div class="d-flex">
        <div class="d-flex">
          <v-btn color="primary" icon :disabled="disablePageButton(1)" @click="changePageTo(1)">
            <v-icon>mdi-page-first</v-icon>
          </v-btn>
          <v-btn icon :disabled="disablePageButton(1)" @click="changePageTo(paginationData.currentPage - 1)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </div>

        <div class="d-flex">
          <v-btn
            :disabled="disablePageButton(getPaginationTotalPages())"
            icon
            @click="changePageTo(paginationData.currentPage + 1)"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>

          <v-btn
            icon
            :disabled="disablePageButton(getPaginationTotalPages())"
            @click="changePageTo(getPaginationTotalPages())"
          >
            <v-icon>mdi-page-last</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import accessibility from '@/accessibilityMixin';
import iotRealtimeMixin from '@/components/PatientIotRealtime/iotRealtimeMixin.js';
import translationMixin, { LanguageVue } from '@/translationMixin';

import BatteryIcon from '@/components/PatientIotRealtime/RealtimeIcons/BatteryIcon.vue';

import { ActivityTypes, IOTDeviceTypeCodes } from '@/components/PatientMonitoring/constants';

import IotRealtimeGridData from './IotRealtimeGridData.vue';

export default {
  name: 'IotRealtimeGridContent',

  components: { BatteryIcon, IotRealtimeGridData },

  mixins: [accessibility, iotRealtimeMixin, translationMixin],

  props: {
    activityTypesCodes: {
      required: true,
      type: Array,
    },

    filteredIotRealtime: {
      required: true,
      type: Array,
    },

    userGridConfiguration: {
      required: false,
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      activityTypes: ActivityTypes,
      iotDeviceTypeCodes: IOTDeviceTypeCodes,
      itemsPerPageOptions: [
        {
          name: 5,
          value: 5,
        },
        {
          name: 10,
          value: 10,
        },
        {
          name: 25,
          value: 25,
        },
        {
          name: 50,
          value: 50,
        },
        {
          name: this.$t('iotRealtime.all'),
          value: 'all',
        },
      ],
      pageItems: [],
      paginationData: {
        itemsPerPage: this.userGridConfiguration?.pagination?.itemsPerPage
          ? this.userGridConfiguration.pagination.itemsPerPage
          : 5,
        currentPage: this.userGridConfiguration?.pagination?.page ? this.userGridConfiguration.pagination.page : 1,
        startIndex: 0,
        endIndex: 5,
      },

      previousItemPerPageValue: 5,
    };
  },

  watch: {
    filteredIotRealtime: function () {
      this.setStartAndEndIndexes();

      if (this.filteredIotRealtime?.length < this.paginationData.startIndex) {
        this.changePageTo(1);
      }

      this.pageItems = this.filteredIotRealtime.slice(this.paginationData.startIndex, this.paginationData.endIndex);
    },
  },

  created() {
    this.setStartAndEndIndexes();

    if (this.paginationData.itemsPerPage === 'all') {
      this.pageItems = this.filteredIotRealtime;
    }

    this.pageItems = this.filteredIotRealtime.slice(this.paginationData.startIndex, this.paginationData.endIndex);
    LanguageVue.$on('projectLanguage', this.userLanguageUpdated);
  },

  beforeDestroy: function () {
    LanguageVue.$off('projectLanguage', this.userLanguageUpdated);
  },

  methods: {
    userLanguageUpdated: function () {
      this.itemsPerPageOptions.at(-1).name = this.$t('iotRealtime.all');
    },

    setStartAndEndIndexes: function () {
      const itemsPerPage =
        this.paginationData.itemsPerPage === 'all' ? this.filteredIotRealtime.length : this.paginationData.itemsPerPage;

      this.paginationData.startIndex = (this.paginationData.currentPage - 1) * itemsPerPage;
      this.paginationData.endIndex = Math.min(
        this.paginationData.startIndex + itemsPerPage,
        this.filteredIotRealtime.length
      );
    },

    getPaginationTotalPages: function () {
      return Math.ceil(this.filteredIotRealtime.length / this.paginationData.itemsPerPage);
    },

    changePageTo: function (page) {
      this.paginationData.currentPage = page;

      this.setStartAndEndIndexes();
      this.pageItems = this.filteredIotRealtime.slice(this.paginationData.startIndex, this.paginationData.endIndex);

      this.$emit('emitPaginationConfig', this.paginationData);
    },

    changeItemsPerPage: function (itemsPerPageValue) {
      this.paginationData.currentPage = 1;

      this.setStartAndEndIndexes();
      this.pageItems = this.filteredIotRealtime.slice(this.paginationData.startIndex, this.paginationData.endIndex);

      if (itemsPerPageValue < this.previousItemPerPageValue) {
        if (itemsPerPageValue === 5) {
          window.scrollTo(0, 0); // Without this line, when we go from selecting 10 items to 5 there's a glitch
        } else {
          this.$refs?.gridContent.scrollIntoView({
            behavior: 'instant',
            block: 'end',
          });
        }
      }

      this.previousItemPerPageValue =
        this.paginationData.itemsPerPage === 'all' ? this.filteredIotRealtime.length : this.paginationData.itemsPerPage;

      this.$emit('emitPaginationConfig', this.paginationData);
    },

    getPaginationValuesText: function () {
      return this.$t('iotRealtime.paginationPagesNumber')
        .replace('{{startIndex}}', this.paginationData.startIndex + 1)
        .replace('{{endIndex}}', this.paginationData.endIndex)
        .replace('{{totalIotRealtimeData}}', this.filteredIotRealtime.length);
    },

    disablePageButton: function (limitPage) {
      return this.paginationData.currentPage === limitPage || this.paginationData.itemsPerPage === 'all';
    },

    viewPatientIotRealtimeDetail: function (data) {
      this.$emit('viewPatientIotRealtimeDetail', data.patient.id);
    },
  },
};
</script>

<style scoped>
.width-120 {
  width: 120px;
}

.width-110 {
  width: 110px;
}

.max-width-70 {
  max-width: 70px;
}

.second-divider-width {
  min-width: 115px;
  max-width: 95%;
}

.divider-height {
  height: 65px;
}

.break-word {
  word-break: break-word;
}
</style>

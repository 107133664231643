<template>
  <v-card>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="showWaitModal" />

    <template v-if="hasPatientIotDevice === false">
      <v-toolbar
        :absolute="$vuetify.breakpoint.xl"
        dense
        :class="{
          'abs-right pt-1': $vuetify.breakpoint.smAndUp,
          'd-flex ': $vuetify.breakpoint.lgAndDown,
          'justify-end': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
          'justify-center': $vuetify.breakpoint.smAndDown,
        }"
        elevation="0"
      >
        <v-btn small color="primary" @click="openVitalSignsManualEntryModal">
          {{ $t('entryData') }}
        </v-btn>
      </v-toolbar>

      <VitalSignsManualEntry
        v-if="showVitalSignsManualEntry"
        :patient-id="patientId"
        :observations="observations"
        :show-manual-entry="showVitalSignsManualEntry"
        @updateChartData="getSaturationData"
        @closeManualEntryModal="closeManualEntryModal"
      />
    </template>

    <template v-if="filterDuration === '24h'">
      <ChartDetails
        ref="chartDetails"
        :chart-title="chartTitle"
        :chart-unit="chartUnit"
        :filters="filters"
        :last-hours-value="lastHoursValue"
        :y-axis="yAxis"
        :values="patientValues"
        :series="saturationSeries"
        :from-notification="fromNotification"
      />
    </template>

    <template v-else>
      <ChartFiltered
        ref="chartFiltered"
        :chart-title="chartTitle"
        :chart-unit="chartUnit"
        :duration="filterDuration"
        :y-axis="yAxis"
        :values="patientValues"
        :series="saturationSeries"
        :filters="filters"
        :from-notification="fromNotification"
      />
    </template>
  </v-card>
</template>

<script>
import ChartDetails from '../Chart/ChartDetails.vue';
import ChartFiltered from '../Chart/ChartFiltered.vue';
import VitalSignsManualEntry from '../ManualEntry/VitalSignsManualEntry.vue';
import patientMonitoringService from '@/services/patientMonitoringService';
import patientVitalSignsTabMixin from './patientVitalSignsTabMixin';
import filterMixin from '../filterMixin';
import translationMixin from '@/translationMixin';

export default {
  name: 'PatientSaturation',
  components: {
    ChartFiltered,
    ChartDetails,
    VitalSignsManualEntry,
  },
  mixins: [filterMixin, patientVitalSignsTabMixin, translationMixin],
  props: {
    patientId: {
      type: Number,
      default: null,
    },

    filters: {
      type: Object,
      default: null,
    },

    activityId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      yAxis: {
        Ymax: 110,
        Ymin: 70,
        YStepSize: 5,
        title: 'saturation',
      },
      chartTitle: 'saturationDetails',
      chartUnit: 'patientChartUnit.saturation',
      observations: [{ name: 'Saturation', value: null }],
      saturationSeries: [],
      patientValues: [],
      filterDuration: null,
      lastHoursValue: null,
      hasPatientIotDevice: null,
      showWaitModal: false,
      error: null,
      requestFilters: null,
      fromNotification: false,
      showVitalSignsManualEntry: false,
    };
  },

  computed: {
    dateFrom() {
      return this.calculateChartDate('start');
    },
    dateTo() {
      return this.calculateChartDate('end');
    },
  },

  watch: {
    patientId: function () {
      this.getSaturationData();
    },

    filters: function () {
      this.getSaturationData();
    },

    requestFilters: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.$emit('update:request-filters', newVal);
          if ((oldVal && oldVal.fromNotification) || (newVal && newVal.fromNotification)) {
            this.fromNotification = true;
          }
        }
      },
      deep: true,
    },
  },

  created() {
    this.fromNotification = this.requestFilters !== null;
    this.getSaturationData();
  },

  methods: {
    async getSaturationData() {
      const newFilters = this.setRequestFilters();
      this.requestFilters = newFilters;
      this.patientValues = [];
      this.saturationSeries = [];

      try {
        this.showWaitModal = true;

        let patientData = await patientMonitoringService.getPatientSaturation(this.requestFilters);
        this.filterDuration = patientData.duration;
        this.lastHoursValue = patientData?.lastHoursValue;
        this.patientValues = patientData.results;
        this.hasPatientIotDevice = patientData.hasPatientIotDevice;

        this.saturationSeries = [
          {
            title: 'saturation',
            thresholds: {
              minThreshold: patientData.threshold,
              thresholdVariation: patientData.thresholdVariation,
              title: 'saturationThresholds',
            },
          },
        ];
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },
  },
};
</script>

<style scoped>
.abs-right {
  right: 4px;
}
</style>

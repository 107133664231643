import { format } from 'date-fns';

import translationMixin, { LanguageVue } from '@/translationMixin';
import { getFormattedTime, isDateToday } from '@/utils/dateUtils';

export default {
  mixins: [translationMixin],

  methods: {
    getStatusLevelColor(statusLevelCode) {
      const obj = this.statusLevelsForActivityType.find((item) => item.statusLevelCode === statusLevelCode);

      if (obj) {
        return obj.iconColor;
      }

      return 'black';
    },

    getStatusLevelColorByCount(statusLevel, alertCount) {
      if (alertCount > 0) {
        return statusLevel.iconColor;
      }
    },

    getWorstStatusLevelCode(alertsByDatetime) {
      const minPriorityAlert = alertsByDatetime
        .flatMap((x) => x.alerts.flatMap((y) => y.statusLevel))
        .reduce((min, current) => (current.priority < min.priority ? current : min));

      return minPriorityAlert?.code;
    },

    getStatusLevelIcon(statusLevelCode) {
      const obj = this.statusLevelsForActivityType.find((item) => item.statusLevelCode === statusLevelCode);

      if (obj) {
        return obj.icon;
      }

      return '';
    },

    getStatusChangeIconColor(alertCount) {
      if (alertCount === 0) {
        return this.changeGreyEssity;
      }

      return this.changeEssity;
    },

    formatDatetime(dateTime, dateTimeFormat) {
      if (dateTimeFormat === 'day') {
        return format(new Date(dateTime), 'yyyy-MM-dd');
      } else if (dateTimeFormat === 'hour')
        return format(new Date(dateTime), this.getLanguage() === 'fr' ? 'HH:mm' : 'hh:mm b');
    },

    getAlertDatetime(alertDatetime) {
      const timeString = getFormattedTime(alertDatetime, LanguageVue.getLanguage());

      if (isDateToday(alertDatetime)) {
        return timeString;
      }

      const date = new Date(alertDatetime);
      const dateString = format(date, 'yyyy-MM-dd');
      return `${dateString} ${timeString}`;
    },
  },
};

<template>
  <v-container>
    <ResourcesGrid />
  </v-container>
</template>

<script>
import ResourcesGrid from './ResourcesGrid';
export default {
  name: 'ResourcesHome',
  components: {
    ResourcesGrid,
  },
};
</script>

<style scoped></style>

import { render, staticRenderFns } from "./PatientProtocolsHistory.vue?vue&type=template&id=31c7d50c&scoped=true&"
import script from "./PatientProtocolsHistory.vue?vue&type=script&lang=js&"
export * from "./PatientProtocolsHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c7d50c",
  null
  
)

export default component.exports
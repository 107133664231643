<template>
  <v-btn :id="getIdByName('save')" text color="primary" :disabled="disableButton" @click.stop="handleClick">
    {{ $t('save') }}
  </v-btn>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';

export default {
  name: 'SaveButton',
  mixins: [translation, accessibility],
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
    showWaitModal: {
      type: Boolean,
      required: true,
    },
    handleClick: {
      type: Function,
      required: true,
    },
  },
  computed: {
    disableButton() {
      return this.isLoading || this.isProcessing || this.showWaitModal;
    },
  },
};
</script>

import UserHome from './UserHome';

export default {
  moduleName: 'user',
  routes: [
    {
      path: '/user',
      name: 'UserHome',
      component: UserHome,
    },
  ],
};

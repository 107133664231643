<template>
  <div :class="[mainClass]">
    <v-tooltip
      max-width="300"
      :top="position === 'top'"
      :bottom="position === 'bottom'"
      open-delay="200"
      transition="fade-transition"
      color="#212121"
    >
      <template #activator="{ on }">
        <span v-show="showText" :aria-describedby="describedBy" :class="[textClass]" v-on="on">
          {{ noIconText }}
        </span>
        <i
          v-show="showText == false"
          ref="iconRef"
          :aria-describedby="describedBy"
          :class="[iconClass]"
          aria-hidden="true"
          v-on="on"
        />
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import accessibility from '@/accessibilityMixin';
export default {
  name: 'IconInfoAndTooltip',
  components: {},
  mixins: [accessibility],
  props: {
    tooltipText: {
      type: String,
      required: true,
      validator: function (tooltipText) {
        return tooltipText.length > 0;
      },
    },
    describedBy: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: 'small',
    },
    position: {
      type: String,
      required: true,
      validator: function (position) {
        return position === 'top' || position === 'bottom' || position === 'left' || position === 'right';
      },
    },
    hasZone: {
      type: Boolean,
      required: false,
      default: false,
    },
    noIconText: {
      type: String,
      required: false,
      default: null,
    },
    textSize: {
      type: String,
      required: false,
      default: 'normal',
    },
  },
  data() {
    return {
      icon: 'mdi-information-outline',
    };
  },

  computed: {
    mainClass: function () {
      return this.hasZone ? 'zone' : '';
    },

    iconClass: function () {
      var classValue = 'notranslate mdi ' + this.icon + ' ';

      switch (this.size) {
        case 'small':
          classValue += 'small-icon';
          break;

        case 'normal':
          classValue += 'normal-icon';
          break;

        case 'large':
          classValue += 'large-icon';
          break;
      }

      return classValue;
    },

    textClass: function () {
      var classValue = '';

      switch (this.textSize) {
        case 'small':
          classValue = 'small-text';
          break;

        case 'normal':
        default:
          classValue = 'normal-text';
          break;

        case 'large':
          classValue = 'large-text';
          break;
      }

      return classValue;
    },

    showText: function () {
      return this.noIconText != null && this.noIconText.trim() != '';
    },
  },

  created: function () {},

  methods: {},
};
</script>

<style scoped>
.zone {
  border-radius: 5px;
  border: 2px dashed var(--eb-primary);
  border-image: none;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 150px;
  padding-bottom: 150px;
  margin-left: 5px;
}

.small-icon {
  line-height: 1.2rem !important;
  font-size: 1em;
}

.normal-icon {
  padding-top: 5px;
  line-height: 2rem !important;
  font-size: 1.5em;
}

.large-icon {
  line-height: 2.9rem !important;
  font-size: 2em;
}

.small-text {
  font-size: 10px;
}

.normal-text {
  font-size: 16px;
}

.large-text {
  font-size: 20px;
}

.icon-text {
  line-height: 2 !important;
}
</style>

import HealthcareProfessionalHome from './HealthcareProfessionalHome';

export default {
  moduleName: 'healthcareprofessional',
  routes: [
    {
      path: '/healthcareprofessional',
      name: 'HealthcareProfessionalHome',
      component: HealthcareProfessionalHome,
    },
  ],
};

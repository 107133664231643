<template>
  <v-container>
    <ProtocolGrid />
  </v-container>
</template>

<script>
import ProtocolGrid from './ProtocolGrid';
export default {
  name: 'ProtocolHome',
  components: {
    ProtocolGrid,
  },
  mixins: [],
  props: {},
  data() {
    return {};
  },
  created: function () {},
  methods: {},
};
</script>

<style scoped></style>

<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="showWaitModal" />
    <v-data-table
      :items="items"
      :headers="headers"
      :footer-props="footerProps"
      :items-per-page="25"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      item-key="alertDate"
      class="elevation-1 mt-4"
      mobile-breakpoint="0"
    >
      <template #item.statusLevelId="{ item }">
        <div v-if="item.statusLevelCode != statusLevelsCH">
          <v-icon :color="getIconColor(item.statusLevelCode)">
            {{ getIconName(item.statusLevelCode) }}
          </v-icon>
        </div>
        <div v-else>
          <img :src="changeEssity" alt="change-incontinence-icon" />
        </div>
      </template>
    </v-data-table>

    <div class="legend my-4">
      <div>
        <div class="font-weight-bold text--secondary text-body-2 mb-1">{{ $t('incontinence') }}</div>
        <div class="legend-items-container">
          <div>
            <v-icon color="#6fcf97" class="mr-2" small>mdi-check-circle</v-icon>
            <span class="text-body-2">{{ $t('patientMonitoringAlert.dr') }}</span>
          </div>
          <div>
            <v-icon color="#F2BA4C" class="mr-2" small>mdi-check-circle</v-icon>
            <span class="text-body-2">{{ $t('patientMonitoringAlert.we') }}</span>
          </div>
          <div>
            <img :src="changeEssity" class="mr-2" height="11px" alt="change-incontinence-icon" />
            <span class="text-body-2">{{ $t('patientMonitoringAlert.change') }}</span>
          </div>
          <div>
            <v-icon color="#d8574c" class="mr-2" small>mdi-alert</v-icon>
            <span class="text-body-2">{{ $t('patientMonitoringAlert.error') }}</span>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import translationMixin from '@/translationMixin';
import patientMonitoringService from '@/services/patientMonitoringService';
import iconMixin from '../iconMixin';
import filterMixin from '../filterMixin';
import { StatusLevels } from '../constants';
import { ActivityTypes } from '@/components/PatientMonitoring/constants';

export default {
  name: 'PatientIncontinence',
  mixins: [translationMixin, iconMixin, filterMixin],
  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },
    filters: {
      type: Object,
      required: false,
      default: null,
      validator(value) {
        return value == null || (typeof value === 'object' && !Array.isArray(value));
      },
    },
    activityId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    items: [],
    statusLevels: [],
    sortBy: 'alertDate',
    sortDesc: true,
    error: null,
    showWaitModal: false,
    statusLevelsDR: StatusLevels.DR,
    statusLevelsCH: StatusLevels.CH,
    changeEssity: '/static/incontinenceicon/change_essity.svg',
    requestFilters: null,
  }),
  computed: {
    footerProps: function () {
      return {
        itemsPerPageOptions: [5, 10, 25, 50, -1],
        showFirstLastPage: true,
        'items-per-page-text': this.$t('rowsByPage'),
      };
    },
    headers() {
      return [
        {
          text: this.$t('statusLevelId'),
          textalign: 'center',
          value: 'statusLevelId',
          align: 'center',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t('alertDate'),
          value: 'alertDate',
          sortable: false,
          order: 'desc',
        },
      ];
    },
    dateFrom() {
      return this.calculateNonChartDate('start');
    },
    dateTo() {
      return this.calculateNonChartDate('end');
    },
  },
  watch: {
    patientId: function () {
      this.getPatientIncontinenceData();
    },
    filters: function () {
      this.getPatientIncontinenceData();
    },
    requestFilters: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.$emit('update:request-filters', newVal);
        }
      },
      deep: true,
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    init: function () {
      this.getPatientIncontinenceData();
    },
    async getPatientIncontinenceData() {
      const newFilters = this.setRequestFilters(ActivityTypes.INC);
      this.requestFilters = newFilters;

      try {
        const incontinenceResponse = await patientMonitoringService.getPatientIncontinence(this.requestFilters);

        this.statusLevels = incontinenceResponse.statusLevels;

        var statusLevelMap = this.statusLevels.reduce((map, level) => map.set(level.id, level.code), new Map());

        this.items = incontinenceResponse.results.map((item) => {
          return {
            ...item,
            statusLevelCode: statusLevelMap.get(item.statusLevelId),
          };
        });
      } catch (error) {
        this.error = error;
        this.showWaitModal = false;
      }
    },
  },
};
</script>

<style scoped>
.legend {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 80px;
  row-gap: 16px;
}
.legend-items-container {
  display: flex;
  list-style: none;
  flex-direction: row;
  align-items: baseline;
  column-gap: 24px;
}
</style>

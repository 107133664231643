import ActivityHome from './ActivityHome';

export default {
  moduleName: 'activity',
  routes: [
    {
      path: '/activity',
      name: 'ActivityHome',
      component: ActivityHome,
    },
  ],
};

import api from './baseApiService';

class DialogueService {
  async getDialogues() {
    return await api.get('dialogues');
  }

  async getDialogue(dialogueId) {
    return await api.get(`dialogue/${dialogueId}`);
  }

  async createDialogue(data) {
    return await api.post('dialogue/', data);
  }

  async updateDialogue(dialogueId, data) {
    return await api.put(`dialogue/${dialogueId}/`, data);
  }

  async deleteDialogue(dialogueId) {
    return await api.delete(`dialogue/${dialogueId}/delete/`);
  }

  async getDialogueActivity(dialogueId) {
    return await api.get(`dialogueactivity/${dialogueId}`);
  }

  async updatePatientDialogueActivities(patientId, data) {
    return await api.put(`patientdialogueactivities/patient/${patientId}/`, data);
  }
}

export default new DialogueService();

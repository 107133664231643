<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />
    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent :max-width="computedMaxWidth">
      <v-card>
        <ModalTitle :title="title" />
        <WaitModal :show="showWaitModal || IOTTypesLoading" />
        <v-tabs v-model="selectedActivityTab" class="px-6" background-color="transparent">
          <v-tab> {{ $t('activity') }} </v-tab>
          <v-tab v-show="prescriptiveLearningActivitySelected"> {{ $t('learning') }} </v-tab>
          <v-tab v-show="alertsTabVisible">
            {{ $t('threshold') + ` (${alertsCount})` }}
          </v-tab>
          <v-tab v-show="dialoguesTabVisible"> {{ $t('dialogues') + ` (${formData.dialogues.length})` }} </v-tab>
          <v-tab v-show="triggersTabVisible"> {{ $t('triggers') + ` (${formData.triggers.length})` }} </v-tab>
          <v-tab v-show="alertsDialogueTabVisible">
            {{ $t('alertConfiguration') + ` (${alertsCount})` }}
          </v-tab>

          <!-- <v-tab v-show="vmTriggersTabVisible"
            >{{ $t('vocalMessagesTriggers') + ` (${formData.vocalMessagesTriggers.length})` }}
          </v-tab> -->
          <v-tab v-show="appointmentTabVisible"> {{ $t('appointment') }} </v-tab>
        </v-tabs>
        <v-card-text>
          <v-tabs-items v-model="selectedActivityTab" class="activity-tabs">
            <v-tab-item>
              <v-form ref="activityForm">
                <v-row class="mt-3">
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-autocomplete
                      ref="activityType"
                      :value="formData.activityType"
                      :items="activityTypes"
                      :label="$t('activityType')"
                      item-text="name"
                      item-value="id"
                      filled
                      :rules="[validationRules.required]"
                      class="required-indicator"
                      :disabled="activityId != null"
                      @input="activityTypeChange"
                    />

                    <v-text-field
                      ref="activityName"
                      v-model="formData.name"
                      filled
                      :label="$t('name')"
                      :rules="[validationRules.required]"
                      class="required-indicator"
                    />

                    <v-textarea
                      ref="description"
                      v-model="formData.description"
                      height="135"
                      :label="$t('description')"
                      filled
                      :rules="[validationRules.required]"
                      class="required-indicator"
                    />

                    <v-text-field
                      v-if="!isAppointmentActivity && !videoCallActivitySelected"
                      ref="activityDisplayName"
                      v-model="formData.displayName"
                      filled
                      :label="$t('activityDisplayName')"
                      :rules="[validationRules.required]"
                      class="required-indicator"
                    />

                    <!-- <v-select
                      ref="vocalMessages"
                      v-model="formData.vocalMessagesSelected"
                      :items="vocalMessages"
                      :label="$t('vocalMessages')"
                      item-text="name"
                      item-value="id"
                      :no-data-text="!formData.iotDeviceType ? $t('iotDeviceTypeRequired') : $t('noDataAvailable')"
                      multiple
                      filled
                    /> -->

                    <v-row>
                      <!-- <v-col cols="auto">
                        <v-checkbox ref="checkActive" v-model="formData.active" :label="$t('active')" hide-details />
                      </v-col> -->
                      <!-- <v-col cols="auto">
                        <v-checkbox
                          ref="checkContinous"
                          v-model="formData.continous"
                          :disabled="iotDeviceContinous"
                          :label="$t('continous')"
                        />
                      </v-col> -->
                      <v-col cols="auto">
                        <v-checkbox
                          ref="checkNormative"
                          v-model="formData.normative"
                          :label="$t('normative')"
                          :disabled="!$can(permissions.actions.manage, permissions.subjects.normative)"
                          @change="checkNonNormativeStuff"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="activityId != null" :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'">
                    <detail-field
                      v-if="activityId != null"
                      :id="getIdByName('createdDate')"
                      ref="createdDate"
                      v-model="formData.createdDate"
                      :label="$t('createdDate')"
                    />

                    <detail-field
                      v-if="activityId != null"
                      :id="getIdByName('createdBy')"
                      ref="createdBy"
                      v-model="formData.createdBy"
                      :label="$t('createdBy')"
                    />

                    <detail-field
                      v-if="activityId != null"
                      :id="getIdByName('modifiedDate')"
                      ref="modifiedDate"
                      v-model="formData.modifiedDate"
                      :label="$t('modifiedDate')"
                    />

                    <detail-field
                      v-if="activityId != null"
                      :id="getIdByName('modifiedBy')"
                      ref="modifiedBy"
                      v-model="formData.modifiedBy"
                      :label="$t('modifiedBy')"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <RequiredFieldsLegend />
            </v-tab-item>

            <v-tab-item class="pt-4">
              <ActivityPrescriptiveLearning
                ref="activityPrescriptiveLearning"
                :prescriptive-videos="prescriptiveVideos"
                :selected-prescriptive-video-id="formData.selectedPrescriptiveVideoId"
                @setPrescriptiveVideoId="setPrescriptiveVideoId"
              />
            </v-tab-item>

            <v-tab-item eager class="pt-4">
              <WaitModal :show="alertFieldsByActivityTypeLoading" />
              <div v-if="activityAlertFields">
                <v-form ref="activityAlertForm">
                  <div v-for="(alert, index) in formData.alerts" :key="alert.internalId">
                    <v-row>
                      <v-col cols="auto">
                        <div class="pt-6">
                          <span class="font-weight-bold">{{ index + 1 }}.</span>
                        </div>
                      </v-col>
                      <v-col>
                        <v-row dense>
                          <v-col
                            v-if="activityAlertFields.monitoringValue"
                            :cols="alertGridSizes.monitoringValue.cols"
                            :md="alertGridSizes.monitoringValue.md"
                            :lg="alertGridSizes.monitoringValue.lg"
                          >
                            <v-select
                              ref="parametersType"
                              v-model="alert.monitoringValue"
                              :label="$t('parametersType')"
                              :items="activityAlertFields.monitoringValue"
                              filled
                              :rules="[validationRules.required]"
                              class="required-indicator"
                            />
                          </v-col>
                          <v-col
                            v-if="activityAlertFields.type"
                            :cols="alertGridSizes.type.cols"
                            :md="alertGridSizes.type.md"
                            :lg="alertGridSizes.type.lg"
                          >
                            <v-select
                              ref="alertType"
                              v-model="alert.type"
                              :label="$t('alertType')"
                              :items="activityAlertFields.type"
                              filled
                              :rules="[validationRules.required]"
                              class="required-indicator"
                              @change="changeAlertType(alert)"
                            />
                          </v-col>
                          <v-col
                            v-if="activityAlertFields.unitType && !(alert.type === 'nottaken')"
                            :cols="alertGridSizes.threshold.cols"
                            :md="alertGridSizes.threshold.md"
                            :lg="alertGridSizes.threshold.lg"
                          >
                            <v-text-field
                              ref="thresholdText"
                              v-model.number="alert.threshold"
                              filled
                              :label="$t('thresholdText')"
                              class="required-indicator"
                              :rules="[
                                validationRules.required,
                                validationRules.inRange(1, alert.type === 'variation' ? 100 : 500),
                              ]"
                              type="number"
                              hide-spin-buttons
                              max="200"
                              :suffix="getSuffix(alert.unitType, activityAlertFields.unitType)"
                              :append-icon="
                                activityAlertFields.unitType.length > 2 ||
                                (alert.type === 'variation' && alert.monitoringValue !== 'SAT')
                                  ? 'mdi-autorenew'
                                  : undefined
                              "
                              @click:append="toggleUnitType(alert)"
                            />
                          </v-col>
                          <v-col
                            v-if="activityAlertFields.lengthType"
                            :cols="alertGridSizes.length.cols"
                            :md="alertGridSizes.length.md"
                            :lg="alertGridSizes.length.lg"
                          >
                            <v-text-field
                              v-if="alert.type == 'variation'"
                              ref="dateLimitText"
                              v-model.number="alert.length"
                              type="number"
                              :rules="[validationRules.required, validationRules.inRange(1, 50)]"
                              filled
                              hide-spin-buttons
                              :label="$t('dateLimitText')"
                              class="required-indicator"
                              max="31"
                              :suffix="getSuffix(alert.lengthType, activityAlertFields.lengthType)"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col
                            v-if="activityAlertFields.lengthType"
                            :cols="alertGridSizes.length.cols"
                            :md="alertGridSizes.length.md"
                            :lg="alertGridSizes.period.lg"
                          >
                            <v-select
                              v-if="alert.type == 'variation'"
                              ref="filterrange"
                              v-model="alert.periods"
                              :label="$t('filterrange')"
                              :items="activityAlertFields.periods"
                              filled
                              :rules="[validationRules.required]"
                              class="required-indicator"
                            />
                          </v-col>
                          <v-col
                            v-if="activityAlertFields.recipients"
                            :cols="alertGridSizes.recipients.cols"
                            :md="alertGridSizes.recipients.md"
                            :lg="alertGridSizes.recipients.lg"
                          >
                            <v-select
                              ref="recipients"
                              v-model="alert.recipients"
                              :items="activityAlertFields.recipients"
                              :label="$t('addNotification')"
                              filled
                              small-chips
                              clearable
                              deletable-chips
                              multiple
                            />
                          </v-col>
                        </v-row>

                        <v-row v-if="isActivityInMonitoringRealtime" dense class="mt-0">
                          <v-col>
                            <v-checkbox
                              ref="isPriorityAlert"
                              v-model="alert.isPriorityAlert"
                              class="mt-0 mb-4"
                              :label="$t('priorityAlert')"
                              hide-details
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="auto">
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-btn
                              color="primary"
                              icon
                              :disabled="alertsCount <= 1"
                              @click="deleteAlert(alert)"
                              v-on="on"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          {{ $t('delete') }}
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-divider v-if="index < alertsCount - 1" class="mb-6" />
                  </div>
                </v-form>
                <RequiredFieldsLegend />
              </div>
            </v-tab-item>
            <v-tab-item>
              <activity-dialogue
                :columns-assigned="dialogueColumns"
                :columns-available="allDialogueColumns"
                :all-items="allDialogues"
                :assigned-items.sync="formData.dialogues"
                :available-title="$t('availableDialogues')"
                :assigned-title="$t('currentActivityDialogues')"
                :data-loading="allDialoguesLoading"
              >
              </activity-dialogue>
            </v-tab-item>
            <v-tab-item eager class="pt-4">
              <v-form ref="triggerForm">
                <div v-for="(trigger, index) in formData.triggers" :key="index">
                  <v-row>
                    <v-col cols="auto">
                      <div class="pt-6">
                        <span class="font-weight-bold">{{ index + 1 }}.</span>
                      </div>
                    </v-col>
                    <v-col cols="auto">
                      <DateTimePickerField
                        ref="triggerTime"
                        v-model="trigger.startTime"
                        :label="$t('timeOnly')"
                        required
                        max="200"
                        picker-type="time"
                      />
                    </v-col>
                    <!-- <v-col cols="auto">
                      <v-text-field
                        ref="triggerRepeatMinute"
                        v-model.number="trigger.repeatMinute"
                        filled
                        :label="$t('repeatMinute')"
                        type="number"
                        hide-spin-buttons
                        max="200"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-text-field
                        ref="triggerRepeatCount"
                        v-model.number="trigger.repeatCount"
                        filled
                        :label="$t('repeatCount')"
                        type="number"
                        hide-spin-buttons
                        max="200"
                      />
                    </v-col> -->
                    <v-col cols="auto">
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn color="primary" icon @click="deleteTrigger(trigger)" v-on="on">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        {{ $t('delete') }}
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
              <RequiredFieldsLegend />
            </v-tab-item>
            <v-tab-item eager class="pt-4">
              <WaitModal :show="alertFieldsByActivityTypeLoading" />
              <div v-if="activityAlertFields && dialoguesActivitySelected && !pictureActivitySelected">
                <v-form ref="configurationAlertDialogueForm">
                  <div v-for="(alert, index) in formData.alerts" :key="alert.internalId">
                    <v-row>
                      <v-col cols="auto">
                        <div class="pt-6">
                          <span class="font-weight-bold">{{ index + 1 }}.</span>
                        </div>
                      </v-col>
                      <v-col>
                        <v-row dense>
                          <v-col
                            v-if="activityAlertFields.monitoringValue"
                            :cols="alertGridSizes.monitoringValue.cols"
                            :md="alertGridSizes.monitoringValue.md"
                            :lg="alertGridSizes.monitoringValue.lg"
                          >
                            <v-select
                              ref="dialogueAlert"
                              v-model="alert.dialogueId"
                              :label="$t('dialogue')"
                              :items="formData.dialogues"
                              item-text="dialogueName"
                              item-value="dialogueId"
                              filled
                              :rules="[validationRules.required]"
                              class="required-indicator"
                            />
                          </v-col>
                          <v-col
                            v-if="activityAlertFields.type"
                            :cols="alertGridSizes.type.cols"
                            :md="alertGridSizes.type.md"
                            :lg="alertGridSizes.type.lg"
                          >
                            <v-select
                              ref="alertType"
                              v-model="alert.type"
                              :label="$t('alertType')"
                              :items="activityAlertFields.type"
                              filled
                              :rules="[validationRules.required]"
                              class="required-indicator"
                            />
                          </v-col>
                          <v-col
                            v-if="activityAlertFields.recipients"
                            :cols="alertGridSizes.recipients.cols"
                            :md="alertGridSizes.recipients.md"
                            :lg="alertGridSizes.recipients.lg"
                          >
                            <v-select
                              ref="recipients"
                              v-model="alert.recipients"
                              :items="activityAlertFields.recipients"
                              :label="$t('addNotification')"
                              filled
                              small-chips
                              deletable-chips
                              multiple
                              :rules="[validationRules.required]"
                              class="required-indicator"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="auto">
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-btn color="primary" icon @click="deleteAlert(alert)" v-on="on">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          {{ $t('delete') }}
                        </v-tooltip>
                      </v-col>
                    </v-row>

                    <v-divider v-if="index < alertsCount - 1" class="mb-6" />
                  </div>
                </v-form>
                <RequiredFieldsLegend />
              </div>
            </v-tab-item>

            <v-tab-item eager class="pt-4">
              <v-form ref="appointmentForm">
                <v-row>
                  <v-col>
                    <v-textarea
                      ref="appointmentDescription"
                      v-model="formData.appointment.description"
                      height="135"
                      :label="$t('descriptionAndInstructions')"
                      filled
                      :rules="[validationRules.required]"
                      class="required-indicator"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="auto">
                    <v-select
                      ref="appointmentReminder"
                      v-model="formData.appointment.reminders"
                      :label="$t('appointmentReminder')"
                      :items="appointmentReminders"
                      filled
                      multiple
                      small-chips
                      deletable-chips
                    />
                  </v-col>
                </v-row>
              </v-form>
              <RequiredFieldsLegend />
            </v-tab-item>

            <v-tab-item class="pt-4">
              <v-form ref="vocalMessagesTriggersForm">
                <div v-for="(vocalMessage, index) in formData.vocalMessagesTriggers" :key="index">
                  <v-row>
                    <v-col cols="auto">
                      <div class="pt-6">
                        <span class="font-weight-bold">{{ index + 1 }}</span>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        ref="vmTrigger"
                        v-model="vocalMessage.vocalMessageId"
                        :label="$t('vocalMessage')"
                        :items="
                          vocalMessages
                            ? vocalMessages.filter((val) => formData.vocalMessagesSelected.includes(val.id))
                            : []
                        "
                        item-text="name"
                        item-value="id"
                        filled
                        :rules="[validationRules.required]"
                        class="required-indicator"
                      />
                    </v-col>

                    <v-col cols="auto">
                      <DateTimePickerField
                        ref="vmTriggerTime"
                        v-model="vocalMessage.triggers.startTime"
                        :label="$t('timeOnly')"
                        required
                        max="200"
                        picker-type="time"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn color="primary" icon @click="deleteVocalMessageTrigger(vocalMessage)" v-on="on">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        {{ $t('delete') }}
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            v-if="selectedActivityTab === 2 && !prescriptiveLearningActivitySelected"
            color="primary"
            @click="addAlert"
            >{{ $t('addAlert') }}</v-btn
          >
          <v-btn v-if="selectedActivityTab === 4" color="primary" @click="addTrigger">{{ $t('addTrigger') }}</v-btn>
          <v-btn v-if="selectedActivityTab === 5" color="primary" @click="addAlert">{{
            $t('addAlertConfiguration')
          }}</v-btn>

          <!-- <v-btn v-if="selectedActivityTab === 7" color="primary" @click="addVocalMessageTrigger">{{
            $t('addVocalMessagesTrigger')
          }}</v-btn> -->

          <v-spacer></v-spacer>
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>

          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="createOrUpdateAction"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationModal
      :question="$t(showConfirmationModalQuestion)"
      :show="showActivityTypeChangeAlert || showNormativeAlert"
      :title="$t(showConfirmationModalTitle)"
      @cancel="cancelConfirmationModal(showActivityTypeChangeAlert, showNormativeAlert)"
      @ok="acceptConfirmationModal(showActivityTypeChangeAlert, showNormativeAlert)"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import virtuoseMixin from '@/virtuoseMixin';
import permissionMixin from '@/auth/permissionMixin';
import activityService from '@/services/activityService';
import iotService from '@/services/iotService';
//import vocalMessageService from '@/services/vocalMessageService';
import ActivityDialogue from './ActivityDialogue.vue';
import SaveButton from '@/components/SaveButton.vue';
import DateTimePickerField from '@/components/DateTimePickerField.vue';
import validationRulesMixin from '@/validationRulesMixin';
import { ActivityTypes } from '@/components/PatientMonitoring/constants';
import ActivityPrescriptiveLearning from './ActivityPrescriptiveLearning.vue';

export default {
  name: 'ActivityModal',

  components: { ActivityDialogue, ActivityPrescriptiveLearning, SaveButton, DateTimePickerField },
  mixins: [translation, accessibility, virtuoseMixin, validationRulesMixin, permissionMixin],

  props: {
    activityId: {
      type: Number,
      required: false,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showWaitModal: false,
      title: '',
      selectedActivityTab: 0,
      error: null,
      isProcessing: false,
      isLoading: false,
      formData: {
        name: '',
        displayName: '',
        activityType: null,
        description: '',
        normative: false,
        active: false,
        continous: false,
        alerts: [],
        dialogues: [],
        triggers: [],
        vocalMessagesSelected: [],
        vocalMessagesTriggers: [],
        appointment: {},
        selectedPrescriptiveVideoId: null,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
      },
      originalFormData: {},
      prescriptiveVideos: [],
      parameters: null,
      cachedAlertFieldsByActivityType: [],
      activityTypes: [],
      vocalMessages: [],
      allVocalMessages: [],
      IOTTypes: [],
      showConfirmationModalTitle: '',
      showConfirmationModalQuestion: '',
      showActivityTypeChangeAlert: false,
      showNormativeAlert: false,
      oldActivityType: null,
      allDialogues: [],
      allDialoguesOriginal: [],
      allDialoguesLoading: false,
      alertFieldsByActivityTypeLoading: false,
      IOTTypesLoading: false,
      appointmentReminders: [
        { text: this.$t('reminder1h'), value: 1 },
        { text: this.$t('reminder4h'), value: 4 },
        { text: this.$t('reminder24h'), value: 24 },
      ],
    };
  },

  computed: {
    iotDeviceContinous: function () {
      return this.IOTTypes.some((iotType) => iotType.code != 'VA');
    },
    canActivityBeMonitoredByVA: function () {
      return this.IOTTypes.some((iotType) => iotType.code === 'VA');
    },
    dialogueColumns: function () {
      return [
        {
          text: this.$t('dialogueOrder'),
          value: 'dialogueOrder',
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t('dialogueName'),
          value: 'dialogueName',
          filterable: true,
        },
        {
          text: this.$t('normative'),
          value: 'normative',
          filterable: true,
        },
      ];
    },
    allDialogueColumns: function () {
      return [
        {
          text: 'dialogueName',
          value: 'dialogueName',
          filterable: true,
        },
        {
          text: 'normative',
          value: 'dialogueNormative',
          filterable: true,
        },
      ];
    },
    activityAlertFields() {
      if (this.formData.activityType === null || this.isAppointmentActivity) {
        return null;
      }

      return this.cachedAlertFieldsByActivityType.find((x) => x.id === this.formData.activityType)?.fields ?? null;
    },
    computedMaxWidth() {
      if (this.selectedActivityTab === 0) {
        return this.activityId != null ? '1200px' : '1000px';
      } else {
        return '1200px';
      }
    },
    alertGridSizes() {
      let nbItemsVisible =
        (this.activityAlertFields?.monitoringValue ? 1 : 0) +
        (this.activityAlertFields?.type ? 1 : 0) +
        (this.activityAlertFields?.unitType ? 1 : 0) +
        (this.activityAlertFields?.periods ? 1 : 0) +
        (this.activityAlertFields?.lengthType ? 1 : 0);

      let values = {};
      let periodsValues = {};
      let monitoringValues = {};
      let typeValues = {};
      let dayValues = {};
      let thresholdValues = {};

      switch (nbItemsVisible) {
        case 5:
          periodsValues = {
            cols: 12,
            md: 6,
            lg: 2,
          };
          monitoringValues = {
            cols: 12,
            md: 6,
            lg: 3,
          };
          typeValues = {
            cols: 12,
            md: 6,
            lg: 2,
          };
          dayValues = {
            cols: 12,
            md: 6,
            lg: 2,
          };
          thresholdValues = {
            cols: 12,
            md: 6,
            lg: 3,
          };
          values = {
            cols: 12,
            md: 6,
            lg: 2,
          };
          break;
        case 4:
          values = {
            cols: 12,
            md: 6,
            lg: 3,
          };
          break;
        case 3:
          typeValues = {
            cols: 12,
            md: 4,
            lg: 4,
          };
          values = {
            cols: 12,
            md: 4,
            lg: 4,
          };
          break;
        case 2:
          typeValues = {
            cols: 12,
            md: 6,
            lg: 6,
          };
          values = {
            cols: 12,
            md: 6,
            lg: 6,
          };
          break;
        default:
          values = {
            cols: 12,
            md: 12,
            lg: 12,
          };
          break;
      }

      if (nbItemsVisible !== 5) {
        (periodsValues & monitoringValues & typeValues & dayValues & thresholdValues) === values;
      }

      let sizes = {
        monitoringValue: monitoringValues,
        type: typeValues,
        threshold: thresholdValues,
        length: dayValues,
        period: periodsValues,
        recipients: {
          cols: 12,
          md: 12,
          lg: 12,
        },
      };
      return sizes;
    },
    alertsTabVisible() {
      return (
        this.formData.activityType &&
        !this.videoCallActivitySelected &&
        (!this.dialoguesActivitySelected ||
          this.prescriptiveLearningActivitySelected ||
          this.pictureActivitySelected) &&
        !this.isAppointmentActivity &&
        this.formData.alerts != null
      );
    },
    dialoguesTabVisible() {
      return this.dialoguesActivitySelected;
    },
    triggersTabVisible() {
      return this.dialoguesActivitySelected || this.pictureActivitySelected;
    },
    vmTriggersTabVisible() {
      return this.formData.vocalMessagesSelected?.length > 0 && !this.isAppointmentActivity;
    },
    alertsDialogueTabVisible() {
      return (
        this.formData.activityType &&
        !this.isAppointmentActivity &&
        this.formData.alerts != null &&
        this.dialoguesActivitySelected &&
        !this.prescriptiveLearningActivitySelected &&
        !this.pictureActivitySelected
      );
    },
    prescriptiveLearningActivitySelected() {
      return this.activityType?.code === ActivityTypes.PRE;
    },
    dialoguesActivitySelected() {
      return this.activityTypes?.some((x) => x.id === this.formData.activityType && x.code === ActivityTypes.DLG);
    },
    appointmentTabVisible() {
      return this.formData.activityType && this.isAppointmentActivity;
    },
    isAppointmentActivity() {
      return this.activityType?.code === ActivityTypes.APPT;
    },
    activityType() {
      return this.activityTypes?.find((x) => x.id === this.formData.activityType);
    },
    alertsCount() {
      return (this.formData.alerts && this.formData.alerts.length) || 0;
    },
    videoCallActivitySelected() {
      return this.activityTypes?.some((x) => x.id === this.formData.activityType && x.code === ActivityTypes.VID);
    },
    pictureActivitySelected() {
      return this.activityTypes?.some((x) => x.id === this.formData.activityType && x.code === ActivityTypes.PHT);
    },
    isActivityInMonitoringRealtime() {
      return [
        ActivityTypes.APR,
        ActivityTypes.CFR,
        ActivityTypes.SAT,
        ActivityTypes.RES,
        ActivityTypes.BDT,
        ActivityTypes.GLY,
        ActivityTypes.FDE,
        ActivityTypes.INC,
      ].includes(this.activityType?.code);
    },
  },

  watch: {
    show: function () {
      if (this.show) {
        this.init();
        this.getAllSelects();
        this.getAvailableDialoguesList();
        if (this.activityId != null) {
          this.editClicked();
        }
      }
    },
    'formData.vocalMessagesSelected': function () {
      if (this.formData.vocalMessagesSelected?.length === 0) {
        this.formData.vocalMessagesTriggers = [];
      }
    },
    'formData.activityType': async function () {
      if (this.formData.activityType !== null && !this.videoCallActivitySelected) {
        this.getElementsOnActivityType();
      }

      if (this.iotDeviceContinous) {
        this.formData.continous = true;
      }
    },
    activityAlertFields() {
      if (this.activityAlertFields) {
        this.formData.alerts?.filter((x) => !x.initialized).forEach((alert) => this.setAlertDefaultValues(alert));
      }
    },
  },

  methods: {
    init: function () {
      this.title = this.$t('createNewActivity');
      this.selectedActivityTab = 0;
      this.parameters = null;
      this.IOTTypes = [];
      this.allDialogues = [];
      this.allDialoguesOriginal = [];
      this.vocalMessages = [];
      this.allVocalMessages = [];
      this.formData = {
        name: '',
        displayName: '',
        activityType: null,
        description: '',
        normative: false,
        active: false,
        continous: false,
        alerts: [],
        dialogues: [],
        triggers: [],
        vocalMessagesSelected: [],
        vocalMessagesTriggers: [],
        appointment: {},
        selectedPrescriptiveVideoId: null,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
      };

      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
    },

    editClicked: function () {
      this.title = this.$t('editActivity');
      this.showWaitModal = true;

      let successCallback = function (response) {
        this.showWaitModal = false;
        let activity = response.data;

        this.formData.name = activity.name;
        this.formData.displayName = activity.displayName;
        this.formData.description = activity.description;
        this.formData.normative = activity.normative;
        this.formData.active = activity.active;
        this.formData.continous = activity.continous;
        this.formData.activityType = activity.activityType.id;
        this.formData.createdDate = activity.created_at;
        this.formData.createdBy = activity.created_by.username;
        this.formData.modifiedDate = activity.updated_at;
        this.formData.modifiedBy = activity.updated_by?.username;
        this.formData.vocalMessagesSelected =
          activity.vocalMessages?.map((vocalMessagesActivity) => vocalMessagesActivity.id) ?? [];

        this.formData.vocalMessagesTriggers = [];

        activity.vocalMessages?.forEach((vm) => {
          if (vm.vocalMessagesTriggers?.length > 0) {
            vm.vocalMessagesTriggers?.forEach((vmt) => {
              this.formData.vocalMessagesTriggers.push({
                startTimeUneditable: true,
                vocalMessageId: vm.id,
                triggers: {
                  startTime: vmt.startTime,
                },
              });
            });
          }
        });

        if (this.formData.normative) {
          this.allDialogues = this.allDialogues.filter((dialogue) => dialogue.dialogueNormative);
        }

        this.formData.dialogues =
          activity.dialogues?.map((x) => {
            return {
              dialogueId: x.id,
              dialogueName: x.dialogueName,
              dialogueOrder: x.dialogueOrder,
              dialogueNormative: x.dialogueNormative,
            };
          }) ?? [];

        this.formData.triggers =
          activity.triggers
            ?.map((x) => {
              return {
                startTime: x.startTime,
                repeatMinute: x.repeatMinute,
                repeatCount: x.repeatCount,
              };
            })
            .sort((a, b) => a.startTime.localeCompare(b.startTime)) ?? [];

        this.formData.selectedPrescriptiveVideoId = activity.parameters?.prescriptiveVideoId;

        this.formData.alerts =
          activity.parameters.alerts?.map((x) => {
            return {
              internalId: this.getNextInternalAlertId(),
              alertId: x.alertId ?? uuidv4(),
              initialized: true,
              monitoringValue: x.monitoringValue ?? null,
              type: x.type ?? null,
              threshold: x.threshold ?? null,
              lengthType: x.lengthType ?? null,
              length: x.length ?? null,
              periods: x.periods ?? null,
              unitType: x.unitType ?? null,
              dialogueId: x.dialogueId ?? null,
              recipients: x.recipients ?? [],
              isPriorityAlert: this.isActivityInMonitoringRealtime ? x.isPriorityAlert ?? false : null,
            };
          }) ?? [];

        this.formData.appointment = {
          description: activity.parameters?.appointment?.description ?? '',
          reminders: activity.parameters?.appointment?.reminders ?? [],
        };

        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      };

      this.getActivityById(this.activityId, successCallback, this.defaultErrorCallBack);
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
      this.allDialoguesLoading = false;
      this.isProcessing = false;
    },

    createOrUpdateAction: function () {
      const selectedActivityTab = this.validateAllFormsAndGetSelectedActivityTab();
      if (typeof selectedActivityTab === 'number') {
        this.selectedActivityTab = selectedActivityTab;
        return;
      }
      if (this.isProcessing) return;

      this.showWaitModal = true;
      this.isProcessing = true;

      if (this.isAppointmentActivity) {
        this.formData.alerts = null;
        if (this.activityId == null) {
          this.addTrigger();
        }
      }

      if (this.videoCallActivitySelected) {
        this.parameters = {};
      } else {
        const alertsToSave = this.formData.alerts ? this.formData.alerts.map(this.buildAlertObject) : [];

        if (
          (!this.dialoguesActivitySelected || this.prescriptiveLearningActivitySelected) &&
          !this.isAppointmentActivity &&
          !this.pictureActivitySelected
        ) {
          this.formData.dialogues = [];
          this.formData.triggers = [];
        }

        this.parameters = this.isAppointmentActivity
          ? { appointment: this.formData.appointment }
          : { alerts: alertsToSave };
      }

      if (this.prescriptiveLearningActivitySelected) {
        this.parameters.prescriptiveVideoId = this.formData.selectedPrescriptiveVideoId;
      }

      let data = {
        name: this.formData.name,
        displayName: this.videoCallActivitySelected || this.isAppointmentActivity ? null : this.formData.displayName,
        activityTypeId: this.formData.activityType,
        description: this.formData.description,
        active: this.formData.active,
        continous: this.formData.continous,
        normative: this.formData.normative,
        dialogues: this.formData.dialogues,
        triggers: this.formData.triggers,
        parameters: this.parameters,
        vocalMessages: this.formData.vocalMessagesSelected.map((vocalMessageId) => {
          let vmTriggers = this.formData.vocalMessagesTriggers
            .filter((y) => vocalMessageId === y.vocalMessageId)
            .map((x) => x.triggers);

          let distinctTimes = Array.from(new Set(vmTriggers.map((x) => x.startTime)));
          return {
            vocalMessageId: vocalMessageId,
            triggers: distinctTimes.map((x) => {
              return { startTime: x };
            }),
          };
        }),
      };

      const successCallback = function () {
        this.closeDialog();
        this.$emit('refresh');
        this.showWaitModal = false;
        this.isProcessing = false;
      };

      if (this.activityId == null) {
        this.createActivity(data, successCallback, this.defaultErrorCallBack);
      } else {
        this.updateActivity(this.activityId, data, successCallback, this.defaultErrorCallBack);
      }
    },

    // async getVocalMessageOnIotType() {
    //   try {
    //     let iotType = this.formData.iotDeviceType;
    //     let vocalMessages = await vocalMessageService.getVocalMessagesByIotType(this.formData.iotDeviceType);

    //     if (iotType === this.formData.iotDeviceType) {
    //       this.allVocalMessages = vocalMessages;
    //       if (this.formData.normative) {
    //         this.vocalMessages = vocalMessages.filter((vm) => vm.normative);
    //       } else {
    //         this.vocalMessages = vocalMessages;
    //       }
    //     }
    //   } catch (error) {
    //     this.error = error;
    //   }
    // },

    addAlert() {
      let newAlert = {
        // the internalId is only used for the key :attribute in the template
        internalId: this.getNextInternalAlertId(),

        // indicates wheather the default values has been set in this alert
        initialized: false,

        alertId: uuidv4(),
        monitoringValue: null,
        type: null,
        threshold: null,
        lengthType: null,
        length: null,
        periods: null,
        unitType: null,
        dialogueId: null,
        isPriorityAlert: null,
        recipients: [],
      };
      this.setAlertDefaultValues(newAlert);
      this.formData.alerts.push(newAlert);
    },

    addTrigger() {
      let newTrigger = {
        startTime: '00:00:00',
        repeatMinute: null,
        repeatCount: null,
      };

      this.formData.triggers.push(newTrigger);
    },

    addVocalMessageTrigger() {
      let trigger = {
        vocalMessageId: null,
        triggers: {
          startTime: '00:00:00',
        },
      };

      this.formData.vocalMessagesTriggers.push(trigger);
    },

    deleteAlert(alert) {
      let indexToRemove = this.formData.alerts.indexOf(alert);
      this.formData.alerts.splice(indexToRemove, 1);
    },

    deleteTrigger(trigger) {
      let indexToRemove = this.formData.triggers.indexOf(trigger);
      this.formData.triggers.splice(indexToRemove, 1);
    },

    deleteVocalMessageTrigger(vocalMessage) {
      let indexToRemove = this.formData.vocalMessagesTriggers?.indexOf(vocalMessage);
      this.formData.vocalMessagesTriggers?.splice(indexToRemove, 1);
    },

    getAvailableDialoguesList: function () {
      this.isLoading = true;

      const DialoguesSuccess = function (response) {
        this.showWaitModal = false;
        let dialogues = response.data;

        this.allDialogues = dialogues.map((item) => {
          return {
            dialogueId: item.id,
            dialogueName: item.name,
            dialogueNormative: item.normative,
          };
        });

        this.allDialoguesOriginal = this.allDialogues;
        this.allDialoguesLoading = false;
      };

      this.allDialoguesLoading = true;
      this.getAvailableDialogues(DialoguesSuccess, this.defaultErrorCallBack);
      this.isLoading = false;
    },

    getAllSelects: async function () {
      try {
        this.activityTypes = await activityService.getActivityTypes();
      } catch (error) {
        this.error = error;
      }
    },

    async getElementsOnActivityType() {
      if (this.isAppointmentActivity) return;
      let vm = this;
      this.isLoading = true;

      try {
        this.IOTTypesLoading = true;
        let iotTypes = await iotService.getIOTDeviceTypesByActivityType(this.formData.activityType);
        this.IOTTypes = iotTypes.map((eachIOTType) => eachIOTType);
      } catch (error) {
        this.error = error;
      } finally {
        this.IOTTypesLoading = false;
      }

      if (this.prescriptiveLearningActivitySelected) {
        try {
          const prescriptiveVideos = await activityService.getPrescriptiveVideos();

          this.prescriptiveVideos = prescriptiveVideos?.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        } catch (error) {
          this.error = error;
        }
      }

      if (!vm.cachedAlertFieldsByActivityType.some((x) => x.id === this.formData.activityType)) {
        try {
          this.alertFieldsByActivityTypeLoading = true;
          let fieldData = await activityService.getActivityAlertFieldsByActivityType(this.formData.activityType);

          let getFieldValues = (items, key) => {
            if (!items) {
              return null;
            }
            return items.map((value) => {
              return {
                value: value,
                text: vm.$t(`${key}_${value}`),
              };
            });
          };

          let fields = {
            lengthType: getFieldValues(fieldData.lengthType, 'lengthType'),
            periods: getFieldValues(fieldData.periods, 'periods'),
            monitoringValue: getFieldValues(fieldData.monitoringValue, 'monitoringValue'),
            recipients: getFieldValues(fieldData.recipients, 'recipients'),
            type: getFieldValues(fieldData.type, 'type'),
            unitType: getFieldValues(fieldData.unitType, 'unitType'),
            dialogueId: getFieldValues(fieldData.dialogueId, 'dialogueId'),
          };

          vm.cachedAlertFieldsByActivityType.push({
            id: this.formData.activityType,
            fields: fields,
          });
        } catch (error) {
          this.error = error;
        } finally {
          this.alertFieldsByActivityTypeLoading = false;
        }
      }

      this.isLoading = false;
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },

    getNextInternalAlertId() {
      if (!this.nextInternalAlertId) {
        this.nextInternalAlertId = 1;
      }
      return this.nextInternalAlertId++;
    },
    async activityTypeChange(newValue) {
      this.oldActivityType = this.formData.activityType;
      this.formData.activityType = newValue;

      // If users has alerts we must clear alerts when we change monitoring type
      let hasAlerts =
        this.formData.alerts.length > 1 ||
        this.formData.alerts.some((x) => x.threshold !== null || x.length !== null || x.recipients?.length > 0);

      if (hasAlerts) {
        this.showActivityTypeChangeAlert = true;
        this.showConfirmationModalTitle = 'resetAlertsTitle';
        this.showConfirmationModalQuestion = 'resetAlertsPrompt';
      } else if ((this.formData.dialogues.length > 0) & (this.formData.triggers.length > 0)) {
        this.showConfirmationModalTitle = 'resetAlertsDialoguesTriggersTitle';
        this.showConfirmationModalQuestion = 'resetAlertsDialoguesTriggersPrompt';
        this.showActivityTypeChangeAlert = true;
      } else if (this.formData.dialogues.length > 0) {
        this.showConfirmationModalTitle = 'resetAlertsDialoguesTitle';
        this.showConfirmationModalQuestion = 'resetAlertsDialoguesPrompt';
        this.showActivityTypeChangeAlert = true;
      } else if (this.formData.triggers.length > 0) {
        this.showConfirmationModalTitle = 'resetAlertsTriggersTitle';
        this.showConfirmationModalQuestion = 'resetAlertsTriggersPrompt';
        this.showActivityTypeChangeAlert = true;
      } else {
        // we reset the dialogues and triggers
        this.formData.dialogues = [];
        this.formData.triggers = [];
        // we reset the alerts in order to update their default values
        this.formData.alerts = [];
        if (!this.dialoguesActivitySelected || this.prescriptiveLearningActivitySelected) {
          this.addAlert();
        }
      }
    },
    acceptConfirmationModal(showMonitoringAlert, showNormativeAlert) {
      if (showMonitoringAlert) {
        this.showActivityTypeChangeAlert = false;
        this.oldActivityType = null;
        this.formData.alerts = [];
        this.formData.dialogues = [];
        this.formData.triggers = [];
        this.formData.appointment = {};
        if (!this.dialoguesActivitySelected || this.prescriptiveLearningActivitySelected) {
          this.addAlert();
        }
      } else if (showNormativeAlert && this.dialoguesTabVisible) {
        this.resetNormativeVocalMessagesDialogues();
      }
    },

    cancelConfirmationModal(showMonitoringAlert, showNormativeAlert) {
      if (showMonitoringAlert) {
        this.showActivityTypeChangeAlert = false;
        this.formData.activityType = this.oldActivityType;
      } else if (showNormativeAlert) {
        this.showNormativeAlert = false;
        this.formData.normative = false;
      }
    },

    checkNonNormativeStuff() {
      if (this.formData.normative) {
        let nonNormativeDialoguesSelected = this.formData.dialogues.some((dialogue) => !dialogue.dialogueNormative);

        let nonNormativeVMSelected = this.formData.vocalMessagesSelected.some((vmId) =>
          this.vocalMessages.some((vm) => !vm.normative && vmId === vm.id)
        );

        if (nonNormativeVMSelected || nonNormativeDialoguesSelected) {
          this.showConfirmationModalQuestion = 'changeNormativeDialogueQuestion';
          this.showConfirmationModalTitle = 'changeNormativeDialogueTitle';
          this.showNormativeAlert = true;
        } else if (this.dialoguesTabVisible) {
          this.resetNormativeVocalMessagesDialogues();
        }
      } else {
        this.vocalMessages = this.allVocalMessages;
        this.allDialogues = this.allDialoguesOriginal;
      }
    },

    resetNormativeVocalMessagesDialogues() {
      this.formData.vocalMessagesSelected = this.formData.vocalMessagesSelected.filter((vmId) =>
        this.vocalMessages.some((vm) => vm.id === vmId && vm.normative)
      );
      this.formData.dialogues = this.formData.dialogues.filter((dialogue) => dialogue.dialogueNormative);

      this.formData.alerts = this.formData.alerts.filter((alert) =>
        this.allDialogues.some((dialogue) => alert.dialogueId === dialogue.dialogueId && dialogue.dialogueNormative)
      );

      this.formData.vocalMessagesTriggers = this.formData.vocalMessagesTriggers.filter((vmt) =>
        this.vocalMessages.some((vm) => vm.id === vmt.vocalMessageId && vm.normative)
      );

      this.vocalMessages = this.vocalMessages.filter((vm) => vm.normative);
      this.allDialogues = this.allDialogues.filter((dialogue) => dialogue.dialogueNormative);

      this.showNormativeAlert = false;
    },

    setAlertDefaultValues(alert) {
      if (this.activityAlertFields) {
        if (this.activityAlertFields.monitoringValue?.length === 1) {
          alert.monitoringValue = this.activityAlertFields.monitoringValue[0].value;
        }
        if (this.activityAlertFields.type?.length === 1) {
          alert.type = this.activityAlertFields.type[0].value;
        }

        // Since there's no dropdown to select the unitType / lengthType (it's inline in the unit / length v-text),
        // we automatically select the first one (otherwise the UI would be too confusing)
        if (this.activityAlertFields.unitType?.length > 0) {
          alert.unitType = this.activityAlertFields.unitType[0].value;
        }
        if (this.activityAlertFields.lengthType?.length > 0) {
          alert.lengthType = this.activityAlertFields.lengthType[0].value;
        }

        if (this.activityAlertFields?.isPriorityAlert && this.isActivityInMonitoringRealtime) {
          alert.isPriorityAlert = this.activityAlertFields.isPriorityAlert;
        }

        alert.initialized = true;
      }
    },

    getSuffix(value, items) {
      if (value !== null && items) {
        return items?.find((x) => x.value === value)?.text;
      }
    },
    toggleUnitType(alert) {
      let idx = this.activityAlertFields.unitType.findIndex((x) => x.value === alert.unitType) + 1;
      if (idx === this.activityAlertFields.unitType.length) {
        idx = 0;
      }
      alert.unitType = this.activityAlertFields.unitType[idx].value;

      if (alert.unitType === 'percentageVariation' && alert.type !== 'variation') {
        alert.unitType = this.activityAlertFields.unitType[0].value;
      }
    },

    changeAlertType(alert) {
      if (alert.unitType === 'percentageVariation' && alert.type !== 'variation') {
        alert.unitType = this.activityAlertFields.unitType[0].value;
      }
    },

    setPrescriptiveVideoId: function (prescriptiveVideoId) {
      this.formData.selectedPrescriptiveVideoId = prescriptiveVideoId;
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
    validateAllFormsAndGetSelectedActivityTab: function () {
      let isActivityFormValid = this.$refs.activityForm.validate();

      let isPrescriptiveLearningFormValid = true;

      if (
        this.prescriptiveLearningActivitySelected &&
        this.$refs?.activityPrescriptiveLearning?.$refs?.prescriptiveVideos
      ) {
        isPrescriptiveLearningFormValid =
          this.$refs?.activityPrescriptiveLearning?.$refs?.prescriptiveVideos?.validate();
      }

      let isActivityAlertFormValid =
        !this.alertsTabVisible || !this.$refs.activityAlertForm || this.$refs.activityAlertForm.validate();

      let isTriggerFormValid = !this.triggersTabVisible || !this.$refs.triggerForm || this.$refs.triggerForm.validate();
      let isAlertDialogueFormValid =
        !this.dialoguesTabVisible ||
        !this.$refs.configurationAlertDialogueForm ||
        this.$refs.configurationAlertDialogueForm.validate();

      let isVocalMessagesTriggersFormValid =
        !this.vmTriggersTabVisible ||
        !this.$refs.vocalMessagesTriggersForm ||
        this.$refs.vocalMessagesTriggersForm.validate();

      let isAppointmentFormValid =
        !this.appointmentTabVisible || !this.$refs.appointmentForm || this.$refs.appointmentForm.validate();

      let selectedActivityTab = undefined;
      if (!isActivityFormValid) {
        selectedActivityTab = 0;
      } else if (!isPrescriptiveLearningFormValid) {
        selectedActivityTab = 1;
      } else if (!isActivityAlertFormValid) {
        selectedActivityTab = 2;
      } else if (!isTriggerFormValid) {
        selectedActivityTab = 4;
      } else if (!isAlertDialogueFormValid) {
        selectedActivityTab = 5;
      } else if (!isAppointmentFormValid) {
        selectedActivityTab = 6;
      } else if (!isVocalMessagesTriggersFormValid) {
        selectedActivityTab = 7;
      } else {
        selectedActivityTab = null;
      }
      return selectedActivityTab;
    },

    buildAlertObject: function (alertData) {
      let alert = {};

      if (
        !this.dialoguesActivitySelected ||
        this.prescriptiveLearningActivitySelected ||
        this.pictureActivitySelected
      ) {
        alert.monitoringValue = alertData.monitoringValue;
      }

      if (alertData.alertId != null) {
        alert.alertId = alertData.alertId;
      }

      if (alertData.type !== null) {
        alert.type = alertData.type;
      }

      if (alertData.threshold !== null) {
        alert.threshold = alertData.threshold;
      }

      if (alertData.lengthType !== null) {
        alert.lengthType = alertData.lengthType;
      }

      if (alertData.length !== null) {
        alert.length = alertData.length;
      }

      if (alertData.periods !== null) {
        alert.periods = alertData.periods;
      }

      if (alertData?.isPriorityAlert !== null && this.isActivityInMonitoringRealtime) {
        alert.isPriorityAlert = alertData.isPriorityAlert;
      }

      switch (alertData.type) {
        case 'nottaken':
          if (alert.lengthType) delete alert.lengthType;
          if (alert.unitType) delete alert.unitType;
          if (alert.length) delete alert.length;
          if (alert.threshold) delete alert.threshold;
          if (alert.periods) delete alert.periods;
          break;

        case 'abnormalvalue':
        case 'biggerThan':
        case 'lesserThan':
          if (alert.lengthType) delete alert.lengthType;
          if (alert.length) delete alert.length;
          if (alertData.unitType) alert.unitType = alertData.unitType;
          break;

        default:
          if (alertData.unitType) alert.unitType = alertData.unitType;
          break;
      }

      if (alertData.dialogueId !== null) {
        alert.dialogueId = alertData.dialogueId;
      }

      if (this.activityAlertFields?.recipients?.length > 0) {
        alert.recipients = alertData.recipients;
      }

      return alert;
    },
  },
};
</script>

<style scoped>
.activity-tabs.v-tabs-items {
  overflow: visible;
}
</style>

<template>
  <div>
    <div :class="{ 'pl-4': !$vuetify.breakpoint.xs }"></div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>

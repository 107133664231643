import DialogueHome from './DialogueHome';

export default {
  moduleName: 'dialogue',
  routes: [
    {
      path: '/dialogue',
      name: 'DialogueHome',
      component: DialogueHome,
    },
  ],
};

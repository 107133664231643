<template>
  <v-container>
    <HealthcareProfessionalGrid />
  </v-container>
</template>

<script>
import HealthcareProfessionalGrid from './HealthcareProfessionalGrid';
export default {
  name: 'HealthcareProfessionalHome',
  components: {
    HealthcareProfessionalGrid,
  },
  mixins: [],
  props: {},
  data() {
    return {};
  },
  created: function () {},
  methods: {},
};
</script>

<style scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"gridContent"},[_vm._l((_vm.pageItems),function(data,pageItemIndex){return _c('v-row',{key:pageItemIndex},[_c('v-col',[_c('v-card',{staticClass:"fill-height d-print-inline-flex",attrs:{"hover":"","min-height":"105"},on:{"click":function($event){return _vm.viewPatientIotRealtimeDetail(data)}}},[_c('div',{staticClass:"fill-height d-flex",class:{
            'ml-2': _vm.$vuetify.breakpoint.xl,
            'align-center': _vm.$vuetify.breakpoint.lgAndUp,
            'flex-column': _vm.$vuetify.breakpoint.mdAndDown,
          }},[_c('div',{staticClass:"d-flex align-center mr-4",class:_vm.$vuetify.breakpoint.lgAndUp ? 'width-120 ml-1' : ''},[_c('div',{staticClass:"d-flex align-center font-weight-bold",class:{
                'break-word': _vm.$vuetify.breakpoint.xl,
                'pt-3 pl-3': _vm.$vuetify.breakpoint.lgAndDown,
              }},[_vm._v(" "+_vm._s(`${data.patient.lastName} ${data.patient.firstName}`)+" ")])]),_c('div',{staticClass:"d-flex",class:{
              'flex-column': _vm.$vuetify.breakpoint.mdAndDown,
              'py-2': _vm.$vuetify.breakpoint.lgAndDown,
            }},[_c('div',{staticClass:"d-flex"},[(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-divider',{staticClass:"divider-height ma-auto",attrs:{"vertical":""}}):_vm._e(),_c('div',{staticClass:"mr-1",class:{
                  'd-flex flex-column justify-center': _vm.$vuetify.breakpoint.xl,
                  'ml-3 order-1 width-110': _vm.$vuetify.breakpoint.lgAndUp,
                  'order-2 ml-1': _vm.$vuetify.breakpoint.mdAndDown,
                }},[(data.lastVitalSignsSyncData)?_c('div',[(data.lastVitalSignsSyncData.lastDataDatetime)?_c('div',{staticClass:"d-flex align-center text--secondary text-body-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.getRelativeDate(data.lastVitalSignsSyncData.timeDifference))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getFormatDate(data.lastVitalSignsSyncData.lastDataDatetime)))])])],1):_vm._e(),(data.lastVitalSignsSyncData.lastDataDatetime)?_c('div',{staticClass:"d-flex align-center"},[(data.lastVitalSignsSyncData.iotDeviceTypeCode === _vm.iotDeviceTypeCodes.PATCH)?_c('img',{attrs:{"src":"/static/healthIcons/chest_monitor.svg","height":"19px","alt":"chest-monitor-icon"}}):_c('v-icon',{attrs:{"size":"19","color":"black"}},[_vm._v(" "+_vm._s(_vm.getIotIconOnDeviceType(data.lastVitalSignsSyncData.iotDeviceTypeCode))+" ")]),(typeof data.lastVitalSignsSyncData.remainingBattery === 'number')?_c('BatteryIcon',{attrs:{"battery-level":data.lastVitalSignsSyncData.remainingBattery}}):_vm._e()],1):_vm._e()]):_vm._e()]),_c('div',{staticClass:"d-flex",class:{
                  'flex-column justify-center': _vm.$vuetify.breakpoint.lgAndDown,
                  'align-center': _vm.$vuetify.breakpoint.xl,
                  'order-1': _vm.$vuetify.breakpoint.mdAndDown,
                  'order-2': _vm.$vuetify.breakpoint.lgAndUp,
                }},_vm._l((_vm.activityTypesCodes.filter((activityTypeCode) =>
                    [
                      _vm.activityTypes.APR,
                      _vm.activityTypes.CFR,
                      _vm.activityTypes.SAT,
                      _vm.activityTypes.RES,
                      _vm.activityTypes.BDT,
                    ].includes(activityTypeCode)
                  )),function(activityTypeCode,activityTypeIndex){return _c('div',{key:activityTypeIndex},[_c('IotRealtimeGridData',{attrs:{"activity-type-code":activityTypeCode,"data":data}})],1)}),0)],1),_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.mdAndDown ? 'flex-column ' : ''},[_c('v-divider',{class:_vm.$vuetify.breakpoint.mdAndDown ? 'second-divider-width ml-2 my-4' : '',attrs:{"vertical":_vm.$vuetify.breakpoint.lgAndUp}}),_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.lgAndDown ? 'flex-column ' : 'align-center'},_vm._l((_vm.activityTypesCodes.filter((x) =>
                    [_vm.activityTypes.WEI, _vm.activityTypes.GLY, _vm.activityTypes.FDE, _vm.activityTypes.INC].includes(x)
                  )),function(activityTypeCode,activityTypeIndex){return _c('div',{key:activityTypeIndex},[_c('IotRealtimeGridData',{attrs:{"activity-type-code":activityTypeCode,"data":data}})],1)}),0)],1)])])])],1)],1)}),_c('div',{staticClass:"d-flex justify-end align-center flex-wrap mt-4"},[_c('div',{staticClass:"d-flex align-center mr-7"},[_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.$t('resultByPage')))]),_c('div',{staticClass:"max-width-70 mx-7"},[_c('v-select',{staticStyle:{"font-size":"14px"},attrs:{"item-text":"name","item-value":"value","items":_vm.itemsPerPageOptions},on:{"change":_vm.changeItemsPerPage},model:{value:(_vm.paginationData.itemsPerPage),callback:function ($$v) {_vm.$set(_vm.paginationData, "itemsPerPage", $$v)},expression:"paginationData.itemsPerPage"}})],1),_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.getPaginationValuesText()))])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"primary","icon":"","disabled":_vm.disablePageButton(1)},on:{"click":function($event){return _vm.changePageTo(1)}}},[_c('v-icon',[_vm._v("mdi-page-first")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.disablePageButton(1)},on:{"click":function($event){return _vm.changePageTo(_vm.paginationData.currentPage - 1)}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"disabled":_vm.disablePageButton(_vm.getPaginationTotalPages()),"icon":""},on:{"click":function($event){return _vm.changePageTo(_vm.paginationData.currentPage + 1)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.disablePageButton(_vm.getPaginationTotalPages())},on:{"click":function($event){_vm.changePageTo(_vm.getPaginationTotalPages())}}},[_c('v-icon',[_vm._v("mdi-page-last")])],1)],1)])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card-title class="text-h5">
    <span ref="title" :aria-label="title">{{ title }}</span>
  </v-card-title>
</template>

<script>
import translation from '@/translationMixin';
export default {
  name: 'ModalTitle',
  mixins: [translation],
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

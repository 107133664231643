<template>
  <div>
    <h3 class="mt-3 mb-3">
      {{ $t('customizeTriggers') }}
    </h3>
    <v-form ref="triggerForm">
      <div v-for="(trigger, index) in selected.overriddenTriggers" :key="index">
        <v-row>
          <v-col cols="1" class="pt-7">
            <div v-if="selected.overridden && trigger.overriddenTrigger">
              <v-icon> mdi-pencil </v-icon>
            </div>
          </v-col>
          <v-col cols="auto">
            <div class="pt-4 ml-n5">
              <span class="font-weight-bold">{{ index + 1 }}.</span>
            </div>
          </v-col>
          <v-col>
            <DateTimePickerField
              ref="triggerTime"
              v-model="trigger.startTime"
              :label="$t('timeOnly')"
              required
              max="200"
              picker-type="time"
              class="align-suffix"
              @input="updateTrigger(selected, index)"
            />
          </v-col>
          <v-col>
            <div v-if="selected.overridden && trigger.overriddenTrigger" class="pt-4">
              <v-btn
                ref="resetTrigger"
                color="primary"
                class="shrink ml-3 mt-0"
                @click="resetOverriddenTriggerStatus(selected, index)"
              >
                {{ $t('resetValue') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import virtuoseMixin from '@/virtuoseMixin';
import patientActivityMixin from './patientActivityMixin';

export default {
  name: 'PatientActivityCustomizeAppointment',

  components: {},
  mixins: [translation, accessibility, virtuoseMixin, patientActivityMixin],

  props: {
    selected: {
      type: Object,
      default: () => {},
    },
    isTriggerMatch: {
      type: Function,
      required: true,
    },
    checkOverriddenTrigger: {
      type: Function,
      required: true,
    },
  },
};
</script>

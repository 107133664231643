import api from './baseApiService';

class HealthcareProfessionalService {
  async getHealthcareProfessionals() {
    return await api.get('healthworkers');
  }

  async getAvailableUsers() {
    return await api.get('healthworker/available_users');
  }

  async getHealthcareProfessionalById(healthcareProfessionalId) {
    return await api.get(`healthworker/${healthcareProfessionalId}`);
  }

  async createHealthcareProfessional(data) {
    return await api.post('healthworker/', data);
  }

  async updateHealthcareProfessional(healthcareProfessionalId, data) {
    return await api.put(`healthworker/${healthcareProfessionalId}/`, data);
  }

  async deleteHealthcareProfessional(healthcareProfessionalId) {
    return await api.delete(`healthworker/${healthcareProfessionalId}/delete/`);
  }

  async getHealthcareProfessionalTypes() {
    return await api.get('healthworkertypes');
  }

  async getPatientHealthcareProfessionals(patientId) {
    return await api.get(`patienthealthworkers/${patientId}`);
  }

  async updatePatientHealthcareProfessional(patientId, data) {
    return await api.put(`patienthealthworker/${patientId}/`, data);
  }
}

export default new HealthcareProfessionalService();

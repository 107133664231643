<template>
  <div>
    <!-- BASELINES SECTION -->
    <v-row>
      <v-col cols="auto">
        <v-icon class="mt-4">mdi-stethoscope</v-icon>
      </v-col>
      <v-col>
        <v-row dense>
          <v-col :cols="baselineTextFieldSizes((sm = 12), (md = 6), (lg = '20%'))">
            <v-text-field
              ref="height"
              v-model.number="formData.baselines.height"
              :label="$t('baselines.height')"
              filled
              type="number"
              hide-spin-buttons
              :rules="[validationRules.atLeast(0)]"
              suffix="cm"
            />
          </v-col>
          <v-col :cols="baselineTextFieldSizes((sm = 12), (md = 6), (lg = '20%'))">
            <v-text-field
              ref="weight"
              v-model.number="formData.baselines.weight"
              :label="$t('baselines.weight')"
              filled
              type="number"
              hide-spin-buttons
              :rules="[validationRules.atLeast(0)]"
              suffix="kg"
            />
          </v-col>
          <v-col :cols="baselineTextFieldSizes((sm = 12), (md = 4), (lg = '20%'))">
            <v-text-field
              ref="heartRate"
              v-model.number="formData.baselines.heartRate"
              :label="$t('baselines.heartRate')"
              filled
              type="number"
              hide-spin-buttons
              :rules="[validationRules.atLeast(0), validationRules.integer]"
              suffix="bpm"
            />
          </v-col>
          <v-col :cols="baselineTextFieldSizes((sm = 12), (md = 4), (lg = '20%'))">
            <v-text-field
              ref="systolic"
              v-model.number="formData.baselines.systolic"
              :label="$t('baselines.systolic')"
              filled
              type="number"
              hide-spin-buttons
              :rules="[validationRules.atLeast(0), validationRules.integer]"
              suffix="mmhg"
            />
          </v-col>
          <v-col :cols="baselineTextFieldSizes((sm = 12), (md = 4), (lg = '20%'))">
            <v-text-field
              ref="diastolic"
              v-model.number="formData.baselines.diastolic"
              :label="$t('baselines.diastolic')"
              filled
              type="number"
              hide-spin-buttons
              :rules="[validationRules.atLeast(0), validationRules.integer]"
              suffix="mmhg"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <!-- HEALTH INFO SECTION -->
      <v-col cols="auto">
        <v-icon class="mt-4">mdi-note-edit-outline</v-icon>
      </v-col>
      <v-col>
        <v-row dense>
          <v-col :cols="12">
            <v-textarea
              ref="medicalConditions"
              v-model="formData.healthInfo.medicalConditions"
              :label="$t('healthInfo.specificMedicalConditions')"
              filled
              rows="4"
              no-resize
              :rules="[validationRules.maxLength(textAreaMaxLengths.MEDICAL_CONDITIONS)]"
            />
          </v-col>
          <v-col :cols="12">
            <v-textarea
              ref="allergies"
              v-model="formData.healthInfo.allergies"
              :label="$t('healthInfo.allergies')"
              filled
              rows="4"
              no-resize
              :rules="[validationRules.maxLength(textAreaMaxLengths.ALLERGIES)]"
            />
          </v-col>
          <v-col :cols="12">
            <v-textarea
              ref="other"
              v-model="formData.healthInfo.other"
              :label="$t('healthInfo.other')"
              filled
              rows="4"
              no-resize
              :rules="[validationRules.maxLength(textAreaMaxLengths.OTHER)]"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import validationRulesMixin from '@/validationRulesMixin';
import { BaselinesFormData } from '@/components/Patient/BaselinesFormData';
import { HealthInfoFormData } from '@/components/Patient/HealthInfoFormData';

const TextAreaMaxLengths = {
  MEDICAL_CONDITIONS: 4000,
  ALLERGIES: 4000,
  OTHER: 4000,
};

export default {
  name: 'ClinicalDataTab',
  mixins: [translation, validationRulesMixin],
  props: {
    formDataProps: {
      type: Object,
      required: false,
      default: () => {
        return {
          baselines: new BaselinesFormData(),
          healthInfo: new HealthInfoFormData(),
        };
      },
    },
  },
  data() {
    return {
      formData: this.formDataProps,
      textAreaMaxLengths: TextAreaMaxLengths,
    };
  },
  methods: {
    baselineTextFieldSizes(sm, md, lg) {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return lg;
      }
      if (this.$vuetify.breakpoint.mdAndUp) {
        return md;
      }
      return sm;
    },
  },
};
</script>

<template>
  <v-container>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row>
      <v-col>
        <div class="page-title">
          {{ $t('cohorts') }}
        </div>
      </v-col>
    </v-row>

    <v-row v-if="$can(permissions.actions.add, permissions.subjects.cohort)">
      <v-col>
        <div>
          <v-btn ref="addCohort" color="primary" @click="editCohortAction(null)">
            {{ $t('add') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <DataGrid
          ref="dataGrid"
          :items="cohorts"
          :columns="columns"
          grid-name="cohort_grid"
          :show-select="false"
          :items-per-page="25"
          :data-loading="showWaitModal"
        >
          <!-- <template #item.autoUpdate="{ item }">
            <v-simple-checkbox v-model="item.autoUpdate" disabled />
          </template> -->
          <!-- <template #item.active="{ item }">
            <v-simple-checkbox v-model="item.active" disabled />
          </template> -->

          <template #item.actions="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn :ref="`actions_${item.id}`" icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="$can(permissions.actions.change, permissions.subjects.cohort)"
                  :ref="`editCohort_${item.id}`"
                  @click="editCohortAction(item.id)"
                >
                  <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                </v-list-item>
                <!-- <v-divider
                  v-if="
                    $can(permissions.actions.change, permissions.subjects.cohort) &&
                    $can(permissions.actions.delete, permissions.subjects.cohort)
                  "
                ></v-divider> -->

                <!-- <v-list-item
                  v-if="$can(permissions.actions.delete, permissions.subjects.cohort)"
                  :ref="`deleteCohort_${item.id}`"
                  @click="deleteAction(item.id)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </template>
        </DataGrid>
      </v-col>
    </v-row>

    <CohortModal v-if="showEditModal" :show.sync="showEditModal" :cohort-id="selectedCohortId" @refresh="init()" />

    <!-- <ConfirmationModal
      :question="$t('deleteCohortQuestion')"
      :show="showDelete"
      :title="$t('delete')"
      @cancel="showDelete = false"
      @ok="deleteSelectedCohort"
    /> -->
  </v-container>
</template>

<script>
import translationMixin from '@/translationMixin';
import DataGrid from '@/components/DataGrid.vue';
import CohortModal from './CohortModal.vue';
import CohortService from '@/services/cohortsService';
import permissionMixin from '@/auth/permissionMixin';

export default {
  name: 'CohortGrid',
  components: {
    DataGrid,
    CohortModal,
  },
  mixins: [translationMixin, permissionMixin],

  data() {
    return {
      showDelete: false,
      showEditModal: false,
      error: null,
      selectedCohortId: null,
      showWaitModal: false,
      cohorts: [],
    };
  },
  computed: {
    columns() {
      let columns = [
        {
          text: 'cohortName',
          value: 'name',
          filterable: true,
        },
        {
          text: 'description',
          value: 'description',
          filterable: true,
        },
        // {
        //   text: 'autoUpdate',
        //   value: 'autoUpdate',
        //   filterable: true,
        // },
        // {
        //   text: 'active',
        //   value: 'active',
        //   filterable: true,
        // },
        {
          text: 'createdDate',
          value: 'createdAt',
          filterable: true,
        },
        {
          text: 'createdBy',
          value: 'createdBy.username',
          filterable: true,
        },
        {
          text: 'modifiedDate',
          value: 'updatedAt',
          filterable: true,
        },
        {
          text: 'modifiedBy',
          value: 'updatedBy.username',
          filterable: true,
        },
      ];

      if (
        this.$can(this.permissions.actions.change, this.permissions.subjects.cohort)
        //|| this.$can(this.permissions.actions.delete, this.permissions.subjects.cohort)
      ) {
        columns.push({
          text: 'actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        });
      }
      return columns;
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    async init() {
      this.cohorts = [];
      this.showWaitModal = true;

      try {
        let cohorts = await CohortService.getCohorts();
        this.cohorts = cohorts.map((item) => {
          let result = item;
          return result;
        });
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    editCohortAction: function (id) {
      this.selectedCohortId = id;
      this.showEditModal = true;
    },

    // deleteAction: function (id) {
    //   this.selectedCohortId = id;
    //   this.showDelete = true;
    // },

    // async deleteSelectedCohort() {
    //   this.showDelete = false;
    //   this.showWaitModal = true;

    //   try {
    //     await CohortService.deleteCohort(this.selectedCohortId);
    //     await this.init();
    //   } catch (error) {
    //     this.error = error;
    //   }
    //   this.showWaitModal = false;
    // },
  },
};
</script>

<style scoped></style>

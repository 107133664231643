import axios from 'axios';
import AxiosUtils from './axiosUtils';

/**
 * ServiceOptions
 * @typedef {{useAccessToken: boolean}} ServiceOptions
 */

const defaultOptions = {
  useAccessToken: true,
};

/**
 * @param {ServiceOptions} options
 * @returns {ServiceOptions}
 */
function getOptions(options) {
  let result = {};
  Object.assign(result, defaultOptions, options);
  return result;
}

export class BaseService {
  /**
   * @param {string} baseURL
   * @param {ServiceOptions} options
   */
  constructor(baseURL, options) {
    this.axiosInstance = axios.create({ baseURL });
    this.options = getOptions(options);
  }

  async get(url, params = {}) {
    let config = { params };
    await this.setAccessToken(config);
    let response = await this.axiosInstance.get(url, config);
    return response?.data;
  }

  async delete(url) {
    let config = AxiosUtils.getDeleteConfig();
    await this.setAccessToken(config);
    let response = await this.axiosInstance.delete(url, config);
    return response?.data;
  }

  async post(url, data) {
    let config = AxiosUtils.getPostConfig();
    await this.setAccessToken(config);
    let response = await this.axiosInstance.post(url, data, config);
    return response?.data;
  }

  async put(url, data) {
    let config = AxiosUtils.getPostConfig();
    await this.setAccessToken(config);
    let response = await this.axiosInstance.put(url, data, config);
    return response?.data;
  }

  async setAccessToken(config) {
    if (this.options.useAccessToken) {
      await AxiosUtils.setAccessToken(config);
    }
  }
}

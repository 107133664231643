import { BaseApiService } from './baseApiService';

class ApplicationService {
  constructor() {
    this.service = new BaseApiService({ useAccessToken: false });
  }
  async getAuthConfig() {
    return await this.service.get('config/auth');
  }
  async getDemoFeatureConfig() {
    return await this.service.get('config/demofeature');
  }

  /**
   *
   * @returns {Promise<{"proximiteIsEnabled": boolean}>}
   */
  async getProximiteConfig() {
    return this.service.get('config/proximite');
  }

  async getPartnerSessionToken(partnerName) {
    return await this.service.get(`config/partnersessiontoken/${partnerName}`);
  }
}
export default new ApplicationService();

import { format } from 'date-fns';
import translationMixin from '@/translationMixin';
import { chartColor } from './chartColor';
import { ActivityTypes } from '@/components/PatientMonitoring/constants.js';

export default {
  mixins: [translationMixin],

  methods: {
    getChartTooltipTitle: function (data) {
      return format(
        new Date(data[0].raw.x),
        this.getLanguage() === 'fr' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd hh:mm:ss b'
      );
    },

    getChartTooltipLabel: function (label, value, chartUnit) {
      const spaceLabelValue = this.$t(chartUnit) === '%' || this.$t(chartUnit) === '°C' ? '' : ' ';
      return `${label}: ${value}` + spaceLabelValue + this.$t(chartUnit);
    },

    removeChartTime: function (currentDate, timeFilterType, timeFilterValue) {
      if (timeFilterType === 'days') {
        timeFilterValue *= 24;
      }

      let editedDate = new Date(currentDate.setHours(currentDate.getHours() - timeFilterValue));
      return format(editedDate, 'yyyy-MM-dd HH:mm:ss');
    },

    getChartZoomLimits: function (xMin, xMax) {
      return {
        x: {
          min: new Date(xMin).getTime(),
          max: new Date(xMax).getTime(),
        },
      };
    },

    getChartDisplayFormats: function (currentLanguage) {
      if (currentLanguage === 'fr') {
        return {
          hour: 'HH:mm',
        };
      }

      return {
        hour: 'h:mm b',
      };
    },

    getVitalSignsChartColors: function (activityTypeCode) {
      switch (activityTypeCode) {
        case ActivityTypes.APR:
          return '#4A94E6';

        case ActivityTypes.CFR:
          return '#4CAF50';

        case ActivityTypes.SAT:
          return '#F39308';

        case ActivityTypes.RES:
          return '#3F51B5';

        case ActivityTypes.BDT:
          return '#bb14bb';

        default:
          return '#69BCFF';
      }
    },

    getChartLegendTriggeredAlertValue: function () {
      return {
        label: this.$t('patientChartUnit.triggeredAlert'),
        borderColor: chartColor.incorrectDataColor,
        backgroundColor: chartColor.incorrectDataColor,
      };
    },

    getAnnotationContentForThreshold: function (threshold, thresholdIndex, lessOrGreater, color, unit) {
      return {
        type: 'line',
        mode: 'horizontal',
        borderDash: [13, 7],
        yMin: threshold,
        yMax: threshold,
        borderColor: color,
        borderWidth: 1,
        label: {
          display: false,
          drawTime: 'afterDatasetsDraw',
          backgroundColor: color,
          content: `${this.$t('patientChartUnit.thresholdValue')} ${lessOrGreater} ${threshold} ${unit}`,
        },
        enter: (ctx, event) => {
          this.showOrHideAnnotationTooltip(ctx, event, thresholdIndex, true);
        },
        leave: (ctx, event) => {
          this.showOrHideAnnotationTooltip(ctx, event, thresholdIndex, false);
        },
      };
    },
  },
};

<template>
  <div>
    <v-btn to="/" icon>
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <div class="mx-2">
      {{ $t(message) }}
    </div>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';

export default {
  name: 'Profile',
  mixins: [translation, accessibility],
  props: {},
  data() {
    return {
      message: '',
    };
  },

  created: function () {
    this.init();
  },

  methods: {
    defaultErrorCallBack: function (error) {
      this.error = error;
    },

    init: function () {
      this.message = this.$route.params.message;
    },
  },
};
</script>

<template>
  <v-dialog v-if="show && !isMfaActive" value="show" eager scrollable persistent max-width="600">
    <v-card>
      <v-card-title class="text-h5 word-break-normal"> {{ $t('mfaTitle') }}</v-card-title>

      <v-card-subtitle class="py-2">
        {{ $t('mfa.followSteps') }}
      </v-card-subtitle>
      <div style="margin-bottom: 20px"></div>

      <v-card-text>
        <v-row>
          <v-col cols="1">
            <span>
              <v-avatar color="primary" size="24"><span class="white--text font-weight-bold">1</span></v-avatar>
            </span>
          </v-col>
          <v-col cols="11">
            <span>{{ $t('mfa.downloadApp') }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="11" class="pa-0">
            <v-list flat dense class="pa-0">
              <v-list-item>
                <v-list-item-content>
                  <span
                    >{{ $t('mfa.androidDevices') }}:
                    <a :href="googleAuthenticatorLinks.android" target="_blank" rel="noopener noreferrer">{{
                      $t('mfa.googleAuthenticatorAndroid')
                    }}</a></span
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <span
                    >{{ $t('mfa.iOSDevices') }}:
                    <a :href="googleAuthenticatorLinks.iOS" target="_blank" rel="noopener noreferrer">{{
                      $t('mfa.googleAuthenticatorIOS')
                    }}</a></span
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1">
            <span class="pr-2">
              <v-avatar color="primary" size="24"><span class="white--text font-weight-bold">2</span></v-avatar>
            </span>
          </v-col>
          <v-col cols="11">
            <span class="wrap-newline">{{ $t('mfa.scanQRCode') }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="auto" class="py-0">
            <span class="py-4">
              <v-progress-circular
                v-if="isAssociationKeyLoading"
                indeterminate
                color="primary"
                class="card-width"
              ></v-progress-circular>
              <qrcode
                v-else-if="!isAssociationKeyLoading && associationKey"
                ref="qrcode"
                :value-input="associationKey"
              />
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1">
            <span class="pr-2">
              <v-avatar color="primary" size="24"><span class="white--text font-weight-bold">3</span></v-avatar>
            </span>
          </v-col>
          <v-col cols="11">
            <span>{{ $t('mfa.enterCode') }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              v-model="verificationCode"
              dense
              outlined
              :label="$t('mfaCode')"
              type="number"
              hide-spin-buttons
              :error-messages="isVerificationError ? $t('invalidCode') : null"
              @click="isVerificationError = false"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn text :disabled="isProcessing" @click="cancel">{{ $t('cancel') }}</v-btn>
        <v-btn text color="primary" :disabled="isProcessing" @click="activateMFA">{{ $t('mfa.activate') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationModal
    v-else-if="show && isMfaActive"
    :question="$t('mfa.confirmDeactivate')"
    :title="$t('mfa.deactivate')"
    :show="true"
    :is-disabled="isProcessing"
    @cancel="closeDialog"
    @ok="deactivateMFA"
  />
</template>

<script>
import translation from '@/translationMixin';
import auth from '@/auth/auth';
import Qrcode from '@/components/Qrcode.vue';

const DEFAULT_GOOGLE_AUTHENTICATOR_LINK_ANDROID =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&gl=CA';
const DEFAULT_GOOGLE_AUTHENTICATOR_LINK_IOS = 'https://apps.apple.com/ca/app/google-authenticator/id388497605';

export default {
  components: { Qrcode },
  mixins: [translation],
  props: {
    isMfaActive: {
      type: Boolean,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      googleAuthenticatorLinks: {
        android: null,
        iOS: null,
      },
      isProcessing: false,
      isAssociationKeyLoading: false,
      associationKey: null,
      verificationCode: null,
      isVerificationError: false,
    };
  },
  watch: {
    async show(newValue) {
      const userLanguage = this.getLanguage();
      this.googleAuthenticatorLinks.android = `${DEFAULT_GOOGLE_AUTHENTICATOR_LINK_ANDROID}&hl=${userLanguage}`;
      this.googleAuthenticatorLinks.iOS = `${DEFAULT_GOOGLE_AUTHENTICATOR_LINK_IOS}?l=${userLanguage}`;
      if (newValue && !this.isMfaActive) {
        this.isAssociationKeyLoading = true;
        this.associationKey = await auth.setupTOTP();
        this.isAssociationKeyLoading = false;
      } else if (!newValue) {
        this.reset();
      }
    },
  },
  methods: {
    reset() {
      this.isAssociationKeyLoading = false;
      this.associationKey = null;
      this.verificationCode = null;
      this.isVerificationError = false;
    },
    async activateMFA() {
      if (this.isProcessing) return;
      this.isProcessing = true;
      const isVerified = await auth.verifyTOTP(this.verificationCode);

      try {
        if (!isVerified) {
          this.isVerificationError = true;
        } else {
          const isMfaActive = await auth.activateMFA();
          this.$emit('update:isMfaActive', isMfaActive);
          this.closeDialog();
        }
      } finally {
        this.isProcessing = false;
      }
    },
    async deactivateMFA() {
      this.isProcessing = true;
      const isMfaDeactivated = await auth.deactivateMFA();
      const isMfaActive = !isMfaDeactivated;
      this.$emit('update:isMfaActive', isMfaActive);
      this.closeDialog();
    },
    cancel() {
      this.closeDialog();
    },
    closeDialog: function () {
      this.isProcessing = false;
      this.$emit('update:show', false);
    },
  },
};
</script>

<style scoped>
.word-break-normal {
  word-break: normal;
}
</style>

export const ActivityTypes = {
  /** Weight */
  WEI: 'WEI',
  /** Medication adherence */
  RXA: 'RXA',
  /** Arterial pressure */
  APR: 'APR',
  /** Saturation */
  SAT: 'SAT',
  /** Cardiac frequency */
  CFR: 'CFR',
  /** Incontinence */
  INC: 'INC',
  /** Fall detection */
  FDE: 'FDE',
  /** Body temperature */
  BDT: 'BDT',
  /** Respiratory rate */
  RES: 'RES',
  /** Dialogs (Anamnesis) */
  DLG: 'DLG',
  /** Appointment */
  APPT: 'APPT',
  /** Prescriptive learning */
  PRE: 'PRE',
  /** Video call */
  VID: 'VID',
  /** Photo */
  PHT: 'PHT',
  /** Capillary Blood */
  GLY: 'GLY',
};

export const StatusLevels = {
  /** Must change */
  CH: 'CH',
  /** Error */
  ERR: 'ERR',
  /** Wet */
  WE: 'WE',
  /** Not completed */
  NC: 'NC',
  /** Dry */
  DR: 'DR',
  /** No following */
  NF: 'NF',
  /** Alert */
  AL: 'AL',
  /** Warning + 24h */
  W24: 'W24',
  /** Warning + 4h */
  W4: 'W4',
  /** Normal */
  NO: 'NO',
  /** Warning */
  WA: 'WA',
};

export const IncontinenceSet = new Set([StatusLevels.CH, StatusLevels.ERR, StatusLevels.WE, StatusLevels.DR]);

export const StandardSet = new Set([
  StatusLevels.NF,
  StatusLevels.AL,
  StatusLevels.WA,
  StatusLevels.W4,
  StatusLevels.W24,
  StatusLevels.NO,
  StatusLevels.NC,
]);

export const TimeFilterType = {
  LAST24: '24h',
  DAYS: 'days',
  HOURS: 'hours',
  RANGE: 'range',
};

export const StatsDuration = {
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

export const IOTDeviceTypeCodes = {
  CAMERA: 'CA',
  XPILL: 'XP',
  RADAR: 'RD',
  VIRTUALASSISTANT: 'VA',
  BALANCE: 'BA',
  INCONTINENCE: 'IS',
  PATCH: 'CM',
  WATCH: 'WA',
};

export const MonitoringTypes = {
  CONVERSATIONS: 'conversations',
};

export const ConversationParticipantTypes = {
  HEALTHWORKER: 'healthworker',
  PATIENT: 'patient',
  CAREGIVER: 'caregiver',
};
